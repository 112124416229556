/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useRef } from 'react'
import { notification, Modal } from 'antd'
import PropTypes from 'prop-types'
import { updateBookAmentor } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'

const StatusModal = ({ record }) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([record])
  const [selectedMentee, setSelectedMentee] = useState({ record })

  const statusDisplay = useRef()

  const handleCancel = () => {
    setShowModal(false)
  }
  const handleStatusChange = async changedStatus => {
    try {
      setLoading(true)
      const data = await API.graphql(
        graphqlOperation(updateBookAmentor, {
          input: {
            id: record.id,
            status: changedStatus
          }
        })
      )
      setLoading(false)

      setData(prev =>
        prev.map(mentor =>
          mentor.id === data.data.updateBookAmentor.id
            ? { ...mentor, status: data.data.updateBookAmentor.status }
            : mentor
        )
      )
      setSelectedMentee({ ...selectedMentee, status: data.data.updateBookAmentor.status })
    } catch (err) {
      setLoading(false)
      notification.error({
        message: err?.message || 'Something went wrong!',
        duration: 10000
      })
    }
  }

  return (
    <div>
      <p
        onClick={() => setShowModal(true)}
        className='cursor-pointer w-full rounded-t text-black text-base hover:bg-gray-300 hover:text-black py-1 px-4 block whitespace-no-wrap'
      >
        <i className='fas fa-flask' /> Status
      </p>
      <Modal
        visible={showModal}
        className=' mb-16 pb-10'
        title={record?.fullName}
        onCancel={() => handleCancel()}
        footer={[]}
      >
        <section className='mx-auto max-w-xl'>
          <div>
            <div>
              <div className='text-lg items-center flex-col flex gap-y-2'>
                <span className='font-bold'>Status: </span>
                {loading ? (
                  <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                ) : (
                  <span ref={statusDisplay}>
                    {selectedMentee.status
                      ? selectedMentee.status
                      : selectedMentee.record.status
                      ? selectedMentee.record.status
                      : 'Not Started'}
                  </span>
                )}
              </div>
              <hr className='w-1/2 mx-auto my-5' />
              <div className='text-lg items-center font-bold flex-col flex gap-y-4'>
                Change Status:
                <span className='flex gap-x-4 gap-y-4 flex-wrap justify-around cursor-pointer'>
                  <button
                    onClick={() => handleStatusChange('In Progress')}
                    className='bg-red-800 text-white py-2 px-3'
                  >
                    <i className='fa fa-play mr-2' />
                    Start
                  </button>
                  <button
                    onClick={() => handleStatusChange('Paused')}
                    className='bg-red-800 text-white py-2 px-3'
                  >
                    <i className='fa fa-pause mr-2' />
                    Pause
                  </button>
                  <button
                    onClick={() => handleStatusChange('Done')}
                    className='bg-red-800 text-white py-2 px-3'
                  >
                    <i className='fa fa-stop mr-2' />
                    Stop
                  </button>
                </span>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </div>
  )
}
StatusModal.propTypes = {
  record: PropTypes.any
}

export default StatusModal
