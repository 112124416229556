/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBookAmentor = /* GraphQL */ `
  subscription OnCreateBookAmentor {
    onCreateBookAmentor {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const onUpdateBookAmentor = /* GraphQL */ `
  subscription OnUpdateBookAmentor {
    onUpdateBookAmentor {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const onDeleteBookAmentor = /* GraphQL */ `
  subscription OnDeleteBookAmentor {
    onDeleteBookAmentor {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const onCreateBookKidmentor = /* GraphQL */ `
  subscription OnCreateBookKidmentor {
    onCreateBookKidmentor {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const onUpdateBookKidmentor = /* GraphQL */ `
  subscription OnUpdateBookKidmentor {
    onUpdateBookKidmentor {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const onDeleteBookKidmentor = /* GraphQL */ `
  subscription OnDeleteBookKidmentor {
    onDeleteBookKidmentor {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const onCreateMentor = /* GraphQL */ `
  subscription OnCreateMentor {
    onCreateMentor {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateMentor = /* GraphQL */ `
  subscription OnUpdateMentor {
    onUpdateMentor {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteMentor = /* GraphQL */ `
  subscription OnDeleteMentor {
    onDeleteMentor {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookCourse = /* GraphQL */ `
  subscription OnCreateBookCourse {
    onCreateBookCourse {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookCourse = /* GraphQL */ `
  subscription OnUpdateBookCourse {
    onUpdateBookCourse {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookCourse = /* GraphQL */ `
  subscription OnDeleteBookCourse {
    onDeleteBookCourse {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBootcamp = /* GraphQL */ `
  subscription OnCreateBootcamp {
    onCreateBootcamp {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBootcamp = /* GraphQL */ `
  subscription OnUpdateBootcamp {
    onUpdateBootcamp {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBootcamp = /* GraphQL */ `
  subscription OnDeleteBootcamp {
    onDeleteBootcamp {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMentorForm = /* GraphQL */ `
  subscription OnCreateMentorForm {
    onCreateMentorForm {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMentorForm = /* GraphQL */ `
  subscription OnUpdateMentorForm {
    onUpdateMentorForm {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMentorForm = /* GraphQL */ `
  subscription OnDeleteMentorForm {
    onDeleteMentorForm {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactUs = /* GraphQL */ `
  subscription OnCreateContactUs {
    onCreateContactUs {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactUs = /* GraphQL */ `
  subscription OnUpdateContactUs {
    onUpdateContactUs {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactUs = /* GraphQL */ `
  subscription OnDeleteContactUs {
    onDeleteContactUs {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVisitor = /* GraphQL */ `
  subscription OnCreateVisitor {
    onCreateVisitor {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVisitor = /* GraphQL */ `
  subscription OnUpdateVisitor {
    onUpdateVisitor {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVisitor = /* GraphQL */ `
  subscription OnDeleteVisitor {
    onDeleteVisitor {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCareersForm = /* GraphQL */ `
  subscription OnCreateCareersForm {
    onCreateCareersForm {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCareersForm = /* GraphQL */ `
  subscription OnUpdateCareersForm {
    onUpdateCareersForm {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCareersForm = /* GraphQL */ `
  subscription OnDeleteCareersForm {
    onDeleteCareersForm {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat {
    onCreateChat {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat {
    onUpdateChat {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat {
    onDeleteChat {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWebinarAttendee = /* GraphQL */ `
  subscription OnCreateWebinarAttendee {
    onCreateWebinarAttendee {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWebinarAttendee = /* GraphQL */ `
  subscription OnUpdateWebinarAttendee {
    onUpdateWebinarAttendee {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWebinarAttendee = /* GraphQL */ `
  subscription OnDeleteWebinarAttendee {
    onDeleteWebinarAttendee {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookAdvisory = /* GraphQL */ `
  subscription OnCreateBookAdvisory {
    onCreateBookAdvisory {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookAdvisory = /* GraphQL */ `
  subscription OnUpdateBookAdvisory {
    onUpdateBookAdvisory {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookAdvisory = /* GraphQL */ `
  subscription OnDeleteBookAdvisory {
    onDeleteBookAdvisory {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
