/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { notification, Result } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { API, graphqlOperation } from 'aws-amplify'
import { updateBookAmentor } from '../graphql/mutations'

const BookMentorSuccess = () => {
  const location = useLocation()
  const { status, tx_ref } = qs.parse(location.search)
  const [loading, setLoading] = useState(true)
  const isSuccess = status === 'successful'

  const updateBookMentor = async () => {
    setLoading(true)
    const id = window.localStorage.getItem('book-mentor-id')
    try {
      await API.graphql(
        graphqlOperation(updateBookAmentor, {
          input: {
            id,
            payment: { status, txRef: tx_ref }
          }
        })
      )
      setLoading(false)
      window.localStorage.removeItem('book-mentor-id')
    } catch (error) {
      setLoading(false)
      notification.error({
        message: 'Something went wrong',
        description: 'Please Screenshot and report this error using the "Contact Us" form',
        duration: 6000
      })
    }
  }

  useEffect(() => {
    updateBookMentor()
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        height: '70vh',
        width: '100vw'
      }}
    >
      {loading && (
        <div className='h-screen fixed bg-black inset-0 bg-opacity-50 grid place-content-center'>
          <i className='fa fa-spin text-white fa-spinner text-xl' />
        </div>
      )}
      <Result
        status={isSuccess ? 'success' : 'error'}
        title={
          isSuccess
            ? 'Payment was successful!'
            : 'Payment was not successful but we will reach out to you soon'
        }
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -70%)'
        }}
        subTitle={
          <>
            <h2 className='text-base'>
              Thanks for booking a mentor with us, we have received your request
              <br /> Yes! thats how easy it is to book a mentor. We will reach out to you on how to
              get you started. So expect emails and call from us.
            </h2>
          </>
        }
        extra={[
          <Link
            to='/'
            key='console'
            className='font-manrope cursor-pointer duration-200 py-3 px-6 hover:bg-opacity-75 sm:mr-5 bg-green-600 text-white hover:text-white font-semibold rounded'
          >
            Back To Website
          </Link>
        ]}
      />
    </div>
  )
}

export default BookMentorSuccess
