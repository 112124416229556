import React, { createContext, Suspense, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import PropTypes from 'prop-types'

import NavBar from './NavBar'
import Footer from './Footer'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { listCourses, mentorByUserSubId } from '../graphql/queries'
import ScroltoTop from '../sharedComponents/ScroltoTop'
import Logout from './auth/Logout'
import { getFileURL } from '../services/amplify'
import { UserProvider } from '../context/UserContext'

import NotFound from '../pages/NotFound'
import SuccessPage from '../pages/SuccessPage'
import ForgotPassword from '../pages/ForgotPassword'
import BookKidMentorSuccess from '../pages/BookKidMentorSuccess'
import WeekendCampSuccess from '../pages/WeekendCampSuccess'
import SingleCourse from '../pages/SingleCourse'
import Chat from '../pages/Chat'
import Home from '../pages/Home'
import Landing from '../pages/Landing'
import Pricing from '../pages/Pricing'
import PaidCourses from '../pages/PaidCourses'
import ContactUs from '../pages/ContactUs'
import Courses from '../pages/Courses'
import Checkout from '../pages/Checkout'
import AddMentor from '../pages/AddMentor'
import BookMentor from '../pages/BookAmentor'
import Bootcamp from '../pages/Bootcamp'
import Signup from '../pages/Signup'
import Login from '../pages/Login'
import KidTech from '../pages/KidTech'
import WeekendCamp from '../pages/WeekendCamp'
import BookKidsMentor from '../pages/BookKidsMentor'
import DiscoverMentors from '../pages/DiscoverMentors'
import Policy from '../pages/policy'
import FeaturedKidCourses from '../pages/FeaturedKidCourses'
import BookMentorSuccess from '../pages/BookMentorSuccess'
import SingleFeaturedCourse from '../pages/SingleFeaturedCourse'
import SingleFeaturedKidCourse from '../pages/SingleFeaturedKidCourse'
import RegisterForWebinar from '../pages/RegisterForWebinar'

import WebinarAll from '../new-admin/WebinarAll'
import bookAdvisory from '../new-admin/BookAdvisory'
import FeaturedCourses from '../pages/FeaturedCourses'
import BootcampAdmin from '../new-admin/BootcampAdmin'
import BookMentorsAll from '../new-admin/BookMentorsAll'
import MenteeApplication from '../new-admin/MenteeApplication'
import kidsApplication from '../new-admin/KidsApplication'
import AdminContactUsList from '../new-admin/contactUs/ContactUs'
import AdminDashboard from '../admin/home'
import AdminLayout from '../admin/Layout'
import MentorDashboard from '../mentors/MentorsDashboard'
import EditProfile from '../mentors/pages/EditProfile'
import SingleMentor from '../mentors/pages/SingleMentor'
import Careers from '../pages/Careers'
import BootcampPaymentSuccess from '../pages/BootcampPaymentSuccess'
import PaymentInvoice from '../pages/PaymentInvoice'

const SumerCamp = React.lazy(() => import('../pages/SumerCamp'))
const AboutUs = React.lazy(() => import('../pages/AboutUs'))

export const CourseContext = createContext(null)

const WithCourses = ({ component: Component, isPrivate = false, ...rest }) => {
  const [courses, setCourses] = useState([])

  const getAllCourses = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listCourses))
      setCourses(data.listCourses)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getAllCourses()
    return () => courses
  }, [])

  return (
    <CourseContext.Provider value={courses}>
      {isPrivate ? (
        <PrivateRoutes {...rest} component={Component} />
      ) : (
        <Route {...rest} component={Component} />
      )}
    </CourseContext.Provider>
  )
}

const Routes = () => {
  const [userInfo, setUserInfo] = useState({
    data: {},
    loading: true,
    isAuthenticated: false,
    email: '',
    sub: ''
  })

  const fetchInitialUser = async () => {
    try {
      const userCredientails = await Auth.currentAuthenticatedUser()
      setUserInfo(current => ({
        ...current,
        isAuthenticated: true,
        email: userCredientails.attributes.email,
        sub: userCredientails.attributes.sub
      }))
      const fetchedUserInfo = await API.graphql(
        graphqlOperation(mentorByUserSubId, {
          userSubId: userCredientails.attributes.sub
        })
      )
      if (fetchedUserInfo.data.mentorByUserSubId.items.length > 0) {
        setUserInfo(current => ({
          ...current,
          data: fetchedUserInfo.data.mentorByUserSubId.items[0],
          loading: false
        }))
        if (fetchedUserInfo.data?.mentorByUserSubId.items[0].imageUrl) {
          const key = fetchedUserInfo.data?.mentorByUserSubId.items[0].imageUrl.key
          const url = await getFileURL(key)
          setUserInfo(current => ({
            ...current,
            data: {
              ...current.data,
              imageUrl: {
                key,
                url
              }
            },
            loading: false
          }))
        }
      } else {
        setUserInfo(current => ({
          ...current,
          loading: false,
          data: {}
        }))
      }
    } catch (error) {
      setUserInfo(prev => ({ ...prev, isAuthenticated: false, loading: false }))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchInitialUser()
    }, 2500)
  }, [])

  return (
    <UserProvider userInfo={userInfo} setUserInfo={setUserInfo}>
      <Suspense fallback={<h1>Loading...</h1>}>
        <NavBar />
        <Switch>
          <ScroltoTop>
            <Route exact path='/' component={Landing} />
            <Route exact path='/pricing' component={Pricing} />
            <WithCourses exact path='/checkout/:id' component={Checkout} />
            <PrivateRoutes exact path='/paid-courses' component={PaidCourses} />
            <Route exact path='/contact-us' component={ContactUs} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/kid-tech' component={KidTech} />
            <Route exact path='/success' component={SuccessPage} />
            <Route exact path='/book-mentor-payment' component={BookMentorSuccess} />
            <WithCourses exact path='/courses' component={Courses} />
            <Route exact path='/add-mentor' component={AddMentor} />
            <Route exact path='/privacy' component={Policy} />
            <Route exact path='/bootcamp' component={Bootcamp} />
            <Route exact path='/book-mentor' component={BookMentor} />
            <Route exact path='/kid-book-mentor' component={BookKidsMentor} />
            <Route exact path='/summer-camp' component={SumerCamp} />
            <Route exact path='/weekend-camp' component={WeekendCamp} />
            <Route exact path='/success-booking' component={BookKidMentorSuccess} />
            <Route exact path='/weekendcampsuccess' component={WeekendCampSuccess} />
            <Route exact path='/bootcamp-payment' component={BootcampPaymentSuccess} />
            <Route exact path='/discover-mentors' component={DiscoverMentors} />
            <Route exact path='/courses/:id' component={SingleCourse} />
            <Route exact path='/featured-courses' component={FeaturedCourses} />
            <Route exact path='/featured-kid-courses' component={FeaturedKidCourses} />
            <Route exact path='/featured-courses/:id' component={SingleFeaturedCourse} />
            <Route exact path='/featured-kid-courses/:id' component={SingleFeaturedKidCourse} />
            <Route exact path='/webinar' component={RegisterForWebinar} />
            <Route exact path='/payment-invoice' component={PaymentInvoice} />
            <Route exact path='/hiring' component={Careers} />
            <Route path='/chat' component={Chat} />
            <Route
              exact
              path='/admin-dashboard/'
              render={() => <Redirect to='/admin-dashboard/kid-mentees-application' />}
            />
            <Route exact path='/admin-dashboard/mentors-application' component={BookMentorsAll} />
            <Route
              exact
              path='/admin-dashboard/mentees-application'
              component={MenteeApplication}
            />
            <Route exact path='/admin-dashboard/webinar' component={WebinarAll} />
            <Route exact path='/admin-dashboard/bootcamp' component={BootcampAdmin} />
            <Route exact path='/admin-dashboard/advisory' component={bookAdvisory} />
            <Route exact path='/admin-dashboard/contact-us' component={AdminContactUsList} />
            <Route
              exact
              path='/admin-dashboard/kid-mentees-application'
              component={kidsApplication}
            />
            <WithCourses isPrivate path='/admin' component={AdminLayout} />
            <Route path='/mentor-dashboard/' component={MentorDashboard} />
            <Route exact path='/edit-profile' component={EditProfile} />
            <Route exact path='/admin-dashboard/' component={AdminDashboard} />
            <PrivateRoutes exact path='/home' component={Home} />
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/mentors/:id' component={SingleMentor} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot_password' component={ForgotPassword} />
            <Route exact path='/logout' component={Logout} />
          </ScroltoTop>
          <Route exact path='*' component={NotFound} />
        </Switch>
        <Footer />
      </Suspense>
    </UserProvider>
  )
}

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const [authState, setAuthState] = React.useState()
  const [user, setUser] = React.useState()

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        if (authState === AuthState.SignedIn && user) {
          window.localStorage.setItem('_usr', JSON.stringify(user.username))
        }
        return JSON.parse(window.localStorage.getItem('_usr')) !== null ? (
          <Component {...props} user={user} />
        ) : (
          <>
            <AmplifyAuthenticator usernameAlias='email' className='flex justify-center mt-20'>
              <AmplifySignIn headerText='Sign in to your account' slot='sign-in' />
            </AmplifyAuthenticator>
          </>
        )
      }}
    />
  )
}
WithCourses.propTypes = {
  component: PropTypes.any,
  isPrivate: PropTypes.bool
}
PrivateRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}
export default Routes
