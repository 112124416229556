import React from 'react'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

const FormMain = ({ isSubmmitting }) => {
  const { errors, touched, handleChange } = useFormikContext()

  return (
    <div id='kids-weekend'>
      <div className='mb-5'>
        <label className='text-gray-900 font-manrope text-base' htmlFor='parentName'>
          Parent’s Full Name
        </label>
        <input
          type='text'
          id='parentName'
          name='parentName'
          onChange={handleChange}
          className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
        />
        {errors.parentName && touched.parentName && (
          <small className='text-red-600'>{errors.parentName}</small>
        )}
      </div>
      <div className='grid mb-5 sm:grid-cols-2 gap-5'>
        <div className=''>
          <label className='text-gray-900 font-manrope text-base' htmlFor='parentEmail'>
            Parent’s Email Address
          </label>
          <input
            type='text'
            id='parentEmail'
            name='parentEmail'
            onChange={handleChange}
            className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
          />
          {errors.parentEmail && touched.parentEmail && (
            <small className='text-red-600'>{errors.parentEmail}</small>
          )}
        </div>
        <div className=''>
          <label className='text-gray-900 font-manrope text-base' htmlFor='parentPhone'>
            Parent’s Phone Number
          </label>
          <input
            type='text'
            id='parentPhone'
            name='parentPhone'
            onChange={handleChange}
            className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
          />
          {errors.parentPhone && touched.parentPhone && (
            <small className='text-red-600'>{errors.parentPhone}</small>
          )}
        </div>
      </div>
      <div className='mb-5'>
        <label className='text-gray-900 font-manrope text-base' htmlFor='noOfKids'>
          How many kids are you enroling?
        </label>
        <input
          type='number'
          name='noOfKids'
          onChange={handleChange}
          min={1}
          id='noOfKids'
          className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
        />
        {errors.noOfKids && touched.noOfKids && (
          <small className='text-red-600'>{errors.noOfKids}</small>
        )}
      </div>
      <div className='grid mb-5 sm:grid-cols-2 gap-5'>
        <div className=''>
          <label className='text-gray-900 font-manrope text-base' htmlFor='firstChildName'>
            First Child’s Full Name
          </label>
          <input
            type='text'
            id='firstChildName'
            name='firstChildName'
            onChange={handleChange}
            className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
          />
          {errors.firstChildName && touched.firstChildName && (
            <small className='text-red-600'>{errors.firstChildName}</small>
          )}
        </div>
        <div className=''>
          <label className='text-gray-900 font-manrope text-base' htmlFor='firstChildAge'>
            First Child’s Age
          </label>
          <input
            type='text'
            id='firstChildAge'
            name='firstChildAge'
            onChange={handleChange}
            className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
          />
          {errors.firstChildAge && touched.firstChildAge && (
            <small className='text-red-600'>{errors.firstChildAge}</small>
          )}
        </div>
      </div>
      <div className='mb-5'>
        <label className='text-gray-900 font-manrope text-base' htmlFor='firstChildInterest'>
          First Child’s Course of Interest (Optional)
        </label>
        <input
          type='text'
          id='firstChildInterest'
          name='firstChildInterest'
          onChange={handleChange}
          className='block w-full bg-gray-100 focus:border-blue-600 rounded text-base py-2 outline-none focus:shadow-outline-blue px-5 mt-2'
        />
        {errors.firstChildInterest && touched.firstChildInterest && (
          <small className='text-red-600'>{errors.firstChildInterest}</small>
        )}
      </div>
      <button className='w-full outline-none focus:outline-none mx-auto rounded-md duration-200 hover:bg-opacity-75 px-15 py-2 text-base bg-btn-primary text-white font-semibold block'>
        {isSubmmitting && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />} Register
      </button>
    </div>
  )
}
FormMain.propTypes = { isSubmmitting: PropTypes.bool }

export default FormMain
