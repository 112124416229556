import React, { useState } from 'react'
import cta from '../images/cta.png'
import PropTypes from 'prop-types'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const url =
  'https://mentortribes.us6.list-manage.com/subscribe/post?u=59a47045a4c546c7947fd1e14&amp;id=809323deb8'

const Subscribe = () => {
  return (
    <div className='w-full bg-black pt-10'>
      <div className='flex flex-wrap' style={{ backgroundColor: '#2F2F2F' }}>
        <div className='w-full py-14 px-4 md:px-40'>
          <div className='flex flex-wrap bg-white rounded-lg'>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />
            <div className='w-full md:w-1/3'>
              <img src={cta} className='mx-auto' alt='icon' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscribe

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const submitHandle = () => {
    console.log(email)
    if (!email) {
      setError('Email is required!')
    } else {
      if (email.indexOf('@') > -1) {
        onValidated({ EMAIL: email })
        setEmail('')
        setError('')
      } else {
        setError('Email is not valid!')
      }
    }
  }

  return (
    <div className='w-full md:w-2/3 pt-10 pb-6 px-6'>
      <div className='text-xl font-bold'>Subscribe to Newsletter</div>
      <div className='text-lg mt-1'>Stay Updated with news about Mentortribes</div>
      <div className='mt-2'>
        {status === 'sending' && <div className='text-blue-800 font-bold'>sending...</div>}
        {status === 'error' && <div className='text-red-800 font-bold'>{message} </div>}
        {error && <div className='text-red-800 font-bold'>{error} </div>}
        {!error && status === 'success' && (
          <div className='text-green-800 font-bold'>{message} </div>
        )}
      </div>
      <div className='mr-16 md:mr-20 flex'>
        <input
          className='inline w-full bg-gray-300 text-gray-900 mt-2 p-3 focus:outline-none focus:shadow-outline'
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          name='email'
          placeholder='Email address'
        />
        <button
          onClick={submitHandle}
          className='hidden md:inline text-base px-8 py-3 font-bold text-white hover:bg-gray-900 bg-gray-800 mt-2'
        >
          Subscribe
        </button>
      </div>
      <button
        onClick={submitHandle}
        className='block md:hidden text-base px-8 py-3 font-bold text-white hover:bg-gray-900 bg-gray-800 mt-2'
      >
        Subscribe
      </button>
    </div>
  )
}

CustomForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func
}
