import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { API, graphqlOperation } from 'aws-amplify'
// import image from '../images/bootcampImage.png'
import { listBootcamps } from '../graphql/queries'
import CountDownTimer from './CountDownTimer'
import ReactPlayer from 'react-player'

const BootcampHero = () => {
  const [totalRegistered, setTotalRegistered] = useState([])

  const getWindowDimensions = () => {
    const { innerWidth: width } = window
    return {
      width
    }
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const getTotal = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listBootcamps))
      const { items } = data.listBootcamps
      const amoutToZero = 10 - items.length
      const totalSlots = amoutToZero >= 0 ? amoutToZero : 0
      setTotalRegistered(totalSlots)
    } catch (error) {
      console.log({
        description: error?.errors ? error?.errors[0].message : 'Something went wrong'
      })
    }
  }
  useEffect(() => {
    getTotal()
  }, [])
  return (
    <div>
      <div style={{ background: '#FFF8F8' }} className='flex flex-nowrap p-3 w-full'>
        <div style={{ minWidth: '300px' }} className='xl:w-6/12 p-5 md:p-20'>
          <p className='font-extrabold font-manrope text-4xl md:text-5xl lg:text-6xl xl:text-7xl'>
            <span style={{ color: 'rgba(245, 103, 103, 0.97)' }}>5-weeks</span> Intensive Bootcamp
          </p>
          <p className='my-4 md:my-10 font-manrope text-sm sm:text-lg font-medium leading-relaxed'>
            Gain the exact set of skills employers are looking for and get access to learning
            resources, our network of mentors and a lot of growth opportunities. <br />{' '}
            <span className='font-bold'>Yes</span>, 5weeks is a race, but we will do our best Start
            by selecting 1 of 6 Top In-Demand Tech Skill to Learn and be willing to put in the time
            required
          </p>
          <Link
            activeClass='active'
            to='register'
            spy
            smooth
            offset={-150}
            duration={500}
            className='inline-block font-manrope text-sm sm:text-lg font-bold leading-relaxed py-2 md:py-3 mr-3 mb-3 w-44 md:w-56 text-center bg-red-800 hover:bg-red-100 text-white rounded-md hover:text-red-800'
          >
            Register
          </Link>
          <Link
            activeClass='active'
            to='watch-video'
            spy
            smooth
            offset={-140}
            duration={500}
            className='inline-block font-manrope text-sm sm:text-lg font-bold leading-relaxed py-2 md:py-3 mr-3 w-44 md:w-56 text-center bg-red-100 hover:bg-red-800 text-red-800 rounded-md hover:text-white'
          >
            Watch Video <i className='fa fa-video text-center pl-1' />
          </Link>
          <div className='flex flex-nowrap items-center gap-2 text-red-800 bg-red-100 p-1 sm:p-3 mt-4 rounded-lg'>
            <div style={{ transform: 'scale(2)' }} className='flex h-4 w-4 justify-center'>
              <i className='fas fa-exclamation-circle text-xs font-semibold m-auto' />
            </div>
            <p className='font-manrope text-sm sm:text-lg font-medium leading-relaxed'>
              With just 20k you could be on your way to that dream job,{'  '}
              <span className='inline font-bold'> {totalRegistered} slots left</span>
            </p>
          </div>
          <p className='p-1 sm:p-3 font-manrope text-sm sm:text-lg font-bold leading-relaxed'>
            Bootcamp begins{' '}
            <span className='text-base sm:text-xl'>
              1st of March 2022, <CountDownTimer date='03/1/2022' /> today.
            </span>
          </p>
        </div>
        <div style={{ minWidth: '600px' }} className='hidden xl:flex justify-center w-6/12'>
          {/* <img className='w-9/12 my-auto rounded-full' src={image} alt='Hero Image' /> */}
          <div className='w-9/12 h-9/12 border-8 bg-gray-800 border-red-300 my-auto rounded-full overflow-hidden'>
            {windowDimensions.width > 1279 ? (
              <ReactPlayer
                height='550px'
                width='650px'
                url='https://www.youtube.com/embed/Ze0g_Yc_Q5I'
                playing
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BootcampHero
