import React from 'react'
import { Link } from 'react-router-dom'
import { featuredCourses } from '../data/featured-courses'

const SomeCourses = () => {
  return (
    <div className='md:pl-4 bg-black pt-14'>
      <div className='flex flex-wrap'>
        <div className='w-full py-8'>
          <h1 className='font-sans font-bold text-center text-white text-3xl'>
            Some Courses we have Mentors for
          </h1>
          <p className='text-gray-400 text-center mb-0 text-xl my-4'>
            Choose a technology skill and we will provide you a dedicated mentor
          </p>
        </div>
      </div>
      <div className='container mt-10 mx-auto px-5 pb-10'>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10'>
          {featuredCourses.slice(0, 8).map(course => (
            <div className='pb-5' key={course.id}>
              <Link to={`/featured-courses/${course.id}`}>
                <figure className='h-52 relative group'>
                  <img
                    className='w-full h-full object-cover rounded-t-lg'
                    src={course.image}
                    alt='course-image'
                  />
                  <div className='absolute inset-0 bg-black grid place-content-center duration-200 opacity-0 group-hover:opacity-75 px-3 rounded-t-lg'>
                    <p className='text-gray-100 text-sm text-center'>
                      {course.programRightCaption}
                    </p>
                  </div>
                </figure>
              </Link>
              <h4 className='mt-3'>
                <Link
                  className='text-base text-blue-50 duration-200 font-semibold hover:text-blue-400'
                  to={`/featured-courses/${course.id}`}
                >
                  {course.title}
                </Link>
              </h4>
            </div>
          ))}
        </div>
        <h3 className='max-w-max ml-auto mt-5'>
          <Link
            className='text-base text-blue-50 duration-200 hover:text-blue-400'
            to='/featured-courses'
          >
            View More Courses
            <i className='fa fa-arrow-right ml-3' />
          </Link>
        </h3>
      </div>
    </div>
  )
}

export default SomeCourses
