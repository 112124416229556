import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { getVisitor } from '../graphql/queries'
import { createChat } from '../graphql/mutations'
import ChatForm from '../components/chat/ChatForm'
import ChatUI from '../components/chat/ChatUI'
import { notification } from 'antd'
import { Helmet } from 'react-helmet-async'

const Chat = () => {
  const [visitor, setVisitor] = useState({})
  const [chats, setChats] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [chatId, setChatId] = useState('')
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const history = useHistory()

  const fetchVisitor = async visitor => {
    try {
      const res = await API.graphql(
        graphqlOperation(getVisitor, {
          id: visitor.id
        })
      )
      setVisitor(res.data.getVisitor)
      setChats(
        res.data.getVisitor.chat.items.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
      )
      setLoading(false)
      if (!res.data.getVisitor) {
        window.localStorage.removeItem('visitor')
        setVisitor({})
      }
    } catch (error) {
      console.log('error', error.errors ? error.errors[0].message : error)
    }
  }

  const checkedForChat = mentorId => {
    return chats.find(chat => chat.mentorId === mentorId)
  }

  const createNewChat = async (visitorId, mentorId) => {
    try {
      const currentChat = checkedForChat(mentorId)
      if (currentChat) {
        history.push(`/chat/${currentChat.id}`)
      } else {
        const chatRes = await API.graphql(
          graphqlOperation(createChat, {
            input: {
              mentorId,
              visitorId
            }
          })
        )
        setVisitor(prev => ({
          ...prev,
          chat: {
            items: [...prev.chat.items, chatRes.data.createChat]
          }
        }))
        history.push(`/chat/${chatRes.data.createChat.id}`)
      }
    } catch (error) {
      notification.error({
        message: error.errors ? error.errors[0].message : 'Something went wrong!'
      })
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem('visitor')) {
      fetchVisitor(JSON.parse(window.localStorage.getItem('visitor')))
    }
  }, [])

  useEffect(() => {
    const visitorId = window.localStorage.getItem('visitor')
    const mentorId = new URLSearchParams(location.search).get('mentor')
    if (visitorId && !loading && !!mentorId) {
      createNewChat(visitor.id, mentorId)
    }
  }, [loading])

  return (
    <div>
      <Helmet>
        <meta name='description' content='If you have a question reach us via chat, easy!!!' />
        <title>Mentortribes | Chat</title>
      </Helmet>
      loading ? (
      <div className='h-screen grid place-content-center'>
        <i className='fa fa-spin fa-spinner text-xl' />
      </div>
      ) : {Object.keys(visitor).length > 0}? (
      <ChatUI setVisitor={setVisitor} chats={chats} setChatId={setChatId} visitor={visitor} />
      ) : (
      <ChatForm setChatId={setChatId} setVisitor={setVisitor} />)
    </div>
  )
}

export default Chat
