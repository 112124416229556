import { notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import BookAdvisoryTable from '../components/BookAdvisoryTable'
import { listBookAdvisorys } from '../graphql/queries'
import Layout from './Layout'

const WebinarAll = () => {
  const [bookAdvisory, setBookAdvisory] = useState([])
  const [loading, setLoading] = useState(true)

  const getBookAdvisory = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listBookAdvisorys))
      const { items } = data.listBookAdvisorys
      const listOfItems = items
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((a, index) => ({ ...a, sn: index + 1 }))
      setLoading(false)
      setBookAdvisory(listOfItems)
    } catch (error) {
      notification.error({
        description: error?.errors ? error?.errors[0].message : 'Something went wrong'
      })
    }
  }

  useEffect(() => {
    getBookAdvisory()
  }, [])

  return (
    <Layout>
      <main className='w-full flex-grow p-4'>
        <h2 className='text-2xl pb-4 flex items-center px-10 font-bold'>
          <i className='fas fa-list mr-3' /> Advisory Call Requests
        </h2>
        <BookAdvisoryTable loading={loading} attendees={bookAdvisory} />
      </main>
    </Layout>
  )
}

export default WebinarAll
