import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import Header from '../components/discover-mentors/Header'
import { listMentors } from '../graphql/queries'
import Mentor from '../components/discover-mentors/Mentor'
import { Skeleton } from 'antd'
import { Helmet } from 'react-helmet-async'

const DiscoverMentors = () => {
  const [mentors, setMentors] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchMentors = async () => {
    const res = await API.graphql(graphqlOperation(listMentors))
    setMentors(res.data.listMentors.items.filter(mentor => mentor.status === 'ACCEPTED'))
    setLoading(false)
  }

  useEffect(() => {
    fetchMentors()
  }, [])

  return (
    <div>
      <Helmet>
        <meta name='keywords' content='Find mentor, pick mentor' />
        <meta name='description' content='Meet our brilliant team of mentors.' />
        <title>Mentortribes | Our Mentors</title>
      </Helmet>
      <Header />
      <section className='pb-10'>
        <div className='container mx-auto px-5'>
          <h3 className='text-gray-900 font-manrope font-bold text-2xl'>Discover Mentors</h3>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5 gap-10 content-center'>
            {loading ? (
              <>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
                <div className=''>
                  <Skeleton.Input
                    style={{ width: 300, height: 300, borderRadius: '1rem' }}
                    active
                  />
                </div>
              </>
            ) : (
              mentors.map(mentor => <Mentor key={mentor?.id} mentor={mentor} />)
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default DiscoverMentors
