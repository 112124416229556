import React from 'react'
import groomYourKidsImage from '../../images/groom-your-kids.png'
import Eazy2conBtn from '../../sharedComponents/Eazy2conBtn'

const GroomYourKids = () => {
  return (
    <div className='bg-black pt-14'>
      <div className='grid md:grid-cols-2 grid-cols-1 bg-black gap-0 px-2 md:px-0'>
        <div className=''>
          <img src={groomYourKidsImage} alt='connecting users' className='w-full' />
        </div>
        <div className='w-ful md:px-16 pb-5 pt-20 bg-no-repeat bg-img-22'>
          <h1 className='font-sans px-5 md:px-0 font-bold text-center md:text-left text-dark text-3xl md:text-4xl'>
            Groom Your Kids In Tech
          </h1>
          <p className='text-lg text-dark py-4 md:py-6 text-justify leading-8 px-2'>
            The use of technology in early-childhood encourages development of hand-eye coordination
            and fine motor skills. One example of this is online games, many of which depend on the
            speed of movements. Many computer games also encourage attention to detail and the
            ability to put together clues that can solve a problem.
          </p>
          <Eazy2conBtn text='Book a Mentor' navigateByUrl='/kid-tech' />
        </div>
      </div>
    </div>
  )
}

export default GroomYourKids
