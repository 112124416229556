import React, { createContext } from 'react'

export const userContext = createContext()

// eslint-disable-next-line react/prop-types
export const UserProvider = ({ children, userInfo, setUserInfo }) => {
  return <userContext.Provider value={{ userInfo, setUserInfo }}>{children}</userContext.Provider>
}

export default UserProvider
