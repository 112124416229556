/* eslint-disable no-template-curly-in-string */
import { Form, Input, Button, Switch, Select, notification, Result } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createWebinarAttendee } from '../graphql/mutations'
import axios from '../services/axios'
import { currentWebinar } from '../services/webinar'
import PropTypes from 'prop-types'

const { Item } = Form
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!'
  }
}
const join =
  'We share insightful, trending tech related articles, right into your inbox. We promise not to spam'

const RegisterForWebinarForm = ({ mailSubscribe, status, message }) => {
  const [form] = Form.useForm()
  const [joinMailList, setJoinMailList] = useState(true)
  const [source, setSource] = useState('Other Medium')
  const [{ success, loading, errors }, setState] = useState({
    success: false,
    loading: false,
    errors: ''
  })

  const handleSubmit = async values => {
    setState(prev => ({ ...prev, loading: true }))
    const newValues = { ...values, joinMailList, source }
    try {
      const res = await API.graphql(graphqlOperation(createWebinarAttendee, { input: newValues }))
      if (res.data.createWebinarAttendee.id) {
        setState(prev => ({ ...prev, success: true, loading: false }))
        if (newValues.joinMailList) {
          mailSubscribe({
            email: newValues.email,
            firstName: newValues.fullName.substr(0, newValues.fullName.indexOf(' '))
          })
        }
        axios.post('/Eazy2con/webinar-success', {
          email: newValues.email,
          name: newValues.fullName,
          ...currentWebinar
        })
      } else if (res?.errors.length > 0) throw Error(res.errors[0].message)
    } catch (error) {
      setState({ error: true, success: false, loading: false })
      notification.error({
        message: 'Something went wrong, please Check your Network connection',
        duration: 5000
      })
    }
  }

  useEffect(() => {
    if (success) {
      notification.success({
        message: 'You are now registered, Please check your email for webinar information'
      })
    }
    if (errors) {
      message.error('A problem occured, please check your network connection ')
    }
    return () => {}
  }, [success, errors])

  return success ? (
    <Result
      status='success'
      title='Thanks for registering, Check your email and remember to add the date to your calendar!.'
    />
  ) : (
    <Form
      onFinish={handleSubmit}
      size='large'
      form={form}
      disabled
      className='md:grid md:grid-cols-2 gap-x-4 gap-y-0 flex flex-col'
      layout='vertical'
      validateMessages={validateMessages}
      style={{
        color: '#404445',
        fontFamily: 'Manrope',
        fontStyle: 'normal'
      }}
    >
      <Item
        className='p-0 m-0'
        rules={[
          {
            required: true
          }
        ]}
        name='fullName'
        label='Full Name'
      >
        <Input
          className='border border-gray-200 rounded'
          style={{ background: '#F4F4F4' }}
          placeholder='John Doe'
        />
      </Item>
      <Item
        className='p-0 m-0'
        name='phone'
        label='Phone'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input
          className='border border-gray-200 rounded'
          style={{ background: '#F4F4F4' }}
          placeholder='+1605000000'
        />
      </Item>
      <Item
        name='email'
        label='Email'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input
          className='border border-gray-200 rounded'
          style={{ background: '#F4F4F4' }}
          placeholder='example@gmail.com'
        />
      </Item>
      <Item
        className='p-0 m-0'
        name='source'
        label='How did you hear about us'
        rules={[
          {
            required: false
          }
        ]}
      >
        <Select defaultValue='Other Medium' bordered onChange={value => setSource(value)}>
          {['Other Medium', 'Facebook', 'Google Search', 'From a Friend', 'Social Media Post'].map(
            options => (
              <Select.Option
                className='border border-gray-200 rounded'
                key={options}
                value={options}
              >
                {options}
              </Select.Option>
            )
          )}
        </Select>
      </Item>
      <b style={{ fontSize: '1.1em' }}>Join our mailing list</b>
      <Item
        name='joinMailList'
        size='small'
        className='col-span-2 w-full'
        label={<span style={{ fontSize: '0.9em' }}>{join}</span>}
      >
        <Switch
          className='bg-red-900'
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked
          onChange={checked => setJoinMailList(checked)}
        />
      </Item>
      <Button
        loading={loading}
        htmlType='submit'
        className='col-span-2 w-full text-center h-15 mx-auto bg-red-900 hover:bg-red-800 active:bg-white hover:text-white text-white active:text-red-800 font-bold border border-red-700 rounded-lg'
      >
        Register
      </Button>
    </Form>
  )
}

export default RegisterForWebinarForm

RegisterForWebinarForm.propTypes = {
  mailSubscribe: PropTypes.any,
  status: PropTypes.any,
  message: PropTypes.any
}
