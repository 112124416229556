import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getFileURL } from '../../services/amplify'
import PropTypes from 'prop-types'

const Course = ({ course, isMentor, deleteCourse }) => {
  const { title, id } = course
  const [isDeleting, setDeleting] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const fetchURl = async () => {
    if (course?.image) {
      setImageUrl(await getFileURL(course?.image))
    }
  }

  useEffect(() => {
    fetchURl()
  }, [])
  return (
    <div className='bg-white rounded-lg group shadow duration-200 hover:shadow-md'>
      <Link className='block' to={`/courses/${id}`}>
        <figure className='m-0 relative p-0 h-40 bg-gray-200'>
          <img className='h-full rounded-t-lg block object-cover w-full' src={imageUrl} />
          <div className='inset-0 rounded-t-lg bg-black bg-opacity-50 absolute grid place-content-center opacity-0 group-hover:opacity-100 duration-200'>
            <button className='h-12 w-12 rounded-full border border-gray-50 focus:outline-none outline-none cursor-pointer grid place-content-center'>
              <i className='fa fa-play text-lg text-white' />
            </button>
          </div>
        </figure>
      </Link>
      <div className='px-2 pb-2'>
        <div className='flex justify-between pt-3'>
          <h6 className='font-manrope text-center pb-1 text-xs font-extrabold text-gray-600'>
            UI UX(Adobe XD)
          </h6>
          <h6 className='font-manrope text-center pb-1 text-xs font-extrabold text-gray-600'>
            12 weeks
          </h6>
        </div>
        <h3 style={{ textOverflow: 'ellipsis' }} className=''>
          <Link
            className='block text-base py-1 font-manrope text-gray-800 font-extrabold leading-6'
            to={`/courses/${id}`}
          >
            {title}
          </Link>
        </h3>
        {isMentor && (
          <div className=''>
            <Link
              to={`/mentor-dashboard/edit-course/${course.id}`}
              className='bg-btn-primary hover:bg-opacity-75 hover:text-white duration-75 py-1.5 px-10 mx-auto mt-2 text-xs font-manrope block max-w-max text-white rounded-sm focus:outline-none outline-none'
            >
              Edit Course
            </Link>
            <button
              onClick={() => deleteCourse(setDeleting, course.id)}
              className='border border-primary text-primary hover:bg-btn-primary hover:text-white mx-auto duration-75 py-1.5 px-5 mt-2 text-xs font-manrope flex items-center max-w-max rounded-sm focus:outline-none outline-none'
            >
              {isDeleting ? (
                <i className='fa fa-spinner fa-spin mr-3' />
              ) : (
                <i className='fa fa-trash mr-3' />
              )}
              Delete Course
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
Course.propTypes = {
  course: PropTypes.array,
  isMentor: PropTypes.bool,
  deleteCourse: PropTypes.func
}

export default Course
