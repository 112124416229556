import React from 'react'
import courseImage1 from '../../images/sumper-camp/course-1.jpeg'
import courseImage2 from '../../images/sumper-camp/course-2.jpeg'
import PropTypes from 'prop-types'

const Courses = ({ coursesRef }) => {
  return (
    <section ref={coursesRef} className='py-10'>
      <div className='container mx-auto px-5'>
        <h3 className='text-gray-900 text-3xl font-semibold text-center md:text-4xl'>
          Our Courses
        </h3>
        <p className='max-w-xs text-gray-800 font-manrope text-base text-center mx-auto'>
          Our kids friendly courses all fall under the following umbrellas.
        </p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mt-5'>
          <figure className='m-0 p-0'>
            <div
              style={{
                backgroundColor: '(0deg, rgba(0, 0, 0, 0.7)',
                backgroundImage: `url(${courseImage1})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundBlendMode: 'overlay'
              }}
              className='p-5 px-10 rounded-lg bg-brown-150'
            >
              <h4 className='text-white font-manrope text-xl mb-2 font-bold'>
                Courses Offered <span className='text-yellow-300'>(7-11years)</span>
              </h4>
              <ul>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Animation
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Computer and technical terms
                  mastery
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Fundamental of programming
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Simple game design
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Logic gates and number system
                </li>
              </ul>
            </div>
          </figure>
          <figure className='m-0 p-0'>
            <div
              style={{
                backgroundColor: '(0deg, rgba(0, 0, 0, 0.7)',
                backgroundImage: `url(${courseImage2})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundBlendMode: 'overlay'
              }}
              className='p-5 px-10 rounded-lg bg-brown-150'
            >
              <h4 className='text-white font-manrope text-xl mb-2 font-bold'>
                Courses Offered <span className='text-yellow-300'>(12-17years)</span>
              </h4>
              <ul>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Fundamentals of programming (Code
                  interactive apps that respond to user input and mouse clicks)
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Networking fundamentals
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Circuit boards and logic gates
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Create fun and playable games
                  like Yahtzee
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Photoshop, Animation and 3D
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Digital creator
                </li>
                <li className='list-none max-w-md text-gray-200 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-200' /> Website and development
                </li>
              </ul>
            </div>
          </figure>
        </div>
      </div>
    </section>
  )
}
Courses.propTypes = { coursesRef: PropTypes.any }

export default Courses
