import { notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import BookMentorsTable from '../components/BookMentorsTable'
import { listBookAmentors } from '../graphql/queries'
import { onDeleteBookAmentor } from '../graphql/subscriptions'
import Layout from './Layout'

const MenteeApplication = () => {
  const [bookMentors, setBookMentors] = useState([])

  const getBookAMentors = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listBookAmentors))
      const { items } = data.listBookAmentors
      const listOfItems = items
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((item, index) => {
          item.key = item.id
          item.sn = items.length - index
          return item
        })

      setBookMentors(listOfItems)
    } catch (error) {
      notification.error({
        description: error?.message
      })
    }
  }

  useEffect(() => {
    getBookAMentors()

    // Subscribe to creation of Todo
    const subscription = API.graphql(graphqlOperation(onDeleteBookAmentor)).subscribe({
      next: ({ value }) => {
        getBookAMentors()
      },
      error: error => console.warn(error)
    })

    // Stop receiving data updates from the subscription
    return () => subscription.unsubscribe()
  }, [])

  return (
    <Layout>
      <main className='w-full flex-grow p-4'>
        <h2 className='text-2xl pb-4 flex items-center px-10 font-bold'>
          <i className='fas fa-list mr-3' /> Mentees Applications
        </h2>
        <BookMentorsTable bookMentors={bookMentors} />
      </main>
    </Layout>
  )
}

export default MenteeApplication
