export const PRICISING_PLANS = [
  {
    id: 1,
    type: 'Individual',
    title:
      'Learning to code isn’t easy. Many learners struggle to stay motivated. Others don’t know if they’re headed in the right direction. We can help you simplify your learning process. guide your learning path, and provide additional learning resources that come from experience.',
    price: '₦45,000',
    color: 'bg-yellow-700',

    margin: 'mb-36',
    listOfPerks: [
      'Career guide',
      'Expose the concept of IT',
      '2Hrs daily one on one within your chosen goal'
    ]
  },
  {
    id: 2,
    price: '₦35,000',
    type: 'Small Group',
    color: 'bg-yellow-400',
    margin: 'mb-16',
    title:
      'Sign up for this plan for your kid to join a small group of 5 students max. If you are a parent we can help you,  We have a passion to support families to get the best out of their Children. The future we envision, the results we have achieved and the children we have helped transform makes us more confident. We can support you to inspire, motivate and prepare your children to have an edge in the tech space for life.',
    listOfPerks: [
      'Career guide',
      'Expose the concept of IT ',
      '2Hrs daily one on one within your chosen goal'
    ]
  },
  {
    id: 3,
    title:
      'If you are a leader in a school or an organization,Your kids could use some confidence boost by learning extra Technical and Soft skills outside of School. We can help you by providing expertise, inspiration, and support in preparing your students to be Tech leaders. Our Mentors will deliver high-standard mentoring through online sessions.',
    price: 'Contact Us',
    type: 'Organisation',
    color: 'bg-green-400',
    margin: 'mb-24',
    listOfPerks: ['Career guide', 'Hands-on Projects ', 'Internship opportunity']
  }
]
