import React, { useEffect, useState, useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { getMentor } from '../../graphql/queries'
import { userContext } from '../../context/UserContext'
import Course from '../components/Course'
import IntroVideo from '../components/IntroVideo'
import { getFileURL } from '../../services/amplify'
import { notification } from 'antd'
import BookSingleCourse from '../components/BookMentor'
import 'react-quill/dist/quill.bubble.css'
import Reviews from '../components/Reviews'

const SingleMentor = () => {
  const {
    params: { id }
  } = useRouteMatch()
  const [mentor, setMentor] = useState({})
  const [isBookFormOpen, setBookFormOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const { userInfo } = useContext(userContext)

  const fetchMentor = async () => {
    try {
      const res = await API.graphql(
        graphqlOperation(getMentor, {
          id
        })
      )
      if (res.data?.getMentor) {
        setMentor(res.data?.getMentor)
        if (res.data?.getMentor.imageUrl && res.data?.getMentor.imageUrl.key) {
          const key = res.data?.getMentor.imageUrl.key
          const url = await getFileURL(key)
          setMentor(prev => ({
            ...prev,
            imageUrl: {
              key,
              url
            }
          }))
        }
      } else {
        setMentor(null)
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: error.errors ? error.errors[0].message : 'Something went wrong!',
        duration: 10000
      })
    }
  }

  useEffect(() => {
    fetchMentor()
  }, [])

  return loading ? (
    <div className='h-screen grid place-content-center'>
      <i className='fa fa-spin fa-spinner text-xl' />
    </div>
  ) : mentor || !mentor?.status === 'ACCEPTED' ? (
    <div className='mt-14 pb-10'>
      <div className='container mx-auto px-5'>
        <div className='md:grid grid-cols-12 gap-10 gap-x-5'>
          <div className='col-span-5 lg:col-span-4 mb-10 pr-5 pb-5'>
            <div className='sticky top-20'>
              <figure className='m-0 p-0 flex justify-center mx-auto my-5'>
                <img
                  src={mentor?.imageUrl ? mentor.imageUrl.url : ''}
                  className='w-32 h-32 border-2 border-gray-200 p-1 object-cover rounded-full'
                  alt=''
                />
              </figure>
              <div>
                <h4 className='text-2xl text-center text-primary capitalize font-bold font-manrope'>
                  {mentor?.firstname} {mentor?.lastname}
                </h4>
                <p className='bg-gray-800 font-bold text-orange-500 max-w-max px-3 py-1 rounded mx-auto text-xs my-1 text-center capitalize mt-1 font-manrope'>
                  {mentor?.specialization}
                </p>
                <p className='text-base text-center capitalize mt-1 font-manrope'>
                  Lives in {mentor?.country}
                </p>
              </div>
              <div className='grid grid-cols-3 my-7 items-end'>
                <div>
                  <h4 className='text-xl text-center capitalize font-bold font-manrope'>30</h4>
                  <h6 className='text-sm text-center capitalize font-manrope'>Students</h6>
                </div>
                <div>
                  <h4 className='text-xl text-center capitalize font-bold font-manrope'>15k</h4>
                  <h6 className='text-sm text-center capitalize font-manrope'>Classes Taken</h6>
                </div>
                <div>
                  <h4 className='text-xl text-center capitalize font-bold font-manrope'>
                    {mentor?.courses?.items.length}
                  </h4>
                  <h6 className='text-sm text-center capitalize font-manrope'>Courses</h6>
                </div>
              </div>
              {!userInfo.loading &&
                (userInfo.isAuthenticated && mentor?.id === userInfo.data.id ? (
                  <div className='my-5'>
                    <Link
                      to='/edit-profile'
                      className='block px-7 py-2 cursor-pointer max-w-max font-manrope text-sm bg-primary hover:bg-opacity-75 duration-200 text-white hover:text-white rounded mx-auto'
                    >
                      <i className='far fa-user mr-3' />
                      Edit Profile
                    </Link>
                  </div>
                ) : (
                  <div className='my-5'>
                    <button
                      onClick={() => setBookFormOpen(true)}
                      className='block px-7 py-2 cursor-pointer outline-none focus:outline-none max-w-max font-manrope text-sm bg-primary hover:bg-opacity-75 duration-200 text-white rounded mx-auto'
                    >
                      Book trial Lesson
                    </button>
                    <Link
                      to={`/chat?mentor=${mentor.id}`}
                      className='block max-w-max px-5 py-1 focus:outline-none outline-none font-manrope text-sm border border-red-800 rounded duration-200 hover:bg-primary hover:text-white text-primary mx-auto mt-5'
                    >
                      <i className='far fa-comment-dots mr-3' />
                      Send Message
                    </Link>
                  </div>
                ))}
              <div className='mt-5 border-t border-gray-200 max-w-xs mx-auto'>
                <h3 className='font-manrope mt-2 text-center py-2 font-semibold text-gray-900'>
                  {mentor?.email}
                </h3>
                <ul className='flex mt-2 justify-center'>
                  {mentor?.facebookUrl && (
                    <li>
                      <a
                        href={mentor?.facebookUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        title='Facebook'
                        className='text-gray-600 text-base duration-200 hover:text-blue-400'
                      >
                        <i className='fa fa-facebook' />
                      </a>
                    </li>
                  )}
                  {mentor?.linkedInUrl && (
                    <li className='ml-4'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={mentor?.linkedInUrl}
                        title='LinkedIn'
                        className='text-gray-600 text-base duration-200 hover:text-blue-400'
                      >
                        <i className='fa fa-linkedin' />
                      </a>
                    </li>
                  )}
                  {mentor?.github && (
                    <li className='ml-4'>
                      <a
                        rel='noopener noreferrer'
                        href={mentor?.github}
                        target='_blank'
                        title='Github'
                        className='text-gray-600 text-base duration-200 hover:text-blue-400'
                      >
                        <i className='fa fa-github' />
                      </a>
                    </li>
                  )}
                  {mentor?.dribbleUrl && (
                    <li className='ml-4'>
                      <a
                        href={mentor?.dribbleUrl}
                        target='_blank'
                        title='Dribble'
                        rel='noopener noreferrer'
                        className='text-gray-600 text-base duration-200 hover:text-blue-400'
                      >
                        <i className='fa fa-dribbble' />
                      </a>
                    </li>
                  )}
                  {mentor?.otherLinkUrl && (
                    <li className='ml-4'>
                      <a
                        href={mentor?.otherLinkUrl}
                        target='_blank'
                        title='Website'
                        rel='noopener noreferrer'
                        className='text-gray-600 text-base duration-200 hover:text-blue-400'
                      >
                        <i className='fa fa-globe' />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className='col-span-7 lg:col-span-8'>
            {mentor?.video && (
              <div className='mb-5'>
                <h4 className='font-manrope font-bold text-orange-800 mb-2 text-2xl'>
                  Intro Video
                </h4>
                <div className='max-w-3xl mx-auto'>
                  <IntroVideo video={mentor?.video} />
                </div>
              </div>
            )}
            <div>
              <h4 className='font-manrope font-bold text-orange-800 mb-2 text-2xl'>
                About {mentor?.firstname}
              </h4>
              <div
                id='single-mentor-bio'
                className='text-base font-semibold mb-4 leading-7 font-manrope text-gray-900'
                dangerouslySetInnerHTML={{ __html: mentor?.bio }}
              />
            </div>
            <div className='mt-5'>
              <h4 className='font-manrope font-bold text-orange-800 mb-2 text-2xl py-1'>Courses</h4>
              <div className='grid grid-cols-1 ssm:grid-cols-2 xl:grid-cols-3 gap-5'>
                {mentor?.courses?.items.map(course => (
                  <Course key={course.id} course={course} />
                ))}
              </div>
            </div>
            <Reviews />
          </div>
        </div>
      </div>
      <BookSingleCourse
        mentor={mentor}
        isBookFormOpen={isBookFormOpen}
        setBookFormOpen={setBookFormOpen}
      />
    </div>
  ) : (
    <div className='h-screen grid place-content-center'>
      <h1 className='text-3xl font-manrope text-primary'>404, Oops not Found</h1>
      <h1 className='text-3xl font-manrope font-semibold text-primary'>Mentor does not exist</h1>
      <Link
        to='/'
        className='bg-primary text-white py-1.5 text-sm block w-full max-w-max px-5 mx-auto mt-2 hover:bg-opacity-50 duration-200 hover:text-white rounded-md'
      >
        <i className='fa fa-home mr-3' />
        Go Home
      </Link>
    </div>
  )
}

export default SingleMentor
