import React, { useState } from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const PasswordInput = ({ label, id, ...props }) => {
  const [field, meta] = useField(props)
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className='mt-5'>
      <div className='flex items-center'>
        <div className='w-full'>
          <span className='text-sm text-black'>{label}</span>

          <div className='relative mt-2'>
            <input
              className='text-base block w-full bg-gray-300 border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:shadow-outline focus:bg-white'
              type={showPassword ? 'text' : 'password'}
              {...field}
              {...meta}
            />
            <button
              onClick={() => setShowPassword(!showPassword)}
              type='button'
              className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
            >
              {showPassword ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
            </button>
          </div>
        </div>
      </div>
      {meta.touched && meta.error && <small className='text-red-500'>{meta.error}</small>}
    </div>
  )
}
PasswordInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string
}

export default PasswordInput
