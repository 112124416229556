import React, { useState, useEffect } from 'react'
import EmailModal from './EmailModal'
import StatusModal from './StatusModal'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { notification } from 'antd'

const DropDownBtn = ({
  record,
  deleteModelType,
  status,
  visible,
  setVisible,
  selectedMentee,
  handleMenteeStatus
}) => {
  const [hidden, setHidden] = useState(true)

  async function deleteApplication(id) {
    try {
      const result = await API.graphql(graphqlOperation(deleteModelType, { input: { id } }))
      if (result.data) {
        notification.success({
          message: 'Deleted successfully'
        })
      }
    } catch (error) {
      notification.error({
        description: error?.message || 'An error occured'
      })
    }
  }

  useEffect(() => {
    return () => {
      setHidden(true)
    }
  }, [setHidden])

  ///
  return (
    <div className='dropdown block md:inline-block md:mr-6 mr-10 relative mb-6 md:mb-0'>
      <button
        onClick={() => setHidden(!hidden)}
        className='w-full items-center bg-red-600 text-xs text-center py-1 px-5 rounded-sm shadow-lg hover:shadow-xl text-white uppercase'
      >
        <span className='mx-auto w-full inline-flex items-center'>
          Action
          <i className='fas fa-chevron-down ml-2' />
        </span>
      </button>
      <ul
        onMouseLeave={() => setHidden(true)}
        className={` ${
          hidden ? 'hidden' : ''
        } w-full dropdown-menu z-50 rounded-sm bg-gray-200 border-gray-300 border absolute text-black pt-1`}
      >
        <li>
          <EmailModal email={record.email} />
        </li>
        <li>
          <StatusModal record={record} />
        </li>
        <li>
          <p
            onClick={() => deleteApplication(record.id)}
            className='w-full text-black text-base hover:bg-gray-300 hover:text-black py-1 px-4 block whitespace-no-wrap cursor-pointer'
          >
            <i className='far fa-trash-alt' /> Delete
          </p>
        </li>
      </ul>
    </div>
  )
}

DropDownBtn.propTypes = {
  record: PropTypes.object.isRequired,
  deleteModelType: PropTypes.string.isRequired,
  status: PropTypes.string,
  setVisible: PropTypes.func,
  visible: PropTypes.bool,
  selectedMentee: PropTypes.any,
  handleMenteeStatus: PropTypes.func
}

export default DropDownBtn
