import React from 'react'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import exclamationIcon from '../../images/pricing/exclamation.svg'

const MentorsFaq = ({ currentFaq }) => {
  const { Panel } = Collapse

  return (
    <div
      className={`bg-white pb-5 ${currentFaq === 3 ? 'block' : 'hidden'}`}
      style={{ borderRadius: '1rem' }}
    >
      <div className='p-5'>
        <div className='flex'>
          <div className='flex flex-col items-center justify-center mr-5'>
            <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
              <img src={exclamationIcon} alt='exclamation' />
            </figure>
          </div>
          <div>
            <h4 className='text-black text-xl font-semibold'>Mentors</h4>
            <p className='text-gray-900'>You can find answers relating to mentors enquries here</p>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        className='pl-10 bg-white border-0'
        expandIconPosition='right'
        expandIcon={item =>
          item.isActive ? (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-minus' />
            </span>
          ) : (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-plus' />
            </span>
          )
        }
      >
        <Panel className='text-lg font-manrope' header='How can I become a mentor?' key='15'>
          <p className='text-base text-gray-600 mb-4'>
            To become a mentor, you need to prove to us that you have the passion to teach and
            learn. Secondly, you must have the required experience for a given course of interest;
            either as a junior mentor or a senior mentor.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='Is there any way to assess our mentors?'
          key='5'
        >
          <p className='text-base text-gray-600 mb-4'>
            Yes! By enrolling in our free trial course. This will give you the opportunity to assess
            our dedicated junior and intermediate mentors while our professional mentors are only
            available in the paid course.
          </p>
          <p className='text-base text-gray-600'>
            See how committed we are in our training as we teach you industrial standards.
          </p>
        </Panel>

        <Panel className='text-lg font-manrope' header='How can I pay for a mentorship?' key='6'>
          <p className='text-base text-gray-600 mb-4'>
            Mentortribes provides several payment methods, depending on your country and location.
            You can also reach out to our support in our contact us page to guide you in any of our
            available payment methods that are best for you.
          </p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='What if I don’t like the mentor assigned to me?'
          key='7'
        >
          <p className='text-base text-gray-600'>
            Your happiness is our concern, any time you are not confortable with the mentor assigned
            to you, feel free to request for change of mentor provided the request was made with a
            genuine reason. Kindly drop a message for the support team in our contact us page and we
            will look into it immediately.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='What if I need a special mentorship package?'
          key='11'
        >
          <p className='text-base text-gray-600 mb-4'>
            We understand our students have their differences and things keeping them busy. For
            special packages like learning at your own pace, taking intermittent breaks in the
            learning process, choosing a unique learning time, group mentorship, which to have
            access to the best mentors in the industry, etc.
          </p>
          <p className='text-base text-gray-600'>Kindly contact our support team for Fee Advice.</p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='Is there any package available for children?'
          key='12'
        >
          <p className='text-base text-gray-600 mb-4'>
            Yes! Children still fall under special packages because they need special attention and
            continuous engagement.
          </p>
          <p className='text-base text-gray-600'>
            We have amazing mentors that are familiar with coaching little children in order to
            manage distractions coming from them during the learning process. Our mentors help to
            boost their self- esteem and confidence in problem solving by supporting independence
            and persistence in the face of initial difficulties.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='What if i want a mentor for just few hours or days?'
          key='13'
        >
          <p className='text-base text-gray-600 mb-4'>
            We can also give you a quick mentor based on your need. We understand that few folks
            want someone to unblock them in a project. Yes you can request someone to help unblock
            while you also learn how they did it.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='What other benefits do I stand to gain?'
          key='16'
        >
          <p className='text-base text-gray-600 mb-4'>
            One in one mentorship gives us a better understanding of you. It allows us to understand
            your passion, learning style and character; and to strongly recommend you to any
            company.{' '}
          </p>
          <p className='text-base text-gray-600'>
            Team mentorship on the other hand provides basic understanding of what you; except when
            the student has some unique characteristics or exceptional skills. Outstanding students
            will have a strong recommendation letter and resume forwarded to companies for any job
            opportunities.
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='Is it only online mentorship?' key='17'>
          <p className='text-base text-gray-600 mb-4'>
            Yes! Firstly, this is the future and that is what we stand for. Most jobs today are
            fully remote and continuous online coding activities get you ready for remote jobs.
          </p>
          <p className='text-base text-gray-600'>
            Secondly, most of our super amazing mentors come from different locations within and
            outside the country and we don&apos;t want location to be a barrier in rendering quality
            mentorship.
          </p>
        </Panel>
      </Collapse>
    </div>
  )
}

MentorsFaq.propTypes = {
  currentFaq: PropTypes.number.isRequired
}

export default MentorsFaq
