import React from 'react'
import MentortribesBtn from '../../sharedComponents/MentortribesBtn'

const Heading = () => {
  return (
    <div className='md:pl-4 bg-black py-20' id='home-header'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/2 md:pl-6 mt-4 md:mt-20 px-4'>
          <h1
            style={{ lineHeight: '4.5rem' }}
            className='max-w-md text-white font-sans font-bold text-center md:text-left text-3xl md:text-6xl'
          >
            Build A More Powerful You!
          </h1>
          <p className='text-lg md:text-xl text-white py-4 md:py-6 text-justify leading-9'>
            We understand how important it is to “follow who know road”, this is why we are
            connecting learners with the top 5% of technology experts in the industry to help them
            become masters.
          </p>

          <MentortribesBtn text='Book a mentor' navigateByUrl='/book-mentor' />
          <MentortribesBtn
            className='animate-bounce border'
            text='Kids Weekend Coding'
            navigateByUrl='/weekend-camp'
          />
        </div>
      </div>
    </div>
  )
}

export default Heading
