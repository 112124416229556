import { notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import BootcampTable from '../components/BootcampTable'
import { listBootcamps } from '../graphql/queries'
import Layout from './Layout'

const BootcampAdmin = () => {
  const [bootcamp, setBootcamp] = useState([])
  const [loading, setLoading] = useState(true)

  const getbootcamp = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listBootcamps))
      const { items } = data.listBootcamps
      const listOfItems = items
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((a, index) => ({ ...a, sn: index + 1 }))
      setLoading(false)
      setBootcamp(listOfItems)
    } catch (error) {
      notification.error({
        description: error?.errors ? error?.errors[0].message : 'Something went wrong'
      })
    }
  }

  useEffect(() => {
    getbootcamp()
  }, [])

  return (
    <Layout>
      <main className='w-full flex-grow p-4'>
        <h2 className='text-2xl pb-4 flex items-center px-10 font-bold'>
          <i className='fas fa-list mr-3' /> Bootcamp Attendees
        </h2>
        <BootcampTable loading={loading} attendees={bootcamp} />
      </main>
    </Layout>
  )
}

export default BootcampAdmin
