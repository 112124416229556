import React, { useState, useEffect, useContext } from 'react'
import { ShoppingCartOutlined } from '@ant-design/icons'
import Rating from '../components/Rating'
import PropTypes from 'prop-types'
import { CourseContext } from '../components/Routes'
import { API, graphqlOperation } from 'aws-amplify'
import { createBookCourse } from '../graphql/mutations'
import { message, notification } from 'antd'
import { useHistory } from 'react-router-dom'

const Checkout = ({ match }) => {
  const { id } = match.params

  const [course, setCourse] = useState({})
  const { items: dbCourses } = useContext(CourseContext)
  const [details, setDetails] = useState({})
  const [isDisabled, setIsDisabled] = useState(true)
  const history = useHistory()

  // TODO: FIX SUBMIT
  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const { data } = await API.graphql(
        graphqlOperation(createBookCourse, {
          input: { ...details, courseId: course.id }
        })
      )
      if (data.createBookCourse.courseId) {
        message.success('Sucessful, we will contact you soon', 6, () => history.push('/courses'))
      }
    } catch (error) {
      notification.error({
        message: 'Network error',
        description: 'Network error occured please refresh on check your connection'
      })
    }
  }

  const handleChange = ({ target }) => {
    setDetails({ ...details, [target.name]: target.value })
  }

  const handleDisable = ({ target }) => {
    setIsDisabled(!target.checked)
  }

  useEffect(() => {
    if (dbCourses) {
      const c = dbCourses.find(c => c.id.toString() === id)
      setCourse(c)
    }
  }, [id, dbCourses])

  const { title, topics, rating, students = '100' } = course

  return (
    <>
      <div className='grid grid-cols-6 gap-1 sm:gap-4 py-6 sm:py-12 bg-gray-200'>
        <div className='col-span-8 mx-3 sm:col-start-2 sm:col-span-4'>
          <div className='md:flex'>
            <div className='mt-4 md:mt-0 md:ml-6'>
              <div className='uppercase tracking-wide text-3xl sm:text-4xl text-brown-500 font-bold '>
                <ShoppingCartOutlined className='text-3xl sm:text-6xl' />{' '}
                <span>MentorTribes Billings</span>
              </div>

              <p className='mt-2 text-lg sm:text-xl text-gray-600'>
                Here, we ensure you get the best with respect to your payment. Enroll today to enjoy
                our super amazing mentors.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-10 gap-6 py-12 bg-gray-100 px-8'>
        {/* span 4 */}
        <div className='col-span-12 sm:col-start-2 sm:col-span-4'>
          <section className='mx-auto max-w-xl'>
            <div className='px-2'>
              <h2 className='font-bold text-xl text-brown-500 tracking-normal'>
                {title && title.toUpperCase()}
              </h2>
              <div className='flex justify-left items-center'>
                <Rating rating={rating} students={students} />
              </div>
              <hr />
            </div>
            <div className='py-2'>
              <ol className='list-decimal mx-5'>
                {topics && topics.map((topic, keys) => <li key={keys}>{topic}</li>)}
              </ol>
            </div>
          </section>
        </div>

        {/* span 4 */}
        <div className='col-span-12 sm:col-span-4'>
          <h1 className='text-2xl text-black font-bold'>YOUR INFORMATION</h1>
          <form onSubmit={handleSubmit}>
            <label className='block'>
              <span className='text-gray-700'>Full Name</span>
              <input
                className='text-black form-input mt-1 block w-full'
                placeholder='Jane Doe'
                onChange={handleChange}
                name='menteeName'
              />
            </label>
            <label className='block mt-5'>
              <span className='text-gray-700'>Phone Number</span>
              <input
                className='text-black form-input mt-1 block w-full'
                placeholder='xxxx xxx xxxx'
                onChange={handleChange}
                required
                name='phone'
              />
            </label>
            <label className='block mt-5'>
              <span className='text-gray-700'>E-mail</span>
              <input
                className='text-black form-input mt-1 block w-full'
                placeholder='example@gmail.com'
                onChange={handleChange}
                required
                name='email'
              />
            </label>
            {/*
            <label className='block mt-4'>
              <span className='text-gray-700'>Requested Limit</span>
              <select className='text-black form-select mt-1 block w-full'>
                <option>$1,000</option>
                <option>$5,000</option>
                <option>$10,000</option>
                <option>$25,000</option>
              </select>
            </label> */}

            <div className='flex mt-6 mb-4'>
              <label className='flex items-center'>
                <input
                  onClick={handleDisable}
                  required
                  // checked={handleDisable}
                  type='checkbox'
                  className='form-checkbox text-brown-500'
                />
                <span className='ml-2 text-gray-500'>
                  I agree to the <span className='underline'>privacy policy</span>
                </span>
              </label>
            </div>
            <label>
              <button
                // onClick={handleSubmit}
                // onSubmit={handleSubmit}
                type='submit'
                disabled={isDisabled}
                className={`bg-transparent ${
                  isDisabled && 'cursor-not-allowed'
                } hover:bg-red-800 active:bg-red-900 text-brown-500 font-semibold hover:text-white py-2 px-8 border border-red-800 hover:border-transparent rounded`}
              >
                schedule a call
              </button>
            </label>
          </form>
        </div>
      </div>
    </>
  )
}

export default Checkout

Checkout.propTypes = {
  match: PropTypes.object
}
