import React, { useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createMessage } from '../../graphql/mutations'
import { notification } from 'antd'
import PropTypes from 'prop-types'

const SendMessageForm = ({ visitor, id }) => {
  const [text, setText] = useState('')

  return (
    <div className='bg-white py-4 pt-3 px-5 shadow '>
      <form
        onSubmit={async e => {
          e.preventDefault()
          try {
            if (text.trim()) {
              await API.graphql(
                graphqlOperation(createMessage, {
                  input: { text, userId: visitor.id, chatId: id }
                })
              )
              setText('')
            }
          } catch (error) {
            notification.error({
              message: error.errors ? error.errors[0].message : 'Something went wrong!'
            })
          }
        }}
        className='border-t border-gray-300 pt-3'
      >
        <div className='relative'>
          <input
            type='text'
            onChange={e => setText(e.target.value)}
            name='text'
            value={text}
            placeholder='Write Something'
            className='py-2 px-5 pr-15 text-sm text-gray-600 duration-200 focus:shadow-outline-blue focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100 rounded-full w-full block round'
          />
          <button className='bg-primary text-white absolute top-0 right-0  w-10 h-10 rounded-full grid place-content-center'>
            <i className='fa fa-paper-plane' />
          </button>
        </div>
      </form>
    </div>
  )
}
SendMessageForm.propTypes = {
  visitor: PropTypes.any,
  id: PropTypes.string
}

export default SendMessageForm
