import React, { useEffect, useState } from 'react'
import {
  Form,
  notification,
  Input,
  Select,
  DatePicker,
  Button,
  Typography,
  message,
  Result,
  Popconfirm
} from 'antd'
import { API, graphqlOperation } from 'aws-amplify'

import { createBookAmentor, updateBookAmentor } from '../graphql/mutations'
import { getBookAmentor } from '../graphql/queries'
import { Link, useLocation, useHistory } from 'react-router-dom'
import axios from '../services/axios'
import PaymentButton from '../components/Payment'
import { Helmet } from 'react-helmet-async'

const { Item } = Form
const MEMBERSHIP_PLANS = [
  { name: 'Trial', price: '$0' },
  { name: 'Individual', price: '34,999' },
  { name: 'Family', price: '99,999' }
]

const BookKidsMentor = () => {
  const registeredId = window.localStorage.getItem('kid-book-mentor-id')
  const history = useHistory()
  const [form] = Form.useForm()
  const [{ loading, errors, isSubmitted, isTrial, email, name, phone, amountToPay }, setState] =
    useState({
      loading: false,
      errors: '',
      isSubmitted: false,
      email: '',
      name: '',
      phone: '',
      amountToPay: '',
      isTrial: false
    })
  const [currentPlan, setCurrentPlan] = useState({})
  const { state } = useLocation()

  const handleSubmit = async values => {
    const menteeInfo = {
      ...values,
      startEndDate: { start: values.startDate?._d, end: values.endDate?._d }
    }
    const { startDate, endDate, ...others } = menteeInfo
    setState(prev => ({ ...prev, loading: true }))
    try {
      const { data } = await API.graphql(graphqlOperation(createBookAmentor, { input: others }))
      if (data.createBookAmentor.id) {
        await axios.post('/Eazy2con/book-mentor-success', {
          fullName: others?.fullName,
          email: others?.email,
          phone: others?.phone,
          plan: others?.type,
          date: `I want to start from ${others.startEndDate.start} - ${others.startEndDate.end} `,
          courseInterest: others?.course,
          age: values?.age
        })
        let amount = 0
        if (values.type === 'Trial') {
          setState(prev => ({
            ...prev,
            isTrial: true
          }))
        } else if (values.type === 'Individual') {
          amount = 34999
        } else if (values.type === 'Family') {
          amount = 99999
        }

        setState(prev => ({
          ...prev,
          loading: false,
          isSubmitted: true,
          email: values?.email,
          phone: values?.phone,
          name: values?.fullName,
          amountToPay: amount
        }))
        if (values.type !== 'Trial') {
          window.localStorage.setItem('kid-book-mentor-id', data.createBookAmentor.id)
        }
      }
    } catch (error) {
      setState({ error: true, loading: false })
      notification.error({
        message: 'Something went wrong, please your Network connection',
        duration: 5000
      })
    }
  }

  const handleClick = async () => {
    const id = window.localStorage.getItem('kid-book-mentor-id')
    try {
      await API.graphql(
        graphqlOperation(updateBookAmentor, {
          input: {
            id: id,
            transferOption: true
          }
        })
      )
    } catch (error) {
      console.error(error)
    }
    history.push('/payment-invoice', {
      email,
      name,
      amount: amountToPay,
      item: 'Mentor for Kid'
    })
  }

  const confirmClose = () => {
    window.localStorage.removeItem('kid-book-mentor-id')
    history.push('/kid-book-mentor')
  }

  const cancelClose = () => {
    // Do Nothing
  }

  const getDetails = async id => {
    try {
      const { data } = await API.graphql(graphqlOperation(getBookAmentor, { id: id }))
      const { fullName, phone, email, type } = data.getBookAmentor
      let amount = 0
      if (type === 'Trial') {
        setState(prev => ({
          ...prev,
          isTrial: true
        }))
      } else if (type === 'Individual') {
        amount = 34999
      } else if (type === 'Family') {
        amount = 99999
      }

      setState(prev => ({
        ...prev,
        email: email,
        phone: phone,
        name: fullName,
        amountToPay: amount
      }))
    } catch (error) {
      console.error('Something went wrong', error)
    }
  }

  useEffect(() => {
    if (registeredId) {
      getDetails(registeredId)
    }
  }, [registeredId])

  useEffect(() => {
    if (errors) {
      message.error('A problem occured, can you check your network connection ')
    }
    return () => {}
  }, [errors])

  useEffect(() => {
    if (state && state.type) {
      const currentPlan = MEMBERSHIP_PLANS.find(({ name }) => name === state.type)
      setCurrentPlan(currentPlan)
    } else {
      setCurrentPlan(MEMBERSHIP_PLANS[2])
    }
  }, [state, currentPlan])

  return (
    <>
      <Helmet>
        <meta
          name='keywords'
          content='Mentor for my kids, kid mentor, teach my kids, teach children'
        />
        <meta
          name='description'
          content='Get your kids a mentor to coach them through their developmental stage'
        />
        <title>Mentortribes | Book Kids Mentor</title>
      </Helmet>
      <div>
        {isSubmitted || registeredId ? (
          isTrial ? (
            <div>
              <Result
                status='success'
                title='You have Successfully Booked a Trial'
                subTitle={
                  <>
                    <h2 className='text-base'>
                      We are sure to win you over during these few days, Thanks for booking a trial
                      with us.
                    </h2>
                  </>
                }
                extra={[
                  <Link
                    to='/'
                    key='console'
                    className='font-manrope cursor-pointer duration-200 py-3 px-6 hover:bg-opacity-75 sm:mr-5 bg-green-600 text-white hover:text-white font-semibold rounded'
                  >
                    Back To Website
                  </Link>
                ]}
              />
            </div>
          ) : (
            <div>
              <Result
                className='h-heroSize flex flex-col justify-center items-center'
                status='success'
                title={
                  <span className=''>
                    {registeredId
                      ? `Welcom back ${name}`
                      : 'Successfully Registered, we will reach out to you soon'}
                  </span>
                }
                subTitle={
                  <>
                    <h2 className=' text-base'>
                      Thanks for booking a mentor for your kid/s, we have received your request
                      <br /> So expect emails or call from us.
                    </h2>
                    <p className=''>Thanks</p>
                    <div className='sm:flex justify-center mt-5'>
                      <PaymentButton
                        details={{
                          email: email,
                          amount: amountToPay,
                          name: name,
                          phone: phone,
                          buttonLabel: 'Pay now',
                          redirectUrl: '/success-booking'
                        }}
                      />
                      <button
                        className='px-10 py-2 block w-full max-w-max mx-auto sm:mx-0 font-manrope mt-5 sm:mt-0 cursor-pointer duration-200 hover:bg-opacity-75 bg-btn-primary text-white font-semibold rounded'
                        onClick={handleClick}
                      >
                        Pay Later
                      </button>
                    </div>
                  </>
                }
                extra={
                  <div className=''>
                    <Popconfirm
                      title='Closing this window will terminate your Booking'
                      onConfirm={confirmClose}
                      onCancel={cancelClose}
                      okText='Yes, Cancel my Booking'
                      cancelText='No, Continue to Payment'
                    >
                      <a
                        href='#'
                        className='px-6 py-2 bg-red-700 hover:bg-red-800 font-semibold rounded-md'
                      >
                        Cancel Booking
                      </a>
                    </Popconfirm>
                  </div>
                }
              />
            </div>
          )
        ) : (
          <div className='w-full h-full bg-dark-primary py-16'>
            <div className='md:w-1/2 w-full mx-auto px-5 p-10 my-16 bg-gray-100'>
              <Typography.Title className='text-center'>Book a Mentor for Kid</Typography.Title>
              <p className='text-center'>
                We are fully remote and have qualified tutors to help your kids learn tech skills in
                realtime and not just watch videos
              </p>
              <Form
                onFinish={handleSubmit}
                size='large'
                form={form}
                className='md:grid md:grid-cols-2 gap-4 flex flex-col '
                layout='vertical'
              >
                <Item
                  rules={[
                    {
                      required: true,
                      message: 'Please input full name'
                    }
                  ]}
                  name='fullName'
                  label='Full Name'
                >
                  <Input placeholder='John Doe' />
                </Item>
                <Item
                  name='phone'
                  label='Phone'
                  rules={[
                    {
                      required: true,
                      message: 'Please input phone'
                    }
                  ]}
                >
                  <Input placeholder='+234901234567' />
                </Item>
                <Item
                  name='email'
                  label='Email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input email address'
                    }
                  ]}
                >
                  <Input placeholder='example@gmail.com' />
                </Item>
                <Item
                  name='type'
                  label='Membership Plan'
                  tooltip={
                    <p>
                      See{' '}
                      <Link to='/kid-tech' className='text-blue-400'>
                        pricing
                      </Link>{' '}
                      for details
                    </p>
                  }
                  initialValue={currentPlan.name}
                  rules={[
                    {
                      required: true,
                      message: 'Select membership type'
                    }
                  ]}
                >
                  <Select
                    placeholder={
                      <div className='flex justify-between'>
                        <span>{currentPlan.name}</span>
                        <span>{currentPlan.price}</span>
                      </div>
                    }
                  >
                    {MEMBERSHIP_PLANS.map(({ name, price }) => (
                      <Select.Option key={name} value={name}>
                        <div className='flex justify-between'>
                          <span>{name}</span>
                          <span>₦ {price}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
                <Item
                  name='course'
                  label='What do you want your kid to learn?'
                  rules={[
                    {
                      required: true,
                      message:
                        'Please input the course you want to learn or put n/a if booking for kids'
                    }
                  ]}
                >
                  <Input placeholder='Put N/A if you have no specific tech skill' />
                </Item>
                <Item
                  name='image'
                  label='Please specify the age bracket of your child/children (optional)'
                >
                  <Select placeholder='7 - 12 years'>
                    <Select.Option value='7 - 12 years'>7 - 12 years</Select.Option>
                    <Select.Option value='12 - 17 years'>7 - 17 years</Select.Option>
                  </Select>
                </Item>
                <Item
                  name='startDate'
                  label='When do you want to Start?'
                  rules={[
                    {
                      required: true,
                      message: "Tell us when you're comfortable to start"
                    }
                  ]}
                >
                  <DatePicker className='w-full' placeholder='Start Date' format='YYYY-MM-DD' />
                </Item>
                <Item
                  name='endDate'
                  label='When do you want to Stop?'
                  rules={[
                    {
                      required: true,
                      message: 'Please tell us when you want to End'
                    }
                  ]}
                >
                  <DatePicker className='w-full' placeholder='End Date' format='YYYY-MM-DD' />
                </Item>
                <Button
                  loading={loading}
                  htmlType='submit'
                  className='col-span-2 w-40 mx-auto bg-transparent hover:bg-red-800 active:bg-red-900 text-brown-500 font-semibold hover:text-white px-8 border border-red-800 hover:border-transparent rounded'
                >
                  Book
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BookKidsMentor
