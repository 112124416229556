import React from 'react'
import { Result } from 'antd'
import PropTypes from 'prop-types'

const SuccessPage = ({ location }) => {
  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        width: '100vw'
      }}
    >
      <Result
        status='success'
        title='Successfully Registerd, we will reach out to you soon'
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -70%)'
        }}
        subTitle={
          <>
            <h2 className='text-base'>
              Thanks for booking a mentor from us, we have received your request
              <br /> Yes! thats how easy it is to book a mentor. We will reach out to you for
              payments and steps to get you started. So expect emails and call from us.
            </h2>
            <p>Thanks {location.state?.name}</p>
          </>
        }
      />
    </div>
  )
}
SuccessPage.propTypes = {
  location: PropTypes.object
}

export default SuccessPage
