/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react'
import { Form, Typography, message, notification, Result, Popconfirm } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { createBookAmentor, updateBookAmentor } from '../graphql/mutations'
import { getBookAmentor } from '../graphql/queries'
import BookMentorsForm from '../components/BookMentorsForm'
import axios from '../services/axios'
import { useLocation, Link, useHistory } from 'react-router-dom'
import PaymentButton from '../components/Payment'
import { Helmet } from 'react-helmet-async'

const MEMBERSHIP_PLANS = [
  { name: 'Trial', price: '0' },
  { name: 'Team', price: '35k' },
  { name: 'Personal', price: '49k - 75k' },
  { name: 'Premium', price: '99k - 299k' }
]

const BookMentor = () => {
  const registeredId = window.localStorage.getItem('book-mentor-id')
  const handleClick = async () => {
    const id = window.localStorage.getItem('book-mentor-id')
    try {
      await API.graphql(
        graphqlOperation(updateBookAmentor, {
          input: {
            id: id,
            transferOption: true
          }
        })
      )
    } catch (error) {
      console.error(error)
    }
    history.push('/payment-invoice', {
      email,
      name,
      amount: amountToPay,
      item: 'Mentor'
    })
  }
  const history = useHistory()
  const [form] = Form.useForm()
  const [whatToLearn, setWhatToLearn] = useState('')

  const getDetails = async id => {
    try {
      const { data } = await API.graphql(graphqlOperation(getBookAmentor, { id: id }))
      const { fullName, phone, email, type } = data.getBookAmentor
      let amount = 0
      if (type === 'Trial') {
        setState(prev => ({
          ...prev,
          isTrial: true
        }))
      } else if (type === 'Team') {
        amount = 35000
      } else if (type === 'Personal') {
        amount = 50000
      } else {
        amount = 200000
      }

      setState(prev => ({
        ...prev,
        email: email,
        phone: phone,
        name: fullName,
        amountToPay: Number(amount)
      }))
    } catch (error) {
      console.error('Something went wrong', error)
    }
  }

  const confirmClose = () => {
    window.localStorage.removeItem('book-mentor-id')
    history.push('/book-mentor')
  }

  const cancelClose = () => {
    // Do Nothing
  }

  const [{ loading, errors, isSubmitted, isTrial, email, name, phone, amountToPay }, setState] =
    useState({
      loading: false,
      errors: '',
      isSubmitted: false,
      email: '',
      name: '',
      phone: '',
      amountToPay: '',
      isTrial: false
    })
  const [currentPlan, setCurrentPlan] = useState({})
  const { state } = useLocation()

  const handleSubmit = async values => {
    let course = values.course === 'Other' ? values.detailCourseName : values.course
    const menteeInfo = {
      ...values,
      course: course,
      startEndDate: { start: values.startDate?._d, end: values.endDate?._d }
    }
    const { startDate, endDate, ...others } = menteeInfo
    setState(prev => ({ ...prev, loading: true }))
    try {
      const res = await API.graphql(graphqlOperation(createBookAmentor, { input: others }))
      if (res.data.createBookAmentor.id) {
        await axios.post('/Eazy2con/book-mentor-success', {
          fullName: others?.fullName,
          email: others?.email,
          phone: others?.phone,
          plan: others?.type,
          date: `I want to start from ${others.startEndDate.start} - ${others.startEndDate.end} `,
          courseInterest: others?.course
        })
        let amount = 0
        if (others.type === 'Trial') {
          setState(prev => ({
            ...prev,
            isTrial: true
          }))
        } else if (others.type === 'Team') {
          amount = 35000
        } else if (others.type === 'Personal') {
          amount = 50000
        } else {
          amount = 200000
        }

        setState(prev => ({
          ...prev,
          success: true,
          loading: false,
          isSubmitted: true,
          email: others?.email,
          phone: others?.phone,
          name: others?.fullName,
          amountToPay: amount
        }))
        if (others.type !== 'Trial') {
          window.localStorage.setItem('book-mentor-id', res.data.createBookAmentor.id)
        }
      } else if (res?.errors.length > 0) throw Error(res.errors[0].message)
    } catch (error) {
      setState({ error: true, success: false, loading: false })
      notification.error({
        message: 'Something went wrong, please check your Network connection',
        duration: 5000
      })
    }
  }

  useEffect(() => {
    if (errors) {
      message.error('A problem occured, can you check your network connection ')
    }
    return () => {}
  }, [errors])

  useEffect(() => {
    if (registeredId) {
      getDetails(registeredId)
    }
  }, [registeredId])

  useEffect(() => {
    if (state && state.type) {
      const currentPlan = MEMBERSHIP_PLANS.find(({ name }) => name === state.type)
      setCurrentPlan(currentPlan)
    } else {
      setCurrentPlan(MEMBERSHIP_PLANS[2])
    }
  }, [state, currentPlan])

  return (
    <div className='w-full h-full bg-dark-primary py-16'>
      <Helmet>
        <meta
          name='keywords'
          content='Book a mentor, learn javascript, learn frontend and backend, learn backend'
        />
        <meta
          name='description'
          content='Get yourself a mentor to guide you through your journey'
        />
        <title>Mentortribes | Book a Mentor</title>
      </Helmet>
      <div className='md:w-1/2 w-full mx-auto px-5 p-10 my-5 bg-gray-100'>
        {registeredId ? (
          <Typography.Title className='text-center'>Welcome back {name}</Typography.Title>
        ) : (
          <Typography.Title className='text-center'>Book a Mentor</Typography.Title>
        )}
        {isSubmitted && registeredId === false ? (
          <h2 className='text-center text-lg'>
            Want to book a mentor for your kid ?{' '}
            <Link className='text-primary' to='/kid-tech'>
              click here
            </Link>
          </h2>
        ) : (
          ''
        )}
        {isSubmitted || registeredId ? (
          isTrial ? (
            <div>
              <Result
                status='success'
                title='You have Successfully Booked a Trial'
                subTitle={
                  <>
                    <h2 className='text-base'>
                      We are sure to win you over during these few days, Thanks for booking a trial
                      with us.
                    </h2>
                  </>
                }
                extra={[
                  <Link
                    to='/'
                    key='console'
                    className='font-manrope cursor-pointer duration-200 py-3 px-6 hover:bg-opacity-75 sm:mr-5 bg-green-600 text-white hover:text-white font-semibold rounded'
                  >
                    Back To Website
                  </Link>
                ]}
              />
            </div>
          ) : (
            <div>
              <Result
                status='success'
                title={
                  <span className=''>Successfully Registered, we will reach out to you soon</span>
                }
                subTitle={
                  <>
                    <h2 className=' text-base'>
                      Thanks for booking a mentor, we have received your request
                      <br /> So expect emails or call from us.
                    </h2>
                    <div className='sm:flex justify-center mt-5'>
                      <PaymentButton
                        details={{
                          email: email,
                          amount: amountToPay,
                          name: name,
                          phone: phone,
                          buttonLabel: 'Pay now',
                          redirectUrl: '/book-mentor-payment'
                        }}
                      />
                      <button
                        className='px-10 py-2 block w-full max-w-max mx-auto sm:mx-0 font-manrope mt-5 sm:mt-0 cursor-pointer duration-200 hover:bg-opacity-75 bg-btn-primary text-white font-semibold rounded'
                        onClick={handleClick}
                      >
                        Pay Later
                      </button>
                    </div>
                  </>
                }
                extra={
                  <div className=''>
                    <Popconfirm
                      title='Closing this window will terminate your Booking'
                      onConfirm={confirmClose}
                      onCancel={cancelClose}
                      okText='Yes, Cancel my Booking'
                      cancelText='No, Continue to Payment'
                    >
                      <a
                        href='#'
                        className='px-6 py-2 bg-red-700 hover:bg-red-800 font-semibold rounded-md'
                      >
                        Cancel Booking
                      </a>
                    </Popconfirm>
                  </div>
                }
              />
            </div>
          )
        ) : (
          <BookMentorsForm
            handleSubmit={handleSubmit}
            loading={loading}
            whatToLearn={whatToLearn}
            form={form}
            currentPlan={currentPlan}
            setWhatToLearn={setWhatToLearn}
          />
        )}
      </div>
    </div>
  )
}

export default BookMentor
