import React from 'react'
import icon4 from '../../images/icons/services-4.png'
import icon5 from '../../images/icons/services-5.png'
import icon6 from '../../images/icons/services-6.png'
import kids from '../../images/icons/kids.png'
import InviewPortAnimate from '../../sharedComponents/InviewPortAnimate'

// eslint-disable-next-line react/prop-types
const WhatToGain = ({ gainRref }) => {
  return (
    <div ref={gainRref} className='pt-12'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/2 mx-auto text-center'>
          <h1 className='text-gray-800 text-4xl text-center font-semibold'>
            What Your <img src={kids} alt='kid' className='inline mr-2' />
            Stands To Gain
          </h1>
        </div>
      </div>
      <div className='container mx-auto px-5'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 pt-12'>
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-white`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon4} alt='arrow' className='img-it-works' />
                  Develop problem-solving skills
                </h1>
                <p className='text-base px-4 pt-3'>
                  Coding helps kids develop soft skills and build aspects of their character, as
                  they develop traits such as resilience, grit, and patience. Which enables them to
                  pursue their dreams
                </p>
              </div>
            )}
            animateClassName='box-animate-left-3'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-white`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon5} alt='arrow' className='img-it-works' />
                  Stimulate Kids Creativity
                </h1>
                <p className='text-base px-4 pt-3'>
                  Giving mental image a form in the real world is the height of creativity. We want
                  to empower kids to not just consume digital media and Technology but to create it.
                </p>
              </div>
            )}
            animateClassName='box-animate-left-2'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-white`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon6} alt='arrow' className='img-it-works' />
                  Improved Concentration and Focus
                </h1>
                <p className='text-base px-4 pt-3'>
                  In our highly distracted world, Learning to code teaches kids to concentrate and
                  focus on a single task. This becomes a life skill
                </p>
              </div>
            )}
            animateClassName='box-animate-left'
          />
        </div>
      </div>
    </div>
  )
}

export default WhatToGain
