import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import PropTypes from 'prop-types'

const Layout = ({ children, userInfo }) => {
  const [open, setOpen] = useState(false)
  const [userDropdownOpen, setUserDropdownOpen] = useState(false)

  return userInfo.loading ? (
    <div className='h-screen grid place-content-center'>
      <i className='fa fa-spin fa-spinner text-xl' />
    </div>
  ) : (
    <div className='flex flex-col h-screen bg-gray-100'>
      {open && (
        <div className='fixed inset-0 z-10 bg-black bg-opacity-50' onClick={() => setOpen(false)} />
      )}
      <nav className='h-12 bg-btn-primary'>
        <div className='flex justify-between h-full items-center px-10'>
          <div className='flex'>
            <button
              onClick={() => setOpen(prev => !prev)}
              className='block md:hidden outline-none mr-4 focus:outline-none cursor-pointer group'
            >
              <div className='mb-1 w-6 h-0.5 group-hover:bg-white bg-gray-200 duration-200' />
              <div className='mb-1 w-6 h-0.5 group-hover:bg-white bg-gray-200 duration-200' />
              <div className='w-6 h-0.5  group-hover:bg-white bg-gray-200 duration-200' />
            </button>
            <h4 className='text-lg text-white font-semibold'>MentorTribes</h4>
          </div>
          <>
            <span
              onClick={() => setUserDropdownOpen(prev => !prev)}
              className='flex group relative cursor-pointer items-center'
            >
              {' '}
              <figure className='m-0 p-0'>
                <img src='/user.svg' className='w-8 h-8' alt='user' />
              </figure>
              <h4 className='text-base text-white ml-3'>
                {userInfo.data.firstname} {userInfo.data.lastname}
              </h4>
              <i
                className={`fa fa-sort-down ml-1 text-gray-300 duration-200 transform ${
                  userDropdownOpen ? '-rotate-180 translate-y-1' : 'rotate-0 translate-y-0'
                }`}
              />
              <div
                style={{
                  zIndex: 100,
                  pointerEvents: userDropdownOpen ? 'all' : 'none'
                }}
                className={`absolute top-10 bg-white py-5 duration-200 shadow-md ${
                  userDropdownOpen ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <ul>
                  <li onClick={() => setUserDropdownOpen(false)}>
                    <Link to={`/mentors/${userInfo.data.id}`}>
                      <a className='text-gray-800 py-2 block px-5 hover:bg-gray-200 duration-200'>
                        <i className='fa fa-user mr-3' />
                        View Profile
                      </a>
                    </Link>
                  </li>
                  <li onClick={() => setUserDropdownOpen(false)}>
                    <Link to='/edit-profile'>
                      <a className='text-gray-800 py-2 block px-5 hover:bg-gray-200 duration-200'>
                        <i className='fa fa-users mr-3' />
                        Edit Profile
                      </a>
                    </Link>
                  </li>
                  <li onClick={() => setUserDropdownOpen(false)}>
                    <Link to='/logout'>
                      <a className='text-gray-800 py-2 block px-5 hover:bg-gray-200 duration-200'>
                        <i className='fa fa-sign-out-alt mr-3' />
                        Logout
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </span>
            {userDropdownOpen && (
              <div
                className='inset-0 h-screen fixed z-50'
                onClick={() => setUserDropdownOpen(false)}
              />
            )}
          </>
        </div>
      </nav>
      <div className='flex-1 overflow-y-auto'>
        <div className='flex h-full overflow-y-auto'>
          <Sidebar userInfo={userInfo} open={open} setOpen={setOpen} />
          <div className='flex-1 overflow-y-auto p-5'>{children}</div>
        </div>
      </div>
    </div>
  )
}
Layout.propTypes = { children: PropTypes.any, userInfo: PropTypes.object.isRequired }

export default Layout
