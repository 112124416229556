import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const Input = ({ label, type, id, noMargin, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className={`${!noMargin && 'mt-2'}`}>
      <label htmlFor={id} className='text-xs uppercase font-manrope font-semibold text-black'>
        {label}
      </label>
      <input
        className='w-full border border-gray-200 text-gray-900 mt-1 px-5 text-sm py-2 rounded-lg focus:outline-none focus:shadow-outline'
        type={type}
        id={id}
        {...field}
        {...meta}
      />
      {meta.touched && meta.error && <small className='text-red-500 text-xs'>{meta.error}</small>}
    </div>
  )
}
Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.any,
  id: PropTypes.string,
  noMargin: PropTypes.string
}

export default Input
