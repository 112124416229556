import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import FormMain from './FormMain'
import { notification, Popconfirm, Result } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { getBookKidmentor } from '../../graphql/queries'
import axios from '../../services/axios'
import { createBookKidmentor, updateBookKidmentor } from '../../graphql/mutations'
import PaymentButton from '../Payment'
import { useHistory } from 'react-router-dom'

const ValidationSchema = Yup.object().shape({
  parentName: Yup.string().required('Parent is required'),
  parentEmail: Yup.string().email('Email is not valid').required('Please enter Email'),
  parentPhone: Yup.string().required('Parent Phone is required'),
  noOfKids: Yup.number().min(1).required('Enter number of kids'),
  firstChildName: Yup.string().required('First Child is required'),
  firstChildAge: Yup.string().required("Enter first child's age").trim(),
  firstChildInterest: Yup.string().trim()
})

const FormContainer = () => {
  const history = useHistory()
  const [isSubmmitting, setSubmitting] = useState(false)
  const [isSubmmitted, setSubmitted] = useState(false)
  const [details, setDetails] = useState({})
  const [amountToPay, setAmountToPay] = useState('')
  const registeredId = window.localStorage.getItem('weekend-camp-id')

  const handlePayLater = async () => {
    const id = window.localStorage.getItem('weekend-camp-id')
    try {
      await API.graphql(
        graphqlOperation(updateBookKidmentor, {
          input: {
            id: id,
            transferOption: true
          }
        })
      )
    } catch (error) {
      console.error(error)
    }
    history.push('/payment-invoice', {
      email: details.email,
      name: details.name,
      amount: amountToPay,
      item: 'Weekend Camp Mentor'
    })
  }

  const getDetails = async id => {
    try {
      const { data } = await API.graphql(graphqlOperation(getBookKidmentor, { id: id }))
      const { parentName, parentPhone, parentEmail, noOfKids } = data.getBookKidmentor
      let amount = 0
      if (noOfKids === 1) {
        setAmountToPay(10000)
      } else {
        const discount = 2000 * (noOfKids - 1)
        amount = noOfKids * 10000 - discount
        setAmountToPay(Number(amount))
      }

      setDetails(prev => ({
        ...prev,
        email: parentEmail,
        phone: parentPhone,
        name: parentName
      }))
    } catch (error) {
      console.error('Something went wrong', error)
    }
  }

  const confirmClose = () => {
    window.localStorage.removeItem('weekend-camp-id')
    history.push('/weekend-camp')
  }

  const cancelClose = () => {
    // Do Nothing
  }

  useEffect(() => {
    if (registeredId) {
      getDetails(registeredId)
    }
  }, [registeredId])

  return (
    <section className='py-10'>
      <div id='kids-form' className='conatiner mx-auto px-5'>
        <h3 className='text-gray-900 text-3xl font-semibold text-center md:text-4xl'>
          Please Read Before Filling the Form
        </h3>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mt-5'>
          <div className='m-0 p-0'>
            <div className='p-5 px-10 rounded-lg bg-gray-50'>
              <h4 className='text-black font-manrope text-xl mb-2 font-bold'>Requirement</h4>
              <ul>
                <li className='list-none max-w-md text-black font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-black' /> Be a registered member
                </li>
                <li className='list-none max-w-md text-black font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-black' /> A computer connected to the internet
                </li>
                <li className='list-none max-w-md text-black font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-black' /> A quiet environment for classes
                </li>
                <li className='list-none max-w-md text-gray-900 font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-gray-900' /> Respect other students by not
                  interrupting the class
                </li>
              </ul>
            </div>
          </div>
          <div className='m-0 p-0'>
            <div className='p-5 px-10 rounded-lg bg-gray-50'>
              <h4 className='text-black font-manrope text-xl mb-2 font-bold'>Tuition</h4>
              <ul>
                <li className='list-none max-w-md text-black font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-black' /> Tuition fee is{' '}
                  <span className='font-bold'>₦10,000</span> for one child
                </li>
                <li className='list-none max-w-md text-black font-manrope text-base py-2'>
                  <i className='fa fa-check mr-2 text-black' /> More than one child? Subsequent
                  children pay <span className='font-bold'>₦8,000</span> enjoying siblings discount.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='max-w-4xl mt-8 mx-auto'>
          {isSubmmitted || registeredId ? (
            <div>
              <Result
                status='success'
                title={
                  <span className=''>
                    {registeredId
                      ? `Welcome back ${details.name ? details.name : ''}`
                      : 'Successfully Registered, we will reach out to you soon'}
                  </span>
                }
                subTitle={
                  <>
                    <h2 className=' text-base'>
                      Thanks for booking a mentor for your kids, we have received your request
                      <br /> So expect emails or call from us.
                    </h2>
                    <div className='sm:flex justify-center mt-5'>
                      <PaymentButton
                        details={{
                          email: details.email,
                          amount: amountToPay,
                          name: details.name,
                          phone: details.phone,
                          buttonLabel: 'Pay now',
                          redirectUrl: '/weekendcampsuccess'
                        }}
                      />
                      <button
                        className='px-10 py-2 block w-full max-w-max mx-auto sm:mx-0 font-manrope mt-5 sm:mt-0 cursor-pointer duration-200 hover:bg-opacity-75 bg-btn-primary text-white font-semibold rounded'
                        onClick={handlePayLater}
                      >
                        Pay Later
                      </button>
                    </div>
                  </>
                }
                extra={
                  <div className=''>
                    <Popconfirm
                      title='Closing this window will terminate your Booking'
                      onConfirm={confirmClose}
                      onCancel={cancelClose}
                      okText='Yes, Cancel my Booking'
                      cancelText='No, Continue to Payment'
                    >
                      <a
                        href='#'
                        className='px-6 py-2 bg-red-700 hover:bg-red-800 font-semibold rounded-md'
                      >
                        Cancel Booking
                      </a>
                    </Popconfirm>
                  </div>
                }
              />
            </div>
          ) : (
            <Formik
              initialValues={{
                parentName: '',
                parentEmail: '',
                parentPhone: '',
                noOfKids: 0,
                firstChildName: '',
                firstChildAge: '',
                firstChildInterest: ''
              }}
              validationSchema={ValidationSchema}
              onSubmit={async values => {
                setSubmitting(true)

                try {
                  const data = await API.graphql(
                    graphqlOperation(createBookKidmentor, {
                      input: values
                    })
                  )
                  if (data?.errors) throw Error(data?.errors[0]?.message)
                  else {
                    setDetails({
                      email: values.parentEmail,
                      name: values.parentName,
                      phone: values.parentPhone
                    })
                    notification.success({
                      message: 'You have successfully registered your child',
                      duration: 6000
                    })
                    if (values?.noOfKids === 1) {
                      setAmountToPay(10000)
                    } else {
                      const discount = 2000 * (values?.noOfKids - 1)
                      const amount = values?.noOfKids * 10000 - discount
                      setAmountToPay(amount)
                    }
                    window.localStorage.setItem('weekend-camp-id', data.data.createBookKidmentor.id)
                    setSubmitting(false)
                    setSubmitted(true)
                    await axios.post('/Eazy2con/book-mentor-success', {
                      fullName: values?.parentName,
                      email: values?.parentEmail,
                      parentPhone: values?.parentPhone,
                      noOfKids: values?.noOfKids,
                      firstChildName: values?.firstChildName,
                      firstChildAge: values?.firstChildAge,
                      firstChildInterest: values?.firstChildInterest
                    })
                  }
                } catch (err) {
                  setSubmitting(false)
                  notification.error({
                    message: err?.errors[0]?.message || 'Something went wrong!',
                    duration: 6000
                  })
                }
              }}
            >
              <Form>
                <FormMain isSubmmitting={isSubmmitting} />
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </section>
  )
}

export default FormContainer
