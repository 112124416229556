import React, { useEffect, useState } from 'react'
import ChatSidebar from './ChatSidebar'
import { Route, Switch, useHistory } from 'react-router-dom'
import SingleChat from './SingleChat'
import PropTypes from 'prop-types'

const ChatUI = ({ chats, visitor, setVisitor, setChatId }) => {
  const [currentChatId, setCurrentChatId] = useState('')
  const history = useHistory()

  useEffect(() => {
    history.listen(() => setCurrentChatId(''))
  }, [])

  return (
    <div className='h-screen bg-gray-100'>
      <div className='flex h-full overflow-y-auto'>
        <ChatSidebar currentChatId={currentChatId} setChatId={setChatId} chats={chats} />
        <div
          className={`flex-1 flex flex-col h-full overflow-y-auto ${
            !currentChatId && 'hidden md:block'
          }`}
        >
          <Switch>
            <Route
              path='/chat'
              exact
              render={() => (
                <div className='h-full grid place-content-center'>
                  <h3 className='font-manrope text-xl font-semibold text-primary'>
                    No selected chat
                  </h3>
                </div>
              )}
            />
            <Route
              path='/chat/:id'
              exact
              render={props => (
                <SingleChat
                  setCurrentChatId={setCurrentChatId}
                  routerProps={props}
                  visitor={visitor}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}
ChatUI.propTypes = {
  chats: PropTypes.any,
  visitor: PropTypes.any,
  setVisitor: PropTypes.any,
  setChatId: PropTypes.any
}

export default ChatUI
