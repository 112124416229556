import React, { useContext, useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { API, Storage, graphqlOperation } from 'aws-amplify'
import InputField from '../fields/InputField'
import profileImage from '../../images/profile.png'
import { userContext } from '../../context/UserContext'
import { updateMentor } from '../../graphql/mutations'
import BioInput from '../fields/BioInput'

const EditProfile = () => {
  const { userInfo, setUserInfo } = useContext(userContext)
  const history = useHistory()
  const [isSubmmitting, setSubmitting] = useState(false)
  const [video, setVideo] = useState({ error: '', file: '' })
  const [isUploading, setUploading] = useState(false)
  const [bio, setBio] = useState(userInfo?.data?.bio)
  const [isRemoving, setRemoving] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')
  const bioRef = useRef()

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    specialization: Yup.string().required('Specialization is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string().required('Phone Number is required'),
    facebookUrl: Yup.string().url('Please enter a valid url'),
    github: Yup.string().url('Please enter a valid url'),
    dribbleUrl: Yup.string().url('Please enter a valid url'),
    linkedInUrl: Yup.string().url('Please enter a valid url'),
    otherLinkUrl: Yup.string().url('Please enter a valid url')
  })

  const handleSubmit = async values => {
    if (!bio) {
      bioRef.current.scrollIntoView()
    } else {
      setSubmitting(true)
      try {
        let videoKey
        values.bio = bio
        if (!video.error && video.file) {
          if (userInfo.video) {
            await Storage.remove(userInfo.video)
          }
          const newVideo = await Storage.put(`profile-${Date.now()}-${video.file.name}`, video, {
            level: 'public'
          })
          console.log('intro______', newVideo)
          videoKey = newVideo.key
        }
        const data = await API.graphql(
          graphqlOperation(updateMentor, {
            input: { ...values, id: userInfo.data.id, video: videoKey }
          })
        )
        if (data?.errors) throw Error(data?.errors[0]?.message)
        else {
          notification.success({
            message: 'Profile Updated',
            description: 'You mentor profile have been updated'
          })
          setSubmitting(false)
          let imageUrl = {}
          if (userInfo.data.imageUrl && userInfo.data.imageUrl.key) {
            imageUrl = userInfo.data.imageUrl
          }
          setUserInfo(current => ({
            ...current,
            data: {
              ...data.data.updateMentor,
              image: imageUrl
            }
          }))
          history.push(`/mentors/${userInfo.data.id}`)
        }
      } catch (err) {
        setSubmitting(false)
        notification.error({
          message: err?.errors[0]?.message || 'Something went wrong!',
          duration: 10000
        })
      }
    }
  }

  const handleRemove = async e => {
    setRemoving(true)
    try {
      if (userInfo.data.imgKey) {
        await Storage.remove(userInfo.data.imgKey)
        const res = await API.graphql(
          graphqlOperation(updateMentor, {
            input: {
              id: userInfo.data.id,
              imageUrl: null
            }
          })
        )
        if (res?.errors) throw Error(res?.errors[0]?.message)
        else {
          notification.success({
            message: 'Profile removed',
            description: 'You mentor profile image have been removed'
          })
          setRemoving(false)
          setUserInfo(current => ({
            ...current,
            data: res.data.updateMentor
          }))
        }
      }
      setRemoving(false)
    } catch (error) {
      setUploading(false)
      notification.error({
        message: 'Something went wrong!',
        duration: 10000
      })
    }
  }

  const handleUpload = async e => {
    setUploading(true)
    const file = e.target.files[0]
    try {
      if (file.size / 1024 > 1024 * 5) {
        notification.error({
          message: 'Image must be 5mb or less',
          duration: 10000
        })
      } else if (!file.type.includes('image')) {
        notification.error({
          message: 'Only image files are accepted',
          duration: 10000
        })
      } else {
        if (userInfo.data.imageUrl && userInfo.data.imageUrl.key) {
          await Storage.remove(userInfo.data.imageUrl.key)
        }

        const newFile = await Storage.put(`profile-${Date.now()}-${file.name}`, file, {
          level: 'public'
        })
        const imageUrl = await Storage.get(newFile.key, {
          level: 'public'
        })

        const res = await API.graphql(
          graphqlOperation(updateMentor, {
            input: {
              id: userInfo.data.id,
              imageUrl: { key: newFile.key, url: imageUrl }
            }
          })
        )
        if (res?.errors) throw Error(res?.errors[0]?.message)
        else {
          notification.success({
            message: 'Profile Updated',
            description: 'You mentor profile image have been updated'
          })
          setUploading(false)
          setUserInfo(current => ({
            ...current,
            data: res.data.updateMentor
          }))
        }
      }
    } catch (error) {
      setUploading(false)
      notification.error({
        message: 'Something went wrong!',
        duration: 10000
      })
    }
  }

  useEffect(() => {
    if (!userInfo.loading && !userInfo.isAuthenticated) {
      // console.log(userInfo.loading, userInfo.isAuthenticated)
      history.push('/login')
      notification.error({ message: 'You need to login' })
    }
    if (!userInfo.loading && !Object.keys(userInfo.data).length) {
      notification.warn({
        message: 'Please fill out the mentorship form',
        duration: 10000
      })
      history.push('/add-mentor', {
        subId: userInfo.sub,
        email: userInfo.email
      })
    }
  }, [userInfo])

  return userInfo.loading ? (
    <div className='h-screen grid place-content-center'>
      <i className='fa fa-spin fa-spinner text-2xl' />
    </div>
  ) : (
    <div className='mt-10 pb-10'>
      <div className='container mx-auto px-5'>
        <div className='md:grid grid-cols-12 gap-10'>
          <div className='col-span-5 mb-10 md:mb-0 md:border-r border-gray-200 pr-5 pb-5'>
            <div className='sticky top-20'>
              <h4 className='text-base font-bold text-center font-manrope'>Profile</h4>
              <figure className='m-0 p-0 flex justify-center mx-auto my-5'>
                <img
                  src={userInfo?.data?.imageUrl ? `${userInfo?.data?.imageUrl.url}` : profileImage}
                  className='w-32 h-32 border-2 border-gray-200 p-1 object-cover rounded-full'
                  alt=''
                />
              </figure>
              <div>
                <h4 className='text-2xl text-center text-primary capitalize font-bold font-manrope'>
                  {userInfo?.data?.firstname} {userInfo?.data?.lastname}
                </h4>
                <p className='text-base text-center capitalize mt-1 font-manrope'>
                  {userInfo?.data?.specialization}
                </p>
                <p className='text-base text-center capitalize mt-1 font-manrope'>
                  Lives in {userInfo?.data?.country}
                </p>
              </div>
              <div className='grid grid-cols-3 my-7'>
                <div>
                  <h4 className='text-base text-center capitalize font-bold font-manrope'>30</h4>
                  <h6 className='text-sm text-center capitalize font-manrope'>Students</h6>
                </div>
                <div>
                  <h4 className='text-xl text-center capitalize font-bold font-manrope'>15k</h4>
                  <h6 className='text-sm text-center capitalize font-manrope'>Classes Taken</h6>
                </div>
                <div>
                  <h4 className='text-base text-center capitalize font-bold font-manrope'>
                    {userInfo?.data?.courses?.items?.length}
                  </h4>
                  <h6 className='text-sm text-center capitalize font-manrope'>Courses</h6>
                </div>
              </div>
              <div className='my-5'>
                <input
                  accept='image/png, image/gif, image/jpeg'
                  className='hidden'
                  onChange={handleUpload}
                  type='file'
                  disabled={isUploading}
                  id='upload'
                />
                <label
                  htmlFor='upload'
                  className='block px-5 py-2 cursor-pointer max-w-max font-manrope text-sm bg-primary hover:bg-opacity-75 duration-200 text-white rounded mx-auto'
                >
                  {isUploading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                  Upload new Image
                </label>
                {userInfo.data.imageUrl && (
                  <button
                    onClick={handleRemove}
                    className='block px-8 py-1 focus:outline-none outline-none font-manrope text-sm border border-red-800 rounded duration-200 hover:bg-primary hover:text-white text-primary mx-auto mt-5'
                  >
                    {isRemoving && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                    Remove Image
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='col-span-7'>
            <Formik
              initialValues={{
                userSubId: userInfo.data.userSubId,
                projects: userInfo.data.projects,
                firstname: userInfo.data.firstname,
                lastname: userInfo.data.lastname,
                specialization: userInfo.data.specialization,
                country: userInfo?.data.country,
                phone: userInfo?.data?.phone,
                facebookUrl: userInfo?.data?.facebookUrl || '',
                github: userInfo?.data?.github || '',
                dribbleUrl: userInfo?.data?.dribbleUrl || '',
                linkedInUrl: userInfo?.data?.linkedInUrl || '',
                otherLinkUrl: userInfo?.data?.otherLinkUrl || ''
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='flex justify-between border-b border-gray-200 pb-5'>
                  <h4 className='text-base font-bold font-manrope'>Basic Info</h4>
                  <div className='flex'>
                    <button
                      type='button'
                      onClick={() => history.push('/mentor-dashboard')}
                      className='block outline-none focus:outline-none px-5 py-1 font-manrope text-sm border border-red-800 duration-200 rounded hover:bg-primary hover:text-white text-primary'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='block outline-none focus:outline-none px-5 py-1 font-manrope text-sm bg-primary hover:bg-opacity-75 duration-200 ml-5 text-white rounded'
                    >
                      {isSubmmitting && (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      )}
                      Save
                    </button>
                  </div>
                </div>
                <div className='grid grid-cols-2 gap-5'>
                  <InputField label='First Name' name='firstname' id='firstname' />
                  <InputField label='Last Name' name='lastname' id='lastname' />
                </div>
                <InputField label='Specialization' name='specialization' id='specialization' />
                <div className='grid grid-cols-2 gap-5'>
                  <InputField label='country' name='country' id='country' />
                  <InputField label='Phone No' name='phone' id='phone' />
                </div>
                <div className='mt-8'>
                  <h4 className='font-manrope font-bold text-base border-b border-gray-200 pb-3'>
                    About Me
                  </h4>
                  <BioInput
                    value={bio}
                    handleChange={value => setBio(value)}
                    name='bio'
                    label='Bio(Describe yourself and what you have you to offer thoroughly)'
                    id='bio'
                    bioRef={bioRef}
                    rows={4}
                  />
                  <div>
                    <input
                      onChange={e => {
                        if (e.target.files.length) {
                          const file = e.target.files[0]
                          setVideoUrl(URL.createObjectURL(file))
                          setVideo({ error: '', file })
                          if (file.size / 1024 > 1024 * 50) {
                            setVideo(prev => ({
                              ...prev,
                              error: 'Video must 50mb or less'
                            }))
                          }
                        }
                      }}
                      type='file'
                      id='video'
                      accept='video/mp4,video/x-m4v,video/*'
                      className='hidden'
                      name='video'
                    />
                    <label
                      htmlFor='video'
                      className={`bg-gray-200 h-52 rounded cursor-pointer w-full mt-5 ${
                        video.file ? 'block' : 'grid place-content-center'
                      }`}
                    >
                      {video.file ? (
                        <div className='m-0 p-0 relative group h-full w-full'>
                          <video
                            autoPlay
                            style={{ height: '100%', width: '100%' }}
                            loop
                            id='myVideo'
                            src={videoUrl}
                          />
                          <div className='absolute inset-0 bg-black bg-opacity-75 sm:opacity-0 grid place-content-center hover:opacity-100 duration-200'>
                            <div className='flex font-manrope justify-center items-center text-white'>
                              <i className='fa fa-exchange-alt mr-3' /> Change Course Video
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='flex text-gray-800 font-manrope justify-center items-center'>
                            <i className='fa fa-plus mr-3' /> Add Course Intro Video(Optional)
                          </div>
                          <p className='text-gray-800 max-w-xl mt-2 font-manrope text-center'>
                            Just a simple recording explaining what you teach in the course, make
                            sure video is attractive and professional
                          </p>
                        </>
                      )}
                    </label>
                    <small className='block mt-1 text-red-700 font-manrope'>{video.error}</small>
                  </div>
                </div>
                <div className='mt-8'>
                  <h4 className='font-manrope font-bold text-base border-b border-gray-200 pb-3'>
                    External Links
                  </h4>
                  <InputField name='facebookUrl' label='Facebook' id='facebookUrl' />
                  <InputField name='github' label='Github' id='github' />
                  <InputField name='dribbleUrl' label='Dribble' id='dribbleUrl' />
                  <InputField name='linkedInUrl' label='linkedIn' id='linkedInUrl' />
                  <InputField name='otherLinkUrl' label='Other Link' id='otherLinkUrl' />
                  <div className='flex mt-7 justify-end'>
                    <button
                      type='button'
                      onClick={() => history.push('/mentor-dashboard')}
                      className='block outline-none focus:outline-none  px-5 py-1 font-manrope text-sm border border-red-800 duration-200 rounded hover:bg-primary hover:text-white text-primary'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='block outline-none focus:outline-none  px-5 py-1 font-manrope text-sm bg-primary hover:bg-opacity-75 duration-200 ml-5 text-white rounded'
                    >
                      {isSubmmitting && (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      )}
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProfile
