const DEV_S3_URL = 'https://masterbucketnameformentortribes171923-test.s3.amazonaws.com/public/'
const PROD_S3_URL = 'https://masterbucketnameformentortribes204820-prod.s3.amazonaws.com/public/'

export const S3_URL = process.env.NODE_ENV === 'production' ? PROD_S3_URL : DEV_S3_URL
export const EMAIL_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://mail-servies.herokuapp.com'
    : 'http://localhos:5000'
export const SET_USER = 'SET_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
