import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { notification } from 'antd'
import { Auth } from 'aws-amplify'

const AdminHome = () => {
  const history = useHistory()
  const [loading] = useState(true)
  const [activated] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      Auth.currentAuthenticatedUser()
        .then(res => res)
        .then(user => {
          // notification.error({
          //   message: 'You are not authorized here',
          //   duration: 10000
          // });
          // history.push('/add-mentor', { subId: user.attributes.sub })
        })
        .catch(_err => {
          notification.error({
            message: 'Auth Error!',
            description: 'You are not currently logged in, please login',
            duration: 10000
          })
          history.push('/login')
        })
    }, 1000)
  }, [])

  return loading ? (
    <div style={{ height: '75vh' }} className='grid place-content-center'>
      <i className='fa fa-spin fa-spinner font-bold text-lg' />
    </div>
  ) : (
    <div>{activated ? <h1>Welcome to your dashboard</h1> : <h1>Your account is in review</h1>}</div>
  )
}

export default AdminHome
