import React from 'react'
import KidsPlanCard from './KidsPlanCard'
import { PRICISING_PLANS } from './constants'
import { Link } from 'react-router-dom'

const KidsPricing = () => {
  return (
    <section className='bg-gray-50 pb-20 py-15'>
      <h2 className='text-gray-900 text-4xl text-center font-semibold'>Kids Pricing plans</h2>
      <p className='text-lg text-center max-w-md mx-auto text-gray-900'>
        Bridging the gap between learners and professionals,{' '}
      </p>
      <div id='kids-price' className='container mt-10 mx-auto px-2 md:px-16'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-15'>
          <div className='relative rounded-lg px-5 bg-white md:px-4 pt-3'>
            <h4 className='text-3xl font-bold'>
              <span className='font-dm-serif-display'>₦0</span>
              <sub className='text-gray-500 text-base font-normal'>/month</sub>
            </h4>
            <h4 className='text-2xl my-3 text-gray-500 font-dm-serif-display font-bold'>
              FREE Trial
            </h4>
            <p className='text-lg text-gray-500 mb-4'>
              Learning to code isn’t easy. Many learners struggle to stay motivated. Others don’t
              know if they’re headed in the right direction. We can help you simplify your learning
              process. guide your learning path, and provide additional learning resources that come
              from experience.
            </p>
            <Link
              to={{ pathname: '/book-mentor', state: { type: 'Trial' } }}
              className='absolute bottom-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block w-11/12 p-2 px-5 text-white text-center bg-red-900 duration-200 hover:bg-opacity-75 rounded-md'
            >
              Get started
            </Link>
          </div>

          {PRICISING_PLANS.map(({ id, listOfPerks, title, type, price, color, margin }) => (
            <KidsPlanCard
              key={type}
              istOfPerks={listOfPerks}
              title={title}
              id={id}
              type={type}
              price={price}
              color={color}
              margin={margin}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default KidsPricing
