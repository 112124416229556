import React, { useEffect, useState } from 'react'
import { Table, notification } from 'antd'
import SearchTable from './SearchTable'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { updateBookAmentor, deleteBookAmentor } from '../graphql/mutations'
import DropDownBtn from '../components/DropDownBtn'
import Moment from 'react-moment'

const BookMentorsTable = ({ bookMentors }) => {
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const [visible, setVisible] = useState(false)
  const [selectedMentee, setSelectedMentee] = useState({})

  const loading = false
  useEffect(() => {
    setData(bookMentors)
    setSearchData(bookMentors)
  }, [bookMentors])

  const handleMenteeStatus = async (status, record, setLoading, setHidden) => {
    try {
      if (setHidden) setHidden(true)
      setLoading(true)
      const data = await API.graphql(
        graphqlOperation(updateBookAmentor, {
          input: {
            id: record.id,
            status
          }
        })
      )
      setLoading(false)
      setData(prev =>
        prev.map(mentor =>
          mentor.id === data.data.updateBookAmentor.id
            ? { ...mentor, status: data.data.updateBookAmentor.status }
            : mentor
        )
      )
      setSelectedMentee({ ...selectedMentee, status: data.data.updateMentor.status })
    } catch (err) {
      setLoading(false)
      notification.error({
        message: err?.message || 'Something went wrong!',
        duration: 10000
      })
    }
  }

  const handleSearch = value => {
    const filteredData = data.filter(
      ele =>
        ele.fullName?.toLowerCase().includes(value.toLowerCase()) ||
        ele.email?.toLowerCase().includes(value.toLowerCase()) ||
        ele.phone?.toLowerCase().includes(value.toLowerCase()) ||
        ele.specialization?.toLowerCase().includes(value.toLowerCase()) ||
        ele.yrsOfexperience?.toLowerCase().includes(value.toLowerCase()) ||
        ele.country?.toLowerCase().includes(value.toLowerCase()) ||
        new Date(ele.createdAt).toDateString().includes(value.toLowerCase())
    )
    setData(filteredData)
  }

  const TABLES_CALENDAR_STRINGS = {
    sameDay: '[Today at] LT',
    lastDay: '[Yesterday at] LT',
    sameElse: 'D MMM YY LT'
  }

  function CreatedAt({ date, className }) {
    return (
      <Moment className={className} calendar={TABLES_CALENDAR_STRINGS}>
        {date}
      </Moment>
    )
  }
  CreatedAt.propTypes = { date: PropTypes.any, className: PropTypes.string }

  const BOOK_A_MENTOR_COLUMN = [
    {
      title: <span className='font-bold'>S/N</span>,
      dataIndex: 'sn',
      key: 'sn',
      render: sn => <span>{sn}</span>
    },
    {
      title: <span className='font-bold'>Full Name</span>,
      dataIndex: 'fullName',
      key: 'fullName',
      render: fullName => <span>{fullName}</span>,
      sorter: (a, b) => a.fullName - b.fullName
    },
    {
      title: <span className='font-bold'>Email Address</span>,
      dataIndex: 'email',
      key: 'email',
      render: email => <span className='lowercase'>{email}</span>
    },
    {
      title: <span className='font-bold'>Phone No </span>,
      key: 'phone',
      dataIndex: 'phone',
      render: phone => <span>{phone}</span>,
      sorter: (a, b) => a.phone - b.phone
    },
    {
      title: <span className='font-bold'>Course</span>,
      key: 'course',
      dataIndex: 'course',
      render: course => <span>{course}</span>,
      sorter: (a, b) => a.course - b.course
    },
    {
      title: <span className='font-bold'>Type</span>,
      key: 'type',
      dataIndex: 'type',
      render: type => <span>{type}</span>,
      sorter: (a, b) => a.type - b.type
    },
    {
      title: <span className='font-bold'>Why Learn</span>,
      key: 'whyLearnTheCourse',
      dataIndex: 'whyLearnTheCourse',
      width: 200,
      render: whyLearnTheCourse => <span>{whyLearnTheCourse}</span>,
      sorter: (a, b) => a.whyLearnTheCourse - b.whyLearnTheCourse
    },
    {
      title: <span className='font-bold'>Payment Status</span>,
      key: 'payment',
      dataIndex: 'payment',
      width: 200,
      render: payment => <span>{payment ? 'Paid' : 'Not Paid'}</span>,
      sorter: (a, b) => a.payment - b.payment
    },
    {
      title: <span className='font-bold'>Clicked on Bank Transfer?</span>,
      key: 'transferOption',
      dataIndex: 'transferOption',
      render: transferOption => <span>{transferOption ? 'Yes' : 'No'}</span>,
      sorter: (a, b) => a.transferOption - b.transferOption
    },
    {
      title: <span className='font-bold'>Start/End Date</span>,
      key: 'startEndDate',
      dataIndex: 'startEndDate',
      width: 200,
      render: startEndDate => (
        <span className='flex justify-between p-3 bg-primary text-white'>
          <CreatedAt
            className='border-r-2 border-cool-gray-50'
            date={startEndDate && startEndDate?.start}
          />
          <CreatedAt date={startEndDate && startEndDate?.end} />
        </span>
      )
    },
    {
      title: <span className='font-bold'>Resources</span>,
      key: 'image',
      dataIndex: 'image',
      width: 200,
      render: imageurl => (
        <a href={imageurl} target='_blank' className='text-red-900' rel='noreferrer'>
          {imageurl ? 'Link to resource' : ''}
        </a>
      )
    },
    {
      title: <span className='font-bold'>Created At</span>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => <CreatedAt date={createdAt} />,
      sorter: (a, b) => a.createdAt - b.createdAt
    },

    {
      title: <span className='font-bold capitalize'>ACTIONS</span>,
      key: 'action',
      render: (record, _) => (
        <DropDownBtn
          handleMenteeStatus={handleMenteeStatus}
          deleteModelType={deleteBookAmentor}
          record={record}
          selectedMentee={selectedMentee}
          visible={visible}
          setVisible={setVisible}
        />
      )
    }
  ]

  return (
    <div className='bg-white shadow'>
      <div
        className='py-2 mb-6 pl-5'
        style={{
          borderBottom: '1px dotted #b4afaf',
          borderTop: '3px solid rgb(219 74 57)'
        }}
      >
        <h3 className='ml-12 text-xl text-gray-700'>{bookMentors.length} Applications found</h3>
      </div>
      <SearchTable
        data={data}
        handleSearch={handleSearch}
        setDataSource={setData}
        searchData={searchData}
      />
      <div className='px-4 overflow-x-auto mb-16'>
        <Table
          columns={BOOK_A_MENTOR_COLUMN}
          onChange={value => setPagination(value)}
          pagination={pagination}
          loading={loading}
          dataSource={data}
        />
      </div>
    </div>
  )
}

BookMentorsTable.propTypes = {
  bookMentors: PropTypes.array.isRequired
}

export default BookMentorsTable
