import React from 'react'
import BootcampForm from '../components/BootcampForm'

const BootcampRegisterSection = () => {
  return (
    <div id='register' className=''>
      <h3 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-3 text-gray-900 font-manrope'>
        Register Here
      </h3>
      <div
        style={{ background: '#FFF8F8' }}
        className='flex flex-wrap h-max p-1 py-10 sm:p-4 md:p-6 lg:p-10 mx-auto'
      >
        <div className='w-full lg:w-1/2 px-0 md:pl-6 py-0 flex flex-col justify-center'>
          <div
            style={{
              minWidth: '300px'
            }}
            className='md:w-4/5 p-4 hover:shadow-2xl duration-500 max-h-fit w-full mx-auto bg-white rounded-xl'
          >
            <h3 className='text-3xl font-bold text-center py-3 text-gray-900 font-manrope'>
              Register for the Bootcamp
            </h3>
            <h3 className='text-base font-bold text-center mb-3 text-red-600 font-manrope'>
              **Registration closed!!!
            </h3>
            <BootcampForm />
          </div>
        </div>
        <div className='w-full lg:w-1/2 order-first lg:order-none px-0 lg:p-4'>
          <div
            style={{ background: '#FFF2F2' }}
            className='hover:shadow-2xl duration-500 sm:w-max mb-4 lg:m-0'
          >
            <div className='p-4 lg:p-15 text-gray-900 font-manrope mx-auto'>
              <h1 className='text-3xl font-bold mb-3'>Requirements</h1>
              <ul>
                <li className='p-1'>
                  <i className='fa fa-check pr-3' />
                  You must have made a one-time payment of ₦20,000
                </li>
                <li className='p-1'>
                  <i className='fa fa-check pr-3' />
                  You must have a Computer with good internet access
                </li>
                <li className='p-1 mb-5'>
                  <i className='fa fa-check pr-3' />
                  You will need a suitable environment for classes
                </li>
              </ul>
              <div>
                <i className='fa fa-slack text-2xl pr-2 text-red-900' />
                <a
                  href='https://join.slack.com/t/mentortribes/shared_invite/zt-11lqs3zwh-vGlAwYVGutP0kYVRjmt7tQ'
                  target='blank'
                  className='text-red-700 text-lg font-bold'
                >
                  Join the Slack channel{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BootcampRegisterSection
