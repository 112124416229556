import React, { useEffect, useContext, useState } from 'react'
import { useHistory, useRouteMatch, Switch, Route, Link } from 'react-router-dom'
import { notification } from 'antd'
import Layout from './layouts/Layout'
import { userContext } from '../context/UserContext'
import Dashboard from './pages/Dashboard'
import AddCourse from './pages/AddCourse'
import EditCourse from './pages/EditCourse'
import Chat from './pages/MentorChat'

const MentorsDashboard = () => {
  const { path: _path } = useRouteMatch()
  // eslint-disable-next-line no-unused-vars
  const { userInfo, setUserInfo } = useContext(userContext)
  const [isMentorAdmin, setIsMentorAdmin] = useState(false)
  const history = useHistory()
  const routes = [
    {
      path: _path,
      exact: true,
      comp: Dashboard
    },
    {
      path: _path + 'add-course',
      exact: true,
      comp: AddCourse
    },
    {
      path: _path + 'edit-course/:id',
      exact: true,
      comp: EditCourse
    },
    {
      path: _path + 'chat/',
      comp: Chat
    }
  ]

  useEffect(() => {
    if (!userInfo.loading) {
      if (Object.keys(userInfo.data).length === 0) {
        notification.warn({
          message: 'Please fill out the mentorship form',
          duration: 10000
        })
        history.push('/add-mentor', {
          subId: userInfo.sub,
          email: userInfo.email
        })
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (!userInfo.loading) {
      if (userInfo.data?.isAdmin) {
        setIsMentorAdmin(true)
      } else {
        setIsMentorAdmin(false)
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (!userInfo.loading && !userInfo.isAuthenticated) {
      notification.error({
        message: 'Auth Error!',
        description: 'You are not currently logged in, please login',
        duration: 10000
      })
      history.push('/login')
    }
  }, [userInfo])

  return (
    <>
      {isMentorAdmin ? (
        <Layout userInfo={userInfo}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                render={() => <route.comp userInfo={userInfo} />}
              />
            ))}
          </Switch>
        </Layout>
      ) : (
        <div className='h-screen flex-col gap-y-10 flex justify-center items-center'>
          <h className='text-7xl'>COMING SOON</h>
          <Link
            to='/'
            className='text-gray-100 py-3 px-6 text-lg font-medium hover:bg-red-900 hover:text-white bg-red-800'
          >
            {' '}
            Go Back{' '}
          </Link>
        </div>
      )}
    </>
  )
}

export default MentorsDashboard
