/* eslint-disable indent */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { userContext } from '../context/UserContext'
import logo from '../images/mentortribes-logo.png'

const NavBar = () => {
  const { pathname } = useLocation()
  const navRef = useRef()

  const [nav, setNav] = useState(false)
  const { userInfo } = useContext(userContext)
  const stickNav = () => {
    if (navRef.current) {
      var sticky = navRef.current.offsetTop
      if (window.pageYOffset >= sticky + 2) {
        navRef.current.style.position = 'fixed'
        navRef.current.style.boxShadow = ' 0 1px 2px 0 rgba(0, 0, 0, 0.05)'
        navRef.current.style.top = 0
      } else {
        navRef.current.style.position = 'relative'
        navRef.current.style.boxShadow = 'none'
        navRef.current.style.top = 'auto'
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', stickNav)
    return () => document.removeEventListener('scroll', stickNav)
  }, [])

  return pathname.includes('/mentor-dashboard') ||
    pathname.includes('/chat') ||
    pathname.includes('/admin-dashboard') ? (
    ''
  ) : (
    <nav
      ref={navRef}
      className='flex justify-between top-0 z-50 md:mb-0 border-b bg-white border-gray-200 w-full px-2 py-1'
    >
      <Link to='/'>
        <div className='flex items-center flex-shrink-0 text-darker p-0 mr-0 md:mr-4'>
          <img className='p-0 m-0 w-56 lg:w-auto' src={logo} alt='logo' />
        </div>
      </Link>
      <div className='block lg:hidden'>
        <button
          style={{ outline: 0 }}
          onClick={() => setNav(prev => !prev)}
          className='flex items-center px-2 py-2 md:px-3 md:py-2 border rounded border-gray-500 text-gray-500 hover:text-gray-500 hover:border-gray-600'
        >
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>
      </div>
      <div className='hidden lg:flex text-md items-baseline flex-row text-base'>
        <Link
          to='/'
          className={`ml-3 mt-4 hidden lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 ${
            pathname === '/' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
          }`}
        >
          Home
        </Link>

        <Link
          to='/kid-tech'
          className={`ml-3 mt-4 hidden lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 ${
            pathname === '/kid-tech' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
          }`}
        >
          Kid Tech
        </Link>
        <Link
          to='/weekend-camp'
          className={`ml-3 mt-4 hidden lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 ${
            pathname === '/weekend-camp' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
          }`}
        >
          Kids Weekend Camp
        </Link>

        <Link
          to='/pricing'
          className={`ml-3 mt-4 hidden lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 ${
            pathname === '/pricing' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
          }`}
        >
          Pricing
        </Link>
        {/* THIS FEATURE WILL BE MADE PUBLIC SOON
          <Link
            to='/discover-mentors'
            className={`block ml-3 mt-4 lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 ${
              pathname === '/discover-mentors' ? 'border-b-2 border-red-900 pb-1' : ''
            }`}
          >
            Discover Mentors
          </Link> */}
        <a
          href='https://www.blog.mentortribes.com'
          className='ml-3 mt-4 hidden lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 mr-8'
        >
          Blog
        </a>
        {!userInfo.loading &&
          (userInfo.isAuthenticated ? (
            <>
              <Link
                to='/mentor-dashboard'
                className='text-sm ml-4 bg-red-800 text-white mt-4 hidden lg:inline-block lg:mt-0 font-semibold px-5 py-2 rounded-md hover:text-white'
              >
                Dashboard
              </Link>
              <Link
                to='/logout'
                className='hidden lg:inline-block ml-4 text-sm px-6 py-3 leading-none border rounded hover:text-white border-primary text-primary hover:border-transparent hover:bg-gray-500 mt-4 lg:mt-0'
              >
                Logout
              </Link>
            </>
          ) : (
            <>
              <Link
                to='/add-mentor'
                className='hidden lg:inline-block ml-4 text-sm px-6 py-3 leading-none border rounded hover:text-white border-primary text-primary hover:border-transparent hover:bg-gray-500 mt-4 lg:mt-0'
              >
                Apply as a Mentor
              </Link>
              <Link
                to={pathname === '/kid-tech' ? '/kid-book-mentor' : '/book-mentor'}
                className='text-sm ml-4 bg-red-800 text-white mt-4 hidden lg:inline-block lg:mt-0 font-semibold px-5 py-2 rounded-md hover:text-white'
              >
                Book a mentor
              </Link>
            </>
          ))}
      </div>

      {/* <div
        onClick={() => setNav(navToggle => !navToggle)}
        className={classnames(
          'w-full lg:flex lg:items-center lg:w-auto transition-all duration-500 ease-in-out',
          {
            hidden: nav
          }
        )}
      /> */}
      <div
        className='bg-white fixed md:hidden pb-5 overflow-y-auto shadow-xl md:shadow-none duration-200 z-30 inset-0 w-9/12 sm:w-6/12 h-full'
        style={{
          flexBasis: '250px',
          transform: nav ? 'translateX(0)' : 'translateX(-100%)'
        }}
      >
        <nav className='h-12 bg-btn-primary flex flex-col justify-center'>
          <div className='flex justify-between md:justify-center px-5'>
            <h4 className='text-lg text-white font-semibold'>MentorTribes</h4>
            <button
              onClick={() => setNav(prev => !prev)}
              className='block outline-none focus:outline-none cursor-pointer group'
            >
              <div className='w-6 h-0.5 transform rotate-45 translate-y-0.5 group-hover:bg-white bg-gray-200 duration-200' />
              <div className='w-6 h-0.5 transform -rotate-45 group-hover:bg-white bg-gray-200 duration-200' />
            </button>
          </div>
        </nav>

        <div className='flex-1 overflow-auto pb-10'>
          <div className='mt-5 py-5'>
            <figure className='m-0 p-0 bg-gray-200 rounded-full w-20 h-20 grid place-items-center mx-auto'>
              <img src='/logo.svg' className='w-15 h-15' alt='logo' />
            </figure>
          </div>
          <ul onClick={() => setNav(prev => !prev)} className='border-b border-gray-100 pb-10'>
            <li>
              <Link
                to='/'
                className={`text-gray-700 mx-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2 ${
                  pathname === '/' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
                }`}
              >
                <i className='fas fa-home mr-2' />
                Home
              </Link>
            </li>

            <li>
              <Link
                to='/kid-tech'
                className={`text-gray-700 mx-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2 ${
                  pathname === '/kid-tech' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
                }`}
              >
                <i className='fas fa-fire mr-2' />
                Kid Tech
              </Link>
            </li>
            <li>
              <Link
                to='/weekend-camp'
                className={`text-gray-700 mx-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2 ${
                  pathname === '/weekend-camp' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
                }`}
              >
                <i className='fas fa-fire-alt mr-2' /> Kids Weekend Camp
              </Link>
            </li>

            <li>
              <Link
                to='/pricing'
                className={`text-gray-700 mx-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2 ${
                  pathname === '/pricing' ? 'border-b-2 border-red-900 pb-1 max-w-max' : ''
                }`}
              >
                <i className='fas fa-tag mr-2' /> Pricing
              </Link>
            </li>
            {/* THIS FEATURE WILL BE MADE PUBLIC SOON
          <Link
            to='/discover-mentors'
            className={`block ml-3 mt-4 lg:inline-block lg:mt-0 text-gray-800 font-semibold hover:text-gray-500 ${
              pathname === '/discover-mentors' ? 'border-b-2 border-red-900 pb-1' : ''
            }`}
          >
            Discover Mentors
          </Link> */}
            <li>
              <a
                href='https://www.blog.mentortribes.com'
                className='text-gray-700 mx-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
              >
                <i className='fas fa-blog mr-2' /> Blog
              </a>
            </li>
          </ul>
          <ul className='mt-10'>
            {!userInfo.loading &&
              (userInfo.isAuthenticated ? (
                <>
                  <li>
                    <Link
                      to='/mentor-dashboard'
                      className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
                    >
                      <i className='fa fa-columns mr-2' /> Mentor&rsquo;s Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/logout'
                      className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
                    >
                      <i className='fa fa-sign-out-alt mr-2' /> Logout
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to='/add-mentor'
                      className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
                    >
                      Apply as a Mentor
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={pathname === '/kid-tech' ? '/kid-book-mentor' : '/book-mentor'}
                      className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
                    >
                      Book a mentor
                    </Link>
                  </li>
                </>
              ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
