import React, { useState } from 'react'
import { Calendar, Modal, Select, notification, Result, Form, Input, Button } from 'antd'
import academicAdvisor from '../images/academic-advisor.svg'
import { API, graphqlOperation } from 'aws-amplify'
import axios from '../services/axios'
import { createBookAdvisory } from '../graphql/mutations'

const ScheduleACall = () => {
  const [form] = Form.useForm()
  const { Item } = Form
  const [selected, setSelected] = useState('')
  const [date, setDate] = useState('')
  const [loading, setLoading] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [source, setSource] = useState('Other Medium')

  const options = [
    { time: '9:00 AM', id: '1' },
    { time: '9:20 AM', id: '2' },
    { time: '9:40 AM', id: '3' },
    { time: '10:00 AM', id: '4' },
    { time: '10:20 AM', id: '5' },
    { time: '10:40 AM', id: '6' },
    { time: '11:00 AM', id: '7' },
    { time: '11:20 AM', id: '8' },
    { time: '11:40 AM', id: '9' },
    { time: '12:00 PM', id: '10' },
    { time: '12:20 PM', id: '11' },
    { time: '12:40 PM', id: '12' },
    { time: '1:00 PM', id: '13' },
    { time: '1:20 PM', id: '14' },
    { time: '1:40 PM', id: '15' },
    { time: '2:00 PM', id: '16' },
    { time: '2:20 PM', id: '17' },
    { time: '2:40 PM', id: '18' },
    { time: '3:00 PM', id: '19' },
    { time: '3:20 PM', id: '20' },
    { time: '3:40 PM', id: '21' },
    { time: '4:00 PM', id: '22' },
    { time: '4:20 PM', id: '23' },
    { time: '4:40 PM', id: '24' }
  ]
  const onDateChange = value => {
    const selectedDate = value.format('DD-MM-YYYY')
    setDate(selectedDate)
  }
  const handleCallSubmit = () => {
    if (!date || !selected) {
      notification.warning({
        message: 'Please pick a Date and Time so we can reach out to you',
        duration: 6000
      })
    } else {
      setModalOpen(true)
    }
  }

  const handleModalSubmit = async values => {
    setLoading(true)
    const details = { ...values, callDate: date, callTime: selected, source }
    try {
      const res = await API.graphql(graphqlOperation(createBookAdvisory, { input: details }))
      if (res.data.createBookAdvisory.id) {
        await axios.post('/Eazy2con/book-advisory', {
          callDate: details?.callDate,
          callTime: details?.callTime,
          phone: details?.phone,
          email: details?.email,
          source: details?.source,
          fullName: details.fullName
        })
        setLoading(false)
        setIsSubmitted(true)
      } else if (res?.errors.length > 0) throw Error(res.errors[0].message)
    } catch (error) {
      setLoading(false)
      notification.error({
        message: 'Something went wrong, please Check your Network connection',
        duration: 5000
      })
    }
  }

  return (
    <div className='flex flex-wrap bg-gray-100 mx-0 lg:mx-32 py-5 md:py-15 rounded-2xl'>
      <div
        style={{ minWidth: '300px' }}
        className='bg-gray-100 pl-0 md:p-5 w-full md:w-1/2 mx-auto'
      >
        <p className='font-bold text-3xl text-center w-5/6 mx-auto mb-15'>
          Schedule a call for personalized recommendations
        </p>
        <div className='bg-white max-w-max mx-auto rounded-xl py-10 w-11/12'>
          <p className='mx-10 my-2 text-2xl font-bold'>What date works for you?</p>
          <Calendar
            className='rounded-xl w-5/6 lg:w-8/12'
            style={{
              margin: 'auto',
              paddingTop: '20px'
            }}
            fullscreen={false}
            onChange={onDateChange}
            color='secondary'
          />
        </div>
      </div>
      <div style={{ minWidth: '300px' }} className=' mx-auto w-10/12 md:w-1/2'>
        <div className='w-5/6 mx-auto'>
          <p className='font-normal text-xl mt-10 md:mt-0 ml-0 mb-2 text-center lg:text-left'>
            Meeting Duration
          </p>
          <div className='w-5/6 mx-auto lg:mx-0 py-3 bg-gray-50 rounded-lg'>
            <div className='text-lg font-bold text-center'>20 Minutes</div>
          </div>
          <p className='font-normal text-xl mx-auto text-center md:text-left my-2'>
            What time works best for you? (GMT+1)
          </p>
          <div className='overflow-scroll overflow-x-hidden max-h-96'>
            {options.map(option => (
              <button
                key={option.id}
                onClick={() => setSelected(option.time)}
                className={`text-lg text-center w-full mx-auto py-2 my-1 cursor-pointer duration-200 hover:bg-red-800 hover:text-white sm:mr-5  ${
                  selected === option.time ? 'bg-red-800 text-white' : 'bg-white text-gray-900'
                } rounded`}
              >
                {option.time}
              </button>
            ))}
          </div>
          <button
            onClick={() => handleCallSubmit()}
            className='w-full text-lg text-center mx-auto py-3 mt-5 bg-red-800 cursor-pointer duration-200 hover:bg-opacity-75 sm:mr-5 text-white font-semibold rounded-xl'
          >
            Schedule a quick call
          </button>
        </div>
      </div>
      <Modal
        title={
          <>
            <div className='mx-auto w-full'>
              <img className='mx-auto' src={academicAdvisor} alt='arrow' />
            </div>
            <button
              onClick={() => setModalOpen(false)}
              className='absolute top-4 right-4 outline-none focus:outline-none'
            >
              <i className='fa fa-times text-3xl text-gray-900' />
            </button>
            <h4 className='font-manrope font-bold text-3xl text-center text-gray-800'>
              How do we contact you?
            </h4>
          </>
        }
        centered
        footer={[]}
        visible={modalOpen}
        closable={false}
        width={450}
      >
        {isSubmitted ? (
          <Result
            status='success'
            title={<span>We have received your request Successfully</span>}
            subTitle={<p>We will contact you promptly!</p>}
          />
        ) : (
          <Form
            className='md:grid md:grid-cols-2 gap-x-4 gap-y-0 flex flex-col'
            onFinish={handleModalSubmit}
            size='large'
            form={form}
            layout='vertical'
          >
            <Item
              rules={[
                {
                  required: true,
                  message: 'Please input full name'
                }
              ]}
              className='mb-0'
              name='fullName'
              label='Full Name'
            >
              <Input
                className='border border-gray-200 mb-0 mt-0 rounded'
                style={{ background: '#F4F4F4' }}
                placeholder='John Doe'
              />
            </Item>
            <Item
              className='p-0 m-0'
              name='phone'
              label='Phone'
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input
                className='border border-gray-200 mb-0 mt-0 rounded'
                style={{ background: '#F4F4F4' }}
                placeholder='+1605000000'
              />
            </Item>
            <Item
              name='email'
              label='Email'
              rules={[
                {
                  required: true
                }
              ]}
              className='mb-0'
            >
              <Input
                className='border border-gray-200 mb-0 mt-0 rounded'
                style={{ background: '#F4F4F4' }}
                placeholder='example@gmail.com'
              />
            </Item>
            <Item
              className='p-0 m-0'
              name='source'
              label='How did you find us?'
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Select defaultValue='Other Medium' bordered onChange={value => setSource(value)}>
                {[
                  'Other Medium',
                  'Facebook',
                  'Google Search',
                  'From a Friend',
                  'Social Media Post'
                ].map(options => (
                  <Select.Option
                    className='border border-gray-200 mb-0 mt-0 rounded'
                    key={options}
                    value={options}
                  >
                    {options}
                  </Select.Option>
                ))}
              </Select>
            </Item>
            <Button
              loading={loading}
              htmlType='submit'
              className='col-span-2 w-full text-center h-15 mt-8  bg-red-900 hover:bg-red-800 active:bg-white hover:text-white text-white active:text-red-800 font-bold border border-red-700 rounded-lg'
            >
              Schedule a quick call
            </Button>
          </Form>
        )}
      </Modal>
    </div>
  )
}

export default ScheduleACall
