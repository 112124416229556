/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Upload,
  Modal,
  message,
  notification,
  Result
} from 'antd'
import { /* Storage, */ API, graphqlOperation } from 'aws-amplify'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { createBookAmentor } from '../../graphql/mutations'
import { featuredKidCourses } from '../../data/featured-kid-courses'
import axios from '../../services/axios'
import PropTypes from 'prop-types'
import PaymentButton from '../Payment'

const { TextArea } = Input
const { Item } = Form
const MEMBERSHIP_PLANS = [
  { name: 'Trial', price: '$0' },
  { name: 'Individual', price: '34,999' },
  { name: 'Family', price: '99,999' }
]

const BookSingleKidCourse = ({ isBookFormOpen, setBookFormOpen }) => {
  const history = useHistory()
  const [{ loading, isSubmitted, isTrial, email, name, phone, amountToPay }, setState] = useState({
    success: false,
    loading: false,
    errors: '',
    isSubmitted: false,
    email: '',
    name: '',
    phone: '',
    amountToPay: '',
    isTrial: false
  })
  const [form] = Form.useForm()
  const [currentPlan, setCurrentPlan] = useState({})
  const [islUploading, setUploading] = useState(false)
  const { id } = useParams()

  const { state } = useLocation()

  const featuredKidCourse = featuredKidCourses.find(course => course.id === id)

  const uploadProps = {
    name: 'file',
    beforeUpload: file => {
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 ? true : Upload.LIST_IGNORE
    },
    onChange: async info => {
      setUploading(true)
      if (
        info.file.status !== 'uploading' &&
        info.file.status !== 'removed' &&
        info.file.size / 1000000 < 35
      ) {
        try {
          const result = await Storage.put(info.file.uid, info.file.originFileObj, {
            level: 'public',
            contentType: info.file.type
          })

          const imagurl = await Storage.get(result.key, {
            contentType: info.file.type,
            level: 'public'
          })
          form.setFieldsValue({ image: imagurl || result.key })
          setUploading(false)
        } catch (error) {
          notification.error({ message: error.message })
        }
      }
    }
  }

  const handleSubmit = async values => {
    const menteeInfo = {
      ...values,
      startEndDate: { start: values.startDate?._d, end: values.endDate?._d },
      course: featuredKidCourse?.title
    }
    const { startDate, endDate, ...others } = menteeInfo
    setState(prev => ({ ...prev, loading: true }))
    try {
      const res = await API.graphql(graphqlOperation(createBookAmentor, { input: others }))
      if (res.data.createBookAmentor.id) {
        await axios.post('/Eazy2con/book-mentor-success', {
          fullName: others?.fullName,
          email: others?.email,
          phone: others?.phone,
          plan: others?.type,
          date: `I want to start from ${others.startEndDate.start} - ${others.startEndDate.end} `,
          course: featuredKidCourse?.title
        })
        let amount = 0
        if (others.type === 'Trial') {
          setState(prev => ({
            ...prev,
            isTrial: true
          }))
        } else if (others.type === 'Individual') {
          amount = 34999
        } else if (others.type === 'Family') {
          amount = 99999
        }
        setState(prev => ({
          ...prev,
          success: true,
          loading: false,
          isSubmitted: true,
          email: others?.email,
          phone: others?.phone,
          name: others?.fullName,
          amountToPay: amount
        }))
        window.localStorage.setItem('book-mentor-id', res.data.createBookAmentor.id)
        notification.success({
          message: 'Mentor successfully booked!',
          description: 'We will reach out to you soon',
          duration: 2000
        })
      } else if (res?.errors.length > 0) throw Error(res.errors[0].message)
    } catch (error) {
      setState({ error: true, success: false, loading: false })
      notification.error({
        message: error.errors
          ? error.errors[0].message
          : 'Something went wrong, please your Network connection',
        duration: 5000
      })
    }
  }

  useEffect(() => {
    if (state && state.type) {
      const currentPlan = MEMBERSHIP_PLANS.find(({ name }) => name === state.type)
      setCurrentPlan(currentPlan)
    } else {
      setCurrentPlan(MEMBERSHIP_PLANS[2])
    }
  }, [state, currentPlan])

  return (
    <Modal
      title={
        <div className='flex items-center justify-between'>
          <h4 className='font-manrope font-semibold text-base text-gray-900'>
            Book a Mentor for {featuredKidCourses.title}
          </h4>
          <button
            onClick={() => setBookFormOpen(false)}
            className='outline-none focus:outline-none'
          >
            <i className='fa fa-times text-gray-900' />
          </button>
        </div>
      }
      centered
      footer={[]}
      visible={isBookFormOpen}
      closable={false}
      width={700}
    >
      {isSubmitted ? (
        isTrial ? (
          <div>
            <Result
              status='success'
              title='You have Successfully Booked a Trial'
              subTitle={
                <>
                  <h2 className='text-base'>
                    We are sure to win you over during these few days, Thanks for booking a trial
                    with us.
                  </h2>
                </>
              }
              extra={[
                <Link
                  to='/'
                  key='console'
                  className='font-manrope cursor-pointer duration-200 py-3 px-6 hover:bg-opacity-75 sm:mr-5 bg-green-600 text-white hover:text-white font-semibold rounded'
                >
                  Back To Website
                </Link>
              ]}
            />
          </div>
        ) : (
          <div>
            <Result
              status='success'
              title={
                <span className=''>Successfully Registered, we will reach out to you soon</span>
              }
              subTitle={
                <>
                  <h2 className=' text-base'>
                    Thanks for booking a mentor for your kids, we have received your request
                    <br /> So expect emails or call from us.
                  </h2>
                  <p className=''>Thanks</p>
                  <div className='sm:flex justify-center mt-5'>
                    <PaymentButton
                      details={{
                        email: email,
                        amount: amountToPay,
                        name: name,
                        phone: phone,
                        buttonLabel: 'Pay now',
                        redirectUrl: '/book-mentor-payment'
                      }}
                    />
                    <button
                      className='px-10 py-2 block w-full max-w-max mx-auto sm:mx-0 font-manrope mt-5 sm:mt-0 cursor-pointer duration-200 hover:bg-opacity-75 bg-btn-primary text-white font-semibold rounded'
                      onClick={() =>
                        history.push('/payment-invoice', {
                          email,
                          name,
                          amount: amountToPay,
                          item: `Kid Mentor for ${featuredKidCourse.title}`
                        })
                      }
                    >
                      Pay Later
                    </button>
                  </div>
                </>
              }
            />
          </div>
        )
      ) : (
        <Form
          onFinish={handleSubmit}
          size='large'
          form={form}
          className='md:grid md:grid-cols-2 gap-4 flex flex-col '
          layout='vertical'
        >
          <Item
            rules={[
              {
                required: true,
                message: 'Please input full name'
              }
            ]}
            name='fullName'
            label='Full Name'
          >
            <Input placeholder='John Doe' />
          </Item>
          <Item
            name='phone'
            label='Phone'
            rules={[
              {
                required: true,
                message: 'Please input phone'
              }
            ]}
          >
            <Input placeholder='+1605000000' />
          </Item>
          <Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                message: 'Please input email address'
              }
            ]}
          >
            <Input placeholder='example@gmail.com' />
          </Item>
          <Item
            name='type'
            label='Membership Plan'
            tooltip={
              <p>
                See{' '}
                <Link to='/pricing' className='text-blue-400'>
                  pricing
                </Link>{' '}
                for details
              </p>
            }
            initialValue={currentPlan.name}
            rules={[
              {
                required: true,
                message: 'Select membership type'
              }
            ]}
          >
            <Select
              placeholder={
                <div className='flex justify-between'>
                  <span>{currentPlan.name}</span>
                  <span>{currentPlan.price}</span>
                </div>
              }
            >
              {MEMBERSHIP_PLANS.map(({ name, price }) => (
                <Select.Option key={name} value={name}>
                  <div className='flex justify-between'>
                    <span>{name}</span>
                    <span>₦ {price}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Item>
          {/* <Item
            name='image'
            label='If you cannot explain what you want to learn in words, you can upload a video or images'
          >
            <Upload {...uploadProps}>
              <Button>Upload any relevant information</Button>
            </Upload>
          </Item> */}
          <Item
            name='startDate'
            label='When do you want to Start?'
            rules={[
              {
                required: true,
                message: "Tell us when you're comfortable to start"
              }
            ]}
          >
            <DatePicker className='w-full' placeholder='Start Date' format='YYYY-MM-DD' />
          </Item>
          <Item
            name='endDate'
            label='When do you want to Stop?'
            rules={[
              {
                required: true,
                message: 'Please tell us when you want to End'
              }
            ]}
          >
            <DatePicker className='w-full' placeholder='End Date' format='YYYY-MM-DD' />
          </Item>
          <Item name='whyLearnTheCourse' label='Why do you want to learn this (optional)'>
            <Select placeholder='Why learn this course'>
              {[
                'Acquire new skill',
                'Needed for current project',
                'I am a novice',
                'I want to know the best practice',
                'This is required for me to pass a course',
                'Trying things out',
                'others'
              ].map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name='courseDetails'
            className='col-span-2'
            label='More details or links to an example of what you want to learn (optional)'
          >
            <TextArea
              cols={3}
              rows={4}
              placeholder='Describe your current knowledge on the course you want to learn'
            />
          </Item>
          <Button
            loading={loading}
            // disabled={islUploading}
            htmlType='submit'
            className='col-span-2 w-40 mx-auto bg-transparent hover:bg-red-800 active:bg-red-900 text-brown-500 font-semibold hover:text-white px-8 border border-red-800 hover:border-transparent rounded'
          >
            Book
          </Button>
        </Form>
      )}
    </Modal>
  )
}

BookSingleKidCourse.propTypes = {
  isBookFormOpen: PropTypes.bool,
  setBookFormOpen: PropTypes.any
}

export default BookSingleKidCourse
