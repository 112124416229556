/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBookAmentor = /* GraphQL */ `
  mutation CreateBookAmentor(
    $input: CreateBookAmentorInput!
    $condition: ModelBookAmentorConditionInput
  ) {
    createBookAmentor(input: $input, condition: $condition) {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const updateBookAmentor = /* GraphQL */ `
  mutation UpdateBookAmentor(
    $input: UpdateBookAmentorInput!
    $condition: ModelBookAmentorConditionInput
  ) {
    updateBookAmentor(input: $input, condition: $condition) {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const deleteBookAmentor = /* GraphQL */ `
  mutation DeleteBookAmentor(
    $input: DeleteBookAmentorInput!
    $condition: ModelBookAmentorConditionInput
  ) {
    deleteBookAmentor(input: $input, condition: $condition) {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const createBookKidmentor = /* GraphQL */ `
  mutation CreateBookKidmentor(
    $input: CreateBookKidmentorInput!
    $condition: ModelBookKidmentorConditionInput
  ) {
    createBookKidmentor(input: $input, condition: $condition) {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const updateBookKidmentor = /* GraphQL */ `
  mutation UpdateBookKidmentor(
    $input: UpdateBookKidmentorInput!
    $condition: ModelBookKidmentorConditionInput
  ) {
    updateBookKidmentor(input: $input, condition: $condition) {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const deleteBookKidmentor = /* GraphQL */ `
  mutation DeleteBookKidmentor(
    $input: DeleteBookKidmentorInput!
    $condition: ModelBookKidmentorConditionInput
  ) {
    deleteBookKidmentor(input: $input, condition: $condition) {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const createMentor = /* GraphQL */ `
  mutation CreateMentor(
    $input: CreateMentorInput!
    $condition: ModelMentorConditionInput
  ) {
    createMentor(input: $input, condition: $condition) {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateMentor = /* GraphQL */ `
  mutation UpdateMentor(
    $input: UpdateMentorInput!
    $condition: ModelMentorConditionInput
  ) {
    updateMentor(input: $input, condition: $condition) {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteMentor = /* GraphQL */ `
  mutation DeleteMentor(
    $input: DeleteMentorInput!
    $condition: ModelMentorConditionInput
  ) {
    deleteMentor(input: $input, condition: $condition) {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const createBookCourse = /* GraphQL */ `
  mutation CreateBookCourse(
    $input: CreateBookCourseInput!
    $condition: ModelBookCourseConditionInput
  ) {
    createBookCourse(input: $input, condition: $condition) {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateBookCourse = /* GraphQL */ `
  mutation UpdateBookCourse(
    $input: UpdateBookCourseInput!
    $condition: ModelBookCourseConditionInput
  ) {
    updateBookCourse(input: $input, condition: $condition) {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookCourse = /* GraphQL */ `
  mutation DeleteBookCourse(
    $input: DeleteBookCourseInput!
    $condition: ModelBookCourseConditionInput
  ) {
    deleteBookCourse(input: $input, condition: $condition) {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const createBootcamp = /* GraphQL */ `
  mutation CreateBootcamp(
    $input: CreateBootcampInput!
    $condition: ModelBootcampConditionInput
  ) {
    createBootcamp(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBootcamp = /* GraphQL */ `
  mutation UpdateBootcamp(
    $input: UpdateBootcampInput!
    $condition: ModelBootcampConditionInput
  ) {
    updateBootcamp(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBootcamp = /* GraphQL */ `
  mutation DeleteBootcamp(
    $input: DeleteBootcampInput!
    $condition: ModelBootcampConditionInput
  ) {
    deleteBootcamp(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMentorForm = /* GraphQL */ `
  mutation CreateMentorForm(
    $input: CreateMentorFormInput!
    $condition: ModelMentorFormConditionInput
  ) {
    createMentorForm(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const updateMentorForm = /* GraphQL */ `
  mutation UpdateMentorForm(
    $input: UpdateMentorFormInput!
    $condition: ModelMentorFormConditionInput
  ) {
    updateMentorForm(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const deleteMentorForm = /* GraphQL */ `
  mutation DeleteMentorForm(
    $input: DeleteMentorFormInput!
    $condition: ModelMentorFormConditionInput
  ) {
    deleteMentorForm(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const createContactUs = /* GraphQL */ `
  mutation CreateContactUs(
    $input: CreateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    createContactUs(input: $input, condition: $condition) {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateContactUs = /* GraphQL */ `
  mutation UpdateContactUs(
    $input: UpdateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    updateContactUs(input: $input, condition: $condition) {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactUs = /* GraphQL */ `
  mutation DeleteContactUs(
    $input: DeleteContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    deleteContactUs(input: $input, condition: $condition) {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const createVisitor = /* GraphQL */ `
  mutation CreateVisitor(
    $input: CreateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    createVisitor(input: $input, condition: $condition) {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVisitor = /* GraphQL */ `
  mutation UpdateVisitor(
    $input: UpdateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    updateVisitor(input: $input, condition: $condition) {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVisitor = /* GraphQL */ `
  mutation DeleteVisitor(
    $input: DeleteVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    deleteVisitor(input: $input, condition: $condition) {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCareersForm = /* GraphQL */ `
  mutation CreateCareersForm(
    $input: CreateCareersFormInput!
    $condition: ModelCareersFormConditionInput
  ) {
    createCareersForm(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const updateCareersForm = /* GraphQL */ `
  mutation UpdateCareersForm(
    $input: UpdateCareersFormInput!
    $condition: ModelCareersFormConditionInput
  ) {
    updateCareersForm(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const deleteCareersForm = /* GraphQL */ `
  mutation DeleteCareersForm(
    $input: DeleteCareersFormInput!
    $condition: ModelCareersFormConditionInput
  ) {
    deleteCareersForm(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createWebinarAttendee = /* GraphQL */ `
  mutation CreateWebinarAttendee(
    $input: CreateWebinarAttendeeInput!
    $condition: ModelWebinarAttendeeConditionInput
  ) {
    createWebinarAttendee(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const updateWebinarAttendee = /* GraphQL */ `
  mutation UpdateWebinarAttendee(
    $input: UpdateWebinarAttendeeInput!
    $condition: ModelWebinarAttendeeConditionInput
  ) {
    updateWebinarAttendee(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebinarAttendee = /* GraphQL */ `
  mutation DeleteWebinarAttendee(
    $input: DeleteWebinarAttendeeInput!
    $condition: ModelWebinarAttendeeConditionInput
  ) {
    deleteWebinarAttendee(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const createBookAdvisory = /* GraphQL */ `
  mutation CreateBookAdvisory(
    $input: CreateBookAdvisoryInput!
    $condition: ModelBookAdvisoryConditionInput
  ) {
    createBookAdvisory(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
export const updateBookAdvisory = /* GraphQL */ `
  mutation UpdateBookAdvisory(
    $input: UpdateBookAdvisoryInput!
    $condition: ModelBookAdvisoryConditionInput
  ) {
    updateBookAdvisory(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookAdvisory = /* GraphQL */ `
  mutation DeleteBookAdvisory(
    $input: DeleteBookAdvisoryInput!
    $condition: ModelBookAdvisoryConditionInput
  ) {
    deleteBookAdvisory(input: $input, condition: $condition) {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
