import React from 'react'
import PropTypes from 'prop-types'

const SentMessage = ({ message }) => {
  return (
    <div className='bg-white max-w-max px-5 py-2 shadow relative rounded-t-xl rounded-l-xl mb-4 ml-auto mr-5'>
      <h4 className='font-manrope text-base'>{message.text}</h4>
    </div>
  )
}
SentMessage.propTypes = {
  message: PropTypes.string
}
export default SentMessage
