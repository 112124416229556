import React from 'react'

const Comment = () => {
  return (
    <div className='flex mb-2'>
      <svg
        className='h-6 w-6 text-teal mr-4'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
      >
        <path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' />
      </svg>
      <div>
        <p className='font-bold p-0 m-0'>
          Anonymous <i className='text-xs font-normal text-cool-gray-500'>~ 2020</i>
        </p>
        <p className='text-sm'>
          We recommend this course for you. Please note that this is a system generated comment.
        </p>
      </div>
    </div>
  )
}
export default Comment
