import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Formik, Form } from 'formik'
import PasswordInput from '../components/auth/PasswordInput'
import Input from '../components/auth/Input'
import { notification } from 'antd'
import * as Yup from 'yup'
import loginImg from '../images/login.png'
import { Helmet } from 'react-helmet-async'

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required')
})

const confirmValidation = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .required('Please confirm Password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

const ForgotPassword = () => {
  const [isSubmmitting, setSubmitting] = useState(false)
  const [isEmailSent, setEmailSent] = useState(false)
  const history = useHistory()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(() => {
      notification.success({
        message: 'Logged In',
        duration: 10000,
        description: 'You are already logged in, check your mentorship dashboard'
      })
      history.push('/mentor-dashboard')
    })
  }, [])

  return (
    <div className='flex flex-wrap pt-12'>
      <Helmet>
        <meta name='description' content='Reset your password' />
        <title>Mentortribes | Password reset</title>
      </Helmet>
      <div className='hidden md:block w-full md:w-1/2'>
        <img
          src={loginImg}
          alt='connect people'
          className='bg-opacity-50 transition duration-500 ease-in-out'
        />
      </div>
      <div className='w-full md:w-1/2 px-4 md:px-16'>
        <div className='mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg'>
          <h1 className='text-center md:mt-12 text-4xl font-bold'>Forgot Password </h1>
          <p className='text-center'>
            {isEmailSent ? (
              <span>
                We have sent a code to your email, please enter the code to change your password{' '}
              </span>
            ) : (
              <span>Please enter your email you used to Sign Up to reset password </span>
            )}
          </p>
          {isEmailSent ? (
            <Formik
              initialValues={{
                code: '',
                password: '',
                confirm_password: ''
              }}
              validationSchema={confirmValidation}
              onSubmit={values => {
                setSubmitting(true)
                notification.destroy()
                Auth.forgotPasswordSubmit(values.email, values.code, values.password)
                  .then(res => {
                    setSubmitting(false)
                    notification.success({
                      message: 'Password Changed!',
                      duration: 10000,
                      description:
                        'Your password has been changed, you can logged in now with your new password'
                    })
                    history.push('/login')
                  })
                  .catch(err => {
                    setSubmitting(false)
                    notification.error({ message: err.message, duration: 10000 })
                  })
              }}
            >
              <Form>
                <div className=''>
                  <PasswordInput id='code' name='code' label='Code' />
                  <PasswordInput id='password' name='password' label='Password' />
                  <PasswordInput
                    id='confirm_password'
                    name='confirm_password'
                    label='Confrim Password'
                  />
                  <div className='mt-7'>
                    <div className='mt-7'>
                      <button
                        type='submit'
                        // disabled={!(user.name && user.email && user.message)}
                        className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                      >
                        {isSubmmitting ? (
                          <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                        ) : (
                          ''
                        )}{' '}
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          ) : (
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={LoginSchema}
              onSubmit={values => {
                setSubmitting(true)
                notification.destroy()
                Auth.forgotPassword(values.email)
                  .then(res => {
                    setSubmitting(false)
                    setEmailSent(true)
                    notification.success({
                      message: 'Email Sent!',
                      duration: 10000,
                      description: 'Check your email and enter the code with your new password'
                    })
                  })
                  .catch(err => {
                    setSubmitting(false)
                    notification.error({ message: err.message, duration: 10000 })
                  })
              }}
            >
              <Form>
                <div className=''>
                  <Input label='Email Address' id='email' name='email' type='email' />
                  <div className='flex justify-end mt-7 items-center'>
                    <Link to='/login' className='text-blue-800 font-semibold'>
                      Back to Login?
                    </Link>
                  </div>
                  <div className='mt-7'>
                    <div className='mt-7'>
                      <button
                        onClick={() => {}}
                        type='submit'
                        // disabled={!(user.name && user.email && user.message)}
                        className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                      >
                        {isSubmmitting ? (
                          <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                        ) : (
                          ''
                        )}{' '}
                        Submit Email
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
