import React from 'react'
import { Link } from 'react-scroll'
import girlImage from '../../images/weekend-camp/girl.png'
import waterMark from '../../images/weekend-camp/water-mark.png'

const Header = () => {
  return (
    <header
      style={{
        background: `url(${waterMark})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundBlendMode: 'overlay',
        backgroundRepeat: 'no-repeat'
      }}
      className='pt-5'
    >
      <div className='container mx-auto px-5'>
        <div className='md:grid grid-cols-12 pb-10'>
          <div className='col-start-6 row-start-1 col-span-7 '>
            <img src={girlImage} alt='' />
          </div>
          <div className='mt-5 md:mt-0 col-start-1 col-span-7 flex flex-col justify-center row-start-1'>
            <div>
              <h1
                style={{ color: '#18191F', lineHeight: '1.4em' }}
                className='font-manrope font-bold text-center lg:text-left text-3xl leading-snug md:text-5xl lg:text-6xl max-w-2xl'
              >
                Kids <span className='text-red-500'>Could</span> Also Learn On Weekends 😀
              </h1>
              <p className='text-base text-center lg:text-left text-gray-800 py-4 md:py-6 pr-8 leading-9'>
                Almost every aspect of our life revolves around technology. With the growth in
                technology, it is now crucial for kids, more so than ever, to acquire tech skills to
                be successful in their future.
              </p>
              <a href='#kids-weekend'>
                <Link
                  to='kids-weekend'
                  spy
                  smooth
                  offset={50}
                  duration={3000}
                  ignoreCancelEvents={false}
                  className='bg-btn-primary text-white mx-auto md:mx-0 max-w-max px-16 py-3 block my-5 text-lg rounded font-semibold hover:text-white duration-200 hover:bg-opacity-75 hover:shadow-md'
                >
                  Register
                </Link>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
