import React from 'react'
import { Helmet } from 'react-helmet-async'

const Policy = () => {
  return (
    <div className=' w-4/5 mx-auto leading-loose text-lg my-10'>
      <Helmet>
        <meta name='keywords' content='Mentortribes privacy policy, privacy' />
        <meta
          name='description'
          content='We value the data our clients share with us, this is our privacy policy'
        />
        <title>Mentortribes | Privacy Policy</title>
      </Helmet>
      <h2 className='font-black text-2xl'>MENTORTRIBES’ PRIVACY POLICY</h2>
      <p>
        MentorTribes value your privacy and we want to give you, our website visitors, the
        opportunity to understand what information we collect and how we treat this data.
      </p>
      <p className='font-black mt-5'>This Privacy Policy explains:</p>
      <ul className='list-disc ml-10'>
        <li>What kind of information do we collect?</li>
        <li>Why we collect the information</li>
        <li>What we do with the information </li>
        <li>What we do with the information </li>
        <li>How we share your information </li>
        <li>How we secure your information </li>
        <li>How to contact Us. </li>
      </ul>
      <h3 className='font-black mt-5'>What kind of information do we collect? </h3>
      <p>
        a) Information collected automatically when you visit our site: When navigating our websites
        and accessing or using our Services, MentorTribes will collect and store Cookies. “Cookies”
        are text files placed in your computer’s browser to store certain preferences and
        information, which may enhance your browsing experience on our website. Cookies may be used
        to personalize your website experience, such as recognizing you by name each and every time
        you return. We do not use Cookies to track or collect personal identification information
        from website users. You may adjust the settings on your browser to warn you before a Cookie
        is stored, block all Cookies or erase Cookies from your hard drive.
      </p>
      <p>
        b) Information is collected when you volunteer to identify yourself and provide requested
        information, such as when you write to us, or fill out certain forms or online data
        requests, which provides your name, telephone number, e-mail address, job and related
        information.
      </p>
      <h3 className='font-black mt-5'>Why do we collect personal information?</h3>
      <p>
        MentorTribes uses the information collected from you to: respond to your inquiries; consider
        your request or application, send you appropriate information in regards to news, events,
        job openings and related information you subscribed to, and for direct marketing purposes,
        including to send you newsletters, client alerts and information we think may interest you
        etc. We may also use such data for market and consumer studies regarding product types,
        demographics and consumer preferences. MentorTribes is authorized to use personal
        information based on our legitimate interest, such as for research and development, to
        market and promote our services and to protect our legal rights and interests, to the extent
        that your interests or your fundamental rights are not overridden; or your consent to do so
        for a specific purpose; or the need to process your data to comply with a legal obligation.
      </p>
      <h3 className='font-black mt-5'>What we do with Personal Information.</h3>
      <p>
        MentorTribes will retain your personal information for as long as necessary to fulfill the
        purposes for which it was collected or as necessary to comply with our legal obligations,
        resolve disputes, and maintain appropriate business records. Once the purpose has been
        fulfilled your personal information will be retained or blocked up due to the possibility of
        legal and governmental requirements and until the statute of limitation has elapsed.
      </p>
      <h3 className='font-black mt-5'>How is this information shared?</h3>
      <p>
        MentorTribes may share your personal information with affiliated companies. In addition,
        MentorTribes reserves the right to disclose information
      </p>
      <ol className='list-decimal list-inside'>
        <li>where required by law; or</li>
        <li>
          to comply with a valid legal process (such as a search warrant, subpoena or court order);
        </li>
        <li>
          or to protect MentorTribes’ rights or property, including without limitation in the event
          of a transfer of control of MentorTribes or substantially all of its assets; or
        </li>
        <li>during emergencies when safety is at risk.</li>
      </ol>

      <h3 className='font-black mt-5'>How we keep Personal Information and Access Right.</h3>
      <ul className='list-disc ml-10'>
        <li>
          <p>
            <strong>Storage of the Information</strong>
          </p>
          All of the information collected by MentorTribes through the Website is stored on secured
          servers, to shield them against unauthorized access and use.
        </li>
        <li>
          <p>
            <strong>Your access rights.</strong>
          </p>
          You can exercise your rights of access, rectification, erasure, restriction, opposition,
          personal information portability, and/ or withdrawal of consent regarding your personal
          information which is stored in our server by email to the following address:
          contact@mentortribes.com
        </li>
      </ul>
      <h3 className='font-black mt-5'>Contact Us</h3>
      <p>
        If you have questions or concerns regarding the way in which your personal data is being
        processed or regarding this Policy, please email us at contact@mentortribes.com
      </p>
    </div>
  )
}

export default Policy
