import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import ChatSidebar from '../components/ChatSidebar'
import SingleChat from '../components/SingleChat'
import PropTypes from 'prop-types'

const MentorChat = ({ userInfo }) => {
  const [chatId, setChatId] = useState('')
  const history = useHistory()
  // conso
  useEffect(() => {
    history.listen(() => setChatId(''))
  }, [])

  return (
    <div className='h-full flex-1 overflow-y-auto bg-gray-100'>
      <div className='flex h-full overflow-y-auto'>
        <ChatSidebar chatId={chatId} chats={userInfo.data.chat?.items} />
        <div
          className={`h-full overflow-y-autoflex flex-1 flex-col ${!chatId && 'hidden md:block'}`}
        >
          <Switch>
            <Route
              exact
              path='/mentor-dashboard/chat/'
              render={() => (
                <div className='h-full grid place-content-center'>
                  <h3 className='font-manrope text-xl font-semibold text-primary'>
                    No selected chat
                  </h3>
                </div>
              )}
            />
            <Route
              exact
              path='/mentor-dashboard/chat/:id'
              render={() => <SingleChat setChatId={setChatId} userInfo={userInfo} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}
MentorChat.propTypes = { userInfo: PropTypes.object.isRequired }

export default MentorChat
