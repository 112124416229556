import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const KidsPlanCard = ({ id, margin, title, price, type, color }) => {
  return (
    <div className='relative bg-white rounded-lg px-2 md:px-4 py-3'>
      <h4 className='text-3xl font-bold'>
        <span className='font-dm-serif-display'>{price}</span>
        {id === 3 ? '' : <sub className='text-gray-500 text-base font-normal'>/month</sub>}
      </h4>
      <h4 style={{ color: '#CD7F32' }} className='text-2xl my-3 font-dm-serif-display font-bold'>
        {type}
      </h4>
      <p className={`text-base text-gray-500 ${margin}`}>{title}</p>
      {/* <ul className='text-base'>
        {istOfPerks.map((perk, i) => (
          <li key={i} className='flex py-1 items-center'>
            <figure className='m-0 p-0 mr-3'>
              <img src={checkIcon} alt='check' />
            </figure>
            <h6 className='text-gray-500 text-base'>{perk}</h6>
          </li>
        ))}
      </ul> */}
      <Link
        to={
          type === 'Organisation'
            ? '/contact-us'
            : { pathname: '/kid-book-mentor', state: { type } }
        }
        className={`absolute bottom-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block w-11/12 text-center py-2 mt-15 text-white duration-200 hover:text-white hover:bg-opacity-75 px-5 rounded-md ${color}`}
      >
        Subscribe as {type}
      </Link>
    </div>
  )
}

export default KidsPlanCard

KidsPlanCard.propTypes = {
  margin: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}
