import React, { useRef, useEffect } from 'react'
import CountDownTimer from './CountDownTimer'

const BootcampPopup = () => {
  const popUpNav = useRef()
  const stickNav = () => {
    if (popUpNav.current) {
      if (window.pageYOffset >= +5) {
        popUpNav.current.style.position = 'fixed'
        popUpNav.current.style.paddingTop = '45px'
        popUpNav.current.style.top = '40px'
      } else {
        popUpNav.current.style.position = 'relative'
        popUpNav.current.style.top = 'auto'
        popUpNav.current.style.paddingTop = '16px'
      }
    }
  }
  useEffect(() => {
    document.addEventListener('scroll', stickNav)
    return () => document.removeEventListener('scroll', stickNav)
  }, [])

  return (
    <div
      ref={popUpNav}
      style={{ background: '#15161D' }}
      className='text-gray-100 w-full text-center px-3 pb-3 pt-3 z-10'
    >
      <div className='animate-pulse text-base font-bold'>
        The <span className='text-yellow-700'>MENTORTRIBES</span> Hire a Sales expert{' '}
        <CountDownTimer date='05/16/2022' />.{' '}
      </div>
    </div>
  )
}

export default BootcampPopup
