import React from 'react'
import { Collapse } from 'antd'
import exclamationIcon from '../../images/pricing/exclamation.svg'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const GeneralFaq = ({ faqs }) => {
  const { Panel } = Collapse

  return (
    <div className='bg-white pb-5 mt-5 container mx-auto px-5' style={{ borderRadius: '1rem' }}>
      <div className='max-w-3xl mx-auto'>
        <div className='p-5'>
          <div className='flex'>
            <div className='flex flex-col items-center justify-center mr-5'>
              <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
                <img src={exclamationIcon} alt='exclamation' />
              </figure>
            </div>
            <div>
              <h4 className='text-black text-xl font-semibold'>Frequently asked Questions </h4>
              <p className='text-gray-900'>
                You if you find your answer here, then use the{' '}
                <Link to='/contact-us' className='text-blue-500'>
                  {' '}
                  contact form
                </Link>{' '}
                to ask your question and we will surely reach out to you
              </p>
            </div>
          </div>
        </div>
        <Collapse
          accordion
          className='pl-10 bg-white border-0'
          expandIconPosition='right'
          expandIcon={item =>
            item.isActive ? (
              <span className='border border-gray-500 rounded-full p-0.5 px-1'>
                <i className='fa fa-minus' />
              </span>
            ) : (
              <span className='border border-gray-500 rounded-full p-0.5 px-1'>
                <i className='fa fa-plus' />
              </span>
            )
          }
        >
          {faqs?.map(({ question, answer }, index) => (
            <Panel className='text-lg font-manrope' header={question} key={index}>
              <div
                dangerouslySetInnerHTML={{ __html: answer }}
                className='text-base text-gray-600'
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

GeneralFaq.propTypes = {
  faqs: PropTypes.array
}

export default GeneralFaq
