import { Spin, Typography } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import Course from '../components/Course'
import { CourseContext } from '../components/Routes'
import SearchInput from '../components/SearchInput'

// TODO: fix debounce to delay user input
export function debounce(func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null

      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)

    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

/**
 *
 * @param {Array} arr The array of objects to search
 * @param {String} searchKey The search query input
 * @returns {Array} Array of objects of the found result
 */
function searcher(arr, searchKey) {
  const regex = new RegExp(searchKey.toLowerCase(), 'ig')
  return arr.filter(obj =>
    Object.keys(obj).some(key => {
      const str = obj[key]
      return (
        str !== null &&
        str !== undefined &&
        typeof str === 'string' &&
        regex.test(str.toLowerCase())
      )
    })
  )
}

const Courses = () => {
  const { items: dbCourses } = useContext(CourseContext)
  const [query, setQuery] = useState('')
  const [courses, setCourses] = useState([])
  const [searching, setSearching] = useState(false)
  const setDelay = debounce(target => setQuery(target.value), 300, false)

  useEffect(() => {
    if (query !== '' && query.length >= 3) {
      setSearching(true)
      const result = searcher(dbCourses, query)
      setCourses(result)
    } else {
      setSearching(false)
      setCourses(dbCourses)
    }
  }, [dbCourses, query, searching])

  const renderContent = () => {
    if (searching && courses?.length > 0) {
      return <Course data={courses} />
    }

    if ((!searching && courses?.length > 0) || courses?.length > 0) {
      return <Course data={courses} />
    }

    if (courses?.length <= 0 && searching) {
      return <Typography.Title level={5}>No result found</Typography.Title>
    }
    return <Spin className='w-full mx-auto my-1/5' size='large' />
  }

  return (
    <div className='bg-gray-100'>
      <h1 className='block w-full py-5 text-4xl font-bold leading-tight text-center text-brown-500'>
        All Modules
      </h1>
      <div className='mx-10 sm:mx-40 md:mx-60'>
        <SearchInput
          className='appearance-none text-center bg-transparent border-none w-full text-red-800 mr-3 py-1 text-lg px-2 leading-tight focus:outline-none'
          type='text'
          name='search'
          ariaLabel='Search Course'
          onChange={({ target }) => setDelay(target)}
          placeholder='Search for a course or a mentor...'
        />
      </div>
      <div className='mb-12 md:gap-5 md:mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-8'>
        {renderContent()}
      </div>
    </div>
  )
}

export default React.memo(Courses)
