/* eslint-disable prettier/prettier */
import { Button } from 'antd'
import React, { useState } from 'react'
import BootcampTracksModal from './BootcampTracksModal'
import tracks from './tracks'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const BootcampTracks = () => {
  const [modalDetails, setModalDetails] = useState({
    visible: false,
    data: null
  })

  const videos = [
    { title: 'YouTube Video', url: 'https://www.youtube.com/embed/Ze0g_Yc_Q5I' },
    { title: 'YouTube Video', url: 'https://www.youtube.com/embed/KbC4qvt90Dg' },
    { title: 'YouTube Video', url: 'https://www.youtube.com/embed/7XRK5zg5XtE' },
    { title: 'YouTube Video', url: 'https://www.youtube.com/embed/isPathpkWrk' }
  ]

  const settings = {
    dots: true,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className='py-5'>
      <div className='container mx-auto px-2 md:px-5'>
        <h3 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-3 text-gray-900 font-manrope'>
          Tracks
        </h3>
        <p className='max-w-2xl mx-auto text-base font-manrope text-center'>
          Bridging the gap between learners and professionals
        </p>
        <div className='grid my-10 grid-cols-1 overflow-auto md:grid-cols-2 xl:grid-cols-3 gap-y-10 gap-5'>
          {tracks.map(track => (
            <div key={track.id} className='shadow-1xl hover:shadow-2xl border-2 p-10 duration-500'>
              <div className='text-center'>
                <i className={`fa fa-${track.icon} fa-4x text-center text-green-400`} />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>{track.title}</h4>
              <p className='text-sm sm:text-base font-manrope text-center text-cool-gray-700 h-56 xxl:h-48'>
                {track.details}
              </p>
              <Button
                onClick={() => setModalDetails({ visible: true, data: track.tableData })}
                className='block h-12 w-44 bg-red-800 hover:bg-gray-300 text-center text-gray-100 hover:text-red-800 text-base mx-auto rounded-lg'
              >
                What to Expect
              </Button>
            </div>
          ))}
          {modalDetails.data && (
            <BootcampTracksModal
              setModalOpen={setModalDetails}
              modalOpen={modalDetails.visible}
              tableData={modalDetails.data}
            />
          )}
        </div>

        <h3
          id='watch-video'
          className='text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-3 text-gray-900 font-manrope'
        >
          Watch Video
        </h3>
        <div className='container bg-gray-100 p-10 mx-auto px-0 md:px-5 my-8'>
          <div className='max-w-4xl mx-auto'>
            <Slider {...settings}>
              {videos.map((video) => (
                <div key={video.url} className='w-full flex justify-center items-center'>
                  <iframe
                    className='mx-auto w-full p-1'
                    height='450'
                    src={video.url}
                    title={video.title}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BootcampTracks
