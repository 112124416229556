/* eslint-disable react/display-name */
import React from 'react'
import coverImage from '../images/courseCoverImage.png'
import Moment from 'react-moment'
import PropTypes from 'prop-types'

export const TABLES_CALENDAR_STRINGS = {
  sameDay: '[Today at] LT',
  lastDay: '[Yesterday at] LT',
  sameElse: 'D MMM YY LT'
}

function CreatedAt({ date, className }) {
  return (
    <Moment className={className} calendar={TABLES_CALENDAR_STRINGS}>
      {date}
    </Moment>
  )
}
CreatedAt.propTypes = { date: PropTypes.any, className: PropTypes.string }

export const data = [
  {
    id: 1,
    mentor: 'Ben Ejiofor',
    lang: 'React Hooks',
    courseTitle: 'How to use Redux Saga in a real project',
    video: 'https://youtu.be/arQvu7ge3Zw',
    coverPage: coverImage,
    duration: '3 weeks',
    topics: 13,
    rating: 3,
    students: 72,
    progress: '30%'
  },
  {
    id: 2,
    mentor: 'Afams Val',
    lang: 'React Components',
    courseTitle: 'redux-saga is a library that aims to make application side effects ',
    video: '',
    coverPage: coverImage,
    duration: '2 weeks',
    topics: 5,
    rating: 5,
    students: 112,
    progress: '65%'
  },
  {
    id: 3,
    mentor: 'Anucha Nora',
    lang: 'JWT',
    courseTitle: 'Json Web Token simplified',
    video: 'https://youtu.be/wrTgfYPYCco',
    coverPage: coverImage,
    duration: '4 days',
    topics: 2,
    rating: 1,
    students: 32,
    progress: '100%'
  },
  {
    id: 4,
    mentor: 'Kingsly',
    lang: 'JSON',
    courseTitle: 'How to use JSON in a given logic',
    video: '',
    coverPage: coverImage,
    duration: '1 weeks',
    topics: 7,
    rating: 4,
    students: 0,
    progress: '5%'
  }
]

export const courses = [
  'Frontend web Development',
  'Data Analytics',
  'Backend web Development(Django)',
  'Python Fundamentals',
  'Object Oriented Programming',
  'API Development(Django)',
  'Fullstack web Development',
  'UI UX Design',
  'Mobile App design',
  'Web Design',
  'Content writting',
  'Digital Marketting',
  'Content Creation',
  'Marketing Strategy',
  'Social Media Ads',
  'Web Analytics',
  'Content Writing',
  'Project Management',
  'Planning Projects',
  'Managing Risk/Changes',
  'Budgeting & Scheduling',
  'Agile Methodology/Scrum',
  'Web Application concepts',
  'Html & CSS',
  'Javascript',
  'React',
  'CSS Frameworks',
  'IT Career Guide',
  'Network Tools',
  'Programming Architecture',
  'Other'
]

export const MEMBERSHIP_PLANS = [
  { name: 'Trial', price: '$0' },
  { name: 'Team', price: '35k' },
  { name: 'Personal', price: '49k - 75k' },
  { name: 'Premium', price: '99k - 299k' }
]
