/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import axios from 'axios'
import { Skeleton } from 'antd'
import logo from '../images/logo.png'

const calendarStrings = {
  sameDay: 'H[h]',
  lastDay: '[Yesterday at] LT',
  lastWeek: '[last] ddd [at] LT',
  sameElse: 'D MMM YY'
}

const OurBlog = () => {
  const [posts, setPosts] = useState({ data: [], loading: true })

  useEffect(() => {
    axios
      .get('https://www.blog.mentortribes.com/api/posts', {
        method: 'GET',
        timeout: 360000
      })
      .then(res => {
        setPosts({ data: res.data.posts, loading: false })
      })
      .catch(err => {
        console.log(err.response)
      })
  }, [])

  return (
    <div className='md:pl-4 bg-black pt-14'>
      <div className='flex flex-wrap'>
        <div className='w-full py-8'>
          <h1 className='font-sans font-bold text-center text-white text-3xl'>
            <a href='https://www.blog.mentortribes.com/'>Our Blog</a>
          </h1>
          <p className='text-gray-400 text-center mb-0 text-xl my-4'>
            Stay enlightened with our top notch blog posts
          </p>
        </div>
      </div>
      <div className='grid md:hidden grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 px-5 md:px-20 pb-6'>
        {posts.loading
          ? [1, 2].map(id => (
              <div key={id} className=''>
                <div className='flex flex-wrap'>
                  <div className='w-full'>
                    <Skeleton.Input
                      className='w-full rounded-t-lg'
                      style={{ height: '12rem' }}
                      active
                    />
                    <div className='bg-white p-4 rounded-b-lg'>
                      <div className='flex items-center justify-between mb-6'>
                        <div className='flex items-center'>
                          <Skeleton.Input className='w-32' style={{ height: '1rem' }} active />
                        </div>
                        <Skeleton.Input className='w-12' style={{ height: '1rem' }} active />
                      </div>
                      <Skeleton.Input className='w-full' style={{ height: '1rem' }} active />
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </div>
            ))
          : posts.data.map(
              (
                { id, first_publication_date, data: { short_text, post_image, title, category } },
                i
              ) =>
                i < 3 ? (
                  <div key={id} className=''>
                    <div className='flex flex-wrap'>
                      <div className='w-full'>
                        <a
                          rel='noopener noreferrer'
                          target='_blank'
                          href={`https://blog.mentortribes.com/posts/${id}`}
                          className='block'
                        >
                          <img
                            src={post_image.url}
                            alt='connecting users'
                            className='rounded-t-lg object-cover h-52 w-full'
                          />
                        </a>
                        <div className='bg-white p-4 rounded-b-lg'>
                          <div className='flex items-center justify-between mb-6'>
                            <div className='flex items-center'>
                              <span className='text-sm text-gray-800'>
                                {category?.data?.category_name[0]?.text}
                              </span>
                            </div>
                            <div className='text-sm text-gray-800'>
                              <Moment calendar={calendarStrings}>{first_publication_date}</Moment>
                            </div>
                          </div>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={`https://blog.mentortribes.com/posts/${id}`}
                            className='block'
                          >
                            <h4 title={title[0].text} className='font-bold text-lg'>
                              {title[0].text}
                            </h4>
                          </a>
                          <p className='text-justify text-base mt-3'>{short_text}</p>
                          <div className='flex items-center justify-between mt-6'>
                            <div className='flex items-center'>
                              <span>
                                <img src={logo} alt='logo' width='40' />
                              </span>
                              <span className='text-blue-600 font-bold text-sm ml-2'>
                                Mentortribes
                              </span>
                            </div>
                            <div className='text-sm text-black'>
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`https://blog.mentortribes.com/posts/${id}`}
                                className='text-blue-600 font-bold'
                              >
                                Read more <i className='fas fa-arrow-right' />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
            )}
      </div>
      <div className='hidden md:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 px-5 md:px-20 pb-6'>
        {posts.loading
          ? [1, 2, 3, 4, 5, 6].map(id => (
              <div key={id} className=''>
                <div className='flex flex-wrap'>
                  <div className='w-full'>
                    <Skeleton.Input
                      className='w-full rounded-t-lg'
                      style={{ height: '12rem' }}
                      active
                    />
                    <div className='bg-white p-4 rounded-b-lg'>
                      <div className='flex items-center justify-between mb-6'>
                        <div className='flex items-center'>
                          <Skeleton.Input className='w-32' style={{ height: '1rem' }} active />
                        </div>
                        <Skeleton.Input className='w-12' style={{ height: '1rem' }} active />
                      </div>
                      <Skeleton.Input className='w-full' style={{ height: '1rem' }} active />
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </div>
            ))
          : posts.data.map(
              ({
                id,
                first_publication_date,
                data: { short_text, post_image, title, category }
              }) => (
                <div key={id} className=''>
                  <div className='flex flex-wrap'>
                    <div className='w-full'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://blog.mentortribes.com/posts/${id}`}
                        className='block'
                      >
                        <img
                          src={post_image.url}
                          alt='connecting users'
                          className='rounded-t-lg object-cover h-52 w-full'
                        />
                      </a>
                      <div className='bg-white p-4 rounded-b-lg'>
                        <div className='flex items-center justify-between mb-6'>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-800'>
                              {category?.data?.category_name[0]?.text}
                            </span>
                          </div>
                          <div className='text-sm text-gray-800'>
                            <Moment calendar={calendarStrings}>{first_publication_date}</Moment>
                          </div>
                        </div>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={`https://blog.mentortribes.com/posts/${id}`}
                          className='block'
                        >
                          <h4 title={title[0].text} className='font-bold text-lg'>
                            {title[0].text}
                          </h4>
                        </a>
                        <p className='text-justify text-base mt-3'>{short_text}</p>
                        <div className='flex items-center justify-between mt-6'>
                          <div className='flex items-center'>
                            <span>
                              <img src={logo} alt='logo' width='40' />
                            </span>
                            <span className='text-blue-600 font-bold text-sm ml-2'>
                              Mentortribes
                            </span>
                          </div>
                          <div className='text-sm text-black'>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={`https://blog.mentortribes.com/posts/${id}`}
                              className='text-blue-600 font-bold'
                            >
                              Read more <i className='fas fa-arrow-right' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
      </div>
    </div>
  )
}

export default OurBlog
