import axios from 'axios'
import axiosRetry from 'axios-retry'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://mail-servies.herokuapp.com'
      : 'http://localhos:5000'
})

axiosRetry(instance, { retries: 3 })

export default instance
