import React from 'react'
import PropTypes from 'prop-types'

const RecievedMessage = ({ message }) => {
  return (
    <div className='flex items-end mb-4'>
      <figure className=''>
        <img
          src='https://thefader-res.cloudinary.com/private_images/w_760,c_limit,f_auto,q_auto:best/2019_07_the_fader_4__web_irvefq/photo-francesco-nazardo-for-i-the-fader-i.jpg'
          alt=''
          className='w-8 h-8 rounded-full object-cover'
        />
      </figure>
      <div className='bg-white max-w-max chat-intructor-message px-5 ml-4 py-2 shadow rounded-t-xl rounded-r-xl relative'>
        <h4 className='font-manrope text-base'>{message.text}</h4>
      </div>
    </div>
  )
}

RecievedMessage.propTypes = {
  message: PropTypes.string
}

export default RecievedMessage
