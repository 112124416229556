import React, { useState } from 'react'
import { Modal, Button, Form, Input, Select, message, notification } from 'antd'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { createCourse } from '../../graphql/mutations'
import { COURSES, MENTORS } from '../../mentors/constants'

const { Item } = Form
const { Option } = Select
const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16
  }
}

const CourseForm = ({ setVisible }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const onFinish = async values => {
    const details = { ...values, topics: values.topics.split(',') }
    try {
      setConfirmLoading(true)
      await API.graphql(
        graphqlOperation(createCourse, {
          input: details
        })
      )
      setVisible(false)
      setConfirmLoading(false)
      message.success('Added successfully')
    } catch (error) {
      setVisible(true)
      setConfirmLoading(false)
      message.error('An error occured, check the console for details')
    }
    // setConfirmLoading(true)
    // setTimeout(() => {
    //   setVisible(false)
    //   setConfirmLoading(false)
    // }, 2000)
  }

  const onFinishFailed = errorInfo => {
    notification.error({ message: `Failed,''. ${errorInfo}` })
  }

  return (
    <>
      <Form
        {...layout}
        name='basic'
        initialValues={{
          remember: false
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {COURSES.map(({ label, name, rules, rows, textArea, placeholder }) => {
          return textArea === undefined ? (
            <Item label={label} name={name} rules={rules}>
              <Input placeholder={placeholder} />
            </Item>
          ) : (
            <Item label={label} name={name} rules={rules}>
              <Input.TextArea placeholder={placeholder} rows={rows || 1} autoSize={!rows && true} />
            </Item>
          )
        })}
        <Item
          label='Mentors'
          name='courseMentorsId'
          rules={[
            {
              required: true,
              message: 'You must select at least one mentor'
            }
          ]}
        >
          <Select placeholder='select one or more mentors' optionLabelProp='label'>
            {MENTORS.map(({ fullName, id }) => (
              <Option key={id} value={id} label={fullName.split(' ')[0]}>
                <div className='demo-option-label-item'>
                  <span role='img' aria-label='China'>
                    {fullName.split(' ')[0]}{' '}
                  </span>
                  {fullName.split(' ')[1]}
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        {/* <Item
          label='Course Logo'
          name='courseLogo'
          value=''
          rules={[
            {
              type: 'object'
            }
          ]}
        >
          <Upload>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Item> */}
        <Item {...tailLayout}>
          <Button type='primary' htmlType='submit' loading={confirmLoading}>
            Submit
          </Button>
        </Item>
      </Form>
    </>
  )
}

CourseForm.propTypes = {
  setVisible: PropTypes.object
}

const CourseModal = () => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Button onClick={() => setVisible(true)} type='m-2 bg-info-box-100 text-white'>
        Add New +
      </Button>

      <Modal
        title='Add courses'
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
        width='600px'
      >
        <CourseForm setVisible={setVisible} />
      </Modal>
    </>
  )
}

export default CourseModal
