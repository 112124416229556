import PropTypes from 'prop-types'
import React from 'react'
import { notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import { deleteCourse } from '../../graphql/mutations'
import Course from './Course'
import { Link } from 'react-router-dom'
import { SET_USER } from '../../constant'

const Courses = ({ userInfo, setUserInfo }) => {
  const handleDeleteCourse = async (setDeleting, id) => {
    setDeleting(true)
    try {
      const data = await API.graphql(
        graphqlOperation(deleteCourse, {
          input: {
            id
          }
        })
      )
      // eslint-disable-next-line no-undef
      userDispatch({
        type: SET_USER,
        payload: {
          ...userInfo,
          data: {
            ...userInfo.data,
            courses: {
              items: userInfo.courses.items.filter(({ id }) => id !== data.data.deleteCourse.id)
            }
          }
        }
      })
      setDeleting(false)
    } catch (error) {
      setDeleting(false)
      notification.error('Error')
      console.log('error', error)
    }
  }

  return (
    <div id='mentees' className='bg-white px-5 py-5 mt-5'>
      <div className='flex justify-between border-b items-center pb-4 border-gray-300'>
        <h4 className='text-xl font-manrope'>Courses</h4>
        <Link
          to='/mentor-dashboard/add-course'
          className='text-xs mt-2 font-manrope px-5 py-2 block max-w-max text-white hover:text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'
        >
          Add Course
        </Link>
      </div>
      <div className='grid grid-cols-4 gap-5 pt-10'>
        {userInfo?.courses?.items?.map(course => (
          <Course isMentor key={course.id} deleteCourse={handleDeleteCourse} course={course} />
        ))}
      </div>
    </div>
  )
}
Courses.propTypes = { userInfo: PropTypes.object.isRequired, setUserInfo: PropTypes.func }

export default Courses
