import webImage from '../images/feature-courses/web-dev.jpg'
import animation from '../images/feature-courses/animation.jpg'
import mlai from '../images/feature-courses/mlai.jpg'
import design from '../images/feature-courses/design.jpg'
import webdev from '../images/feature-courses/webdev.jpg'
import scratch from '../images/feature-courses/scratch.jpg'

const featuredKidCourses = [
  {
    title: 'Basic Animation Principles',
    id: 'animations',
    image: animation,
    video: 'https://www.youtube.com/embed/LCZpeS3l_zg',
    description:
      'Imagine how engaged your kids will be trying to figure out how the Cartoons they love so much are produced. Your kid/s will be able to cook up a few seconds of animation within a short period of time',
    programRightCaption:
      'Children who are eager to figure things out, and who love animated series will do well in this course',
    whatYouGet: [
      'A curriculum specially designed for Kids',
      'One-on-one video calls with your mentor 2-3 times a week(only for Personal Plan)',
      'Unlimited access to a mentor to get needed assistance',
      'Active huge community for support and help',
      'Fun activities and Assignments'
    ],
    aboutClass:
      'This is an Introductory course into Animation focused on the principles and theory behind animation. The aim of the course is to introduce students to the animation concept and process, from planning to the actual animation sequence. These workflows are the base for films and games animation. This course also offers an insight into the physics concepts in connection to the animation principles – e.g gravity. As animators, we use basic physics principles in order to capture the mechanics of a believable motion.'
  },
  {
    title: 'Fundamentals of Programming',
    id: 'fundamentals-of-programming',
    image: webdev,
    video: 'https://www.youtube.com/embed/3vZgREKKsO8',
    description:
      'Programming is never as complex as the movies make it look, young ones all around the world have already embraced programming. This course will give your ward the foundation needed to make a respectable name in the programming industry',
    programRightCaption:
      'Catch those young Zuckerbergs and give them the foundation they deserve to excel',
    whatYouGet: [
      'A curriculum specially designed for Kids',
      'One-on-one video calls with your mentor 2-3 times a week(only for Personal Plan)',
      'Unlimited access to a mentor to get needed assistance',
      'Active huge community for support and help',
      'Fun activities and Assignments'
    ],
    aboutClass:
      'This is a children level programming course designed to teach children the basic concepts of computer programming. The course will focus on the number system, simple logic gates theory and basic Python Syntax, helping students see how these concepts relate to computer programming in the real world. The course is intended to prepare students for a programming-oriented life path.'
  },
  {
    title: 'Basic Scratch Programming and Animations',
    id: 'scratch',
    image: scratch,
    video: 'https://www.youtube.com/embed/BoCyN0fzP8U',
    description:
      'We have created this course to reach young creative minds, Scratch is a useful tool designed to teach critical thinking for programming. Get your kids engaged building creative animations and Games.',
    programRightCaption:
      'Scratch allows your kids build fun games and animations while acquiring programmatic skills',
    whatYouGet: [
      'A curriculum specially designed for Kids',
      'One-on-one video calls with your mentor 2 to 3 days in a week (only for Personal Plan)',
      'Video calls in small groups with a mentor 3 to 4 days in a week (only for Small Group Plan)',
      'Unlimited access to a mentor to get needed assistance',
      'Active huge community for support and help',
      'Fun activities and Assignments'
    ],
    aboutClass:
      'This is a children level programming course designed to teach children the basic concepts of computer programming. The course will focus on the number system, helping students see the different number systems and how they relate to computer programming. The course is intended to prepare students for a programming-oriented life path.'
  },
  {
    title: 'Basic Web Development for Kids',
    id: 'basic-webdev',
    image: webImage,
    video: 'https://www.youtube.com/embed/hJHvdBlSxug',
    description:
      'Give your Child the opportunity to learn this life-long, worth-while and in-demand skill of Web Developement/Programming! Your kid could be the next Zuckerberg!',
    programRightCaption:
      "If your kids ever show interest in surfing the internet, here's a chance to show them how it's made",
    whatYouGet: [
      'A curriculum specially designed for Kids',
      'One-on-one video calls with your mentor 2-3 times a week(only for Personal Plan)',
      'Unlimited access to a mentor to get needed assistance',
      'Active huge community for support and help',
      'Fun activities and Assignments'
    ],
    aboutClass:
      'In this online Web Developement course for kids, Kids will learn HTML/CSS and JavaScript programming in a fun and friendly way while making a real website!'
  },
  {
    title: 'Graphic Design (Illustrator, Gimp)',
    id: 'graphic-design',
    image: design,
    video: 'https://www.youtube.com/embed/B7Y1Jn9rLLE',
    description:
      'Our goal here at Mentortribes is to show your kids how to use technology to be creators, rather than consumers only. Chidren learn best when they are excited Your young developer will have the time of his life making their ideas come to life!',
    programRightCaption: 'For kids who are drawn to creative art',
    whatYouGet: [
      'A curriculum specially designed for Kids',
      'One-on-one video calls with your mentor 2-3 times a week(only for Personal Plan)',
      'Unlimited access to a mentor to get needed assistance',
      'Active huge community for support and help',
      'Fun activities and Assignments'
    ],
    aboutClass:
      "If your kid shows an interest for creative art, if he draws and enjoys playing around with pencils and colors, making impressions on paper, here's how he can do this in a digital and more appreciable FormData, let us hone this skill."
  },
  {
    title: 'Fundamentals of Machine Learning and Artificial Intelligence',
    id: 'ml-ai',
    image: mlai,
    video: 'https://www.youtube.com/embed/1fJhya8WGns',
    description:
      'Kids will be introduced to the concept of Artificial Intelligence and Machine Learning. They will learn how these concepts and technology will be applicable in solving world problems',
    programRightCaption: 'This course if for the next phase of Technocrats',
    whatYouGet: [
      'A curriculum specially designed for Kids',
      'One-on-one video calls with your mentor 2-3 times a week(only for Personal Plan)',
      'Unlimited access to a mentor to get needed assistance',
      'Active huge community for support and help',
      'Fun activities and Assignments'
    ],
    aboutClass:
      'The future is Technology, as you may already know, the field of Artificial intelligence has brought upon us advancements that mean Computers can make prcise and accurate decisions that could save lives. This is fueled by their ability to learn, this course aims to position your kids strategically for the future that is almost upon us.'
  }
]

export { featuredKidCourses }
