import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import ScrollToBottom from 'react-scroll-to-bottom'
import { getChat, getVisitor } from '../../graphql/queries'
import { onCreateMessage } from '../../graphql/subscriptions'
import Chatbar from './Chatbar'
import SendMessageForm from '../../components/chat/SendMessageForm'
import RecievedMessage from '../../components/chat/RecievedMessage'
import SentMessage from '../../components/chat/SentMessage'
import PropTypes from 'prop-types'
import { notification } from 'antd'

const SingleChat = ({ userInfo, setChatId }) => {
  const [visitor, setVisitor] = useState({})
  const { id } = useParams()
  const [chat, setChat] = useState({})
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const fetchChat = async () => {
    setChatId(id)
    try {
      setLoading(true)
      const res = await API.graphql(
        graphqlOperation(getChat, {
          id
        })
      )
      setChat(res.data.getChat)
      if (res.data.getChat) {
        setMessages(
          res.data.getChat.messages.items.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
        )
        const res2 = await API.graphql(
          graphqlOperation(getVisitor, {
            id: res.data.getChat.visitorId
          })
        )
        setVisitor(res2.data.getVisitor)
      }
      setLoading(false)
    } catch (error) {
      console.log('error', error.errors ? error.errors[0].message : error)
    }
  }

  useEffect(() => {
    fetchChat()
  }, [id])

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onCreateMessage)).subscribe({
      next: ({ value }) => {
        if (value.data.onCreateMessage.chatId === id) {
          setMessages(prev => [...prev, value.data.onCreateMessage])
        }
      },
      error: error => console.warn(error)
    })
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!chat || chat.mentorId !== userInfo.data.id) {
        history.push('/mentor-dashboard/chat')
        notification.error({ message: 'Chat does not exist' })
      }
    }
  }, [loading])

  return (
    <div className='flex-1 flex flex-col overflow-y-auto h-full'>
      <Chatbar visitor={visitor} />
      <div className='flex-1 overflow-y-auto'>
        <div className='flex flex-col overflow-y-auto h-full'>
          <div className='flex-1 overflow-y-auto relative'>
            <div
              className={`w-8 h-8 bg-white rounded-full mx-auto absolute transform translate-y-5 delay-200 ${
                loading && 'opacity-100'
              } opacity-0 duration-200 shadow-md top-0 left-0 right-0 grid place-content-center`}
            >
              <i className='fa fa-spin fa-spinner' />
            </div>
            <ScrollToBottom className='h-full overflow-y-auto p-5'>
              {messages.map(msg =>
                msg.userId === userInfo.data.id ? (
                  <SentMessage message={msg} />
                ) : (
                  <RecievedMessage message={msg} />
                )
              )}
            </ScrollToBottom>
          </div>
          <SendMessageForm setMessages={setMessages} id={id} visitor={userInfo.data} />
        </div>
      </div>
    </div>
  )
}
SingleChat.propTypes = { userInfo: PropTypes.object.isRequired, setChatId: PropTypes.func }

export default SingleChat
