import React, { useState } from 'react'
import Moment from 'react-moment'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import { TABLES_CALENDAR_STRINGS } from '../data/courses'

const MentorApplicationModal = ({
  visible,
  setVisible,
  selectedMentor,
  handleMentorStatusChange
}) => {
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [declineLoading, setDeclineLoading] = useState(false)

  return (
    <Modal
      visible={visible}
      title={`${selectedMentor.firstname} Application`}
      // onOk={this.handleOk}
      onCancel={() => setVisible(false)}
      footer={[
        <button
          key={selectedMentor}
          onClick={() => handleMentorStatusChange('DECLINED', selectedMentor, setDeclineLoading)}
          className='bg-red-700 focus:outline-none px-5 py-1 mr-5 text-white rounded shadow-xl'
        >
          {declineLoading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
          Decline
        </button>,
        <button
          key={selectedMentor}
          onClick={() => handleMentorStatusChange('ACCEPTED', selectedMentor, setAcceptLoading)}
          className='bg-green-600 focus:outline-none px-5 py-1 text-white rounded shadow-xl'
        >
          {acceptLoading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
          Approve
        </button>
      ]}
    >
      <div className='bg-gray-100'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Full Name:</h4>
          <h4 className='font-semibold'>
            {selectedMentor.firstname} {selectedMentor.lastname}
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Email:</h4>
          <h4 className='font-semibold'>
            <a className='text-blue-500' href={`mailto:${selectedMentor.email}`}>
              {selectedMentor.email}
            </a>
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Status:</h4>
          <h4 className='font-semibold'>{selectedMentor.status}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Applied on:</h4>
          <h4 className='font-semibold'>
            <Moment calendar={TABLES_CALENDAR_STRINGS}>{selectedMentor.createdAt}</Moment>
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Phone No:</h4>
          <h4 className='font-semibold'>{selectedMentor.phone}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Github:</h4>
          <h4 className='font-semibold'>
            <a
              target='_blank'
              className='text-blue-500'
              href={selectedMentor.github}
              rel='noopener noreferrer'
            >
              {selectedMentor.github}
            </a>
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Country:</h4>
          <h4 className='font-semibold'>{selectedMentor.country}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Specialization:</h4>
          <h4 className='font-semibold'>{selectedMentor.specialization}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Years Of Experience:</h4>
          <h4 className='font-semibold'>{selectedMentor.yrsOfexperience}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Projects:</h4>
          <h4 className='font-semibold'>{selectedMentor.projects}</h4>
        </div>
      </div>
    </Modal>
  )
}
MentorApplicationModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  selectedMentor: PropTypes.any,
  handleMentorStatusChange: PropTypes.func
}

export default MentorApplicationModal
