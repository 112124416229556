import React from 'react'
import Heading from '../components/landing/Heading'
import SomeCourses from '../components/SomeCourses'
import Statistics from '../components/Statistics'
import HowItWorks from '../components/HowItWorks'
import ConnectToPro from '../components/ConnectToPro'
import OurBlog from '../components/OurBlog'
import Subscribe from '../components/Subscribe'
import ExpandKnowledge from '../components/ExpandKnowledge'
import ClientSpeakWidget from '../sharedComponents/clientSpeakWidget/ClientSpeakWidget'
import { CLIENT } from '../sharedComponents/clientSpeakWidget/constants'
import GroomYourKids from '../components/landing/GroomYourKids'
import BootcampPopup from '../components/BootcampPopup'
import { Helmet } from 'react-helmet-async'

const Landing = () => {
  return (
    <>
      <Helmet>
        <meta
          name='keywords'
          content='Learn coding, React Mentor, Mentor, one to one learning, kids, kids coding, nigeria kids, kids programming'
        />
        <meta
          name='description'
          content='Mentortribes connects you to a tech expert and also gives you access to a personal mentor'
        />
        <title>Mentortribes</title>
      </Helmet>
      <BootcampPopup />
      <Heading />
      <Statistics />
      <ExpandKnowledge />
      <SomeCourses />
      <HowItWorks />
      <ConnectToPro />
      <ClientSpeakWidget title='Testimonials' client={CLIENT} />
      <GroomYourKids />
      <OurBlog />
      <Subscribe />
      <ul className='bubbles'>
        <li className='rounded-full bg-gray-300 bg-opacity-25' />
        <li className='rounded-full bg-red-300 bg-opacity-25' />
        <li className='rounded-full bg-yellow-600 bg-opacity-25' />
        <li className='rounded-full bg-gray-300 bg-opacity-25' />
        <li className='rounded-full bg-yellow-800 bg-opacity-25' />
        <li className='rounded-full bg-red-400 bg-opacity-25' />
        <li className='rounded-full bg-yellow-400 bg-opacity-25' />
        <li className='rounded-full bg-gray-600 bg-opacity-25' />
      </ul>
    </>
  )
}

export default Landing
