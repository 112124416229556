import { API, graphqlOperation } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import BookKidsMentorsTable from '../components/BookKidsMentorsTable'
// import BookMentorsTable from '../components/BookMentorsTable'
import { listBookKidmentors } from '../graphql/queries'
import { onDeleteBookKidmentor } from '../graphql/subscriptions'
import Layout from './Layout'

const KidsApplication = () => {
  const [bookMentors, setBookMentors] = useState([])
  const [loading, setLoading] = useState(true)

  const getBookAMentors = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listBookKidmentors))
      const { items } = data.listBookKidmentors
      const listOfItems = items
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((item, index) => {
          item.key = item.id
          item.sn = items.length - index
          item.email = item.parentEmail
          return item
        })

      setLoading(false)
      setBookMentors(listOfItems)
    } catch (error) {
      console.error({
        description: error?.errors ? error?.errors[0].message : 'Something has gone wrong'
      })
    }
  }
  console.log(bookMentors)

  useEffect(() => {
    getBookAMentors()

    const subscription = API.graphql(graphqlOperation(onDeleteBookKidmentor)).subscribe({
      next: ({ value }) => {
        getBookAMentors()
      },
      error: error => console.warn(error)
    })

    // Stop receiving data updates from the subscription
    return () => subscription.unsubscribe()
  }, [])

  return (
    <Layout>
      <main className='w-full flex-grow p-4'>
        <h2 className='text-2xl pb-4 flex items-center px-10 font-bold'>
          <i className='fas fa-list mr-3' /> Kids Mentees Application
        </h2>
        <BookKidsMentorsTable loading={loading} bookMentors={bookMentors} />
      </main>
    </Layout>
  )
}

export default KidsApplication
