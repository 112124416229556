import React from 'react'
// import { Typography } from 'antd'
// import hiring from '../images/hiring.jpg'
// import CareerForm from '../components/CareerForm'

const Careers = () => {
  return (
    <div className='flex flex-wrap bg-primary'>
      <div className='w-full md:w-1/2 bg-white md:pl-6 px-4 py-0 flex flex-col justify-center'>
        <div
          style={{
            boxShadow: '0px 4px 8px rgba(24, 25, 31, 0.11)'
          }}
          className='w-full md:min-w-custom1 p-4 mx-auto my-5 rounded-xl'
        >
          {/* <Typography.Title
            level={3}
            className='text-center'
            style={{
              color: '#404445',
              fontFamily: 'Manrope',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '2em',
              lineHeight: '30px'
            }}
          >
            Join our fast growing firm
          </Typography.Title> */}
          {/* Desktop version */}
          <iframe
            src='https://docs.google.com/forms/d/e/1FAIpQLSfEL7tnwlwVSMPxAJQc5f6RtY15mn6eTujkHjrTGfFsU2WXSw/viewform?embedded=true'
            width='640'
            height='966'
            frameBorder='0'
            marginHeight='0'
            marginWidth='0'
            className='md:block hidden'
          >
            Loading…
          </iframe>
          {/* Mobile version */}
          <iframe
            src='https://docs.google.com/forms/d/e/1FAIpQLSfEL7tnwlwVSMPxAJQc5f6RtY15mn6eTujkHjrTGfFsU2WXSw/viewform?embedded=true'
            width='360'
            height='740'
            frameBorder='0'
            marginHeight='0'
            marginWidth='0'
            className='md:hidden block mx-auto overflow-scroll'
          >
            Loading…
          </iframe>
        </div>
      </div>
      <div className='w-full h-full hidden md:block md:w-1/2 px-2 md:m-auto bg-primary'>
        <h3 className='text-white animate-bounce text-6xl w-1/2 text-center mx-auto md:-mt-44 leading-loose'>
          We are hiring a sales expert
        </h3>
        {/* <img src={hiring} alt='We are hiring' className='h-full object-cover' /> */}
      </div>
    </div>
  )
}

export default Careers
