import React, { useState } from 'react'
import { Modal, notification } from 'antd'

import PropTypes from 'prop-types'
import { EMAIL_BASE_URL } from '../constant'
const EmailModal = ({ email }) => {
  const [contact, setContact] = useState({ email: email.toLowerCase(), subject: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [msg, setMessage] = useState(false)

  const handleCancel = () => {
    setShowModal(false)
  }

  const onChangeText = e => {
    setContact({ ...contact, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    const { email, subject, message } = contact
    if (email.length && subject.length && message.length) {
      setLoading(true)
      const sendMail = async () => {
        try {
          //  http://localhost:4000
          const { success } = await window
            .fetch(`${EMAIL_BASE_URL}/Eazy2con/admin-custom-template`, {
              method: 'POST',
              body: JSON.stringify(contact),
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(res => res.json())
          if (success) {
            setMessage(true)
            setLoading(false)
            setTimeout(() => setMessage(false), 4000)
          }
        } catch (error) {
          notification.error({ message: 'Network error' })
        }
      }
      sendMail()
    } else {
      notification.error({
        message: 'Validation Error',
        description: 'Please fill all field '
      })
    }
  }

  return (
    <>
      <p
        onClick={() => setShowModal(true)}
        className='cursor-pointer w-full rounded-t text-black text-base hover:bg-gray-300 hover:text-black py-1 px-4 block whitespace-no-wrap'
      >
        <i className='fas fa-envelope-open-text' /> Mail
      </p>
      <Modal
        visible={showModal}
        className=' mb-16 pb-10'
        title='Send Mail'
        // onOk={() => handleSubmit()}
        onCancel={() => handleCancel()}
        footer={[]}
      >
        <section className='mx-auto max-w-xl'>
          <div>
            {msg && (
              <h2 className='bg-green-600 px-4 py-2 mb-3 text-white'>Mail Sent Successfully</h2>
            )}
            <form className='flex flex-wrap pb-4'>
              <div className='w-1/2 pr-1'>
                <span className='uppercase text-sm text-gray-600 font-bold'>Subject</span>
                <input
                  className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                  type='text'
                  value={contact.subject}
                  onChange={onChangeText}
                  name='subject'
                />
              </div>
              <div className='w-1/2 pl-1'>
                <span className='uppercase text-sm text-gray-600 font-bold'>Email</span>
                <input
                  className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                  type='email'
                  value={contact.email}
                  onChange={onChangeText}
                  name='email'
                />
              </div>
              <div className='w-full mt-4'>
                <span className='uppercase text-sm text-gray-600 font-bold'>Message</span>
                <textarea
                  className='w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                  onChange={onChangeText}
                  value={contact.message}
                  minLength='11'
                  name='message'
                />
              </div>
              <div className='mt-8'>
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='px-3 h-10 text-sm font-bold bg-gray-900 text-gray-100  rounded-lg focus:outline-none focus:shadow-outline'
                >
                  {loading && <i className='fa fa-spin fa-spinner mr-2' />}
                  Send Mail
                </button>
              </div>
            </form>
          </div>
        </section>
      </Modal>
    </>
  )
}

EmailModal.propTypes = {
  email: PropTypes.string.isRequired
}

export default EmailModal
