import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PlayCircleFilled } from '@ant-design/icons'
import { Table, Space, Input, InputNumber, Popconfirm, Form, Button } from 'antd'
import PropTypes from 'prop-types'
import { MENTORS } from '../constants'
import Modal from 'antd/lib/modal/Modal'
import ReactPlayer from 'react-player'
const originData = []

// Dummy data generator for the table
for (let i = 0; i < 50; i++) {
  originData.push({
    key: i.toString(),
    title: `Redux ${i}`,
    language: `Javascript ${i}`,
    courseLogo:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.freebiesupply.com%2Flogos%2Fthumbs%2F1x%2Fredux-saga-logo.png&f=1&nofb=1',
    topics: MENTORS,
    videoUrl: 'https://youtu.be/5YTKhjR1gxY',
    duration: `London Park no. ${i}`,
    description: `London Park no. ${i}`,
    mentors: MENTORS
  })
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const Bookings = ({ courses }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState(originData)
  const [editingKey, setEditingKey] = useState('')
  const [visible, setVisible] = useState(false)

  const isEditing = record => record.key === editingKey

  const edit = record => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const columns = [
    {
      title: 'Course Logo',
      dataIndex: 'courseLogo',
      render: img => (
        <>
          <img src={img} className='h-20 w-20 p-2 ' alt='courses' />
        </>
      ),
      editable: true
    },
    {
      title: 'Title',
      dataIndex: 'title',
      // width: '15%',
      editable: true
    },
    {
      title: 'Programing language',
      dataIndex: 'language',
      // width: '25%',
      editable: true
    },

    {
      title: 'Topics',
      dataIndex: 'topics',
      render: topics => <p title={topics}>{topics.length}</p>
    },
    {
      title: 'Intro Video',
      dataIndex: 'videoUrl',
      editable: true,
      render: videoUrl => (
        <>
          <Button
            onClick={() => setVisible(true)}
            className='cursor-pointer hover:bg-red-800 my-auto active:bg-gray-400 bg-brown-400 flex items-center justify-center '
          >
            <PlayCircleFilled style={{ fontSize: '25px' }} />
          </Button>
          <Modal visible={visible} footer={false} onCancel={() => setVisible(false)} width='800px'>
            <div className='relative p-6 flex-auto'>
              <ReactPlayer url={videoUrl} />
            </div>
          </Modal>
        </>
      )
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      editable: true
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: des => <span title={des}>{des.substr(0, 100)}...</span>,
      editable: true
    },
    {
      title: 'Mentors',
      key: 'mentors',
      dataIndex: 'mentors',
      render: mentors => <span>{mentors?.firstname}</span>
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Link to='javascript:;' onClick={() => save(record.key)} className='mr-8 text-black'>
              Save
            </Link>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a className='text-black'>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Space size='middle' align='center'>
              <Button
                className='text-black'
                disabled={editingKey !== '' || true}
                onClick={() => edit(record)}
              >
                Edit
              </Button>
              <Button disabled className='text-black'>
                Delete
              </Button>
            </Space>
          </>
        )
      }
    }
  ]

  const save = async key => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => key === item.key)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    }
  })

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={courses}
        columns={mergedColumns}
        rowClassName='editable-row'
        pagination={{
          onChange: cancel
        }}
      />
    </Form>
  )
}

Bookings.propTypes = {
  courses: PropTypes.array
}

EditableCell.propTypes = {
  courses: PropTypes.array,
  editing: PropTypes.string,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string || PropTypes.number,
  record: PropTypes.object,
  index: PropTypes.number,
  children: PropTypes.object
}

export default Bookings
