import React from 'react'
import { Rate, Carousel } from 'antd'
import userAvatar from '../../images/profile.png'

const Reviews = () => {
  return (
    <div className='mt-7'>
      <h3 className='font-bold  font-manrope text-xl'>Reviews</h3>
      <h2 className='text-3xl font-manrope font-bold'>4.5</h2>
      <Rate allowHalf disabled defaultValue={4.5} />
      <div className='max-w-md mt-4'>
        <div className='flex items-center'>
          <h4 className='font-manrope font-semibold text-lg mr-5'>5.0</h4>
          <div className='bg-gray-200 h-1 rounded-sm flex-1'>
            <div className='bg-green-500 h-full w-4/5' />
          </div>
        </div>
        <div className='flex items-center'>
          <h4 className='font-manrope font-semibold text-lg mr-5'>4.0</h4>
          <div className='bg-gray-200 h-1 rounded-sm flex-1'>
            <div className='bg-green-600 h-full w-5/6' />
          </div>
        </div>
        <div className='flex items-center'>
          <h4 className='font-manrope font-semibold text-lg mr-5'>3.0</h4>
          <div className='bg-gray-200 h-1 rounded-sm flex-1'>
            <div className='bg-yellow-400 h-full w-2/5' />
          </div>
        </div>
        <div className='flex items-center'>
          <h4 className='font-manrope font-semibold text-lg mr-5'>2.0</h4>
          <div className='bg-gray-200 h-1 rounded-sm flex-1'>
            <div className='bg-orange-500 h-full w-2/6' />
          </div>
        </div>
        <div className='flex items-center'>
          <h4 className='font-manrope font-semibold text-lg mr-5'>1.0</h4>
          <div className='bg-gray-200 h-1 rounded-sm flex-1'>
            <div className='bg-orange-700 h-full w-1/6' />
          </div>
        </div>
      </div>
      <div className='mt-10 max-w-2xl'>
        <Carousel autoplay>
          <div className='mb-10'>
            <div className='flex items-center'>
              <figure>
                <img className='w-12 h-12 rounded-full' src={userAvatar} alt='' />
              </figure>
              <div className='ml-5'>
                <h4 className='font-bold text-base font-manrope'>Santos Brigh</h4>
                <div className='flex justify-between items-end'>
                  <Rate allowHalf disabled defaultValue={4.5} />
                  <h4 className='font-semibold font-manrope text-base ml-5'>4.5</h4>
                </div>
              </div>
            </div>
            <p className='font-manrope font-base font-medium mt-3'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis repudiandae cum, aut
              modi beatae magnam ipsam sit exercitationem. Provident incidunt ea magnam molestias
              adipisci consequuntur odio! Dolore consequuntur impedit eveniet maiores similique odio
              accusantium, inventore iste pariatur expedita deserunt quibusdam exercitationem. Nihil
              ipsa excepturi unde quaerat iusto maxime impedit ex.
            </p>
          </div>

          <div className='mb-10'>
            <div className='flex items-center'>
              <figure>
                <img className='w-12 h-12 rounded-full' src={userAvatar} alt='' />
              </figure>
              <div className='ml-5'>
                <h4 className='font-bold text-base font-manrope'>Santos Brigh</h4>
                <div className='flex justify-between items-end'>
                  <Rate allowHalf disabled defaultValue={4.5} />
                  <h4 className='font-semibold font-manrope text-base ml-5'>4.5</h4>
                </div>
              </div>
            </div>
            <p className='font-manrope font-base font-medium mt-3'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis repudiandae cum, aut
              modi beatae magnam ipsam sit exercitationem. Provident incidunt ea magnam molestias
              adipisci consequuntur odio! Dolore consequuntur impedit eveniet maiores similique odio
              accusantium, inventore iste pariatur expedita deserunt quibusdam exercitationem. Nihil
              ipsa excepturi unde quaerat iusto maxime impedit ex.
            </p>
          </div>
        </Carousel>
      </div>
      <div className='mt-7'>
        <h3 className='font-bold  font-manrope text-xl'>Add a Reviews</h3>
        <div className='max-w-xl mt-3'>
          <form>
            <Rate defaultValue={1} />
            <div className='mb-5 mt-2'>
              <label htmlFor='name' className='font-semibold block mb-2 text-base text-gray-900'>
                Name:
              </label>
              <input
                type='text'
                className='block w-full py-2 px-5 text-gray-900 outline-none focus:outline-none border border-gray-300 focus:border-blue-300 duration-200 rounded text-base'
              />
            </div>
            <div className='mb-5'>
              <label htmlFor='name' className='font-semibold block mb-2 text-base text-gray-900'>
                Message:
              </label>
              <textarea
                name=''
                className='block w-full py-2 px-5 text-gray-900 outline-none focus:outline-none border border-gray-300 focus:border-blue-300 duration-200 rounded text-base'
                id=''
                cols='30'
                rows='10'
              />
            </div>
            <div>
              <button className='bg-btn-primary text-white font-semibold block max-w-max px-5 py-2 rounded'>
                Rate Mentor
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Reviews
