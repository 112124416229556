import React from 'react'
import Eazy2conBtn from '../../sharedComponents/Eazy2conBtn'
import girlImage from '../../images/weekend-camp/girl.png'
import waterMark from '../../images/weekend-camp/water-mark.png'

const KidsHeading = () => {
  return (
    <header
      style={{
        background: `url(${waterMark})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundBlendMode: 'overlay',
        backgroundRepeat: 'no-repeat'
      }}
      className='pt-5'
    >
      <div className='container mx-auto px-5'>
        <div className='md:grid grid-cols-12 pb-10'>
          <div className='col-start-6 row-start-1 col-span-7 '>
            <img src={girlImage} alt='' />
          </div>
          <div className='mt-5 md:mt-0 col-start-1 col-span-7 flex flex-col justify-center row-start-1'>
            <div>
              <h1
                style={{ color: '#18191F', lineHeight: '1.4em' }}
                className='font-manrope font-bold text-center lg:text-left text-3xl leading-snug md:text-5xl lg:text-6xl max-w-2xl'
              >
                Every Child Deserves Tech Knowledge.
              </h1>
              <p className='text-base text-center lg:text-left text-gray-800 py-4 md:py-6 pr-8 leading-9'>
                It is arguably more important than ever for kids to grow up with a certain level of
                education in tech. That’s not to say children should necessarily be inundated with
                technology too early or too often. But it’s undeniable at this point that the more
                tech-savvy someone is, the more opportunities and hobbies that person will have at
                his or her disposal moving forward.
              </p>
              <Eazy2conBtn text='Book for Kid' navigateByUrl='/kid-book-mentor' />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default KidsHeading
