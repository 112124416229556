import React from 'react'
import iconOne from '../images/works-1.png'
import iconTwo from '../images/works-2.png'
import iconThree from '../images/works-3.png'

const HowItWorks = () => {
  return (
    <div className='py-12 bg-white mx-1'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/3 mx-auto'>
          <h1 className='font-sans font-bold text-center text-black text-3xl md:text-5xl py-6 pb-2 px-6'>
            How it works
          </h1>
        </div>
      </div>
      <div className='flex flex-wrap pt-12 px-8'>
        <div className='w-full md:w-1/3 md:pl-8 mb-14 md:mb-1'>
          <h1 className='font-sans font-bold text-center text-black text-xl px-3'>
            <img
              src={iconOne}
              alt='connect people'
              className='block mx-auto text-center bg-opacity-50 transition duration-500 ease-in-out rounded-3xl'
            />
            <p className='mt-4'>Create an account for FREE</p>
          </h1>
          <p className='font-sans text-black text-center text-base px-6'>
            Fill a book mentor form to get started.
          </p>
        </div>
        <div className='w-full md:w-1/3 md:pl-8 mb-14 md:mb-1'>
          <h1 className='font-sans font-bold text-center text-black text-xl px-3'>
            <img
              src={iconTwo}
              alt='connect people'
              className='block mx-auto text-center bg-opacity-50 transition duration-500 ease-in-out rounded-3xl'
            />
            <p className='mt-4'>Get connected with mentors</p>
          </h1>
          <p className='font-sans text-black text-center text-base px-6'>
            Mentortribes connects you to top mentors in your field of interest easily.
          </p>
        </div>
        <div className='w-full md:w-1/3 md:pl-8 mb-14 md:mb-1'>
          <h1 className='font-sans font-bold text-center text-black text-xl px-3'>
            <img
              src={iconThree}
              alt='connect people'
              className='block mx-auto text-center bg-opacity-50 transition duration-500 ease-in-out rounded-3xl'
            />
            <p className='mt-4'>Climb the ladder fast!</p>
          </h1>
          <p className='font-sans text-black text-center text-base px-6'>
            With the aid of mentors, you don’t get to make mistakes they made while at early stages.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
