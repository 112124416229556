import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { featuredCourses } from '../data/featured-courses'

const FeaturedCourses = () => {
  return (
    <div>
      <Helmet>
        <meta
          name='keywords'
          content='mentor for Frontend, backend mentor, data analysis mentor, UI/UX mentor'
        />
        <meta name='description' content='Some of the course that we have mentors for' />
        <title>Mentortribes | Featured Courses</title>
      </Helmet>
      <header style={{ background: '#0a3b4a' }} className='py-16'>
        <div className='container mx-auto px-5'>
          <div className='flex flex-col justify-center h-full'>
            <h1 className='text-2xl md:text-3xl text-white font-manrope font-bold'>
              Get Mentored in the following Career Paths/ Fields
            </h1>
            <div className='flex items-center mt-5'>
              <p className='text-gray-100 text-lg'>
                We cover skill which are essential to survive in the industry at large, we don&#39;t
                point you towards acquiring any irrelevant skill rather we direct you towards
                in-demand skills to help you excel in your respected field.
              </p>
            </div>
          </div>
        </div>
      </header>
      <div className='container mt-10 mx-auto px-5 pb-10'>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10'>
          {featuredCourses.map(course => (
            <div className='pb-5' key={course.id}>
              <Link to={`/featured-courses/${course.id}`}>
                <figure className='h-52 relative group'>
                  <img
                    className='w-full h-full object-cover rounded-t-lg'
                    src={course.image}
                    alt='course-image'
                  />
                  <div className='absolute inset-0 bg-black grid place-content-center duration-200 opacity-0 group-hover:opacity-75 px-3 rounded-t-lg'>
                    <p className='text-gray-100 text-sm text-center'>
                      {course.programRightCaption}
                    </p>
                  </div>
                </figure>
              </Link>
              <h4 className='text-base mt-3 text-cool-gray-900 font-manrope font-bold'>
                {course.title}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturedCourses
