import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { getMentor } from '../../graphql/queries'
import { Link, useParams } from 'react-router-dom'
import userAvatar from '../../images/profile.png'
import PropTypes from 'prop-types'

const ChatSideLink = ({ chat, chatId }) => {
  const [mentor, setMentor] = useState({})
  const params = useParams()
  const fetchMentor = async () => {
    try {
      const res = await API.graphql(graphqlOperation(getMentor, { id: chat.mentorId }))
      setMentor(res.data.getMentor)
      console.log('ress____', params, res.data)
    } catch (error) {}
  }

  useEffect(() => {
    fetchMentor()
  }, [])

  return (
    <Link
      to={`/mentor-dashboard/chat/${chat.id}`}
      className={`${
        chatId === chat.id && 'bg-gray-200'
      } cursor-pointer hover:bg-gray-100 duration-200 py-4 px-4 flex items-center`}
    >
      <figure className='m-0 p-0'>
        <img src={userAvatar} alt='' className='w-12 h-12 rounded-full object-cover' />
      </figure>
      <div className='ml-3 flex-1'>
        <div className='flex justify-between'>
          <div>
            <div>
              <h4 className='font-manrope font-semibold text-base'>
                {mentor?.firstname} {mentor?.lastname}
              </h4>
              <p className='font-manrope text-gray-400 font-medium'>{mentor?.specialization}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
ChatSideLink.propTypes = { chat: PropTypes.any, chatId: PropTypes.string }

export default ChatSideLink
