import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { createVisitor, createChat } from '../../graphql/mutations'
import Input from '../auth/Input'
import PropTypes from 'prop-types'

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string().required('Email is required')
})

const ChatForm = ({ setChatId, setVisitor }) => {
  const [isSubmmitting, setSubmmitting] = useState(false)
  const location = useLocation()
  const mentorId = new URLSearchParams(location.search).get('mentor')

  return (
    <div className='flex flex-col justify-center min-h-screen'>
      <div className='w-full bg-gray-50 max-w-md mx-auto py-8 px-5'>
        <div className=''>
          <figure className='m-0 p-0 bg-gray-200 rounded-full w-20 h-20 grid place-items-center mx-auto'>
            <img src='/logo.svg' className='w-15 h-15' alt='logo' />
          </figure>
        </div>
        <h4 className='text-lg mt-5 text-gray-800 text-center font-manrope font-semibold'>
          MentorTribes
        </h4>
        <h4 className='text-lg mt-5 text-gray-800 font-medium'>Help us reach you</h4>
        <Formik
          initialValues={{
            email: '',
            phone: '',
            fullName: ''
          }}
          validationSchema={ValidationSchema}
          onSubmit={async values => {
            setSubmmitting(true)
            try {
              const res = await API.graphql(
                graphqlOperation(createVisitor, {
                  input: values
                })
              )
              setSubmmitting(false)
              localStorage.setItem('visitor', JSON.stringify(res.data.createVisitor))
              setVisitor(res.data.createVisitor)
              const chatRes = await API.graphql(
                graphqlOperation(createChat, {
                  input: {
                    mentorId,
                    visitorId: res.data.createVisitor.id
                  }
                })
              )
              setChatId(chatRes.data.createChat.id)
            } catch (error) {}
          }}
        >
          <Form>
            <div className=''>
              <Input label='Email Address' id='email' name='email' type='email' />
              <Input label='Name' id='name' name='fullName' />
              <Input label='Phone' id='phone' name='phone' type='phone' />
              <div className='mt-7'>
                <button
                  type='submit'
                  // disabled={!(user.name && user.email && user.message)}
                  className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                >
                  {isSubmmitting && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                  Let&apos;s Chat
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}
ChatForm.propTypes = { setChatId: PropTypes.object, setVisitor: PropTypes.object }

export default ChatForm
