import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import PasswordInput from '../components/auth/PasswordInput'
import Input from '../components/auth/Input'
import { notification } from 'antd'
import * as Yup from 'yup'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import loginImg from '../images/login.png'
import ConfirmAccount from '../components/auth/ConfirmAccount'
import { userContext } from '../context/UserContext'
import { mentorByUserSubId } from '../graphql/queries'
import { getFileURL } from '../services/amplify'
import { Helmet } from 'react-helmet-async'

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required')
})

const Login = () => {
  const [isSubmmitting, setSubmitting] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const { userInfo, setUserInfo } = useContext(userContext)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        notification.success({
          message: 'Logged In',
          duration: 10000,
          description: 'You are already logged in, check your mentorship dashboard'
        })
        history.push('/mentor-dashboard')
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <div className='flex flex-wrap pt-12'>
      <Helmet>
        <meta name='description' content='Mentortribes login' />
        <title>Mentortribes | Login</title>
      </Helmet>
      <div className='hidden md:block w-full md:w-1/2'>
        <img
          src={loginImg}
          alt='connect people'
          className='bg-opacity-50 transition duration-500 ease-in-out'
        />
      </div>
      <div className='w-full md:w-1/2 px-4 md:px-16'>
        <ConfirmAccount
          password={password}
          isLogin
          isVisible={isVisible}
          setVisible={setVisible}
          setLoginSubmitting={setSubmitting}
          username={username}
        />

        <div className='mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg'>
          <h1 className='text-center md:mt-12 text-4xl font-bold'>Log In </h1>
          <Formik
            initialValues={{
              email: '',
              password: '',
              remember_me: false
            }}
            validationSchema={LoginSchema}
            onSubmit={async values => {
              setSubmitting(true)
              notification.destroy()
              try {
                const res = await Auth.signIn(values.email, values.password)
                const fetchedUserInfo = await API.graphql(
                  graphqlOperation(mentorByUserSubId, {
                    userSubId: res.attributes.sub
                  })
                )
                if (fetchedUserInfo.data.mentorByUserSubId.items.length > 0) {
                  setUserInfo(current => ({
                    ...current,
                    data: fetchedUserInfo.data.mentorByUserSubId.items[0],
                    loading: false,
                    isAuthenticated: true
                  }))
                  if (fetchedUserInfo.data?.mentorByUserSubId.items[0].imageUrl) {
                    const key = fetchedUserInfo.data?.mentorByUserSubId.items[0].imageUrl.key
                    const url = await getFileURL(key)
                    setUserInfo(current => ({
                      ...current,
                      data: {
                        ...current.data,
                        imageUrl: {
                          key,
                          url
                        }
                      },
                      loading: false
                    }))
                  }
                } else {
                  setUserInfo(current => ({
                    ...current,
                    loading: false,
                    data: {}
                  }))
                }
                setSubmitting(false)
                history.push('/mentor-dashboard')
                notification.success({
                  message: 'Login',
                  duration: 10000,
                  description: 'You are now logged in successfully!'
                })
              } catch (err) {
                setSubmitting(false)
                notification.error({ message: err.message, duration: 10000 })
                if (err?.name?.includes('UserNotConfirmedException')) {
                  setUsername(values.email)
                  setPassword(values.password)
                  setVisible(true)
                }
              }
            }}
          >
            <Form>
              <div className=''>
                <Input label='Email Address' id='email' name='email' type='email' />

                <div className='mt-7'>
                  <PasswordInput id='password' name='password' label='Password' />
                  <div className='flex justify-end mt-7 items-center'>
                    {/* <CheckboxBox name='remember_me' id='remember_me' label='Remember Me' /> */}
                    <Link to='/forgot_password' className='text-blue-800 font-semibold'>
                      Forgot Password?
                    </Link>
                  </div>
                  <div className='mt-7'>
                    <button
                      onClick={() => {}}
                      type='submit'
                      // disabled={!(user.name && user.email && user.message)}
                      className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                    >
                      {isSubmmitting ? (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      ) : (
                        ''
                      )}{' '}
                      Log In
                    </button>
                  </div>
                  <div className='mt-7 text-center text-lg'>OR</div>
                  {/* <div className='mt-7'>
                    <button
                      onClick={() => {
                        Auth.federatedSignIn({ provider: 'Google' })
                      }}
                      type='button'
                      className='py-3 bg-white text-black rounded-md w-full focus:outline-none focus:shadow-outline  text-lg'
                    >
                      <img
                        src={googleIcon}
                        alt='google icon'
                        className='inline object-contain mr-2'
                      />
                      Continue with Google
                    </button>
                  </div> */}
                  <div className='text-center my-7 text-lg'>
                    Don&lsquo;t Have an Account?
                    <Link to='/signup' className='text-blue-600 no-underline ml-2 hover:underline '>
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Login
