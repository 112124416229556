import React from 'react'
import ProfileImg from '../images/profile.png'

const Profile = () => {
  return (
    <div>
      <div className='shadow-lg rounded-lg mx-4 my-12 max-w-2xl '>
        <div className='flex items-start px-4 py-6'>
          <img
            className='w-12 h-12 rounded-full object-cover mr-4 shadow'
            src={ProfileImg}
            alt='avatar'
          />
          <div className=''>
            <div className='flex items-center justify-between'>
              <h2 className='text-xl font-bold text-brown-500 -mt-1'>Afams Val </h2>
              <small className='text-sm text-gray-700'>22h ago</small>
            </div>
            <p className='text-gray-700'>Joined 12 SEP 2020. </p>
            <p className='mt-3 text-gray-700 text-sm'>
              Lorem ipsum, dolor sit amet conse. Saepe optio minus rem dolor sit amet!
            </p>
            <div className='flex items-center mt-4 text-gray-700'>
              <svg className='h-6 w-6 fill-current text-brown-500' viewBox='0 0 512 512'>
                <path d='M239.208 343.937c-17.78 10.103-38.342 15.876-60.255 15.876-21.909 0-42.467-5.771-60.246-15.87C71.544 358.331 42.643 406 32 448h293.912c-10.639-42-39.537-89.683-86.704-104.063zM178.953 120.035c-58.479 0-105.886 47.394-105.886 105.858 0 58.464 47.407 105.857 105.886 105.857s105.886-47.394 105.886-105.857c0-58.464-47.408-105.858-105.886-105.858zm0 186.488c-33.671 0-62.445-22.513-73.997-50.523H252.95c-11.554 28.011-40.326 50.523-73.997 50.523z' />
                <g>
                  <path d='M322.602 384H480c-10.638-42-39.537-81.691-86.703-96.072-17.781 10.104-38.343 15.873-60.256 15.873-14.823 0-29.024-2.654-42.168-7.49-7.445 12.47-16.927 25.592-27.974 34.906C289.245 341.354 309.146 364 322.602 384zM306.545 200h100.493c-11.554 28-40.327 50.293-73.997 50.293-8.875 0-17.404-1.692-25.375-4.51a128.411 128.411 0 0 1-6.52 25.118c10.066 3.174 20.779 4.862 31.895 4.862 58.479 0 105.886-47.41 105.886-105.872 0-58.465-47.407-105.866-105.886-105.866-37.49 0-70.427 19.703-89.243 49.09C275.607 131.383 298.961 163 306.545 200z' />
                </g>
              </svg>
              <h1 className='px-2 text-sm text-brown-500 '>MerakiTeam</h1>
            </div>
            <div className='flex items-center mt-4 text-gray-700'>
              <svg className='text-brown-500  h-6 w-6 fill-current' viewBox='0 0 512 512'>
                <path d='M256 32c-88.004 0-160 70.557-160 156.801C96 306.4 256 480 256 480s160-173.6 160-291.199C416 102.557 344.004 32 256 32zm0 212.801c-31.996 0-57.144-24.645-57.144-56 0-31.357 25.147-56 57.144-56s57.144 24.643 57.144 56c0 31.355-25.148 56-57.144 56z' />
              </svg>
              <h1 className='px-2 text-sm text-brown-500 '>Nigeria</h1>
            </div>
            <div>
              <hr className='my-4' />
            </div>
            <div className='mt-4 flex items-center'>
              <div className='flex mr-2 text-gray-700 text-sm mr-3'>
                <svg fill='none' viewBox='0 0 24 24' className='w-4 h-4 mr-1' stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
                  />
                </svg>
                <span>12</span>
              </div>
              <div className='flex mr-2 text-gray-700 text-sm mr-8'>
                <svg fill='none' viewBox='0 0 24 24' className='w-4 h-4 mr-1' stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z'
                  />
                </svg>
                <span>8</span>
              </div>
              <div className='flex mr-2 text-gray-700 text-sm mr-4'>
                <svg fill='none' viewBox='0 0 24 24' className='w-4 h-4 mr-1' stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
                  />
                </svg>
                <span>share</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
