import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { featuredKidCourses } from '../data/featured-kid-courses'
import BookSingleKidCourse from '../components/featured-courses/BookSingleKidCourse'
import { Helmet } from 'react-helmet-async'

const SingleFeaturedKidCourse = () => {
  const [isBookFormOpen, setBookFormOpen] = useState(false)
  const { id } = useParams()
  const [course, setCourse] = useState({})

  useEffect(() => {
    setCourse(featuredKidCourses.find(item => item.id === id))
  }, [id])

  return !!course && !!Object.keys(course).length ? (
    <div>
      <Helmet>
        <meta
          name='keywords'
          content='Coding mentors, someone to teach coding, professional mentors'
        />
        <meta name='description' content={course.description} />
        <title>Mentortribes | {course.title}</title>
      </Helmet>
      <header className='bg-gray-900 py-16'>
        <div className='container mx-auto px-5'>
          <div className='md:grid grid-cols-12 gap-10'>
            <div className='col-span-5 max-h-96'>
              <figure className='m-0 p-0 h-full'>
                <img src={course.image} className='w-full h-full object-cover rounded-lg' alt='' />
              </figure>
            </div>
            <div className='col-span-7 mt-5 md:mt-0'>
              <div className='flex flex-col justify-center h-full'>
                <h2 className='text-2xl md:text-3xl text-white font-manrope font-bold'>
                  {course.title}
                </h2>
                <div className='flex items-center mt-5'>
                  <p className='text-gray-100 text-lg'>{course?.description}</p>
                </div>
                <button
                  onClick={() => setBookFormOpen(true)}
                  className='bg-btn-primary max-w-max block mt-7 outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-7 py-3 text-white rounded'
                >
                  Book Mentor for your Kid
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className=' py-14' style={{ background: '#0a3b4a' }}>
        <div className='container mx-auto px-5'>
          <h4 className='text-white text-2xl font-manrope font-extrabold text-center'>
            What&#39;s in it for your Kid?
          </h4>
          <div className='grid md:grid-cols-3 gap-14 mt-10'>
            <div>
              <div className='bg-green-400 h-1 w-16 mb-4 rounded-lg' />
              <h4 className='text-white text-lg font-medium font-manrope'>
                Unlimited access to a community of Bright Minds.
              </h4>
            </div>
            <div>
              <div className='bg-green-400 h-1 w-16 mb-4 rounded-lg' />
              <h4 className='text-white text-lg font-medium font-manrope'>
                A Mentor who&#39;s available to help
              </h4>
            </div>
            <div>
              <div className='bg-green-400 h-1 w-16 mb-4 rounded-lg' />
              <h4 className='text-white text-lg font-medium font-manrope'>
                An edge over peers in Tech Related activities
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto px-5 my-8'>
        <h3 className='font-manrope text-cool-gray-900 text-center text-3xl font-bold py-5 my-5'>
          Intro Video
        </h3>
        <div className='max-w-4xl mx-auto'>
          <iframe
            style={{ width: '100%' }}
            height='550'
            src={course.video}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
      </div>
      <div className='pt-12 mt-20 bg-cool-gray-50'>
        <div className='container mx-auto px-5'>
          <div className='pb-12'>
            <div className='col-span-7'>
              <div className='md:flex items-center mb-5'>
                <h4 className='text-cool-gray-900 text-xl font-manrope font-extrabold'>
                  About This Class
                </h4>
              </div>
              <div
                id='single-course-desc'
                className='text-base font-semibold mb-4 leading-7 font-manrope text-gray-900'
              >
                <p>{course.aboutClass}</p>
                <button
                  onClick={() => setBookFormOpen(true)}
                  className='bg-btn-primary outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-5 py-2 my-5 text-white rounded-md'
                >
                  Book Mentor for this Course
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-5'>
        <div className='container mx-auto px-5'>
          <h3 className='text-2xl md:text-3xl text-center py-5 text-gray-900 font-manrope'>
            A proven bootcamp to get your ward ready for the future
          </h3>
          <p className='max-w-2xl mx-auto text-base font-manrope text-center'>
            We at Mentortribes understand that technology in the hands of Kids give them a voice and
            a medium to express their creativity. We have designed our courses to suit the
            inquisitive nature of children. Hand your Kids this great opportunity!
          </p>
          <div className='grid mt-10 grid-cols-1 gap-y-10 md:grid-cols-3 gap-5'>
            <div>
              <div className='text-center'>
                <i className='fa fa-clock fa-4x text-center text-green-400' />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>Flexible</h4>
              <p className='text-base font-manrope text-center text-cool-gray-700'>
                Your kids learn from the comfort of home at a flexible time.
              </p>
            </div>
            <div>
              <div className='text-center'>
                <i className='fa fa-user fa-4x text-center text-green-400' />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>Personalized</h4>
              <p className='text-base font-manrope text-center text-cool-gray-700'>
                You get a personal Mentor, this implies One-on-One mentorship and regular access to
                a mentor.
              </p>
            </div>
            <div>
              <div className='text-center'>
                <i className='fa fa-tools fa-4x text-center text-green-400' />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>Practical</h4>
              <p className='text-base font-manrope text-center text-cool-gray-700'>
                Your kids will learn from practice
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='py-5'>
        <div className='container mx-auto px-5' />
      </div>
      <div className='py-10 bg-blue-50'>
        <div className='container mx-auto px-5'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-5 content-center'>
            <div className='flex flex-col justify-center'>
              <h3 className='text-xl text-cool-gray-700 font-semibold font-manrope'>
                Apply for this Bootcamp / Mentorship
              </h3>
              <button
                onClick={() => setBookFormOpen(true)}
                className='bg-btn-primary max-w-max block mt-3 outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-7 py-3 text-white rounded-md'
              >
                Book a Mentor
              </button>
              <p className='mt-2 text-lg font-medium text-cool-gray-700'>
                The application takes just 5 minutes to complete.
              </p>
            </div>
            <div>
              <h4 className='font-manrope font-semibold text-xl mb-5'>
                What is included in the course price?
              </h4>
              <div>
                {course?.whatYouGet?.map((caption, index) => (
                  <div key={index} className='flex items-start mb-3'>
                    <div className='bg-green-400 w-6 h-6 mr-3 grid place-items-center rounded-full'>
                      <i className='fa fa-check text-white' />
                    </div>
                    <p className='text-base font-medium text-gray-700'>{caption}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookSingleKidCourse
        mentor={{}}
        isBookFormOpen={isBookFormOpen}
        setBookFormOpen={setBookFormOpen}
      />
    </div>
  ) : (
    <div className='h-screen grid place-content-center'>
      <h1 className='text-3xl font-manrope text-primary'>404, Oops not Found</h1>
      <h1 className='text-3xl font-manrope font-semibold text-primary'>
        Featured Course does not exist
      </h1>
      <p className='text-xl text-gray-900 font-manrope'>
        We don&apos;t teach this course currently, please check the url again or{' '}
        <Link to='/contact-us'>Contact Us</Link> and we can create this class for you personally
      </p>
      <Link
        to='/'
        className='bg-primary text-white py-1.5 text-sm block w-full max-w-max px-5 mx-auto mt-2 hover:bg-opacity-50 duration-200 hover:text-white rounded-md'
      >
        <i className='fa fa-home mr-3' />
        Go Home
      </Link>
    </div>
  )
}

export default SingleFeaturedKidCourse
