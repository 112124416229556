import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import PropTypes from 'prop-types'

const SearchInput = ({ name, type, ariaLabel, value, onChange, placeholder, className }) => {
  return (
    <form className='w-full' autoComplete='off'>
      <div className='flex  w-full items-center border-b border-red-800 py-2'>
        <input
          className={className}
          type={type}
          placeholder={placeholder}
          aria-label={ariaLabel}
          autoFocus
          name={name}
          value={value}
          onChange={onChange}
        />

        <button
          className='flex-shrink-0 border-transparent border-4 button-outline-none text-brown-500 hover:text-red-900 active:text-black text-lg py-1 px-2 rounded'
          type='button'
        >
          <SearchOutlined />
        </button>
      </div>
    </form>
  )
}
SearchInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.any,
  ariaLabel: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string
}
export default SearchInput
