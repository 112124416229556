import React from 'react'
import checkIcon from '../../images/pricing/check.svg'
import PricingPlanCard from './PricingPlanCard'
import { PRICISING_PLANS } from './constants'
import { Link } from 'react-router-dom'

const PlansPricing = () => {
  return (
    <section className='bg-black pb-20 py-15'>
      <h2 className='text-gray-400 text-4xl text-center pt-14 mb-4 font-semibold'>
        Choose a Plan Today
      </h2>
      <p className='text-lg text-center max-w-xs mx-auto text-gray-100'>
        Ready to start growing? Select a suitable plan to begin
      </p>
      <div className='container mt-10 mx-auto px-5'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-14'>
          <div className='relative bg-white rounded-lg px-5 md:px-10 py-5'>
            <h4 className='text-3xl font-bold'>
              <span className='font-dm-serif-display'>₦0</span>
              <sub className='text-gray-500 text-base font-normal'>/month</sub>
            </h4>
            <h4 className='text-2xl my-3 text-red-900 font-dm-serif-display font-bold'>
              FREE Trial!!!{' '}
            </h4>
            <p className='text-lg text-gray-500 mb-4'>Career Guide - To prepare you for success</p>
            <ul>
              <li className='flex py-2 items-center'>
                <figure className='m-0 p-0 mr-3'>
                  <img src={checkIcon} alt='check' />
                </figure>
                <h6 className='text-gray-500 text-lg'>Getting started with software dev </h6>
              </li>
              <li className='flex py-2 items-center'>
                <figure className='m-0 p-0 mr-3'>
                  <img src={checkIcon} alt='' />
                </figure>
                <h6 className='text-gray-500 text-lg'>
                  A day one on one with any mentor of choice
                </h6>
              </li>
              <li className='flex py-2 items-center'>
                <figure className='m-0 p-0 mr-3'>
                  <img src={checkIcon} alt='' />
                </figure>
                <h6 className='text-gray-500 text-lg'>Free docs and References</h6>
              </li>
              <li className='flex py-2 items-center'>
                <figure className='m-0 p-0 mr-3'>
                  <img src={checkIcon} alt='' />
                </figure>
                <h6 className='text-gray-500 text-lg'>Upgrade at anytime</h6>
              </li>
            </ul>
            <Link
              to={{ pathname: '/book-mentor', state: { type: 'Trial' } }}
              className='absolute bottom-1 md:bottom-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block w-11/12 py-2 text-center text-white bg-red-900 duration-200 hover:bg-opacity-75 rounded-md'
            >
              Get started
            </Link>
          </div>
          {PRICISING_PLANS.map(({ id, listOfPerks, title, type, price, color, margin }) => (
            <PricingPlanCard
              id={id}
              key={type}
              istOfPerks={listOfPerks}
              title={title}
              type={type}
              price={price}
              color={color}
              margin={margin}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default PlansPricing
