import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const Input = ({ label, type, id, noMargin, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className={`${!noMargin && 'mt-5'}`}>
      <label htmlFor={id} className='text-sm text-black'>
        {label}
      </label>
      <input
        className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
        type={type}
        id={id}
        {...field}
        {...meta}
      />
      {meta.touched && meta.error && <small className='text-red-500'>{meta.error}</small>}
    </div>
  )
}
Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.any,
  id: PropTypes.string,
  noMargin: PropTypes.string
}

export default Input
