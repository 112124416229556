import React from 'react'
import PropTypes from 'prop-types'

const AboutMentor = ({ mentor }) => {
  return (
    <div className='pt-12 bg-cool-gray-50'>
      <div className='container mx-auto px-5'>
        <div className='border-b border-gray-200 pb-12 lg:grid grid-cols-12 gap-10'>
          <div className='col-span-7'>
            <h4 className='text-gray-900 mb-3 text-xl font-manrope font-extrabold'>
              Meet Your Mentor
            </h4>
            <div className='flex'>
              <figure className='m-0 p-0'>
                <img
                  src='https://static.skillshare.com/uploads/users/7458116/user-image-medium.jpg?1377992329'
                  alt=''
                  className='w-14 h-14 object-cover rounded-full'
                />
              </figure>
              <div className='ml-5'>
                <h4 className='text-gray-900  text-base font-manrope font-extrabold'>
                  {mentor?.firstname} {mentor?.lastname}
                </h4>
                <p className='bg-gray-200 font-bold text-gray-900 max-w-max px-3 py-0.5 rounded text-xs my-1 text-center capitalize mt-1 font-manrope'>
                  {mentor?.specialization}
                </p>
                <h5 className='text-gray-900 mb-3 text-sm font-manrope font-medium'>
                  <i className='fa fa-map-marker-alt mr-3' /> {mentor?.country}
                </h5>
              </div>
            </div>
            <div>
              <div
                id='single-mentor-bio'
                className='text-base font-extrabold mb-4 leading-7 font-manrope text-gray-900'
                dangerouslySetInnerHTML={{ __html: mentor?.bio }}
              />
            </div>
          </div>
          <div className='col-span-5 sticky bg-white h-96 mt-10 lg:mt-0 rounded-2xl shadow' />
        </div>
      </div>
    </div>
  )
}
AboutMentor.propTypes = { mentor: PropTypes.array }

export default AboutMentor
