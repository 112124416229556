import React from 'react'
import { Collapse } from 'antd'
import { Link } from 'react-router-dom'
import exclamationIcon from '../../images/pricing/exclamation.svg'
const KidsFaq = () => {
  const { Panel } = Collapse
  return (
    <div className='w-full bg-white pt-10'>
      <div className='flex flex-wrap'>
        <div className='w-full pb-10 px-4 md:px-40'>
          <div className='pb-5'>
            <h3 className='text-3xl text-center text-gray-900 font-semibold'>
              Frequently Asked Questions
            </h3>
            <h4 className='text-gray-800 text-center mb-3 text-lg'>
              Can&apos;t find your answer below? Send a message!
            </h4>
          </div>
          <div className='flex flex-wrap bg-red-50 rounded-lg'>
            <div className='w-full py-10 pr-5' style={{ borderRadius: '1rem' }}>
              <div className='p-5'>
                <div className='flex'>
                  <div className='flex flex-col items-center justify-center mr-5'>
                    <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
                      <img src={exclamationIcon} alt='exclamation' />
                    </figure>
                  </div>
                  <div>
                    <h4 className='text-black text-xl font-semibold'>Kid Tech</h4>
                    <p className='text-gray-900'>
                      You can find answers relating to kid tech enquries here.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <Collapse
                accordion
                className='pl-10 bg-red-50 border-0'
                expandIconPosition='right'
                expandIcon={item =>
                  item.isActive ? (
                    <span className='border border-gray-500 rounded-full p-0.5 px-1'>
                      <i className='fa fa-minus' />
                    </span>
                  ) : (
                    <span className='border border-gray-500 rounded-full p-0.5 px-1'>
                      <i className='fa fa-plus' />
                    </span>
                  )
                }
              >
                <Panel
                  className='text-lg font-manrope'
                  header='Does my child need a mentor?'
                  key='1'
                >
                  <p className='text-base text-gray-600 mb-4'>
                    Children need to know their strengths, talents, aptitudes, and inclinations as
                    the economy becomes more volatile and opportunities become less accessible.
                    Mentoring would highly complement their career choices and vocational pursuits.
                  </p>
                </Panel>
                <Panel
                  className='text-lg font-manrope'
                  header='How long should a mentorship program last?'
                  key='2'
                >
                  <p className='text-base text-gray-600 mb-4'>
                    How Long Should the Mentoring Connection Last? The minimum amount of time the
                    relationship must last is 6 months but there is no maximum. Some mentoring
                    relationships last a lifetime depending on the willingness of the two parties.
                  </p>
                </Panel>

                <Panel
                  className='text-lg font-manrope'
                  header='Why does every student need a mentor?'
                  key='3'
                >
                  <p className='text-base text-gray-600 mb-4'>
                    “Everybody has genius but, if you judge a fish by its ability to climb a tree it
                    will go through its life feeling like it’s stupid” – Albert Einstein.
                  </p>
                </Panel>

                <Panel
                  className='text-lg font-manrope'
                  header='Why does every student need a mentor?'
                  key='4'
                >
                  <p className='text-base text-gray-600 mb-4'>
                    We understand our students have their differences and things keeping them busy.
                    For special packages like learning at your own pace, taking intermittent breaks
                    in the learning process, choosing a unique learning time, group mentorship,
                    which to have access to the best mentors in the industry, etc.
                  </p>
                  <p className='text-base text-gray-600 mb-4'>
                    It’s a shame that this is a reality for many children in schools but mentoring
                    can create balance for them.
                  </p>
                  <p className='text-base text-gray-600'>
                    Mentors can assist learners to discover and develop their passions and determine
                    their career aspirations. Net photo. Mentors are an important part of personal
                    and professional progress. They guide students to follow the right path and
                    support them to enable success.
                  </p>
                </Panel>
                <Panel
                  className='text-lg font-manrope'
                  header='How do I become a child mentor?'
                  key='5'
                >
                  <p className='text-base text-gray-600 mb-4 ml-6'>
                    <ul className='list-disc'>
                      <li> Attend a Mentor Information Session.</li>
                      <li> Complete a Mentor Application Form.</li>
                      <li> Participate in a Face-to-Face Interview.</li>
                      <li> Participate in Mentor Training.</li>
                      <li>
                        Successfully complete the following paperwork: Personality Assessment
                        Screener. National Police Check. Working with Children&apos;s Check.
                      </li>
                    </ul>
                    <p className='mt-8'>
                      <Link
                        to='/kid-book-mentor'
                        className='text-center py-2 mt-15 text-white  hover:bg-red-900 bg-btn-primary duration-200 px-5 rounded-md '
                      >
                        Learn more
                      </Link>
                    </p>
                  </p>
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KidsFaq
