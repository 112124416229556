import React from 'react'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3'
import PropTypes from 'prop-types'
import { notification } from 'antd'

const flutterwaveKey =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_FLUTTERWAVE_KEY_PROD
    : process.env.REACT_APP_FLUTTERWAVE_KEY_DEV

const PaymentButton = ({ details }) => {
  const config = {
    public_key: flutterwaveKey,
    tx_ref: Date.now(),
    amount: details.amount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    redirect_url: details.redirectUrl,
    customer: {
      email: details.email,
      phonenumber: details.phone,
      name: details.name
    },
    customizations: {
      title: 'Mentortribes Payment Portal',
      description: 'Enrollment Fee',
      logo: 'https://media-exp1.licdn.com/dms/image/C4E0BAQFHNJVRSvz1dw/company-logo_200_200/0/1627299435288?e=2159024400&v=beta&t=wskZz9xMLHHwVSuWQRG5fnJurbxgf00KPqgo0x84BJ8'
    }
  }

  const handleFlutterPayment = useFlutterwave(config)

  return (
    <div className='App'>
      <button
        className='px-10 py-2 block w-full max-w-max mx-auto sm:mx-0 font-manrope cursor-pointer duration-200 hover:bg-opacity-75 sm:mr-5 bg-green-600 text-white font-semibold rounded'
        onClick={() => {
          handleFlutterPayment({
            callback: response => {
              if (response?.status === 'successful') {
              } else {
                notification.error('Payment not Successful')
              }
              closePaymentModal()
            },
            onClose: () => {
              notification.warning({ message: 'The Payment Transaction is incomplete' })
            }
          })
        }}
      >
        {details.buttonLabel}
      </button>
    </div>
  )
}
PaymentButton.propTypes = {
  details: PropTypes.object
}

export default PaymentButton
