import React from 'react'
import { Helmet } from 'react-helmet-async'
import Faq from '../components/pricing/FAQ'
import Header from '../components/pricing/Header'
import PlansPricing from '../components/pricing/PlansPricing'
import Subscribe from '../components/Subscribe'

const Pricing = () => {
  return (
    <div className='pt-0'>
      <Helmet>
        <meta name='keywords' content='cost of getting a mentor, cost, mentor, price' />
        <meta
          name='description'
          content='Our pricing page, details on the cost for all our packages'
        />
        <title>Mentortribes | Pricing</title>
      </Helmet>
      <Header />
      <PlansPricing />
      <Faq />
      <Subscribe />
    </div>
  )
}

export default Pricing
