import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { Formik, Form } from 'formik'
import { Auth } from 'aws-amplify'
import * as Yup from 'yup'
import checkedIcon from '../../images/checked.svg'
import PasswordInput from './PasswordInput'
import PropTypes from 'prop-types'

const ValidationSchema = Yup.object().shape({
  code: Yup.string().required('Code is required')
})

const ConfirmAccount = ({
  isVisible,
  username,
  placement,
  setVisible,
  subId,
  isLogin,
  password
}) => {
  const [isSubmmitting, setSubmitting] = useState(false)
  const [isResending, setResending] = useState(false)
  const history = useHistory()

  return isVisible ? (
    <div className='fixed h-screen flex overflow-y-auto flex-col justify-center inset-0 w-full bg-black bg-opacity-50 z-50'>
      <div
        style={{ maxWidth: '400px' }}
        className='bg-white mx-auto mt-10 py-10 mx w-full rounded-lg'
      >
        <div>
          <div className='flex py-5 justify-center'>
            <figure className='w-24 h-24'>
              <img className='w-full h-full object-cover' src={checkedIcon} alt='checked' />
            </figure>
          </div>
          <div className='px-5'>
            <h3 style={{ color: '#091E42' }} className='text-lg text-center font-semibold'>
              Account Created, Now Confirm Your Account
            </h3>
            <p className='pt-2 text-center text-base'>
              Account has been created succesfully, you can log in but check your email and confirm
              your account
            </p>
            <Formik
              validationSchema={ValidationSchema}
              initialValues={{
                code: ''
              }}
              onSubmit={values => {
                setSubmitting(true)
                notification.destroy()
                Auth.confirmSignUp(username, values.code)
                  .then(res => {
                    if (isLogin) {
                      Auth.signIn(username, password)
                        .then(res => {
                          setSubmitting(false)
                          setVisible(false)
                          const userSub = res.attributes.sub
                          Auth.signOut().then(() => {
                            notification.success({
                              message: 'Account Confirmed!',
                              description:
                                'Account has been confirmed, one more step, please finish up your mentorship account now',
                              placement
                            })
                            history.push('/add-mentor', { subId: userSub })
                          })
                        })
                        .catch(err => {
                          setSubmitting(false)
                          notification.error({ message: err.message, duration: 10000 })
                        })
                    } else {
                      setSubmitting(false)
                      notification.success({
                        message: 'Account Confirmed!',
                        description:
                          'Account has been confirmed, one more step, please finish up your mentorship account now',
                        placement
                      })
                      setVisible(false)
                      history.push('/add-mentor', { subId, email: username })
                    }
                  })
                  .catch(err => {
                    setSubmitting(false)
                    notification.error({
                      message: err.message,
                      placement,
                      duration: 10000
                    })
                  })
              }}
            >
              <Form>
                <div className=''>
                  <PasswordInput label='Enter Confirm Code' name='code' id='code' />
                  <div className='mt-5'>
                    <button
                      type='submit'
                      className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                    >
                      {isSubmmitting ? (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      ) : (
                        ''
                      )}{' '}
                      Confirm Account
                    </button>
                  </div>
                  <div className='flex justify-between'>
                    <a
                      onClick={async e => {
                        e.preventDefault()
                        setResending(true)
                        try {
                          await Auth.resendSignUp(username)
                          setResending(false)
                          notification.success({
                            message: 'Confirm message sent!',
                            description: 'Check you mail for a message to confirm your account'
                          })
                        } catch (error) {
                          setResending(false)
                          alert(JSON.stringify(error))
                        }
                      }}
                      className='text-blue-700 pt-2 block'
                    >
                      {isResending && (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      )}
                      Resend
                    </a>
                    {!isLogin && (
                      <Link to='/login' className='text-blue-700 pt-2 block'>
                        Go to Login
                      </Link>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}
ConfirmAccount.propTypes = {
  isVisible: PropTypes.bool,
  username: PropTypes.string.isRequired,
  placement: PropTypes.string,
  setVisible: PropTypes.object,
  subId: PropTypes.string,
  isLogin: PropTypes.bool,
  password: PropTypes.string
}
export default ConfirmAccount
