import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { featuredCourses } from '../data/featured-courses'
import BookSingleCourse from '../components/featured-courses/BookSingleCourse'
import Faq from '../components/single-featured-course/FAQ'
import { Helmet } from 'react-helmet-async'

const SingleFeaturedCourse = () => {
  const [isBookFormOpen, setBookFormOpen] = useState(false)
  const { id } = useParams()
  const [course, setCourse] = useState({})

  useEffect(() => {
    setCourse(featuredCourses.find(item => item.id === id))
  }, [id])

  return !!course && !!Object.keys(course).length ? (
    <div>
      <Helmet>
        <meta
          name='keywords'
          content='Coding mentors, someone to teach coding, professional mentors'
        />
        <meta name='description' content={course.description} />
        <title>Mentortribes | {course.title}</title>
      </Helmet>
      <header className='bg-gray-900 py-16'>
        <div className='container mx-auto px-5'>
          <div className='md:grid grid-cols-12 gap-10'>
            <div className='col-span-5 max-h-80'>
              <figure className='m-0 p-0 h-full'>
                <img src={course.image} className='w-full h-full object-cover' alt='' />
              </figure>
            </div>
            <div className='col-span-7 mt-5 md:mt-0'>
              <div className='flex flex-col justify-center h-full'>
                <h2 className='text-2xl md:text-3xl text-white font-manrope font-bold'>
                  {course.title}
                </h2>
                <div className='flex items-center mt-5'>
                  <p
                    dangerouslySetInnerHTML={{ __html: course.description }}
                    className='text-gray-100 text-lg'
                  />
                </div>
                <button
                  onClick={() => setBookFormOpen(true)}
                  className='bg-btn-primary max-w-max block mt-7 outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-7 py-3 text-white rounded-md'
                >
                  Book Mentor for this Course
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className=' py-14' style={{ background: '#0a3b4a' }}>
        <div className='container mx-auto px-5'>
          <h4 className='text-white text-2xl font-manrope font-extrabold text-center'>
            What you stand to gain
          </h4>
          <div className='grid md:grid-cols-3 gap-14 mt-10'>
            <div>
              <div className='bg-green-400 h-1 w-16 mb-4 rounded-lg' />
              <h4 className='text-white text-lg font-medium font-manrope'>
                Get unlimited access to a community.
              </h4>
            </div>
            <div>
              <div className='bg-green-400 h-1 w-16 mb-4 rounded-lg' />
              <h4 className='text-white text-lg font-medium font-manrope'>
                Get a Mentor to teach you and help you anytime you have issues
              </h4>
            </div>
            <div>
              <div className='bg-green-400 h-1 w-16 mb-4 rounded-lg' />
              <h4 className='text-white text-lg font-medium font-manrope'>
                Learn from the Industry&apos;s Top 10%
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto px-5 my-8'>
        <h3 className='font-manrope text-cool-gray-900 text-center text-3xl font-bold py-5 my-5'>
          Intro Video for Visual Learners
        </h3>
        <div className='max-w-4xl mx-auto'>
          <iframe
            style={{ width: '100%' }}
            height='450'
            src={course.video}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
      </div>
      <div className='pt-12 mt-20 bg-cool-gray-50'>
        <div className='container mx-auto px-5'>
          <div className='pb-12'>
            <div className='col-span-7'>
              <div className='md:flex items-center mb-5'>
                <h4 className='text-cool-gray-900 text-xl font-manrope font-extrabold'>
                  About This Class
                </h4>
              </div>
              <div
                id='single-course-desc'
                className='text-base font-semibold mb-4 leading-7 font-manrope text-gray-900'
              >
                <p dangerouslySetInnerHTML={{ __html: course.aboutClass }} />
                <button
                  onClick={() => setBookFormOpen(true)}
                  className='bg-btn-primary outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-5 py-2 my-5 text-white rounded-md'
                >
                  Book Mentor for this Course
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-5'>
        <div className='container mx-auto px-5'>
          <h3 className='text-2xl md:text-3xl text-center py-5 text-gray-900 font-manrope'>
            A proven {course.title} bootcamp to get you{' '}
            {course.title === 'Cryptocurrency Trading' ? 'set up for profit' : 'job ready'}
          </h3>
          <p className='max-w-2xl mx-auto text-base font-manrope text-center'>
            We at Mentortribes understand that You shouldn&apos;t have to put your life on hold to
            start a new career. And that why we want to make sure the Learning should fit your life,
            not the other way around.
          </p>
          <div className='grid mt-10 grid-cols-1 gap-y-10 md:grid-cols-3 gap-5'>
            <div>
              <div className='text-center'>
                <i className='fa fa-clock fa-4x text-center text-green-400' />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>Flexible</h4>
              <p className='text-base font-manrope text-center text-cool-gray-700'>
                No need to quit your job. Learn the skills you need to become a {course?.profession}{' '}
                at your own pace and schedule, from the comfort of your home
              </p>
            </div>
            <div>
              <div className='text-center'>
                <i className='fa fa-user fa-4x text-center text-green-400' />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>Personalized</h4>
              <p className='text-base font-manrope text-center text-cool-gray-700'>
                You can get mentored 1:1 weekly by an industry expert mentor. Get coached 1:1 by a
                dedicated career coach. All you have to do is book for the personal plan
              </p>
            </div>
            <div>
              <div className='text-center'>
                <i className='fa fa-tools fa-4x text-center text-green-400' />
              </div>
              <h4 className='text-center text-lg font-manrope font-semibold py-5'>Practical</h4>
              <p className='text-base font-manrope text-center text-cool-gray-700'>
                You will get to work on real world {course.title} projects and gain experience with
                client facing work.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-100 mt-10 py-5'>
        <div className='container mx-auto px-5'>
          <h3 className='text-2xl md:text-3xl text-center py-5 text-gray-900 font-manrope'>
            Is this program right for me?
          </h3>
          <p className='max-w-2xl mx-auto text-cool-gray-900 text-base font-manrope text-center'>
            Do you love to build, do have ideas that you want to bring to live? If so, then this is
            the program for you
          </p>
          <div className='mt-10'>
            <h4 className='font-manrope mb-5 text-xl text-cool-gray-900 font-semibold'>
              Prerequisites
            </h4>
            {course?.prerequites?.map((caption, index) => (
              <div key={index} className='flex flex-wrap md:flex-no-wrap items-start mb-3'>
                <div className='bg-green-400 w-6 h-6 mr-3 grid place-items-center rounded-full'>
                  <i className='fa fa-check text-white' />
                </div>
                <p className='text-base text-gray-700'>{caption}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='py-5'>
        <div className='container mx-auto px-5'>
          <div className=''>
            <h4 className='font-manrope text-xl text-cool-gray-900 font-semibold'>
              Not conviced yet?
            </h4>
            <p className='max-w-2xl text-cool-gray-900 text-base mb-3 font-manrope'>
              We understand all this might be overwelming for you, so you can reach out to us and
              contact you later or book for a trail lesson
            </p>
            <div className='flex items-center'>
              <button
                onClick={() => setBookFormOpen(true)}
                className='bg-btn-primary max-w-max block mt-7 outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-7 py-3 text-white rounded-md'
              >
                Book trial Lesson
              </button>
              <Link
                to='/contact-us'
                className='ml-5 border border-primary max-w-max block mt-7 outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-7 py-3 text-primary rounded-md'
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='py-10 bg-blue-50'>
        <div className='container mx-auto px-5'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-5 content-center'>
            <div className='flex flex-col justify-center'>
              <h3 className='text-xl text-cool-gray-700 font-semibold font-manrope'>
                Apply for the {course?.title} Bootcamp / Mentorship
              </h3>
              <button
                onClick={() => setBookFormOpen(true)}
                className='bg-btn-primary max-w-max block mt-3 outline-none focus:outline-none font-semibold hover:bg-opacity-75 duration-200 px-7 py-3 text-white rounded-md'
              >
                Book a Mentor
              </button>
              <p className='mt-2 text-lg font-medium text-cool-gray-700'>
                The application takes just 5 minutes to complete.
              </p>
            </div>
            <div>
              <h4 className='font-manrope font-semibold text-xl mb-5'>
                What is included in the course price?
              </h4>
              <div>
                {course?.whatYouGet?.map((caption, index) => (
                  <div key={index} className='flex items-start mb-3'>
                    <div className='bg-green-400 w-6 h-6 mr-3 grid place-items-center rounded-full'>
                      <i className='fa fa-check text-white' />
                    </div>
                    <p className='text-base font-medium text-gray-700'>{caption}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Faq faqs={course?.faqs} />
      <BookSingleCourse
        mentor={{}}
        isBookFormOpen={isBookFormOpen}
        setBookFormOpen={setBookFormOpen}
      />
    </div>
  ) : (
    <div className='h-screen grid place-content-center'>
      <h1 className='text-3xl font-manrope text-primary'>404, Oops not Found</h1>
      <h1 className='text-3xl font-manrope font-semibold text-primary'>
        Featured Course does not exist
      </h1>
      <p className='text-xl text-gray-900 font-manrope'>
        We don&apos;t teach this course currently, please check the url again or{' '}
        <Link to='/contact-us'>Contact Us</Link> and we can create this class for you personally
      </p>
      <Link
        to='/'
        className='bg-primary text-white py-1.5 text-sm block w-full max-w-max px-5 mx-auto mt-2 hover:bg-opacity-50 duration-200 hover:text-white rounded-md'
      >
        <i className='fa fa-home mr-3' />
        Go Home
      </Link>
    </div>
  )
}

export default SingleFeaturedCourse
