import React from 'react'
import { Link } from 'react-router-dom'
import SingleSideChat from './SingleSideChat'
import PropTypes from 'prop-types'

const ChatSidebar = ({ chats, setSelectedMentor, currentChatId, setChatId }) => {
  return (
    <div
      className={`bg-white overflow-y-auto w-full md:w-80 ${currentChatId && 'hidden md:block'}`}
    >
      <div className='px-3'>
        <div className='border-b h-20 border-gray-100 flex items-center'>
          <Link
            to='/'
            className='bg-gray-100 px-5 py-2 text-gray-600 grid place-content-center rounded-md'
          >
            <i className='fa fa-angle-left text-lg' />
          </Link>
          <h4 className='text-xl ml-5'>Chat</h4>
        </div>
        <div className='mt-5 py-5'>
          <figure className='m-0 p-0 bg-gray-200 rounded-full w-20 h-20 grid place-items-center mx-auto'>
            <img src='/logo.svg' className='w-15 h-15' alt='logo' />
          </figure>
        </div>
        <h4 className='text-center text-2xl font-manrope font-semibold text-primary'>
          MentorTribes
        </h4>
      </div>
      <div>
        <h4 className='text-gray-900 font-manrope text-sm px-5 py-5 font-semibold'>Chats</h4>
        <div>
          {chats.map(chat => (
            <SingleSideChat
              chat={chat}
              key={chat.id}
              currentChatId={currentChatId}
              setChatId={setChatId}
              setSelectedMentor={setSelectedMentor}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
ChatSidebar.propTypes = {
  chats: PropTypes.array,
  setSelectedMentor: PropTypes.any,
  currentChatId: PropTypes.string,
  setChatId: PropTypes.any
}

export default ChatSidebar
