import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

const WebinarTable = ({ attendees, loading }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })

  const COLUMN = [
    {
      title: <span className='font-bold'>S/N</span>,
      dataIndex: 'sn',
      key: 'sn',
      render: sn => <span>{sn}</span>
    },
    {
      title: <span className='font-bold'>Email</span>,
      dataIndex: 'email',
      key: 'email',
      render: email => <span>{email}</span>,
      sorter: (a, b) => a.email - b.email
    },
    {
      title: <span className='font-bold'>Fullname</span>,
      key: 'fullName',
      dataIndex: 'fullName',
      render: fullName => <span>{fullName}</span>,
      sorter: (a, b) => a.fullName - b.fullName
    },
    {
      title: <span className='font-bold'>Phone No</span>,
      key: 'phone',
      dataIndex: 'phone',
      render: phone => <span>{phone}</span>,
      sorter: (a, b) => a.phone - b.phone
    },
    {
      title: <span className='font-bold'>Source</span>,
      key: 'source',
      dataIndex: 'source',
      render: source => <span>{source}</span>,
      sorter: (a, b) => a.source - b.source
    },
    {
      title: <span className='font-bold'>Date for Call</span>,
      key: 'callDate',
      dataIndex: 'callDate',
      render: callDate => <span>{callDate}</span>,
      sorter: (a, b) => a.callDate - b.callDate
    },
    {
      title: <span className='font-bold'>Time for Call</span>,
      key: 'callTime',
      dataIndex: 'callTime',
      render: callTime => <span>{callTime}</span>,
      sorter: (a, b) => a.callTime - b.callTime
    }
  ]
  useEffect(() => {
    setData(attendees)
  }, [attendees])

  return (
    <div className='bg-white shadow'>
      <div className='px-4 overflow-x-auto mb-16'>
        <Table
          columns={COLUMN}
          onChange={value => setPagination(value)}
          pagination={pagination}
          loading={loading}
          dataSource={data}
        />
      </div>
    </div>
  )
}
WebinarTable.propTypes = { attendees: PropTypes.any, loading: PropTypes.bool }
export default WebinarTable
