import React from 'react'
import { Collapse } from 'antd'
import exclamationIcon from '../../images/pricing/exclamation.svg'
import PropTypes from 'prop-types'
const GeneralFaq = ({ currentFaq }) => {
  const { Panel } = Collapse

  return (
    <div
      className={`bg-white pb-5 ${currentFaq === 1 ? 'block' : 'hidden'}`}
      style={{ borderRadius: '1rem' }}
    >
      <div className='p-5'>
        <div className='flex'>
          <div className='flex flex-col items-center justify-center mr-5'>
            <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
              <img src={exclamationIcon} alt='exclamation' />
            </figure>
          </div>
          <div>
            <h4 className='text-black text-xl font-semibold'>General Enquires</h4>
            <p className='text-gray-900'>You can find answers relating to general enquries here</p>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        className='pl-10 bg-white border-0'
        expandIconPosition='right'
        expandIcon={item =>
          item.isActive ? (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-minus' />
            </span>
          ) : (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-plus' />
            </span>
          )
        }
      >
        <Panel className='text-lg font-manrope' header='What is MentorTribes?' key='1'>
          <p className='text-base text-gray-600'>
            MentorTribes is providing you someone to hold your hands while you learn that skill.
            Have you thought of learning and working in a real project? yes we apply that principle
            here. Your mentor is entitled to give you some side jobs to do while you&apos;re
            learning.
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='Why did we create MentorTribes?' key='2'>
          <p className='text-base text-gray-600'>
            Growth is hard and sometimes we just need to talk to someone that &quot;gets it&quot;,
            and that why we created Easy2Con
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='How does it work?' key='3'>
          <p className='text-base text-gray-600'>
            Book a Mentor, fill the information properly to find you the most suitable mentor, pick
            a plan and We match you with a mentor. You will develop your skills with a mentor,
            exchanging ideas, solutions and encouragement. A mentor is always available to entertain
            all your questions as you learn. You are not alone.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='What if I don’t like the course I purchased?'
          key='7'
        >
          <p className='text-base text-gray-600 mb-4'>
            Your happiness is our concern, in as much as we don’t refund after payment; we give room
            for all eligible courses paid on Mentortribes to be switched to another course within
            one week of starting the course.
          </p>
          <p className='text-base text-gray-600'>
            Example, you can switch from python to JavaScript, provided that the payment for the
            course is the same. If you are unhappy with a course, you can also request for a change
            of mentor, provided the request was made with a genuine reason.
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='Where can I go for help?' key='8'>
          <p className='text-base text-gray-600 mb-4'>
            We understand the need that effective mentorship starts with quality communication. We
            also know that personality type influences many areas of your life and that is why we
            are working hard to get the best mentors assigned to our students.
          </p>
          <p className='text-base text-gray-600'>
            At any point you are not comfortable with the assigned mentor, kindly reach out to our
            support team and a new mentor will be assigned to you.
          </p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='How do I get started with paid courses? '
          key='9'
        >
          <p className='text-base text-gray-600 mb-4'>
            To get started, kindly register and make payment. Once your payment is confirmed, our
            support team will contact you for more information to get you started. A mentor will be
            assigned to you so that you can start your learning process.
          </p>
          <p className='text-base text-gray-600'>
            In case there is any reason why you can’t start your learning immediately, our support
            will make a one-time adjustment to that effect.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='How long do I have to complete my course?'
          key='10'
        >
          <p className='text-base text-gray-600 mb-4'>
            We make sure that the time allocated to a given course is enough for you to complete the
            course. In a situation you did not, a free two weeks grace will be given to you.
          </p>
          <p className='text-base text-gray-600'>
            In case you need extra mentorship after exhausting the available time. You can only
            write to our support for Fee Advice on that. Note that even after completing a given
            course, you will continue to have access to materials and resources for that course.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='What if I need a special mentorship package?'
          key='11'
        >
          <p className='text-base text-gray-600 mb-4'>
            We understand our students have their differences and things keeping them busy. For
            special packages like learning at your own pace, taking intermittent breaks in the
            learning process, choosing a unique learning time, group mentorship, which to have
            access to the best mentors in the industry, etc.
          </p>
          <p className='text-base text-gray-600'>Kindly contact our support team for Fee Advice.</p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='Is there any package available for children?'
          key='12'
        >
          <p className='text-base text-gray-600 mb-4'>
            Yes! Children still fall under special packages because they need special attention and
            continuous engagement.
          </p>
          <p className='text-base text-gray-600'>
            We have amazing mentors that are familiar with coaching little children in order to
            manage distractions coming from them during the learning process. Our mentors help to
            boost their self- esteem and confidence in problem solving by supporting independence
            and persistence in the face of initial difficulties.
          </p>
        </Panel>

        <Panel className='text-lg font-manrope' header='Is there any referral bonus?' key='14'>
          <p className='text-base text-gray-600'>
            Yes! We value those that have our interest at hand and that is why we are given a 10%
            discount to anyone who brings in two fully registered students. We also give out gift
            items to our top three referrals at the end of each year.
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='How can I become a mentor?' key='15'>
          <p className='text-base text-gray-600 mb-4'>
            To become a mentor, you need to prove to us that you have the passion to teach and
            learn. Secondly, you must have the required experience for a given course of interest;
            either as a junior mentor or a senior mentor.
          </p>
        </Panel>
        {/* <Panel
          className='text-lg font-manrope'
          header='What other benefits do I stand to gain?'
          key='16'
        >
          <p className='text-base text-gray-600 mb-4'>
            One on one mentorship gives us a better understanding of you. It allows us to understand
            your passion, learning style and character; and to strongly recommend you to any
            company.{' '}
          </p>
          <p className='text-base text-gray-600'>
            Team mentorship on the other hand provides basic understanding of what you; except when
            the student has some unique characteristics or exceptional skills. Outstanding students
            will have a strong recommendation letter and resume forwarded to companies for any job
            opportunities.
          </p>
        </Panel> */}
        <Panel className='text-lg font-manrope' header='Is it only online classes?' key='17'>
          <p className='text-base text-gray-600 mb-4'>
            Yes! Firstly, this is the future and that is what we stand for. Most jobs today are
            fully remote and continuous online coding activities get you ready for remote jobs.
          </p>
          <p className='text-base text-gray-600'>
            Secondly, most of our super amazing mentors come from different locations within and
            outside the country and we don&apos;t want location to be a barrier in rendering quality
            mentorship.
          </p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='How do I know I will get the help I need?'
          key='4'
        >
          <p className='text-base text-gray-600 mb-4'>
            Full disclaimer- there are no silver bullets for Growth, so we can’t make any false
            promises.
          </p>
          <p className='text-base text-gray-600 mb-4'>
            If you have a growth mindset and are looking to connect with various experienced
            individuals and learn from them, you will get value from this platform.
          </p>
          <p className='text-base text-gray-600'>
            We have a great filtering system, so we guarantee We will match you with the perfect
            Mentor.
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='How can I become a partner?' key='18'>
          <p className='text-base text-gray-600 mb-4'>
            Be part of the good work we are doing by becoming a partner today. Feel free to reach
            out via email, phone number or the message box in the contact us page for further
            discussion.
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='Is there any scholarship program?' key='19'>
          <p className='text-base text-gray-600 mb-4'>
            Yes! We are doing our best to get the less privileged ones involved in acquiring skills
            in software development at little or no cost. We are still seeking for sponsorships and
            partnerships to enable us equip more youths that genuinely cannot afford to pay
          </p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='Will I get a certificate at the end of the course?'
          key='20'
        >
          <p className='text-base text-gray-600 mb-4'>
            Yes! We issue a certificate of completion to our students after completing a course. You
            are expected to show us what you developed by the end of any program before a
            certificate can be issued.{' '}
          </p>
          <p className='text-base text-gray-600'>
            Outstanding students will have a strong recommendation letter and resume forwarded to
            companies for job opportunities.
          </p>
        </Panel>
      </Collapse>
    </div>
  )
}
GeneralFaq.propTypes = { currentFaq: PropTypes.number }

export default GeneralFaq
