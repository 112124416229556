import React, { useRef } from 'react'
import WhatToGain from '../components/weekend-camp/WhatToGain'
import Header from '../components/weekend-camp/Header'
import WatchVideo from '../components/weekend-camp/WatchVideo'
import RemoteClass from '../components/weekend-camp/RemoteClass'
import Subscribe from '../components/Subscribe'
import Form from '../components/weekend-camp/Form'
import Courses from '../components/weekend-camp/Courses'
import Faq from '../components/weekend-camp/FAQ'
import ClientSpeakWidget from '../sharedComponents/clientSpeakWidget/ClientSpeakWidget'
import { testimonialData } from '../sharedComponents/clientSpeakWidget/constants'
import { Helmet } from 'react-helmet-async'

const WeekendCamp = () => {
  const gainRref = useRef()
  const coursesRef = useRef()

  return (
    <div className='mt-0'>
      <Helmet>
        <meta name='keywords' content='weekend coding camp for kids, kids camp, kids programming' />
        <meta
          name='description'
          content='Our goal is to groom younger ones so as to have solid foundation in tech, the Mentortribes weekend camp is designed to reach children at their leisure'
        />
        <title>Mentortribes | Weekend Camp</title>
      </Helmet>
      <Header gainRref={gainRref} />
      <ul className='bubbles'>
        <li className='rounded-full bg-cool-gray-400 bg-opacity-25' />
        <li className='rounded-full bg-red-300 bg-opacity-25' />
        <li className='rounded-full bg-orange-300 bg-opacity-25' />
        <li className='rounded-full bg-cool-gray-400 bg-opacity-25' />
        <li className='rounded-full bg-orange-300 bg-opacity-25' />
        <li className='rounded-full bg-red-300 bg-opacity-25' />
        <li className='rounded-full bg-orange-300 bg-opacity-25' />
        <li className='rounded-full bg-cool-gray-400 bg-opacity-25' />
      </ul>
      <WhatToGain gainRref={gainRref} />
      <RemoteClass coursesRef={coursesRef} />
      <WatchVideo embedId='hv32M0Tl-ug' />
      <Courses coursesRef={coursesRef} />
      <ClientSpeakWidget
        title='Testimonials From Parents'
        client={testimonialData}
        subTitle='Don’t take our word for it alone. Hear directly from parents of kids who passed through our
        summer camp.'
      />
      <Form />
      <Faq />
      <Subscribe />
    </div>
  )
}

export default WeekendCamp
