import React, { useContext } from 'react'
import { userContext } from '../../context/UserContext'
import Mentees from '../components/Mentees'
import Classes from '../components/Classes'
import Courses from '../components/Courses'

const Dashboard = () => {
  const { userInfo, setUserInfo } = useContext(userContext)
  const data = [
    {
      time: '9am',
      day: 'Wednesday',
      menteeName: 'Santos Bright',
      duration: '2hrs',
      courseName: 'Web dev(java)'
    }
  ]

  return (
    <div className='container mx-auto'>
      <h2 className='mb-5 text-2xl font-manrope'>Welcome, {userInfo.data?.firstname}</h2>
      <div className='grid grid-cols-1 sm:grid-cols-3 gap-10'>
        <div className='grid place-content-center bg-white h-32 rounded-2xl shadow'>
          <h3 className='text-3xl font-manrope font-bold text-center'>2</h3>
          <h6 className='font-manrope text-center text-base text-gray-600'>No of classes Today</h6>
        </div>
        <div className='grid place-content-center bg-white h-32 rounded-2xl shadow'>
          <h3 className='text-3xl font-manrope font-bold text-center'>4</h3>
          <h6 className='font-manrope text-center text-base text-gray-600'>
            No of students assign to you
          </h6>
        </div>
        <div className='grid place-content-center bg-white h-32 rounded-2xl shadow'>
          <h3 className='text-3xl font-manrope font-bold text-center'>10,000</h3>
          <h6 className='font-manrope text-center text-base text-gray-600'>Account Balance</h6>
        </div>
      </div>
      <Classes data={data} />
      <Courses setUserInfo={setUserInfo} userInfo={userInfo.data} />
      <Mentees />
    </div>
  )
}

export default Dashboard
