import React from 'react'
import icon1 from '../images/icons/icon-1.png'
import icon2 from '../images/icons/icon-2.png'
import icon3 from '../images/icons/icon-3.png'

const Statistics = () => {
  return (
    <div className='w-full bg-no-repeat bg-img'>
      <div className='flex flex-wrap'>
        <div className='w-full py-16 md:px-52'>
          <div className='flex flex-wrap bg-white py-8 rounded-lg'>
            <div className='w-full md:w-1/3  mb-12 md:mb-1'>
              <div>
                <img src={icon1} className='mx-auto' alt='icon' width='60' />
              </div>
              <div className='text-5xl font-bold text-red-900 text-center'>48+</div>
              <div className='text-center text-black text-lg'>Courses</div>
            </div>
            <div className='w-full md:w-1/3  mb-12 md:mb-1'>
              <div>
                <img src={icon2} className='mx-auto' alt='icon' width='60' />
              </div>
              <div className='text-5xl font-bold text-red-900 text-center'>100+</div>
              <div className='text-center text-black text-lg'>Dedicated Students</div>
            </div>
            <div className='w-full md:w-1/3  mb-12 md:mb-1'>
              <div>
                <img src={icon3} className='mx-auto' alt='icon' width='60' />
              </div>
              <div className='text-5xl font-bold text-red-900 text-center'>84</div>
              <div className='text-center text-black text-lg'>Mentors</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistics
