import React from 'react'
import { Link } from 'react-router-dom'
import kids from '../images/kids.png'

const KidTechWeekendClasses = () => {
  return (
    <div>
      <div style={{ background: '#2f2f2f' }} className='md:mb-15 text-white md:mt-5 md:p-15'>
        <div className='flex flex-wrap'>
          <div className='mx-auto w-full md:w-1/2'>
            <div className='m-5 max-w-5/6 lg:m-24'>
              <h1 className='font-bold text-4xl text-white mb-8'>Weekend Classes are Available.</h1>
              <p className='mt-5 text-base'>
                Kids too occupied during the week? We made plans for them too.
              </p>
              <p className='mt-5 text-base'>
                Our summer camp runs for one (1) month. 18th September 2021 - 18th October 2021.
              </p>
              <p className='mt-5 mb-15 text-base'>
                Classes run for 3 hours daily, Monday to Friday. You can also choose between morning
                and afternoon slots.
              </p>
              <Link
                className='block md:inline-block text-center mx-auto md:mx-0 text-base outline-none px-15 py-3 font-bold rounded text-white hover:text-white hover:bg-red-900 bg-btn-primary mt-4'
                to='/weekend-camp'
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className='hidden md:block  m-auto w-1/2 min-w-48'>
            <img className='mx-auto' src={kids} alt='arrow' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KidTechWeekendClasses
