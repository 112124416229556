import { API, graphqlOperation } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import MentorsApplicationTable from '../components/MentorsApplicationTable'
import { listMentors } from '../graphql/queries'
import Layout from './Layout'

const BookMentorsAll = () => {
  const [mentors, setMentors] = useState([])
  const [loading, setLoading] = useState(true)

  const getBookAMentors = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listMentors))

      const { items } = data.listMentors
      const listOfItems = items
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((item, index) => {
          item.key = item.id
          item.sn = items.length - index
          item.fullName = `${item.firstname} ${item.lastname}`
          return item
        })

      setMentors(listOfItems)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBookAMentors()
  }, [])

  // // Subscribe to creation of Todo
  // const subscription = API.graphql(graphqlOperation(onDeleteBookAmentor)).subscribe({
  //   next: ({ value }) => {
  //     getBookAMentors()
  //   },
  //   error: error => console.warn(error)
  // })

  // // Stop receiving data updates from the subscription
  // return () => subscription.unsubscribe()

  return (
    <Layout>
      <main className='w-full flex-grow p-4'>
        <h2 className='text-2xl pb-4 flex items-center px-10 font-bold'>
          <i className=' fas fa-list mr-3' /> Mentor Applications
        </h2>
        <MentorsApplicationTable loading={loading} bookMentors={mentors} />
      </main>
    </Layout>
  )
}

export default BookMentorsAll
