import React from 'react'
import ProgressBar from './ProgressBar'

const PaidCourse = ({ paidCourses }) => {
  return paidCourses.map(({ id, lang, courseTitle, coverPage, duration, topics, progress }) => (
    <div key={id} className='max-w-xs rounded overflow-hidden shadow-lg my-2 mx-auto'>
      <img className='w-full' src={coverPage} alt={lang} />
      <div className='px-4 py-4'>
        <div className='font-bold text-lg text-center mb-2'>{lang}</div>
        <p className='text-center'>
          ({courseTitle.slice(0, 35)}
          {courseTitle.length > 35 && '...'})
        </p>
      </div>

      <ProgressBar progress={progress} />

      <div className='flex justify-center pb-3 text-grey-dark'>
        <div className='text-center mr-3 border-r pr-3'>
          <h2>{topics}</h2>
          <span className='text-brown-400'>Topics</span>
        </div>
        <div className='text-center block'>
          <h2>{duration}</h2>
          <span className='text-brown-400'>Duration</span>
        </div>
      </div>
    </div>
  ))
}

export default PaidCourse
