import React from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'
import Rating from './Rating'
import RateNow from './RateNow'
import Comments from './Comments'

const ModalBox = ({ modalProps, showModal, handleBook, handleCancel }) => {
  const { visible } = showModal

  const { id, title, topics, rating, students, video, lang } = modalProps

  return (
    <>
      <Modal
        visible={visible}
        className=' mb-16 pb-10'
        title={lang}
        onOk={() => handleBook()}
        onCancel={() => handleCancel()}
        footer={[
          <Link
            to='/#'
            key='back'
            className='ml-2 bg-brown-500 hover:bg-red-900 text-white hover:text-white font-bold py-2 px-4 border border-brown-500 rounded'
            onClick={() => handleCancel()}
          >
            Cancel
          </Link>,
          <Link
            key='submit'
            className='ml-2 bg-gray-400 hover:bg-gray-500 text-black hover:text-black font-bold py-2 px-4 border border-brown-500 rounded'
            to={`checkout/${id}`}
          >
            + Book Now
          </Link>
        ]}
      >
        <section className='mx-auto max-w-xl'>
          {video && (
            <div className='md:flex-shrink-0 py-3'>
              <ReactPlayer width='100%' url={video} />
            </div>
          )}
          <div className='px-2'>
            <h2 className='font-bold text-xl text-brown-500 tracking-normal'>
              {title?.toUpperCase()}
            </h2>
            <div className='flex justify-left items-center'>
              <Rating rating={rating} students={students} />
            </div>
            <div className='py-2'>
              <RateNow />
            </div>
            {/* <p className='text-gray-500'>
              <span className='font-bold'>Created by:</span> {mentor}
            </p> */}
            <hr />
          </div>
          <div className='py-2'>
            <h4 className='px-2 mb-2 text-base text-black italic'>
              Some of the topics to be covered:
            </h4>
            <ol className='list-decimal mx-5'>
              {topics && topics.map((topic, keys) => <li key={keys}>{topic}</li>)}
            </ol>
          </div>
          <div className='py-2'>
            <Comments />
          </div>
        </section>
      </Modal>
    </>
  )
}

ModalBox.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  modalProps: PropTypes.any,
  showModal: PropTypes.any,
  handleBook: PropTypes.func
}

export default ModalBox
