import React from 'react'
import checkIcon from '../../images/pricing/check.svg'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PricingCard = ({ istOfPerks, title, price, type, color, margin }) => {
  return (
    <div className='relative bg-white rounded-lg px-5 md:px-10 py-10'>
      <h4 className='text-3xl font-bold'>
        <span className='font-dm-serif-display'>₦{price}</span>
        <sub className='text-gray-500 text-base font-normal'>/month</sub>
      </h4>
      <h4 style={{ color: '#CD7F32' }} className='text-2xl my-3 font-dm-serif-display font-bold'>
        {type}
      </h4>
      <p className='text-lg text-gray-500 mb-4'>{title}</p>
      <ul>
        {istOfPerks.map((perk, i) => (
          <li key={i} className='flex py-2 items-center'>
            <figure className='m-0 p-0 mr-3'>
              <img src={checkIcon} alt='check' />
            </figure>
            <h6 className='text-gray-500 text-lg'>{perk}</h6>
          </li>
        ))}
      </ul>
      <Link
        to={{ pathname: '/book-mentor', state: { type } }}
        className={`absolute bottom-1 md:bottom-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block w-11/12 text-center py-2 ${margin} text-white duration-200 hover:text-white hover:bg-opacity-75 px-5 rounded-md ${color}`}
      >
        Subscribe to {type}
      </Link>
    </div>
  )
}

export default PricingCard

PricingCard.propTypes = {
  istOfPerks: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired
}
