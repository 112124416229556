/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBookAmentor = /* GraphQL */ `
  query GetBookAmentor($id: ID!) {
    getBookAmentor(id: $id) {
      id
      mentorId
      fullName
      phone
      email
      createdAt
      course
      image
      type
      startEndDate {
        start
        end
      }
      whyLearnTheCourse
      courseDetails
      detailCourseName
      payment {
        status
        txRef
      }
      status
      transferOption
      updatedAt
    }
  }
`;
export const listBookAmentors = /* GraphQL */ `
  query ListBookAmentors(
    $filter: ModelBookAmentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookAmentors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mentorId
        fullName
        phone
        email
        createdAt
        course
        image
        type
        startEndDate {
          start
          end
        }
        whyLearnTheCourse
        courseDetails
        detailCourseName
        payment {
          status
          txRef
        }
        status
        transferOption
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookKidmentor = /* GraphQL */ `
  query GetBookKidmentor($id: ID!) {
    getBookKidmentor(id: $id) {
      id
      parentName
      parentEmail
      parentPhone
      noOfKids
      createdAt
      firstChildName
      firstChildAge
      firstChildInterest
      payment {
        status
        txRef
      }
      transferOption
      updatedAt
    }
  }
`;
export const listBookKidmentors = /* GraphQL */ `
  query ListBookKidmentors(
    $filter: ModelBookKidmentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookKidmentors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentName
        parentEmail
        parentPhone
        noOfKids
        createdAt
        firstChildName
        firstChildAge
        firstChildInterest
        payment {
          status
          txRef
        }
        transferOption
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMentor = /* GraphQL */ `
  query GetMentor($id: ID!) {
    getMentor(id: $id) {
      id
      userSubId
      isActive
      status
      firstname
      lastname
      phone
      email
      projects
      country
      specialization
      github
      createdAt
      imageUrl {
        url
        key
      }
      video
      accountBalance
      yrsOfexperience
      courses {
        items {
          id
          mentorId
          title
          image
          video
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      isAdmin
      bio
      facebookUrl
      dribbleUrl
      linkedInUrl
      otherLinkUrl
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listMentors = /* GraphQL */ `
  query ListMentors(
    $filter: ModelMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSubId
        isActive
        status
        firstname
        lastname
        phone
        email
        projects
        country
        specialization
        github
        createdAt
        imageUrl {
          url
          key
        }
        video
        accountBalance
        yrsOfexperience
        courses {
          nextToken
        }
        isAdmin
        bio
        facebookUrl
        dribbleUrl
        linkedInUrl
        otherLinkUrl
        chat {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      mentorId
      title
      image
      video
      description
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mentorId
        title
        image
        video
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookCourse = /* GraphQL */ `
  query GetBookCourse($id: ID!) {
    getBookCourse(id: $id) {
      id
      courseId
      menteeName
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const listBookCourses = /* GraphQL */ `
  query ListBookCourses(
    $filter: ModelBookCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        menteeName
        phone
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBootcamp = /* GraphQL */ `
  query GetBootcamp($id: ID!) {
    getBootcamp(id: $id) {
      id
      fullName
      phone
      email
      country
      source
      whyLearnTheCourse
      doYouHaveAlaptop
      track
      transferOption
      payment {
        status
        txRef
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBootcamps = /* GraphQL */ `
  query ListBootcamps(
    $filter: ModelBootcampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBootcamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        phone
        email
        country
        source
        whyLearnTheCourse
        doYouHaveAlaptop
        track
        transferOption
        payment {
          status
          txRef
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMentorForm = /* GraphQL */ `
  query GetMentorForm($id: ID!) {
    getMentorForm(id: $id) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const listMentorForms = /* GraphQL */ `
  query ListMentorForms(
    $filter: ModelMentorFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentorForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        phone
        email
        resume
        source
        hoursPerDay
        anotherRole
        track
        yearsOfExperience
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactUs = /* GraphQL */ `
  query GetContactUs($id: ID!) {
    getContactUs(id: $id) {
      id
      fullName
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const listContactUss = /* GraphQL */ `
  query ListContactUss(
    $filter: ModelContactUsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactUss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        email
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVisitor = /* GraphQL */ `
  query GetVisitor($id: ID!) {
    getVisitor(id: $id) {
      id
      email
      fullName
      phone
      chat {
        items {
          id
          mentorId
          visitorId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVisitors = /* GraphQL */ `
  query ListVisitors(
    $filter: ModelVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        fullName
        phone
        chat {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareersForm = /* GraphQL */ `
  query GetCareersForm($id: ID!) {
    getCareersForm(id: $id) {
      id
      fullName
      phone
      email
      resume
      source
      hoursPerDay
      anotherRole
      track
      yearsOfExperience
      createdAt
      updatedAt
    }
  }
`;
export const listCareersForms = /* GraphQL */ `
  query ListCareersForms(
    $filter: ModelCareersFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareersForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        phone
        email
        resume
        source
        hoursPerDay
        anotherRole
        track
        yearsOfExperience
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      mentorId
      visitorId
      messages {
        items {
          id
          chatId
          text
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mentorId
        visitorId
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      chatId
      text
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatId
        text
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebinarAttendee = /* GraphQL */ `
  query GetWebinarAttendee($id: ID!) {
    getWebinarAttendee(id: $id) {
      id
      fullName
      phone
      email
      source
      joinMailList
      createdAt
      updatedAt
    }
  }
`;
export const listWebinarAttendees = /* GraphQL */ `
  query ListWebinarAttendees(
    $filter: ModelWebinarAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebinarAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        phone
        email
        source
        joinMailList
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookAdvisory = /* GraphQL */ `
  query GetBookAdvisory($id: ID!) {
    getBookAdvisory(id: $id) {
      id
      fullName
      phone
      email
      source
      callDate
      callTime
      createdAt
      updatedAt
    }
  }
`;
export const listBookAdvisorys = /* GraphQL */ `
  query ListBookAdvisorys(
    $filter: ModelBookAdvisoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookAdvisorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        phone
        email
        source
        callDate
        callTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const mentorByEmail = /* GraphQL */ `
  query MentorByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentorByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSubId
        isActive
        status
        firstname
        lastname
        phone
        email
        projects
        country
        specialization
        github
        createdAt
        imageUrl {
          url
          key
        }
        video
        accountBalance
        yrsOfexperience
        courses {
          nextToken
        }
        isAdmin
        bio
        facebookUrl
        dribbleUrl
        linkedInUrl
        otherLinkUrl
        chat {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const mentorByUserSubId = /* GraphQL */ `
  query MentorByUserSubId(
    $userSubId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentorByUserSubId(
      userSubId: $userSubId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSubId
        isActive
        status
        firstname
        lastname
        phone
        email
        projects
        country
        specialization
        github
        createdAt
        imageUrl {
          url
          key
        }
        video
        accountBalance
        yrsOfexperience
        courses {
          nextToken
        }
        isAdmin
        bio
        facebookUrl
        dribbleUrl
        linkedInUrl
        otherLinkUrl
        chat {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const mentorByStatus = /* GraphQL */ `
  query MentorByStatus(
    $status: MentorStatus
    $sortDirection: ModelSortDirection
    $filter: ModelMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentorByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSubId
        isActive
        status
        firstname
        lastname
        phone
        email
        projects
        country
        specialization
        github
        createdAt
        imageUrl {
          url
          key
        }
        video
        accountBalance
        yrsOfexperience
        courses {
          nextToken
        }
        isAdmin
        bio
        facebookUrl
        dribbleUrl
        linkedInUrl
        otherLinkUrl
        chat {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const mentorByActiveAndUserSubId = /* GraphQL */ `
  query MentorByActiveAndUserSubId(
    $userSubId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMentorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentorByActiveAndUserSubId(
      userSubId: $userSubId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSubId
        isActive
        status
        firstname
        lastname
        phone
        email
        projects
        country
        specialization
        github
        createdAt
        imageUrl {
          url
          key
        }
        video
        accountBalance
        yrsOfexperience
        courses {
          nextToken
        }
        isAdmin
        bio
        facebookUrl
        dribbleUrl
        linkedInUrl
        otherLinkUrl
        chat {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseByDate = /* GraphQL */ `
  query CourseByDate(
    $createdAt: String
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseByDate(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mentorId
        title
        image
        video
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
