import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { NavLink, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined
} from '@ant-design/icons'
import AdminHome from './home'
import AddCourses from './courses/addCourses'
import AddMentor from '../pages/AddMentor'
import Bookings from './bookings/AllBookings'
import BootCampList from './bootcamp/Table'

const { Sider, Content } = Layout

const SidePanel = () => {
  const { path } = useRouteMatch()
  const [collapsed, setCollapse] = useState(false)
  const location = useLocation()

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className='logo' />
      <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={`${path}`}
        selectedKeys={location.pathname}
      >
        <Menu.Item icon={<UserOutlined />}>
          <NavLink to={`${path}`}>Home</NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/add-courses`} icon={<VideoCameraOutlined />}>
          <NavLink to={`${path}/add-courses`}>Add courses</NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/add-mentors`} icon={<UploadOutlined />}>
          <NavLink to={`${path}/add-mentors`}>Add Mentors</NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/bookings`} icon={<UploadOutlined />}>
          <NavLink to={`${path}/bookings`}>Custom Bookings</NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/bootcamp`} icon={<UploadOutlined />}>
          <NavLink to={`${path}/bootcamp`}>Boot Camp</NavLink>
        </Menu.Item>
        <div className='w-full mx-1/5 absolute bottom-3' onClick={() => setCollapse(!collapsed)}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          {collapsed ? '' : ' Close'}
        </div>
      </Menu>
    </Sider>
  )
}

const AdminLayout = () => {
  const { path: _path } = useRouteMatch()
  return (
    <Layout className='mt-0'>
      <SidePanel />
      <Layout>
        <Content
          className='site-layout-background'
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 450
          }}
        >
          <Switch>
            <Route path={`${_path}`} exact component={AdminHome} />
            <Route path={`${_path}/add-courses`} exact component={AddCourses} />
            <Route path={`${_path}/add-mentors`} exact component={AddMentor} />
            <Route path={`${_path}/bookings`} exact component={Bookings} />
            <Route path={`${_path}/bootcamp`} exact component={BootCampList} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout
