import React from 'react'
import icon1 from '../../images/icons/services-1.png'
import icon2 from '../../images/icons/services-2.png'
import icon3 from '../../images/icons/services-3.png'
import icon4 from '../../images/icons/services-4.png'
import icon5 from '../../images/icons/services-5.png'
import icon6 from '../../images/icons/services-6.png'
import kids from '../../images/icons/kids.png'
import InviewPortAnimate from '../../sharedComponents/InviewPortAnimate'

const WhatToGain = () => {
  return (
    <div className='pt-12 bg-white'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/2 mx-auto text-center'>
          <h1 className='text-gray-400 text-4xl text-center font-semibold'>
            What Your <img src={kids} alt='Kid' className='inline mr-2' />
            Stands To Gain
          </h1>
        </div>
      </div>
      <div className='container mx-auto px-5'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 pt-12'>
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-gray-100`}
              >
                <h1 className='font-bold text-xl px-4'>
                  <img src={icon1} alt='arrow' className='img-it-works' />
                  Better preparation for Tomorrow
                </h1>
                <p className='text-base px-4 py-2 pt-3'>
                  <span className='block py-1'>
                    Nearly 50% of all high-paying jobs require coding skills. Making it a good way
                    to future proof your kids
                  </span>
                  <br />
                  <span className='md:block hidden'>
                    With jobs in the technology sector experiencing steady growth from year to year,
                    . Getting your child started young with coding will help better prepare them
                    with the skills sought by employers around the world.
                  </span>
                </p>
              </div>
            )}
            animateClassName='box-animate'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-gray-100`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon2} alt='arrow' className='img-it-works' />
                  Builds Kids Self-Confidence
                </h1>
                <p className='text-base px-4 pt-3'>
                  If you are a parent we can help you, we have a passion to support families get the
                  best out of their Children.The future we envision, the results we have achieved
                  and the children we have helped transform makes us more confident. We can support
                  you to inspire, motivate and prepare your children to have an edge in the Tech
                  space for life.
                </p>
              </div>
            )}
            animateClassName='box-animate-2'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-gray-100`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon3} alt='arrow' className='img-it-works' />
                  Improved Critical Thinking Skill
                </h1>
                <p className='text-base px-4 pt-3'>
                  kids who code develop a disciplined process for analyzing problems, gathering
                  data, understanding objectives, distinguishing between possible solutions,
                  predicting possible results, and choosing a problem-solving approach.
                </p>
              </div>
            )}
            animateClassName='box-animate-3'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-gray-100`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon4} alt='arrow' className='img-it-works' />
                  Develop problem-solving skills
                </h1>
                <p className='text-base px-4 pt-3'>
                  Coding helps kids develop soft skills and build aspects of their character, as
                  they develop traits such as resilience, grit, and patience. Which enables them to
                  pursue their dreams
                </p>
              </div>
            )}
            animateClassName='box-animate-left-3'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-gray-100`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon5} alt='arrow' className='img-it-works' />
                  Stimulate Kids Creativity
                </h1>
                <p className='text-base px-4 pt-3'>
                  Giving mental image a form in the real world is the height of creativity. We want
                  to empower kids to not just consume digital media and Technology but to create it.
                </p>
              </div>
            )}
            animateClassName='box-animate-left-2'
          />
          <InviewPortAnimate
            Component={({ className }) => (
              <div
                className={`${className} px-4 font-manrope h-full rounded-md py-5 md:py-8 bg-gray-100`}
              >
                <h1 className='font-bold text-xl px-3'>
                  <img src={icon6} alt='arrow' className='img-it-works' />
                  Improved Concentration and Focus
                </h1>
                <p className='text-base px-4 pt-3'>
                  In our highly distracted world, Learning to code teaches kids to concentrate and
                  focus on a single task. This becomes a life skill
                </p>
              </div>
            )}
            animateClassName='box-animate-left'
          />
        </div>
      </div>
    </div>
  )
}

export default WhatToGain
