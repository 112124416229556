import React from 'react'
import { Link } from 'react-router-dom'
import userAvatar from '../../images/profile.png'
import PropTypes from 'prop-types'

const Chatbar = ({ visitor }) => {
  return (
    <nav className='h-20 flex flex-col'>
      <div className='bg-white flex-1 w-full' />
      <div
        style={{ backdropFilter: 'blur(10px)' }}
        className='flex items-center py-2 px-5 bg-transparent z-10 shadow rounded-t-xl'
      >
        <figure className='m-0 p-0 hidden md:block'>
          <img src={userAvatar} alt='' className='w-12 h-12 rounded-full object-cover' />
        </figure>
        <Link to='/mentor-dashboard/chat' className='flex items-center md:hidden'>
          <i className='fa text-gray-900 duration-200 hover:text-gray-700 mr-3 fa-arrow-left' />
          <figure className='m-0 p-0 w-12 h-12 rounded-full bg-gray-300'>
            <img src={userAvatar} alt='' className='w-12 h-12 rounded-full object-cover' />
          </figure>
        </Link>
        <div className='ml-3 flex-1'>
          <div className='flex justify-between'>
            <div>
              <div>
                <h4 className='font-manrope font-semibold text-base'>{visitor?.fullName}</h4>
                <p className='font-manrope text-gray-400 font-medium'>
                  {visitor?.email} <span className='mx-3'>|</span> {visitor?.phone}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
Chatbar.propTypes = { visitor: PropTypes.any }

export default Chatbar
