import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { notification } from 'antd'
import Input from '../components/auth/Input'
import PasswordInput from '../components/auth/PasswordInput'
import signBg from '../images/signup.png'
import ConfirmAccount from '../components/auth/ConfirmAccount'
import { Helmet } from 'react-helmet-async'

const SignupSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .required('Please confirm Password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

const Signup = () => {
  const [isSubmmitting, setSubmitting] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [username, setUsername] = useState('')
  const [subId, setSubId] = useState('')
  const history = useHistory()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(() => {
      notification.success({
        message: 'Logged In',
        duration: 10000,
        description: 'You are already logged in, check your mentorship dashboard'
      })
      history.push('/mentor-dashboard')
    })
  }, [])

  return (
    <div className=''>
      <Helmet>
        <meta name='keywords' content='Create an Account with mentortribes, mentortribes account' />
        <meta
          name='description'
          content='We have as our Mission providing you with access to a personal mentor'
        />
        <title>Mentortribes | Sign Up</title>
      </Helmet>
      <ConfirmAccount
        subId={subId}
        isVisible={isVisible}
        setVisible={setVisible}
        username={username}
      />
      <div className='flex flex-wrap pt-12'>
        <div className='w-full md:w-1/2 px-4 md:px-16'>
          <div className='mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto text-gray-900'>
            <h1 className='text-center text-4xl font-bold'>Create Account</h1>
            <Formik
              validationSchema={SignupSchema}
              initialValues={{
                email: '',
                password: '',
                confirmPassword: ''
              }}
              onSubmit={values => {
                setSubmitting(true)
                notification.destroy()
                Auth.signUp({
                  username: values.email,
                  password: values.password
                  // attributes: { 'custom:isAdmin': 1 }
                })
                  .then(res => {
                    setSubmitting(false)
                    setSubId(res.userSub)
                    setUsername(values.email)
                    setVisible(true)
                  })
                  .catch(err => {
                    setSubmitting(false)
                    notification.error({
                      message: err.message,
                      placement: 'topLeft',
                      duration: 10000
                    })
                  })
              }}
            >
              <Form>
                <div className=''>
                  <Input type='email' id='email' name='email' label='Email' />
                  <PasswordInput label='Password' name='password' id='password' />
                  <PasswordInput
                    label='Confirm Password'
                    name='confirm_password'
                    id='confirm_password'
                  />
                  <div className='mt-10'>
                    <button
                      type='submit'
                      className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                    >
                      {isSubmmitting ? (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      ) : (
                        ''
                      )}{' '}
                      Create Account
                    </button>
                  </div>
                  <div className='mt-5 text-center text-lg'>OR</div>
                  {/* <div className='mt-7'>
                    <button
                      onClick={async () => {
                        const result = await Auth.federatedSignIn({ provider: 'Google' })
                        console.log(result)
                      }}
                      type='button'
                      className='py-3 bg-gray-100 text-black rounded-md flex justify-center items-center w-full focus:outline-none focus:shadow-outline text-lg'
                    >
                      <img
                        src={googleIcon}
                        alt='google icon'
                        className='inline object-contain mr-2'
                      />
                      Continue with Google
                    </button>
                  </div> */}
                  <div className='text-center my-5 text-lg'>
                    Already Have an Account?
                    <Link to='/login' className='text-blue-700 no-underline hover:underline ml-2'>
                      Log In
                    </Link>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${signBg})` }}
          className='hidden md:block w-full md:w-1/2 bg-no-repeat signup-bg-img text-white text-center bg-opacity-20'
        >
          <h1 className='text-white text-4xl font-bold mt-48'>Create an Account With Us</h1>
          <p className='mt-4 text-xl'>
            <span className='bg-black opacity-50 px-2 rounded-md'>
              You are on your way to becoming a mentor
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Signup
