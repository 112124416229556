import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import coverImage from '../images/courseCoverImage.png'
import ModalBox from './ModalBox'
import Rating from './Rating'
import PropTypes from 'prop-types'
import { PlayCircleFilled } from '@ant-design/icons'

const Course = ({ data }) => {
  const [showModal, setShowModal] = useState({
    loading: false,
    visible: false
  })

  const [modalProps, setModalProps] = useState({
    id: '',
    title: '',
    topics: '',
    duration: '',
    // video: '',
    rating: '',
    students: 0
    // mentor: ''
  })

  const handleBook = () => {
    setShowModal({ ...showModal, visible: false })
    // hhhhh
  }

  const handleCancel = () => {
    setShowModal({ ...showModal, visible: false })
  }

  const visibleModalHandle = ({
    id,
    language,
    title,
    topics,
    duration,
    // video,
    rating,
    students
    // mentor
  }) => {
    setModalProps({
      ...modalProps,
      id,
      language,
      title,
      topics,
      duration,
      // video,
      rating,
      students
      // mentor
    })
    setShowModal({ ...showModal, visible: true })
  }
  // Courses is now available here
  return (
    <>
      {data.map(
        (
          {
            id,
            language, // mentor,
            title,
            topics,
            duration,
            rating,
            students = 100
          } // video, // coverPage,
        ) => (
          <div key={id} className='rounded rounded-t-lg overflow-hidden shadow pb-4 mx-auto mb-6'>
            <img src={coverImage} alt='course details' className='w-full' />
            <div className='flex justify-center -mt-8'>
              <div
                onClick={() =>
                  visibleModalHandle({
                    id,
                    language,
                    title,
                    topics,
                    duration,
                    // video,
                    rating,
                    students
                    // mentor
                  })
                }
                className='animate-pulse rounded-full cursor-pointer h-16 w-16 hover:bg-red-800 active:bg-gray-400 bg-brown-400 flex items-center justify-center border-gray-400 border-2'
              >
                {/* {video ? ( */}
                <PlayCircleFilled style={{ fontSize: '25px' }} />
                {/* ) : (
                <InfoCircleOutlined style={{ fontSize: "25px" }} />
              )} */}
              </div>
            </div>
            <div className='text-center'>
              <h3
                onClick={() =>
                  visibleModalHandle({
                    id,
                    language,
                    title,
                    topics,
                    duration,
                    // video,
                    rating,
                    students
                    // mentor
                  })
                }
                className='mt-2 text-black text-sm font-bold font-sans cursor-pointer hover:text-gray-500'
              >
                {language}
              </h3>
              <p className='font-sans text-gray-600 my-1' title={title}>
                ({title.slice(0, 33)}
                {title.length > 33 && '...'})
              </p>
              <p className='font-sans text-gray-800 my-1'> ~ mentor ~</p>
            </div>
            <div className='flex justify-center items-center mb-1 '>
              <Rating rating={rating} students={students} />
            </div>
            <div className='flex justify-center pb-3 text-grey-dark'>
              <div className='text-center mr-3 border-r pr-3'>
                <h2>{topics.length}</h2>
                <span className='text-brown-400'>Topics</span>
              </div>
              <div className='text-center block'>
                <h2>{duration}</h2>
                <span className='text-brown-400'>Duration</span>
              </div>
            </div>
            <div className='flex justify-center pb-3 text-grey-dark'>
              <Link
                to={`/checkout/${id}`}
                className='px-4 py-1 bg-brown-500 hover:bg-red-900 text-white hover:text-white rounded'
              >
                + Book Now
              </Link>
            </div>
          </div>
        )
      )}
      <ModalBox
        modalProps={modalProps}
        showModal={showModal}
        handleBook={handleBook}
        handleCancel={handleCancel}
      />
    </>
  )
}

Course.propTypes = {
  data: PropTypes.object.isRequired
}

export default Course
