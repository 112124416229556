import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ClassDropDown = ({ record, handleMentorStatusChange, setSelectedMentor, setVisible }) => {
  const [hidden, setHidden] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleView = () => {
    setVisible(true)
    setSelectedMentor(record)
  }

  useEffect(() => {
    return () => {
      setHidden(true)
    }
  }, [setHidden])

  ///
  return (
    <div className='dropdown block md:inline-block md:mr-6 mr-10 relative mb-6 md:mb-0'>
      <button
        onClick={() => setHidden(!hidden)}
        className='w-full items-center focus:outline-none bg-red-600 text-xs text-center py-1 px-5 rounded-sm shadow-lg hover:shadow-xl text-white uppercase'
      >
        <span className='mx-auto w-full inline-flex items-center'>
          {loading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
          Action
          <i className='fas fa-chevron-down ml-2' />
        </span>
      </button>
      <ul
        onMouseLeave={() => setHidden(true)}
        className={` ${
          hidden ? 'hidden' : ''
        } w-full dropdown-menu z-50 rounded-sm bg-gray-200 border-gray-300 border absolute text-black pt-1`}
      >
        <li>
          <p
            onClick={handleView}
            className='w-full text-yellow-600 text-base hover:bg-gray-300 py-1 px-2 block whitespace-no-wrap cursor-pointer'
          >
            <i className='fa fa-pen' /> Edit
          </p>
        </li>
        <li>
          <p
            onClick={() => handleMentorStatusChange('DECLINED', record, setLoading, setHidden)}
            className='w-full text-red-600 text-base hover:bg-gray-300 py-1 px-2 block whitespace-no-wrap cursor-pointer'
          >
            <i className='far fa-trash-alt' /> Delete
          </p>
        </li>
      </ul>
    </div>
  )
}

ClassDropDown.propTypes = {
  record: PropTypes.object.isRequired,
  handleMentorStatusChange: PropTypes.func,
  setSelectedMentor: PropTypes.any,
  setVisible: PropTypes.any
}

export default ClassDropDown
