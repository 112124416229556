/* eslint-disable no-template-curly-in-string */
import { Form, Input, Button, Select, notification, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createBootcamp, updateBootcamp } from '../graphql/mutations'
import axios from '../services/axios'
import PropTypes from 'prop-types'
import PaymentButton from '../components/Payment'
import { useHistory } from 'react-router-dom'

const { Item } = Form
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!'
  }
}

const BootcampForm = ({ message }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [{ success, loading, email, name, phone, errors }, setState] = useState({
    success: false,
    loading: false,
    errors: '',
    email: '',
    name: '',
    phone: ''
  })

  const handleSubmit = async values => {
    let link = ''
    let startDate = ''
    setState(prev => ({ ...prev, loading: true }))
    try {
      const data = await API.graphql(graphqlOperation(createBootcamp, { input: values }))
      const name = values.fullName.substr(0, values.fullName.indexOf(' '))

      if (values.track === 'Forex and Crypto Trading') {
        link = 'https://app.slack.com/client/T015J560HV5/C02UU79CN4T'
      } else if (values.track === 'Frontend') {
        link = 'https://app.slack.com/client/T015J560HV5/C02UWE73TNY'
      } else if (values.track === 'Backend') {
        link = 'https://app.slack.com/client/T015J560HV5/C02UP59MX5Z'
      } else if (values.track === 'Digital Marketing') {
        link = 'https://app.slack.com/client/T015J560HV5/C02UR8ZNDV3'
      } else if (values.track === 'UI/UX') {
        link = 'https://app.slack.com/client/T015J560HV5/C030UMFTAD7'
      } else {
        link = 'https://app.slack.com/client/T015J560HV5/C0303TNQMG8'
      }

      if (values.track === 'Forex and Crypto Trading') {
        startDate = '1st March, 2022'
      } else if (values.track === 'Frontend') {
        startDate = '1st March, 2022'
      } else if (values.track === 'Backend') {
        startDate = '1st March, 2022'
      } else if (values.track === 'Digital Marketing') {
        startDate = '1st March, 2022'
      } else {
        startDate = '1st March, 2022'
      }

      if (data.data.createBootcamp.id) {
        axios.post('/Eazy2con/bootcamp-success', {
          email: values.email,
          firstName: name || values.fullName,
          fullName: values.fullName,
          track: values.track,
          slackLink: link,
          startDate
        })

        setState(prev => ({
          ...prev,
          success: true,

          loading: false,
          email: values?.email,
          phone: values?.phone,
          name: values?.fullName
        }))
        window.localStorage.setItem('bootcamp-id', data.data.createBootcamp.id)
      } else if (data?.errors.length > 0) throw Error(data.errors[0].message)
    } catch (error) {
      setState({ error: true, success: false, loading: false })
      notification.error({
        message: 'Something went wrong, please Check your Network connection',
        duration: 5000
      })
    }
  }

  const handleClick = async () => {
    const id = window.localStorage.getItem('bootcamp-id')
    try {
      await API.graphql(
        graphqlOperation(updateBootcamp, {
          input: {
            id: id,
            transferOption: true
          }
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (success) {
      notification.success({
        message: 'You are now registered, Please check your email'
      })
    }
    if (errors) {
      message.error('A problem occured, please check your network connection ')
    }
    return () => {}
  }, [success, errors])

  return success ? (
    <Result
      status='success'
      title='Your Registeration has been received, To confirm your spot in the Bootcamp go ahead to make a One-time payment of ₦20,000 only'
      subTitle={
        <>
          <div className='flex justify-center mt-5'>
            <PaymentButton
              details={{
                email: email,
                amount: 20000,
                name: name,
                phone: phone,
                buttonLabel: 'Pay Online',
                redirectUrl: '/bootcamp-payment'
              }}
            />
            <button
              className='px-8 py-2 block w-full max-w-max mx-auto sm:mx-0 font-manrope cursor-pointer duration-200 hover:bg-opacity-75 bg-green-600 text-white font-semibold rounded'
              onClick={
                (handleClick,
                () =>
                  history.push('/payment-invoice', {
                    email,
                    name,
                    amount: 20000,
                    item: 'Bootcamp Payment'
                  }))
              }
            >
              Bank Transfer
            </button>
          </div>
        </>
      }
    />
  ) : (
    <Form
      onFinish={handleSubmit}
      disabled
      size='large'
      form={form}
      className='md:grid md:grid-cols-2 gap-x-4 gap-y-0 flex flex-col'
      layout='vertical'
      validateMessages={validateMessages}
      style={{
        color: '#404445',
        fontFamily: 'Manrope',
        fontStyle: 'normal'
      }}
    >
      <Item
        className='p-0 m-0'
        rules={[
          {
            required: true
          }
        ]}
        name='fullName'
        label='Full Name'
      >
        <Input
          disabled
          placeholder='John Doe'
          className='border border-gray-300 hover:border-gray-500 rounded'
        />
      </Item>
      <Item
        name='email'
        label='Email'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input
          disabled
          className='border border-gray-300 hover:border-gray-500 rounded'
          placeholder='example@gmail.com'
        />
      </Item>
      <Item
        className='p-0 m-0'
        name='phone'
        label='Phone'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input
          disabled
          className='border border-gray-300 hover:border-gray-500 rounded'
          placeholder='+1605000000'
        />
      </Item>
      <Item
        name='country'
        label='Country'
        rules={[
          {
            required: true,
            message: 'Please input your country'
          }
        ]}
      >
        <Input
          disabled
          className='border border-gray-300 hover:border-gray-500 rounded'
          placeholder='Country is required'
        />
      </Item>
      <Item
        name='source'
        label='How did you hear about us?'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Select disabled placeholder='How did you hear about us'>
          {[
            'From a friend',
            'Co worker',
            'Facebook',
            'Instagram',
            'Twitter',
            'Whatsapp',
            'Linkedin',
            'Google search',
            'others'
          ].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item
        name='doYouHaveAlaptop'
        label='Do you have a laptop'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Select disabled placeholder='Select...' bordered>
          {['Yes', 'No'].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item
        className='p-0 m-0'
        name='track'
        label='Pick a Track'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Select disabled placeholder='Select...' bordered>
          {[
            'Forex and Crypto Trading',
            'Frontend',
            'Digital Marketing',
            'Backend',
            'Data Analysis',
            'UI/UX'
          ].map(options => (
            <Select.Option key={options} value={options}>
              {options}
            </Select.Option>
          ))}
        </Select>
      </Item>

      <Item
        rules={[
          {
            required: false
          }
        ]}
        name='whyLearnTheCourse'
        label='Why learn this? (optional)'
      >
        <Select disabled placeholder='Why learn this course'>
          {[
            'Acquire new skill',
            'Needed for current project',
            'I am a novice',
            'I want to know the best practice',
            'This is required for me to pass a course',
            'Trying things out',
            'others'
          ].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Button
        disabled
        loading={loading}
        htmlType='submit'
        className='col-span-2 w-full text-center h-15 mx-auto mt-8 bg-red-900 hover:bg-red-800 active:bg-white hover:text-white text-white active:text-red-800 font-bold border border-red-700 rounded-lg'
      >
        Register
      </Button>
    </Form>
  )
}

export default BootcampForm

BootcampForm.propTypes = {
  message: PropTypes.any
}
