import { notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import WebinarTable from '../components/admin/WebinarTable'
import { listWebinarAttendees } from '../graphql/queries'
import { onDeleteBookKidmentor } from '../graphql/subscriptions'
import Layout from './Layout'

const WebinarAll = () => {
  const [bookMentors, setBookMentors] = useState([])
  const [loading, setLoading] = useState(true)

  const getBookAMentors = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(listWebinarAttendees))
      const { items } = data.listWebinarAttendees
      const listOfItems = items
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((a, index) => ({ ...a, sn: index + 1 }))
      setLoading(false)
      setBookMentors(listOfItems)
    } catch (error) {
      notification.error({
        description: error?.errors ? error?.errors[0].message : 'Something went wrong'
      })
    }
  }

  useEffect(() => {
    getBookAMentors()

    const subscription = API.graphql(graphqlOperation(onDeleteBookKidmentor)).subscribe({
      next: ({ value }) => {
        getBookAMentors()
      },
      error: error => console.warn(error)
    })

    // Stop receiving data updates from the subscription
    return () => subscription.unsubscribe()
  }, [])

  return (
    <Layout>
      <main className='w-full flex-grow p-4'>
        <h2 className='text-2xl pb-4 flex items-center px-10 font-bold'>
          <i className='fas fa-list mr-3' /> Webinars Application
        </h2>
        <WebinarTable loading={loading} attendees={bookMentors} />
      </main>
    </Layout>
  )
}

export default WebinarAll
