import React, { useState, useEffect } from 'react'
import PaidCourse from '../components/PaidCourse'
import SearchInput from '../components/SearchInput'
import { data } from '../data/courses'
import Profile from '../components/Profile'

const PaidCourses = () => {
  const [courses, setCourses] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query) {
      const res = data.filter(
        courses => courses.lang.toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
      setCourses(res)
    } else {
      setCourses(data)
    }
  }, [query])

  const searchHandle = ({ target }) => {
    setQuery(target.value)
  }
  return (
    <div className='bg-gray-100'>
      <h1 className='text-center block w-full ml-5 pt-6 text-4xl font-bold leading-tight text-brown-500'>
        Paid Courses
      </h1>
      <Profile />
      <div className='mx-10 sm:mx-40 md:mx-60'>
        <SearchInput
          className='appearance-none text-center bg-transparent border-none w-full text-red-800 mr-3 py-1 text-lg px-2 leading-tight focus:outline-none'
          type='text'
          name='search'
          ariaLabel='Search Course'
          value={query}
          onChange={searchHandle}
          placeholder='Search for a course or a mentor...'
        />
      </div>
      <div className='mt-5 pb-12 mx-10 gap-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
        <PaidCourse paidCourses={courses} />
      </div>
    </div>
  )
}

export default PaidCourses
