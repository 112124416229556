import React from 'react'
import KidsHeading from '../components/kid-tech/KidsHeading'
import SomeKidCourses from '../components/kid-tech/SomeKidCourses'
import KidsFaq from '../components/kid-tech/kidsFaq'
import Subscribe from '../components/Subscribe'
import YoutubeEmbeded from '../components/kid-tech/YoutubeEmbeded'
import ClientSpeakWidget from '../sharedComponents/clientSpeakWidget/ClientSpeakWidget'
import KidsPricing from '../components/kid-tech/KidsPricing'
import { KIDS } from '../sharedComponents/clientSpeakWidget/constants'
import WhatToGain from '../components/sumper-camp/WhatToGain'
import WhatIsNeeded from '../components/WhatIsNeeded'
import AcademicAdvisor from '../components/AcademicAdvisor'
import ScheduleACall from '../components/ScheduleACall'
import KidTechWeekendClasses from '../components/KidTechWeekendClasses'
import { Helmet } from 'react-helmet-async'

const KidTech = () => {
  return (
    <div className='pt-0'>
      <Helmet>
        <meta
          name='keywords'
          content='kids Coding mentors, fundamentals of programming, basic animation principles, professional mentors'
        />
        <meta
          name='description'
          content='Every child deserves Tech knowledge, we feature courses for kids.'
        />
        <title>Mentortribes | Kids</title>
      </Helmet>
      <KidsHeading />
      <WhatToGain />
      <WhatIsNeeded />
      <YoutubeEmbeded embedId='hv32M0Tl-ug' />
      <SomeKidCourses />
      <KidsPricing />
      <ClientSpeakWidget title='Testimonials from our Kids' client={KIDS} />
      <KidTechWeekendClasses />
      <AcademicAdvisor />
      <ScheduleACall />
      <KidsFaq />
      <Subscribe />
      <ul className='bubbles'>
        <li className='rounded-full bg-cool-gray-400 bg-opacity-25' />
        <li className='rounded-full bg-red-300 bg-opacity-25' />
        <li className='rounded-full bg-orange-300 bg-opacity-25' />
        <li className='rounded-full bg-cool-gray-400 bg-opacity-25' />
        <li className='rounded-full bg-orange-300 bg-opacity-25' />
        <li className='rounded-full bg-red-300 bg-opacity-25' />
        <li className='rounded-full bg-orange-300 bg-opacity-25' />
        <li className='rounded-full bg-cool-gray-400 bg-opacity-25' />
      </ul>
    </div>
  )
}

export default KidTech
