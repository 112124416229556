import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const SearchTable = ({ data, setDataSource, searchData, handleSearch }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (value.trim()) {
      handleSearch(value)
    } else {
      setDataSource(searchData)
    }
  }, [value])

  const handleChange = e => {
    setValue(e.target.value)
  }

  return (
    <div className='flex items-center justify-between px-5 mb-5'>
      <div>
        <label className='text-black text-base' htmlFor='search-table'>
          Search:{' '}
        </label>
        <input
          style={{ outline: 0 }}
          className='border px-4 text-gray-700 rounded-sm py-1 border-gray-300'
          id='search-table'
          type='text'
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
SearchTable.propTypes = {
  data: PropTypes.any,
  setDataSource: PropTypes.func,
  searchData: PropTypes.any,
  handleSearch: PropTypes.func
}

export default SearchTable
