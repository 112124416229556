import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { notification } from 'antd'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { mentorByUserSubId } from '../graphql/queries'
import Sidebar from './Sidebar'

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const { path: _path } = useRouteMatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    setTimeout(() => {
      Auth.currentAuthenticatedUser()
        .then(res => res)
        .then(user => {
          API.graphql(
            graphqlOperation(mentorByUserSubId, {
              userSubId: user.attributes.sub
            })
          )
            .then(res => {
              if (res.data.mentorByUserSubId.items.length > 0) {
                if (res.data.mentorByUserSubId.items[0]?.isAdmin) {
                  setUserInfo(res.data.mentorByUserSubId.items[0])
                  setLoading(false)
                } else {
                  history.push('/mentor-dashboard')
                }
              } else {
                notification.warn({
                  message: 'Please fill out the mentorship form',
                  duration: 10000
                })
                history.push('/add-mentor', {
                  subId: user.attributes.sub,
                  email: user.attributes.email
                })
              }
            })
            .catch(err => {
              notification.error({
                message: err?.errors[0]?.message || 'Something went wrong',
                duration: 10000
              })
            })
        })
        .catch(() => {
          notification.error({
            message: 'Auth Error!',
            description: 'You are not currently logged in, please login',
            duration: 10000
          })
          history.push('/login')
        })
    }, 2500)
  }, [])

  return loading ? (
    <div className='h-screen grid place-content-center'>
      <i className='fa fa-spin fa-spinner text-xl' />
    </div>
  ) : (
    <div className='flex flex-col h-screen bg-gray-100'>
      {open && (
        <div className='fixed inset-0 z-10 bg-black bg-opacity-50' onClick={() => setOpen(false)} />
      )}
      <nav className='h-12 bg-btn-primary'>
        <div className='flex justify-between h-full items-center px-10'>
          <div className='flex'>
            <button
              onClick={() => setOpen(prev => !prev)}
              className='block md:hidden outline-none mr-4 focus:outline-none cursor-pointer group'
            >
              <div className='mb-1 w-6 h-0.5 group-hover:bg-white bg-gray-200 duration-200' />
              <div className='mb-1 w-6 h-0.5 group-hover:bg-white bg-gray-200 duration-200' />
              <div className='w-6 h-0.5  group-hover:bg-white bg-gray-200 duration-200' />
            </button>
            <h4 className='text-lg text-white font-semibold'>MentorTribes Admin</h4>
          </div>
          <div className='flex items-center'>
            <figure className='m-0 p-0'>
              <img src='/user.svg' className='w-8 h-8' alt='user' />
            </figure>
            <h4 className='text-white text-sm ml-2 font-manrope font-semibold'>
              {userInfo?.firstname} {userInfo?.lastname}
            </h4>
          </div>
        </div>
      </nav>
      <div className='flex-1 overflow-y-auto'>
        <div className='flex h-full overflow-y-auto'>
          <Sidebar open={open} setOpen={setOpen} />
          <div className='flex-1 overflow-y-auto'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Layout
