import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactQuill from 'react-quill'
import { getFileURL } from '../../services/amplify'
import userAvatar from '../../images/profile.png'
import PropTypes from 'prop-types'

const Mentor = ({ mentor }) => {
  const [imageUrl, setImageUrl] = useState('')
  const [caption, setCaption] = useState('')
  let reactQuillRef = {}

  const fetchURl = async () => {
    if (mentor?.imageUrl) {
      setImageUrl(await getFileURL(mentor?.imageUrl.key))
    } else {
      setImageUrl(userAvatar)
    }
  }

  useEffect(() => {
    fetchURl()
  }, [])

  useEffect(() => {
    if (reactQuillRef.editor) {
      const editor = reactQuillRef.getEditor()
      const unprivilegedEditor = reactQuillRef.makeUnprivilegedEditor(editor)
      setCaption(unprivilegedEditor.getText().substring(0, 125))
    }
  }, [reactQuillRef])

  return (
    <div>
      <ReactQuill
        className='hidden'
        ref={el => (reactQuillRef = el)}
        theme='snow'
        defaultValue={mentor?.bio}
      />
      <Link to={`/mentors/${mentor.id}`}>
        <figure className='m-0 p-0 bg-gray-50 cursor-pointer overflow-hidden relative group'>
          <img
            src={imageUrl}
            alt=''
            className='h-60 border border-gray-100 w-full rounded-xl duration-200 object-cover'
          />
          <div className='opacity-0 absolute rounded-xl inset-0 bg-black bg-opacity-25 group-hover:opacity-100 duration-200' />
          <div className='absolute bg-black bg-opacity-50 inset-x-0 bottom-0 rounded-b-xl p-2 opacity-0 group-hover:opacity-100 duration-200 delay-200'>
            <h4 className='text-white font-manrope font-bold'>
              {caption}...{' '}
              <span className='text-blue-400 duration-200 hover:underline'>Read more</span>
            </h4>
          </div>
        </figure>
      </Link>
      <div className='mt-2'>
        <h4
          title='Santos Bright Lorem ipsum dolor sit amet.'
          className='text-gray-900 font-manrope font-semibold whitespace-no-wrap overflow-hidden text-lg'
        >
          4.5 <i className='fa fa-star text-yellow-300 text-2xl' />
        </h4>
        <h4
          style={{ textOverflow: 'ellipsis' }}
          title={
            `${mentor?.firstname} ${mentor?.lastname}`.length >= 18
              ? `${mentor?.firstname} ${mentor?.lastname}`
              : false
          }
          className='flex-1'
        >
          <Link
            className='text-gray-900 font-manrope max-w-full font-extrabold whitespace-no-wrap overflow-hidden text-lg'
            to={`/mentors/${mentor.userSubId}`}
          >
            {`${mentor?.firstname} ${mentor?.lastname}`.substring(0, 18)}{' '}
            {`${mentor?.firstname} ${mentor?.lastname}`.length >= 18 && '...'}
          </Link>
        </h4>
        <h4
          style={{ textOverflow: 'ellipsis' }}
          title='Santos Bright Lorem ipsum dolor sit amet.'
          className='text-gray-900 font-manrope font-semibold whitespace-no-wrap overflow-hidden text-sm'
        >
          {mentor?.specialization}
        </h4>
        <ul className='flex   justify-center'>
          {mentor?.facebookUrl && (
            <li>
              <a
                href={mentor?.facebookUrl}
                target='_blank'
                rel='noopener noreferrer'
                title='Facebook'
                className='text-gray-600 text-base duration-200 hover:text-blue-400'
              >
                <i className='fa fa-facebook' />
              </a>
            </li>
          )}
          {mentor?.linkedInUrl && (
            <li className='ml-4'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={mentor?.linkedInUrl}
                title='LinkedIn'
                className='text-gray-600 text-base duration-200 hover:text-blue-400'
              >
                <i className='fa fa-linkedin' />
              </a>
            </li>
          )}
          {mentor?.github && (
            <li className='ml-4'>
              <a
                rel='noopener noreferrer'
                href={mentor?.github}
                target='_blank'
                title='Github'
                className='text-gray-600 text-base duration-200 hover:text-blue-400'
              >
                <i className='fa fa-github' />
              </a>
            </li>
          )}
          {mentor?.dribbleUrl && (
            <li className='ml-4'>
              <a
                href={mentor?.dribbleUrl}
                target='_blank'
                title='Dribble'
                rel='noopener noreferrer'
                className='text-gray-600 text-base duration-200 hover:text-blue-400'
              >
                <i className='fa fa-dribbble' />
              </a>
            </li>
          )}
          {mentor?.otherLinkUrl && (
            <li className='ml-4'>
              <a
                href={mentor?.otherLinkUrl}
                target='_blank'
                title='Website'
                rel='noopener noreferrer'
                className='text-gray-600 text-base duration-200 hover:text-blue-400'
              >
                <i className='fa fa-globe' />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

Mentor.propTypes = {
  mentor: PropTypes.array
}

export default Mentor
