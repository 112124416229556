import React, { useContext, useState, useEffect, useRef } from 'react'
import { notification } from 'antd'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { Form, Formik } from 'formik'
import { updateCourse } from '../../graphql/mutations'
import { getCourse } from '../../graphql/queries'
import * as Yup from 'yup'
import { useParams, useHistory, Link } from 'react-router-dom'
import { userContext } from '../../context/UserContext'
import Input from '../../components/auth/Input'
import CourseDescInput from '../fields/CourseDescInput'
import { getFileURL } from '../../services/amplify'

const EditCourse = () => {
  const [course, setCourse] = useState({})
  const [isEditSubmitting, setEditSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)
  const { userInfo, setUserInfo } = useContext(userContext)
  const { id } = useParams()
  const [image, setImage] = useState({ error: '', file: '' })
  const [video, setVideo] = useState({ error: '', file: '' })
  const [description, setDescription] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const history = useHistory()
  const descriptionRef = useRef()

  const handleEditCourse = async (values, id) => {
    setEditSubmitting(true)
    try {
      let imageUrl = course?.image
      if (image.file) {
        await Storage.remove(course?.image)
        const savedFile = await Storage.put(
          `${Math.floor(Date.now() * Math.random())}-${image.file.name}`,
          image.file
        )
        imageUrl = savedFile.key
      }
      let videoUrl = course?.video
      if (video.file) {
        if (course?.video) {
          await Storage.remove(course?.video)
        }
        const savedFile = await Storage.put(
          `${Math.floor(Date.now() * Math.random())}-${video.file.name}`,
          video.file
        )
        videoUrl = savedFile.key
      }
      const data = await API.graphql(
        graphqlOperation(updateCourse, {
          input: {
            ...values,
            id: id,
            video: videoUrl,
            image: imageUrl
          }
        })
      )
      if (data?.errors) throw Error(data?.errors[0]?.message)
      else {
        notification.success({
          message: 'Course Updated',
          description: 'You have updated your successfully!'
        })
        setImage({ error: '', file: '' })
        setVideo({ error: '', file: '' })
        setEditSubmitting(false)
        setUserInfo(prev => ({
          ...prev,
          data: {
            ...prev.data,
            courses: {
              items: prev.data.courses.items.map(course =>
                course.id === data.data.updateCourse.id ? data.data.updateCourse : course
              )
            }
          }
        }))
        history.push('/mentor-dashboard')
      }
    } catch (err) {
      setEditSubmitting(false)
      notification.error({
        message: err?.errors ? err?.errors[0]?.message : 'Something went wrong!',
        duration: 10000
      })
    }
  }

  const fetchCourse = async () => {
    try {
      const res = await API.graphql(
        graphqlOperation(getCourse, {
          id
        })
      )
      setCourse(res.data.getCourse)
      if (res.data.getCourse && Object.keys(res.data.getCourse).length) {
        setDescription(res.data.getCourse.description)
        setImageUrl(await getFileURL(res.data.getCourse.image))
        setVideoUrl(await getFileURL(res.data.getCourse.video))
      }
      setLoading(false)
    } catch (err) {
      notification.error({
        message: err?.errors ? err?.errors[0].message : 'Something went wrong!',
        duration: 10000
      })
    }
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    if (!loading && course?.mentorId !== userInfo?.data?.id) {
      history.push('/mentor-dashboard')
      notification.error({
        message: 'Unauthorized'
      })
    }
  }, [course])

  return loading ? (
    <div className='h-screen grid place-content-center'>
      <i className='fa fa-spin fa-spinner text-xl' />
    </div>
  ) : course ? (
    <div className='max-w-xl bg-white py-6 shadow mx-auto px-5'>
      <Formik
        initialValues={{
          title: course?.title,
          mentorId: course?.mentorId
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().trim().required('Please enter a course title')
        })}
        onSubmit={values => {
          if (!description) {
            descriptionRef.current.scrollIntoView()
          } else {
            values.description = description
            if (!video.error && !image.error && imageUrl) {
              handleEditCourse(values, course.id)
            }
          }
        }}
      >
        <Form>
          <Input label='Course Title' id='title' name='title' noMargin />
          <CourseDescInput
            value={description}
            ref={descriptionRef}
            handleChange={value => setDescription(value)}
            name='description'
            label='Description'
            id='description'
            rows={4}
          />
          <div>
            <input
              onChange={e => {
                const file = e.target.files[0]
                setImage({ error: '', file })
                setImageUrl(URL.createObjectURL(file))
                if (file.size / 1024 > 1024 * 3) {
                  setImage(prev => ({
                    ...prev,
                    error: 'Image must 3mb or less'
                  }))
                }
              }}
              type='file'
              accept='image/png, image/gif, image/jpeg'
              id='image'
              className='hidden'
              name='image'
            />
            <label
              htmlFor='image'
              className='bg-gray-200 rounded relative cursor-pointer w-full mt-5 block'
            >
              <figure className='m-0 p-0 rounded relative group h-56 w-full'>
                <img className='h-full w-full rounded object-cover' src={imageUrl} alt='' />
                <div className='absolute inset-0 bg-black bg-opacity-75 sm:opacity-0 grid place-content-center hover:opacity-100 duration-200'>
                  <div className='flex font-manrope items-center text-white'>
                    <i className='fa fa-exchange-alt mr-3' /> Change Course Image
                  </div>
                </div>
              </figure>
            </label>
            <small className='block mt-1 text-red-700 font-manrope'>{image.error}</small>
          </div>
          <div>
            <input
              onChange={e => {
                if (e.target.files.length) {
                  const file = e.target.files[0]
                  setVideoUrl(URL.createObjectURL(file))
                  setVideo({ error: '', file })
                  if (file.size / 1024 > 1024 * 50) {
                    setVideo(prev => ({
                      ...prev,
                      error: 'Video must 50mb or less'
                    }))
                  }
                }
              }}
              type='file'
              id='video'
              accept='video/mp4,video/x-m4v,video/*'
              className='hidden'
              name='video'
            />
            <label
              htmlFor='video'
              className={`bg-gray-200 h-52 rounded cursor-pointer w-full mt-5 ${
                videoUrl ? 'block' : 'grid place-content-center'
              }`}
            >
              <div className='m-0 p-0 relative group h-full w-full'>
                <video
                  autoPlay
                  style={{ height: '100%', width: '100%' }}
                  loop
                  muted
                  id='myVideo'
                  src={videoUrl}
                />
                <div className='absolute inset-0 bg-black bg-opacity-75 sm:opacity-0 grid place-content-center hover:opacity-100 duration-200'>
                  <div className='flex font-manrope justify-center items-center text-white'>
                    <i className='fa fa-exchange-alt mr-3' /> Change Course Video
                  </div>
                </div>
              </div>
            </label>
            <small className='block mt-1 text-red-700 font-manrope'>{video.error}</small>
          </div>
          <div className='flex justify-end'>
            <button
              type='button'
              onClick={() => {
                setImage({ error: '', file: '' })
                setVideo({ error: '', file: '' })
                setVideoUrl('')
                history.push('/mentor-dashboard')
              }}
              className='border border-red-700 focus:outline-none px-5 mt-5 block max-w-max py-1 mr-5 text-red-700 rounded shadow-xl'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='bg-red-700 focus:outline-none px-5 mt-5 ml-auto block max-w-max py-1 text-white rounded shadow-xl'
            >
              {isEditSubmitting && <i className='fa fa-spin fa-spinner mr-3' />}
              Edit Course
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  ) : (
    <div className='h-full grid place-content-center'>
      <h1 className='text-3xl font-manrope text-primary'>404, Oops not Found</h1>
      <h1 className='text-3xl font-manrope font-semibold text-primary'>Course does not exist</h1>
      <Link
        to='/mentor-dashboard'
        className='bg-primary text-white py-1.5 text-sm block w-full max-w-max px-5 mx-auto mt-2 hover:bg-opacity-50 duration-200 hover:text-white rounded-md'
      >
        <i className='fa fa-backward mr-3' />
        Go to dashboard
      </Link>
    </div>
  )
}

export default EditCourse
