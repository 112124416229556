import React from 'react'
import PropTypes from 'prop-types'

const AboutClass = ({ course }) => {
  return (
    <div className='pt-12 bg-cool-gray-50'>
      <div className='container mx-auto px-5'>
        <div className=' border-b border-gray-200 pb-12'>
          <div className='col-span-7'>
            <h4 className='text-gray-900 mb-3 text-xl font-manrope font-extrabold'>
              About This Class
            </h4>
            <div
              id='single-course-desc'
              className='text-base font-semibold mb-4 leading-7 font-manrope text-gray-900'
              dangerouslySetInnerHTML={{ __html: course?.description }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
AboutClass.propTypes = { course: PropTypes.any }

export default AboutClass
