import React from 'react'
import Moment from 'react-moment'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

const StudentModal = ({ visible, setVisible, selectedMentee }) => {
  return (
    <Modal
      visible={visible}
      title={`${selectedMentee.fullName}`}
      footer={[
        <button
          key={selectedMentee}
          onClick={() => setVisible(false)}
          className='bg-red-700 focus:outline-none px-5 py-1 text-white rounded shadow-xl'
        >
          Close
        </button>
      ]}
      // onOk={this.handleOk}
      onCancel={() => setVisible(false)}
    >
      <div className='bg-gray-100'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Full Name:</h4>
          <h4 className='font-semibold'>{selectedMentee.fullName}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Course Name:</h4>
          <h4 className='font-semibold'>{selectedMentee.courseName}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Email:</h4>
          <h4 className='font-semibold'>
            <a className='text-blue-500' href={`mailto:${selectedMentee.email}`}>
              {selectedMentee.email}
            </a>
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Phone No:</h4>
          <h4 className='font-semibold'>{selectedMentee.phone}</h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Assigned on:</h4>
          <h4 className='font-semibold'>
            <Moment parse='YYYY-MM-DD HH:mm'>{selectedMentee.createdAt}</Moment>
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Github:</h4>
          <h4 className='font-semibold'>
            <a
              target='_blank'
              className='text-blue-500'
              href={selectedMentee?.github}
              rel='noreferrer'
            >
              {selectedMentee?.github}
            </a>
          </h4>
        </div>
      </div>
      <div className='bg-gray-100 mt-4'>
        <div className='flex py-2 px-5'>
          <h4 className='text-gray-600 mr-5'>Location:</h4>
          <h4 className='font-semibold'>{selectedMentee.location}</h4>
        </div>
      </div>
    </Modal>
  )
}

StudentModal.propTypes = {
  visible: PropTypes.array,
  setVisible: PropTypes.func,
  selectedMentee: PropTypes.array
}

export default StudentModal
