/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Form, Input, Select, DatePicker, Button, Upload, message, notification } from 'antd'
// import { Storage } from 'aws-amplify'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { courses, MEMBERSHIP_PLANS } from '../data/courses'

const { TextArea } = Input
const { Item } = Form

const BookMentorsForm = ({
  handleSubmit,
  loading,
  whatToLearn,
  form,
  currentPlan,
  setWhatToLearn
}) => {
  const [islUploading, setUploading] = useState(false)

  const uploadProps = {
    name: 'file',
    beforeUpload: file => {
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 ? true : Upload.LIST_IGNORE
    },
    onChange: async info => {
      setUploading(true)
      if (
        info.file.status !== 'uploading' &&
        info.file.status !== 'removed' &&
        info.file.size / 1000000 < 35
      ) {
        try {
          const result = await Storage.put(info.file.uid, info.file.originFileObj, {
            level: 'public',
            contentType: info.file.type
          })

          const imagurl = await Storage.get(result.key, {
            contentType: info.file.type,
            level: 'public'
          })
          form.setFieldsValue({ image: imagurl || result.key })
          setUploading(false)
        } catch (error) {
          notification.error({ message: error.message })
        }
      }
    }
  }

  return (
    <Form
      onFinish={handleSubmit}
      size='large'
      form={form}
      className='md:grid md:grid-cols-2 gap-4 flex flex-col'
      layout='vertical'
    >
      <Item
        rules={[
          {
            required: true,
            message: 'Please input full name'
          }
        ]}
        name='fullName'
        label='Full Name'
      >
        <Input placeholder='John Doe' />
      </Item>
      <Item
        name='phone'
        label='Phone'
        rules={[
          {
            required: true,
            message: 'Please input phone'
          }
        ]}
      >
        <Input placeholder='+1605000000' />
      </Item>
      <Item
        name='email'
        label='Email'
        rules={[
          {
            required: true,
            message: 'Please input email address'
          }
        ]}
      >
        <Input placeholder='example@gmail.com' />
      </Item>
      <Item
        name='type'
        label='Membership Plan'
        tooltip={
          <p>
            See{' '}
            <Link to='/pricing' className='text-blue-400'>
              pricing
            </Link>{' '}
            for details
          </p>
        }
        initialValue={currentPlan.name}
        rules={[
          {
            required: true,
            message: 'Select membership type'
          }
        ]}
      >
        <Select
          placeholder={
            <div className='flex justify-between'>
              <span>{currentPlan.name}</span>
              <span>{currentPlan.price}</span>
            </div>
          }
        >
          {MEMBERSHIP_PLANS.map(({ name, price }) => (
            <Select.Option key={name} value={name}>
              <div className='flex justify-between'>
                <span>{name}</span>
                <span>₦ {price}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item
        name='course'
        label='What do you want to learn'
        rules={[
          {
            required: true,
            message: 'Please input the course you want to learn'
          }
        ]}
      >
        <Select onChange={e => setWhatToLearn(e)} placeholder='What do you want to learn?'>
          {courses.map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name='whyLearnTheCourse' label='Why do you want to learn this (optional)'>
        <Select placeholder='Why learn this course'>
          {[
            'Acquire new skill',
            'Needed for current project',
            'I am a novice',
            'I want to know the best practice',
            'This is required for me to pass a course',
            'Trying things out',
            'others'
          ].map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item
        name='startDate'
        label='When do you want to Start?'
        rules={[
          {
            required: true,
            message: "Tell us when you're comfortable to start"
          }
        ]}
      >
        <DatePicker className='w-full' placeholder='Start Date' format='YYYY-MM-DD' />
      </Item>
      <Item
        name='endDate'
        label='When do you want to Stop?'
        rules={[
          {
            required: true,
            message: 'Please tell us when you want to End'
          }
        ]}
      >
        <DatePicker className='w-full' placeholder='End Date' format='YYYY-MM-DD' />
      </Item>
      {/* <Item
        name='image'
        label='If you cannot explain what you want to learn in words, you can upload video or images'
      >
        <Upload {...uploadProps}>
          <Button>Upload any relevant information</Button>
        </Upload>
      </Item> */}
      {whatToLearn === 'Other' && (
        <Item name='detailCourseName' className='col-span-2' label='Tell us what you want to learn'>
          <Input placeholder='I want NodeJS' />
        </Item>
      )}
      <Item
        name='courseDetails'
        className='col-span-2'
        label='More details or links to an example of what you want to learn (optional)'
      >
        <TextArea
          cols={3}
          rows={4}
          placeholder='Describe your current knowledge on the course you want to learn'
        />
      </Item>
      <Button
        loading={loading}
        // disabled={islUploading}
        htmlType='submit'
        className='col-span-2 w-40 mx-auto bg-transparent hover:bg-red-800 active:bg-red-900 text-brown-500 font-semibold hover:text-white px-8 border border-red-800 hover:border-transparent rounded'
      >
        Book
      </Button>
    </Form>
  )
}

BookMentorsForm.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  whatToLearn: PropTypes.string,
  form: PropTypes.any,
  currentPlan: PropTypes.any,
  setWhatToLearn: PropTypes.func
}

export default BookMentorsForm
