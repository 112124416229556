import React, { useContext } from 'react'
import CousrsTable from './ListCoursesTable'
import CourseModal from './CourseModal'
import { CourseContext } from '../../components/Routes'

const AddCourses = () => {
  const { items: courses } = useContext(CourseContext)

  return (
    <div>
      <div className='flex justify-between'>
        <h1 className='text-4xl'>
          &quot;{courses?.length}&quot; All courses with associated mentors{' '}
        </h1>
        <CourseModal />
      </div>
      <CousrsTable courses={courses} />
    </div>
  )
}

export default AddCourses
