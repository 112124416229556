import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import SendMessageForm from './SendMessageForm'
import SentMessage from './SentMessage'
import RecievedMessage from './RecievedMessage'
import { getFileURL } from '../../services/amplify'
import userAvatar from '../../images/profile.png'
import { Link } from 'react-router-dom'
import ScrollToBottom from 'react-scroll-to-bottom'
import PropTypes from 'prop-types'

const ChatMessages = ({ visitor, messages, mentor, loading, setMessages, routerProps }) => {
  const { id } = useParams()
  const [imageUrl, setImageUrl] = useState('')

  const fetchImage = async () => {
    if (!loading) {
      if (mentor?.imageUrl && mentor?.imageUrl.key) {
        setImageUrl(await getFileURL(mentor.imageUrl.key))
      } else {
        setImageUrl(userAvatar)
      }
    }
  }

  useEffect(() => {
    fetchImage()
  }, [loading])

  return (
    <div className='h-full flex flex-col'>
      <nav className='h-20 flex flex-col'>
        <div className='bg-white flex-1 w-full' />
        <div
          style={{ backdropFilter: 'blur(10px)' }}
          className='flex py-2 px-5 bg-transparent z-10 shadow rounded-t-xl'
        >
          <figure className='m-0 hidden md:block p-0 w-12 h-12 rounded-full bg-gray-300'>
            <img src={imageUrl} alt='' className='w-12 h-12 rounded-full object-cover' />
          </figure>
          <Link to='/chat' className='flex items-center md:hidden'>
            <i className='fa text-gray-900 duration-200 hover:text-gray-700 mr-3 fa-arrow-left' />
            <figure className='m-0 p-0 w-12 h-12 rounded-full bg-gray-300'>
              <img src={imageUrl} alt='' className='w-12 h-12 rounded-full object-cover' />
            </figure>
          </Link>
          <div className='ml-3 flex-1'>
            <div className='flex justify-between'>
              <div>
                <div>
                  <h4 className='font-manrope font-semibold text-base'>
                    {mentor?.firstname} {mentor?.lastname}
                  </h4>
                  <p className='font-manrope text-gray-400 font-medium'>{mentor?.specialization}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className='flex-1 overflow-y-auto'>
        <div className='flex flex-col overflow-y-auto h-full'>
          <div className='flex-1 overflow-y-auto relative'>
            <div
              className={`w-8 h-8 bg-white rounded-full mx-auto absolute transform translate-y-5 delay-200 ${
                loading && 'opacity-100'
              } opacity-0 duration-200 shadow-md top-0 left-0 right-0 grid place-content-center`}
            >
              <i className='fa fa-spin fa-spinner' />
            </div>
            {!loading && (
              <ScrollToBottom className='h-full overflow-y-auto p-5'>
                {messages.map(msg =>
                  msg.userId === visitor.id ? (
                    <SentMessage message={msg} />
                  ) : (
                    <RecievedMessage message={msg} />
                  )
                )}
              </ScrollToBottom>
            )}
          </div>
          <SendMessageForm visitor={visitor} setMessages={setMessages} id={id} />
        </div>
      </div>
    </div>
  )
}
ChatMessages.propTypes = {
  visitor: PropTypes.any,
  messages: PropTypes.string,
  mentor: PropTypes.any,
  loading: PropTypes.bool,
  setMessages: PropTypes.object,
  routerProps: PropTypes.any
}

export default ChatMessages
