import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { featuredKidCourses } from '../data/featured-kid-courses'

const FeaturedKidCourses = () => {
  return (
    <div>
      <Helmet>
        <meta name='keywords' content='mentors for kids, children tech, teach my kids' />
        <meta name='description' content='Courses to brush up kids in the field of technology' />
        <title>Mentortribes | Kids Courses</title>
      </Helmet>
      <header style={{ background: '#0a3b4a' }} className='py-16'>
        <div className='container mx-auto px-5'>
          <div className='flex flex-col justify-center h-full'>
            <h1 className='text-2xl md:text-3xl text-white font-manrope font-bold'>
              Our Featured Courses
            </h1>
            <div className='flex items-center mt-5'>
              <p className='text-gray-100 text-lg'>
                We cover skill which are essential to survive in the industry at large, we don&#39;t
                cover any outdated materail but rather we teach futuristics topics to help you excel
                in your respected field.
              </p>
            </div>
          </div>
        </div>
      </header>
      <div className='container mt-10 mx-auto px-5 pb-10'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10'>
          {featuredKidCourses.map(course => (
            <div className='pb-5' key={course.id}>
              <Link to={`/featured-kid-courses/${course.id}`}>
                <figure className='h-52 relative group'>
                  <img
                    className='w-full h-full object-cover rounded-t-lg'
                    src={course.image}
                    alt='course-image'
                  />
                  <div className='absolute inset-0 bg-black grid place-content-center duration-200 opacity-0 group-hover:opacity-75 px-3 rounded-t-lg'>
                    <p className='text-gray-100 text-sm text-center'>
                      {course.programRightCaption}
                    </p>
                  </div>
                </figure>
              </Link>
              <h4 className='text-base mt-3 text-cool-gray-900 font-manrope font-bold'>
                {course.title}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturedKidCourses
