import React from 'react'
import handleViewport from 'react-in-viewport'
import PropTypes from 'prop-types'

const Block = props => {
  const { inViewport, enterCount, forwardedRef, Component, animateClassName } = props

  return (
    <div ref={forwardedRef}>
      {inViewport && enterCount === 1 ? <Component className={animateClassName} /> : <Component />}
    </div>
  )
}
Block.propTypes = {
  inViewport: PropTypes.bool,
  enterCount: PropTypes.number,
  forwardedRef: PropTypes.any,
  Component: PropTypes.any,
  animateClassName: PropTypes.string
}

export default handleViewport(Block /** options: {}, config: {} **/)
