import React from 'react'
import academicAdvisor from '../images/academic-advisor.svg'

const AcademicAdvisory = () => {
  return (
    <div>
      <p className='text-4xl text-center mt-15 font-bold'>Meet with an Academic Advisor</p>
      <div className='bg-gray-50 md:mb-15 md:mt-5 md:p-15'>
        <div className='flex flex-nowrap'>
          <div className='mx-auto w-full lg:w-1/2'>
            <div className='m-5 max-w-5/6 lg:m-24'>
              <h1 className='font-bold text-3xl mb-8'>Are you asking the following questions?</h1>
              <ul>
                <li className='flex mb-4'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    viewBox='0 0 20 20'
                    fill='#c53030'
                  >
                    <path
                      fillRule='evenodd'
                      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <p className='ml-2 text-lg'>
                    How can i turn my child’s latest interest into useful skills?
                  </p>
                </li>
                <li className='flex mb-4'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    viewBox='0 0 20 20'
                    fill='#c53030'
                  >
                    <path
                      fillRule='evenodd'
                      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <p className='ml-2 text-lg'>What skill is best for my child?</p>
                </li>
                <li className='flex mb-4'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    viewBox='0 0 20 20'
                    fill='#c53030'
                  >
                    <path
                      fillRule='evenodd'
                      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <p className='ml-2 text-lg'>
                    What’s the quickest way for my child to fill in learning gaps?
                  </p>
                </li>
                <li className='flex mb-4'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    viewBox='0 0 20 20'
                    fill='#c53030'
                  >
                    <path
                      fillRule='evenodd'
                      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <p className='ml-2 text-lg'>
                    Between One - One and Small groups, which is preferable for my child?
                  </p>
                </li>
                <li className='flex mb-4'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    viewBox='0 0 20 20'
                    fill='#c53030'
                  >
                    <path
                      fillRule='evenodd'
                      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <p className='ml-2 text-lg'>
                    How can i turn my child’s latest interest into useful skills?
                  </p>
                </li>
              </ul>
              <p className='mt-5 text-base'>
                Our team is here to answer these questions and more. We can’t wait to get in touch
                with you and discuss your child academic pathway.
              </p>
            </div>
          </div>
          <div className='hidden lg:block mx-auto w-1/2'>
            <img className='mx-auto' src={academicAdvisor} alt='arrow' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcademicAdvisory
