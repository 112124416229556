import React from 'react'
import kidsImage from '../../images/sumper-camp/kids.jpg'
import InviewPortAnimate from '../../sharedComponents/InviewPortAnimate'
import PropTypes from 'prop-types'

const RemoteClass = ({ coursesRef }) => {
  return (
    <div className='container mx-auto px-5 mt-5'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        <InviewPortAnimate
          Component={({ className }) => (
            <figure className='m-0 p-0 relative'>
              <img className={className} src={kidsImage} alt='' />
              <div className='absolute inset-0' />
            </figure>
          )}
          animateClassName='images-animate'
        />
        <div className='flex flex-col justify-center'>
          <div>
            <h2 className='text-gray-900 text-4xl leading-snug max-w-sm font-semibold'>
              How Our Remote Weekend Class Works
            </h2>
            <p className='text-gray-800 text-base max-w-lg'>
              Our weekend classes runs for 2 hours.{' '}
              <span className='font-semibold'>10am - 12pm on Satuday</span> and{' '}
              <span className='font-semibold'>4pm - 6pm on Sunday.</span>
            </p>
            <p className='text-gray-800 text-base max-w-lg'>
              Assignments are given to the kids also to validate the mentors effort and class
              efficiency.
            </p>
            <button
              onClick={() => coursesRef.current.scrollIntoView()}
              className='bg-btn-primary text-white mx-auto md:mx-0 max-w-max px-16 py-3 block my-5 text-lg rounded font-semibold hover:text-white duration-200 hover:bg-opacity-75 hover:shadow-md'
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
RemoteClass.propTypes = { coursesRef: PropTypes.any }
export default RemoteClass
