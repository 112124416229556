import React from 'react'

// eslint-disable-next-line react/prop-types
const WatchVideo = ({ embedId }) => {
  return (
    <div className='w-full pt-12'>
      <div className='flex flex-wrap'>
        <div className='w-full pb-10 px-4 md:px-40'>
          <div className='pb-5'>
            <h3 className='text-gray-900 text-4xl text-center font-semibold'>Watch Video</h3>
            <h4 className='text-gray-800 text-center mb-3 text-lg'>
              For more explaination, please watch the video below.
            </h4>
          </div>
          <div className='flex flex-wrap bg-gray-200 rounded-lg'>
            <div className='w-full py-6 px-4' style={{ borderRadius: '1rem' }}>
              <div className='video-responsive'>
                <iframe
                  width='853'
                  height='300'
                  src={`https://www.youtube.com/embed/${embedId}`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='Embedded youtube'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WatchVideo
