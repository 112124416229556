import React from 'react'
import { NavLink, useLocation, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Sidebar = ({ open, setOpen, userInfo }) => {
  const location = useLocation()

  return (
    <>
      <div
        className='bg-white fixed md:hidden pb-5 overflow-y-auto shadow-xl md:shadow-none duration-200 z-30 inset-0 w-9/12 sm:w-6/12 h-full'
        style={{
          flexBasis: '250px',
          transform: open ? 'translateX(0)' : 'translateX(-100%)'
        }}
      >
        <nav className='h-12 bg-btn-primary flex flex-col justify-center'>
          <div className='flex justify-between md:justify-center px-5'>
            <h4 className='text-lg text-white font-semibold'>MentorTribes</h4>
            <button
              onClick={() => setOpen(prev => !prev)}
              className='block outline-none focus:outline-none cursor-pointer group'
            >
              <div className='w-6 h-0.5 transform rotate-45 translate-y-0.5 group-hover:bg-white bg-gray-200 duration-200' />
              <div className='w-6 h-0.5 transform -rotate-45 group-hover:bg-white bg-gray-200 duration-200' />
            </button>
          </div>
        </nav>
        <div className='mt-5 py-5'>
          <figure className='m-0 p-0 bg-gray-200 rounded-full w-20 h-20 grid place-items-center mx-auto'>
            <img src='/logo.svg' className='w-15 h-15' alt='logo' />
          </figure>
        </div>
        <ul className='border-b border-gray-100 pb-10'>
          <li className=''>
            <NavLink
              to='#home'
              className={`${
                location.hash.includes('#home') || !location.hash
                  ? 'bg-gray-300 text-black'
                  : 'bg-none'
              } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
            >
              <i className='fa fa-home mr-2' /> Dashboard
            </NavLink>
          </li>
          <li className=''>
            <a
              href='#classes'
              className={`${
                location.hash.includes('#classes') && 'bg-gray-300 text-black'
              } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
            >
              <i className='fa fa-school mr-2' /> Classes
            </a>
          </li>
          <li className=''>
            <a
              href='#add-classes'
              className={`${
                location.hash.includes('#add-classes') && 'bg-gray-300 text-black'
              } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
            >
              <i className='fa fa-calendar-plus mr-2' /> Add Class
            </a>
          </li>
          <li className=''>
            <a
              href='#mentees'
              className={`${
                location.hash.includes('#mentees') && 'bg-gray-300 text-black'
              } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
            >
              <i className='fa fa-users mr-2' /> Mentees
            </a>
          </li>
          <li className=''>
            <a
              href='#mentees'
              className={`${
                location.hash.includes('#mentees') && 'bg-gray-300 text-black'
              } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
            >
              <i className='fa fa-message mr-2' /> Chat
            </a>
          </li>
        </ul>
        <ul className='mt-10'>
          {userInfo.data?.isAdmin && (
            <li>
              <NavLink
                to='/admin-dashboard'
                className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
              >
                <i className='fa fa-columns mr-2' /> Go to Admin
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to='/'
              className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
            >
              <i className='fa fa-backward mr-2' /> Back to Site
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/logout'
              className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
            >
              <i className='fa fa-sign-out-alt mr-2' /> Logout
            </NavLink>
          </li>
        </ul>
      </div>
      <div
        className='bg-white hidden md:flex overflow-y-auto flex-col h-full shadow-xl md:shadow-none duration-200 z-30 inset-0 w-9/12 sm:w-6/12'
        style={{
          flexBasis: '300px',
          minWidth: '270px'
        }}
      >
        <div className='flex-1 overflow-auto pb-10'>
          <div className='mt-5 py-5'>
            <figure className='m-0 p-0 bg-gray-200 rounded-full w-20 h-20 grid place-items-center mx-auto'>
              <img src='/logo.svg' className='w-15 h-15' alt='logo' />
            </figure>
          </div>
          <ul className='border-b border-gray-100 pb-10'>
            <li className=''>
              <NavLink
                to='#home'
                className={`${
                  location.hash.includes('#home') || !location.hash
                    ? 'bg-gray-300 text-black'
                    : 'bg-none'
                } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
              >
                <i className='fa fa-home mr-2' /> Dashboard
              </NavLink>
            </li>
            <li className=''>
              <a
                href='#classes'
                className={`${
                  location.hash.includes('#classes') && 'bg-gray-300 text-black'
                } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
              >
                <i className='fa fa-school mr-2' /> Classes
              </a>
            </li>
            <li className=''>
              <a
                href='#add-classes'
                className={`${
                  location.hash.includes('#add-classes') && 'bg-gray-300 text-black'
                } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
              >
                <i className='fa fa-calendar-plus mr-2' /> Add Class
              </a>
            </li>
            <li className=''>
              <a
                href='#mentees'
                className={`${
                  location.hash.includes('#mentees') && 'bg-gray-300 text-black'
                } text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2`}
              >
                <i className='fa fa-users mr-2' /> Mentees
              </a>
            </li>
            <li className=''>
              <Link
                to='/mentor-dashboard/chat'
                className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
              >
                <i className='fa fa-comment-dots mr-2' /> Chat
              </Link>
            </li>
          </ul>
          <ul className='mt-10'>
            {userInfo.data?.isAdmin && (
              <li>
                <NavLink
                  to='/admin-dashboard'
                  className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
                >
                  <i className='fa fa-columns mr-2' /> Go to Admin
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to='/'
                className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
              >
                <i className='fa fa-backward mr-2' /> Back to Site
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/logout'
                className='text-gray-700 px-5 block text-lg font-manrope hover:text-gray-900 hover:bg-gray-200 py-2'
              >
                <i className='fa fa-sign-out-alt mr-2' /> Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
Sidebar.propTypes = {
  open: PropTypes.string,
  setOpen: PropTypes.any,
  userInfo: PropTypes.object.isRequired
}

export default Sidebar
