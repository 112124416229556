import React from 'react'
import { Collapse } from 'antd'
import exclamationIcon from '../../images/pricing/exclamation.svg'

const GeneralFaq = () => {
  const { Panel } = Collapse

  return (
    <section className='mt-10' id='weekend-faq'>
      <div className='container mx-auto px-5 pb-10'>
        <h3 className='text-gray-900 text-3xl font-semibold text-center md:text-4xl'>
          Frequently Asked Questions
        </h3>
        <p className='max-w-lg text-gray-800 font-manrope mt-4 text-lg text-center mx-auto'>
          Can&apos;t find your answer below? Contact us @
          <span>
            <a className='text-red-400' href='mailto:contact@mentortribes.com'>
              contact@mentortribes.com
            </a>
          </span>{' '}
          or Call
          <span>
            <a className='text-red-400' href='phone:+2348131482181'>
              +234-813-148-2181
            </a>
          </span>
        </p>
        <div className='bg-gray-50 pb-5 mt-8' style={{ borderRadius: '1rem' }}>
          <div className='p-5'>
            <div className='flex'>
              <div className='flex flex-col items-center justify-center mr-5'>
                <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
                  <img src={exclamationIcon} alt='exclamation' />
                </figure>
              </div>
              <div>
                <h4 className='text-black text-xl font-semibold'>Weekend Camp</h4>
                <p className='text-gray-900'>
                  You can find answers relating to summer camp enquries here.{' '}
                </p>
              </div>
            </div>
          </div>
          <Collapse
            accordion
            className='pl-10 border-0'
            expandIconPosition='right'
            expandIcon={item =>
              item.isActive ? (
                <span className='border border-gray-500 rounded-full p-0.5 px-1'>
                  <i className='fa fa-minus' />
                </span>
              ) : (
                <span className='border border-gray-500 rounded-full p-0.5 px-1'>
                  <i className='fa fa-plus' />
                </span>
              )
            }
          >
            <Panel
              className='text-lg font-manrope'
              header='What program do you use to teach coding?'
              key='1'
            >
              <p className='text-base text-red-800'>
                We teach several programming languages depending on your child’s age and experience.
                we use visual programming languages like Scratch, and Kodular, and will eventually
                proceed to learn text-based programming languages like Python when the child
                understands the basics
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header="How will learning TO CODE HELP MY child's education?"
              key='2'
            >
              <p className='text-base text-red-800 mb-4'>
                In our highly distracted world, Learning to code teaches kids to concentrate and
                focus on a single task.
              </p>
              <p className='text-base text-red-800'>
                This becomes a life skill Children also uses mathematics and scientific skills to
                solve programming problems. In addition to that, we encourage collaboration in our
                classes, thus helping a child develop his confidence and social skills.
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header='What can my child do after learning to code?'
              key='3'
            >
              <p className='text-base text-red-800'>
                Our innovative curriculum guarantees that most of our students will be able to
                develop a simple software after going through our summer program. What kind of
                software depends on your child’s interest and passions. He/she could build games,
                animations, mobile apps, and any kind of software.
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header='How old does my child need to be to start learning to code?'
              key='4'
            >
              <p className='text-base text-red-800 mb-4'>
                For Online classes our students’ age ranges from 7 to 17 years old. We start from
                age 7 because we believe that at this age the child is able to use a computer
                independently.
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header='What kind of job can our children do with programming?'
              key='5'
            >
              <p className='text-base text-red-800 mb-4'>
                Almost all industries from manufacturing, banking, medical, transportation,
                telecomunication, IT… make use of technology. Meaning knowledge of coding gives a
                child more advantage to access these opportunities
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header='My child already has computer lessons at school. what is the difference?'
              key='6'
            >
              <p className='text-base text-red-800 mb-4'>
                While most schools&apos; computer curriculum teaches students how to use computer
                software (microsoft office, paint, etc), Mentortribes teaches how to build software.
              </p>
              <p className='text-base text-red-800 mb-4'>
                We teach students how to build technology instead of only using technology. Unlike
                most schools too, we have specialist and full-time Coding educator for students.
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header='What programming language is suitable for my child to learn?'
              key='7'
            >
              <p className='text-base text-red-800 mb-4'>
                This depends on the age of the child and if the child has prior knowledge on
                computer programming. Call to talk with our counselor to find out which course is
                most appropriate for your child.
              </p>
            </Panel>
            <Panel
              className='text-lg font-manrope'
              header='Why is coding important for my child?'
              key='8'
            >
              <p className='text-base text-red-800 mb-4'>
                Because technology and software controls everything we do in this digital age,
                demand for those who will create this technology has skyrocketed and this has made
                coding the number one in-demand skill for everyone in the 21st century.
              </p>
              <p className='text-base text-red-800 mb-4'>
                Coding teaches creativity, logical reasoning, critical thinking and most importantly
                helps us to solve problems by creating software that makes life easier for people.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </section>
  )
}

export default GeneralFaq
