import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { getChat, getMentor } from '../../graphql/queries'
import ChatMessages from './ChatMessages'
import { onCreateMessage } from '../../graphql/subscriptions'
import PropTypes from 'prop-types'
import { notification } from 'antd'

const SingleChat = ({ visitor, setCurrentChatId }) => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [mentor, setMentor] = useState({})
  const [chat, setChat] = useState({})
  const { id } = useParams()
  const history = useHistory()

  const fetchChat = async () => {
    try {
      setLoading(true)
      setCurrentChatId(id)
      const res = await API.graphql(
        graphqlOperation(getChat, {
          id
        })
      )
      setChat(res.data.getChat)
      if (res.data.getChat) {
        setMessages(
          res.data.getChat.messages.items.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
        )
        const res2 = await API.graphql(
          graphqlOperation(getMentor, { id: res.data.getChat.mentorId })
        )
        setMentor(res2.data.getMentor)
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: error.errors ? error.errors[0].message : 'Something went wrong!'
      })
    }
  }

  useEffect(() => {
    fetchChat()
  }, [id])

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onCreateMessage)).subscribe({
      next: ({ value }) => {
        if (value.data.onCreateMessage.chatId === id) {
          setMessages(prev => [...prev, value.data.onCreateMessage])
        }
      },
      error: error => console.warn(error)
    })
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!chat || chat.visitorId !== visitor.id) {
        history.push('/chat')
        notification.error({ message: 'Chat does not exist' })
      }
    }
  }, [loading])

  return (
    <ChatMessages
      setMessages={setMessages}
      mentor={mentor}
      messages={messages}
      visitor={visitor}
      loading={loading}
      addMessage={() => {}}
    />
  )
}
SingleChat.propTypes = { visitor: PropTypes.any, setCurrentChatId: PropTypes.any }

export default SingleChat
