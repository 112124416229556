import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import SearchTable from './SearchTable'
import PropTypes from 'prop-types'
import DropDownKids from '../components/DropDownKids'
import { deleteBookKidmentor } from '../graphql/mutations'
import Moment from 'react-moment'
import { TABLES_CALENDAR_STRINGS } from '../data/courses'

const BookKidsMentorsTable = ({ bookMentors, loading }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })

  const BOOK_A_MENTOR_COLUMN = [
    {
      title: <span className='font-bold'>S/N</span>,
      dataIndex: 'sn',
      key: 'sn',
      render: sn => <span>{sn}</span>
    },
    {
      title: <span className='font-bold'>First Child Fullname</span>,
      dataIndex: 'firstChildName',
      key: 'firstChildName',
      render: firstChildName => <span>{firstChildName}</span>,
      sorter: (a, b) => a.firstChildName - b.firstChildName
    },
    {
      title: <span className='font-bold'>Parent Email</span>,
      dataIndex: 'parentEmail',
      key: 'parentEmail',
      render: parentEmail => <span className='lowercase'>{parentEmail}</span>
    },
    {
      title: <span className='font-bold'>Parent Name</span>,
      dataIndex: 'parentName',
      key: 'parentName',
      render: parentName => <span className='lowercase'>{parentName}</span>
    },
    {
      title: <span className='font-bold'>Parent Phone No </span>,
      key: 'parentPhone',
      dataIndex: 'parentPhone',
      render: parentPhone => <span>{parentPhone}</span>,
      sorter: (a, b) => a.parentPhone - b.parentPhone
    },
    {
      title: <span className='font-bold'>No of Kids</span>,
      key: 'noOfKids',
      dataIndex: 'noOfKids',
      render: noOfKids => <span>{noOfKids}</span>,
      sorter: (a, b) => a.noOfKids - b.noOfKids
    },
    {
      title: <span className='font-bold'>First Child Age</span>,
      key: 'firstChildAge',
      dataIndex: 'firstChildAge',
      render: firstChildAge => <span>{firstChildAge}</span>,
      sorter: (a, b) => a.firstChildAge - b.firstChildAge
    },
    {
      title: <span className='font-bold'>First Child Interest</span>,
      key: 'firstChildInterest',
      dataIndex: 'firstChildInterest',
      render: firstChildInterest => <span>{firstChildInterest}</span>,
      sorter: (a, b) => a.firstChildInterest - b.firstChildInterest
    },
    {
      title: <span className='font-bold'>Created At</span>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => <Moment calendar={TABLES_CALENDAR_STRINGS}>{createdAt}</Moment>,
      sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
      title: <span className='font-bold'>Payment Status</span>,
      key: 'payment',
      dataIndex: 'payment',
      render: payment => <span>{payment ? 'Paid' : 'Not Paid'}</span>,
      sorter: (a, b) => a.payment - b.payment
    },
    {
      title: <span className='font-bold'>Clicked on Bank Transfer?</span>,
      key: 'transferOption',
      dataIndex: 'transferOption',
      render: transferOption => <span>{transferOption ? 'Yes' : 'No'}</span>,
      sorter: (a, b) => a.transferOption - b.transferOption
    },
    {
      title: <span className='font-bold capitalize'>ACTIONS</span>,
      key: 'action',
      render: (record, _) => <DropDownKids record={record} deleteModelType={deleteBookKidmentor} />
    }
  ]
  useEffect(() => {
    setData(bookMentors)
  }, [bookMentors])

  const handleSearch = value => {
    // const filteredData = data.filter(
    //   ele =>
    //     ele.business_name.toLowerCase().includes(value.toLowerCase()) ||
    //     ele.business_email.toLowerCase().includes(value.toLowerCase()) ||
    //     String(ele.loan_amount).to LowerCase().includes(value.toLowerCase()) ||
    //     String(ele.number_of_signatories).toLowerCase().includes(value.toLowerCase()) ||
    //     ele.loan_application_status.toLowerCase().includes(value.toLowerCase())
    // )
    setData(data)
  }

  return (
    <div className='bg-white shadow'>
      <div
        className='py-2 mb-6 pl-5'
        style={{
          borderBottom: '1px dotted #b4afaf',
          borderTop: '3px solid rgb(219 74 57)'
        }}
      >
        <h3 className='ml-12 text-xl text-gray-700'>{bookMentors.length} Applications found</h3>
      </div>
      <SearchTable data={data} handleSearch={handleSearch} setDataSource={setData} />
      <div className='px-4 overflow-x-auto mb-16'>
        <Table
          columns={BOOK_A_MENTOR_COLUMN}
          onChange={value => setPagination(value)}
          pagination={pagination}
          loading={loading}
          dataSource={data}
        />
      </div>
    </div>
  )
}

BookKidsMentorsTable.propTypes = {
  bookMentors: PropTypes.array.isRequired,
  loading: PropTypes.bool
}

export default BookKidsMentorsTable
