import React, { useState } from 'react'
import { UserAddOutlined } from '@ant-design/icons'

const Comment = () => {
  const [rate, setRate] = useState({ email: '', loading: false, error: '' })

  const ratingHandle = ({ target }) => {
    const { email } = rate
    if (email) {
      setRate({ ...rate, loading: true })
      setTimeout(() => {
        setRate({ ...rate, error: 'Thanks for rating!', email: '', loading: false })
      }, 1500)
    } else {
      setRate({ ...rate, error: 'Emial is required' })
    }
  }
  return (
    <div>
      {rate.error && <small className='text-red-700 text-base ml-4'>**{rate.error}**</small>}
      <div className='mt-1 relative rounded-md shadow-sm'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
          <span className='text-brown-500 text-lg leading-5'>
            <UserAddOutlined />
          </span>
        </div>
        <input
          id='price'
          className='form-input block w-full pl-8 text-sm leading-5'
          name='email'
          value={rate.email}
          onChange={({ target }) => setRate({ ...rate, email: target.value })}
          placeholder='example@gmail.com'
        />
        <div className='absolute inset-y-0 right-0 flex items-center border-2'>
          <select
            aria-label='Currency'
            className='form-select h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5'
            name='star'
            onChange={ratingHandle}
          >
            {!rate.loading ? (
              <>
                <option value=''>Rate Now</option>
                <option value='1'>1 STAR</option>
                <option value='2'>2 STAR</option>
                <option value='3'>3 STAR</option>
                <option value='4'>4 STAR</option>
                <option value='5'>5 STAR</option>
              </>
            ) : (
              <option value=''>
                {/* <LoadingOutlined /> */}
                Loading...
              </option>
            )}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Comment
