import React from 'react'
import { Helmet } from 'react-helmet-async'
import BootcampHero from '../components/BootcampHero'
import BootcampRegisterSection from '../components/BootcampRegisterSection'
import BootcampTracks from '../components/BootcampTracks'

const Bootcamp = () => {
  return (
    <div>
      <Helmet>
        <title>Bootcamp | 5 weeks intensive Bootcamp</title>
        <meta
          name='keywords'
          content='Learn frondtend developement, Learn Backend Developement, Learn Data Analysis, Learn Digital Marketing, Learn Crypto trading, Learn Forex trading, Become efficient in 5 weeks, 5 week intensive bootcamp'
        />
        <meta
          name='description'
          content='The Mentortribes 5 week intensive bootcamp helps you learn all you need to become profficient'
        />
      </Helmet>
      <BootcampHero />
      <BootcampTracks />
      <BootcampRegisterSection />
    </div>
  )
}

export default Bootcamp
