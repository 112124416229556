import React, { useContext, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { userContext } from '../../context/UserContext'

const Logout = () => {
  const history = useHistory()
  const { setUserInfo } = useContext(userContext)

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        notification.success({
          message: 'Logged Out!',
          description:
            'You have been logged out successfully, login again to get to your dashboard '
        })
        setUserInfo(prev => ({
          ...prev,
          isAuthenticated: false,
          data: {},
          email: '',
          sub: '',
          loading: false
        }))
        history.push('/')
      })
      .catch(err => {
        notification.error({ message: err.error })
      })
  }, [])

  return (
    <div style={{ height: '85vh' }} className='grid place-content-center'>
      <i className='fa fa-spin fa-spinner mr-2 font-bold text-xl' />
    </div>
  )
}

export default Logout
