import React from 'react'
import ClientSpeaks from './ClientSpeaks'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ClientSpeakWidget = ({ title, client, subTitle }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className='w-full px-8 md:px-24 pb-16 bg-no-repeat bg-img-1'>
      <h1 className='font-sans font-bold text-center text-white text-3xl md:text-4xl py-6 pb-2 px-6'>
        {title}
      </h1>
      {subTitle && (
        <p className='max-w-full text-gray-800 font-manrope text-base md:text-lg font-normal md:font-bold text-center mx-auto'>
          {subTitle}
        </p>
      )}

      <Slider {...settings}>
        {client.map(({ id, title1, body1, image1, clientName1, portfolio1 }) => (
          <ClientSpeaks
            key={id}
            title1={title1}
            body1={body1}
            image1={image1}
            clientName1={clientName1}
            portfolio1={portfolio1}
          />
        ))}
      </Slider>
    </div>
  )
}

ClientSpeakWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  client: PropTypes.array.isRequired
}

export default ClientSpeakWidget
