import profile from '../../images/profile.png'

export const CLIENT = [
  {
    id: 1,
    title1: 'Friendly Mentors',
    body1:
      'Mentortribes services are super amazing. I have grown from novice to professional, made relevant connections and friends, and have gotten career guidance based on Industry changes',
    image1: profile,
    clientName1: 'Imagbe Reuben',
    portfolio1: 'Student'
  },
  {
    id: 10,
    title1: 'One in a Million',
    body1:
      'I can frankly say that Mentortribes is the best mentorship organisation I have seen in Nigeria. One thing that stands out about them is how dedicated their mentors are assisting us ',
    image1: profile,
    clientName1: 'Victor N.',
    portfolio1: 'Student'
  },
  {
    id: 20,
    title1: 'Devoted  Mentors',
    body1:
      'Mentortribes has been very instrumental to the great boost I have enjoyed for the past months of our mentorship despite the scourge of Covid-19 on the nations economy. You guys are the best!',
    image1: profile,
    clientName1: 'Cynthia Ada',
    portfolio1: 'Intern'
  },
  {
    id: 30,
    title1: 'Career Developement',
    body1:
      'The best benefits I have enjoyed from being mentored by Mentortribes is the ability to respond to our coding challenges. Once we reachout to them, they always respond with great speed. Thank you!',
    image1: profile,
    clientName1: 'Chiamaka Faith',
    portfolio1: 'IT Student'
  },
  {
    id: 40,
    title1: 'Professional Coaches',
    body1:
      'Mentortribes services are super amazing. I have grown from novice to professional, made relevant connections and friends, and have gotten career guidance based on Industry changes',
    image1: profile,
    clientName1: 'Benson',
    portfolio1: 'Graduate'
  }
]

export const KIDS = [
  {
    id: 1,
    title1: 'Best Mentorship',
    body1:
      'Mentortribes provided us with the best mentors that understands our needs and this made our learning throughout our stay super amazing. They always make sure we do our assignment very well.',
    image1: profile,
    clientName1: 'Zazzy Ben',
    portfolio1: '10 years'
  },
  {
    id: 10,
    title1: 'Incredible Experience',
    body1:
      'Mentortribes services are super amazing. I have grown from novice to professional, made relevant connections and friends, and have gotten career guidance based on Industry changes',
    image1: profile,
    clientName1: 'Amanda N.',
    portfolio1: '15 years'
  },
  {
    id: 20,
    title1: 'Amazing Journey',
    body1:
      'I was afraid when I my mum told me that she want me to take part in online coding classes. I never knew I will make a new family of programmers that really want to see the best in me.',
    image1: profile,
    clientName1: 'Miracle Chi',
    portfolio1: '8 years '
  },
  {
    id: 30,
    title1: 'Best Community',
    body1:
      'Mentortribes services are super amazing. I have grown from novice to professional, made relevant connections and friends, and have gotten career guidance based on Industry changes',
    image1: profile,
    clientName1: 'Adewale',
    portfolio1: '17 years'
  },
  {
    id: 40,
    title1: 'Career Developement',
    body1:
      'God bless CEO Mentortribes for creating this amazing platform. I have grown from novice to intermediate, made some quality friends, and have gotten career guidance based on Industry changes',
    image1: profile,
    clientName1: 'Benson Tony',
    portfolio1: '13 years'
  }
]

export const testimonialData = [
  {
    body1:
      ' Great mentors, always ready to help and push you to achieve your goal of becoming a programmer',
    portfolio1: 'Praise',
    clientName1: 'Student / Designer',
    id: 1,
    image1: profile
  },
  {
    body1:
      ' Mentortribes has amazing Mentors who have impacted my career positively, I highly recommend them to every tech learner out there.',
    portfolio1: 'Bernard Chika',
    clientName1: 'Web Developer',
    id: 2,
    image1: profile
  },
  {
    body1:
      'Mentortribes services are super amazing. I have grown from novice to professional, made relevant connections and friends, and have gotten career guidance based on Industry changes',
    portfolio1: 'Afam Val',
    clientName1: 'Mentee / Web Developer',
    id: 3,
    image1: profile
  }
]
