import React, { useRef, useEffect } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { getFileURL } from '../../services/amplify'

// eslint-disable-next-line react/prop-types
const IntroVideo = ({ video }) => {
  const playerRef = useRef()
  const videoRef = useRef(null)

  const options = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: 'https://s3//masterbucketnameformentortribes171923-test/public/1527495978341-16.%20How%20to%20get%20the%20Most%20out%20of%20This%20Course.mp4',
        type: 'video/mp4'
      }
    ]
  }

  const onReady = player => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('dispose', () => {
      console.log('player will dispose')
    })
  }

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log('player is ready')
        onReady && onReady(player)
      }))
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [])

  const fetchVideo = async () => {
    console.log(await getFileURL(video))
  }

  useEffect(() => {
    fetchVideo()
  }, [])

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered' />
    </div>
  )
}

// https://s3://masterbucketnameformentortribes171923-test/public/1527495978341-16. How to get the Most out of This Course.mp4

export default IntroVideo
