import React from 'react'
import { Table, Input, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { listBootcamps } from '../../graphql/queries'

// const data = [
//   {
//     key: '1',
//     name: 'John Brown',
//     age: 32,
//     address: 'New York No. 1 Lake Park'
//   },
//   {
//     key: '2',
//     name: 'Joe Black',
//     age: 42,
//     address: 'London No. 1 Lake Park'
//   },
//   {
//     key: '3',
//     name: 'Jim Green',
//     age: 32,
//     address: 'Sidney No. 1 Lake Park'
//   },
//   {
//     key: '4',
//     name: 'Jim Red',
//     age: 32,
//     address: 'London No. 2 Lake Park'
//   }
// ]

class BootCampList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    data: []
  }

  async componentDidMount() {
    try {
      const { data } = await API.graphql(graphqlOperation(listBootcamps))
      this.setState({ ...this.state, data: data.listBootcamps.items })
    } catch (error) {
      console.log(error)
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex
              })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    const columns = [
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        ...this.getColumnSearchProps('fullName')
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        ...this.getColumnSearchProps('phone')
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        ...this.getColumnSearchProps('country')
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'address',
        ...this.getColumnSearchProps('email')
      },
      {
        title: 'How you get to know us',
        dataIndex: 'referralInfo',
        key: 'referralInfo',
        ...this.getColumnSearchProps('referralInfo')
      },
      {
        title: 'What to learn',
        dataIndex: 'whatToLearn',
        key: 'whatToLearn',
        ...this.getColumnSearchProps('whatToLearn')
      },
      {
        title: 'Why the course',
        dataIndex: 'whyLearnTheCourse',
        key: 'whyLearnTheCourse',
        ...this.getColumnSearchProps('whyLearnTheCourse')
      },
      {
        title: 'Laptop status',
        dataIndex: 'doYouHaveAlaptop',
        key: 'doYouHaveAlaptop',
        ...this.getColumnSearchProps('doYouHaveAlaptop')
      },
      {
        title: 'More course detail',
        dataIndex: 'courseDetails',
        key: 'courseDetails',
        ...this.getColumnSearchProps('courseDetails')
      }
    ]
    return <Table columns={columns} dataSource={this.state.data} />
  }
}

export default BootCampList
