/* eslint-disable new-cap */
import React, { useRef, useState } from 'react'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { useHistory } from 'react-router-dom'
import '../paymentInvoice.css'
import logo from '../images/mentortribes-logo.png'
import { Button } from 'antd'

const PaymentInvoice = () => {
  const invoiceRef = useRef()
  const history = useHistory()
  const { name, email, amount, item } = history.location.state
  const [loading, setLoading] = useState(false)

  const printDocument = () => {
    const input = document.getElementById('print')
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('img/png')
      const pdf = new jsPDF('p', 'px', 'a4')
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save('Mentortribes-invoice.pdf')
    })
    setLoading(false)
  }

  const invoiceNumber = Date.now()
  const today = new Date()
  const createdDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()

  return (
    <div>
      <div className='h-max w-screen overflow-auto md:h-auto my-15'>
        <div ref={invoiceRef} id='print' className='invoice-box'>
          <table cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr className='top'>
                <td colSpan='2'>
                  <table>
                    <tr>
                      <td className='title'>
                        <img src={logo} style={{ width: '100%', maxWidth: '200px' }} />
                      </td>

                      <td>
                        Invoice #: {invoiceNumber}
                        <br />
                        Created: {createdDate}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className='information'>
                <td colSpan='2'>
                  <table>
                    <tr>
                      <td>
                        Mentortribes Hub.
                        <br />
                        No 19 Ganiyu Jimoh Street
                        <br />
                        Lagos State, Nigeria
                      </td>

                      <td>
                        {name || 'No Name received'}
                        <br />
                        {email || 'No Email received'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className='heading'>
                <td>Pay To</td>

                <td />
              </tr>

              <tr className='item'>
                <td>Bank</td>

                <td>Parkway - ReadyCash</td>
              </tr>
              <tr className='item'>
                <td>Account Number</td>

                <td>0605005069</td>
              </tr>
              <tr className='item'>
                <td>Account Name</td>

                <td>MENTORTRIBES HUB</td>
              </tr>

              <tr className='heading'>
                <td>Item</td>

                <td>Price</td>
              </tr>

              <tr className='item'>
                <td>{item}</td>

                <td>₦{amount || 'No Value'}</td>
              </tr>

              <tr className='total'>
                <td />

                <td>Total: ₦{amount || 'No Value'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=''>
        <Button
          loading={loading}
          className='py-4 px-6 text-large font-semibold flex items-center bg-red-800 m-auto text-white hover:text-white hover:bg-red-700 rounded'
          onClick={(() => setLoading(true), printDocument)}
        >
          Print
        </Button>
        <Button
          loading={loading}
          className='py-4 px-6 text-large font-semibold flex items-center my-5 bg-red-800 m-auto text-white hover:text-white hover:bg-red-700 rounded'
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  )
}

export default PaymentInvoice
