import React from 'react'
import { Collapse } from 'antd'
import exclamationIcon from '../../images/pricing/exclamation.svg'
import PropTypes from 'prop-types'

const PricingFaq = ({ currentFaq }) => {
  const { Panel } = Collapse

  return (
    <div
      className={`bg-white pb-5 ${currentFaq === 2 ? 'block' : 'hidden'}`}
      style={{ borderRadius: '1rem' }}
    >
      <div className='p-5'>
        <div className='flex'>
          <div className='flex flex-col items-center justify-center mr-5'>
            <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
              <img src={exclamationIcon} alt='exclamation' />
            </figure>
          </div>
          <div>
            <h4 className='text-black text-xl font-semibold'>Pricing</h4>
            <p className='text-gray-900'>You can find answers relating to pricing enquries here</p>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        className='pl-10 bg-white border-0'
        expandIconPosition='right'
        expandIcon={item =>
          item.isActive ? (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-minus' />
            </span>
          ) : (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-plus' />
            </span>
          )
        }
      >
        <Panel className='text-lg font-manrope' header='How can I pay for a course?' key='6'>
          <p className='text-base text-gray-600 mb-4'>
            Mentortribes provides several payment methods, depending on your country and location.
            You can also reach out to our support in our contact us page to guide you in any of our
            available payment methods that are best for you.
          </p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='What if I don’t like the course I purchased?'
          key='7'
        >
          <p className='text-base text-gray-600 mb-4'>
            Your happiness is our concern, in as much as we don’t refund after payment; we give room
            for all eligible courses paid on Mentortribes to be switched to another course within
            one week of starting the course.
          </p>
          <p className='text-base text-gray-600'>
            Example, you can switch from python to JavaScript, provided that the payment for the
            course is the same. If you are unhappy with a course, you can also request for a change
            of mentor, provided the request was made with a genuine reason.
          </p>
        </Panel>

        <Panel
          className='text-lg font-manrope'
          header='How do I get started with paid courses? '
          key='9'
        >
          <p className='text-base text-gray-600 mb-4'>
            To get started, kindly register and make payment. Once your payment is confirmed, our
            support team will contact you for more information to get you started. A mentor will be
            assigned to you so that you can start your learning process.
          </p>
          <p className='text-base text-gray-600'>
            In case there is any reason why you can’t start your learning immediately, our support
            will make a one-time adjustment to that effect.
          </p>
        </Panel>

        <Panel className='text-lg font-manrope' header='What if I need a special package?' key='11'>
          <p className='text-base text-gray-600 mb-4'>
            We understand our students have their differences and things keeping them busy. For
            special packages like learning at your own pace, taking intermittent breaks in the
            learning process, choosing a unique learning time, group mentorship, which to have
            access to the best mentors in the industry, etc.
          </p>
          <p className='text-base text-gray-600'>Kindly contact our support team for Fee Advice.</p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='Is there any package available for children?'
          key='12'
        >
          <p className='text-base text-gray-600 mb-4'>
            Yes! Children still fall under special packages because they need special attention and
            continuous engagement.
          </p>
          <p className='text-base text-gray-600'>
            We have amazing mentors that are familiar with coaching little children in order to
            manage distractions coming from them during the learning process. Our mentors help to
            boost their self- esteem and confidence in problem solving by supporting independence
            and persistence in the face of initial difficulties.
          </p>
        </Panel>
        <Panel
          className='text-lg font-manrope'
          header='Can I pay Installmentally for a course?'
          key='13'
        >
          <p className='text-base text-gray-600 mb-4'>
            You are expected to pay before the commencement of each month of training. This will
            enable us to make perfect arrangements for your learning.
          </p>
          <p className='text-base text-gray-600'>
            You can as well make a long-term commitment by making complete payment with respect to
            the duration of your course choice. This helps us know how serious a student is when
            planning for the paid course of choice.{' '}
          </p>
        </Panel>
        <Panel className='text-lg font-manrope' header='Is there any referral bonus?' key='14'>
          <p className='text-base text-gray-600'>
            Yes! We value those that have our interest at hand and that is why we are given a 10%
            discount to anyone who brings in two fully registered students. We also give out gift
            items to our top three referrals at the end of each year.
          </p>
        </Panel>

        <Panel className='text-lg font-manrope' header='Is there any scholarship program?' key='19'>
          <p className='text-base text-gray-600 mb-4'>
            Yes! We are doing our best to get the less privileged ones involved in acquiring skills
            in software development at little or no cost. We are still seeking for sponsorships and
            partnerships to enable us equip more youths that genuinely cannot afford to pay
          </p>
        </Panel>
      </Collapse>
    </div>
  )
}

PricingFaq.propTypes = { currentFaq: PropTypes.number }
export default PricingFaq
