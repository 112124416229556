import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

const ContactTable = ({ info, loading }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })

  const COLUMN = [
    {
      title: <span className='font-bold'>S/N</span>,
      dataIndex: 'sn',
      key: 'sn',
      render: sn => <span>{sn}</span>
    },
    {
      title: <span className='font-bold'>Email</span>,
      dataIndex: 'email',
      key: 'email',
      render: email => <span>{email}</span>,
      sorter: (a, b) => a.email - b.email
    },
    {
      title: <span className='font-bold'>Fullname</span>,
      key: 'fullName',
      dataIndex: 'fullName',
      render: fullName => <span>{fullName}</span>,
      sorter: (a, b) => a.fullName - b.fullName
    },
    {
      title: <span className='font-bold'>Message</span>,
      key: 'message',
      dataIndex: 'message',
      render: message => <span>{message}</span>
    }
  ]
  useEffect(() => {
    setData(info)
  }, [info])

  return (
    <div className='bg-white shadow'>
      <div className='px-4 overflow-x-auto mb-16'>
        <Table
          columns={COLUMN}
          onChange={value => setPagination(value)}
          pagination={pagination}
          loading={loading}
          dataSource={data}
        />
      </div>
    </div>
  )
}

ContactTable.propTypes = { info: PropTypes.object, loading: PropTypes.bool }
export default ContactTable
