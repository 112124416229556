import React from 'react'
import { Table } from 'antd'
import ClassDropDown from './ClassDropDown'

// eslint-disable-next-line react/prop-types
const Classes = ({ data }) => {
  const COLUMNS = [
    {
      title: <span className='font-bold'>Time</span>,
      dataIndex: 'time',
      key: 'time',
      render: time => <span>{time}</span>,
      sorter: (a, b) => a.time - b.time
    },
    {
      title: <span className='font-bold'>Day</span>,
      dataIndex: 'day',
      key: 'day',
      render: day => <span>{day}</span>
    },
    {
      title: <span className='font-bold'>Mentee</span>,
      dataIndex: 'menteeName',
      key: 'menteeName',
      render: menteeName => <span className='lowercase'>{menteeName}</span>
    },
    {
      title: <span className='font-bold'>Duration</span>,
      key: 'duration',
      dataIndex: 'duration',
      render: duration => <span>{duration}</span>
    },
    {
      title: <span className='font-bold'>Course Name</span>,
      key: 'courseName',
      dataIndex: 'courseName',
      render: courseName => <span>{courseName}</span>
    },
    {
      title: <span className='font-bold capitalize'>ACTIONS</span>,
      key: 'action',
      render: (record, _) => <ClassDropDown record={record} />
    }
  ]

  return (
    <div id='classes' className='py-5 mt-5'>
      <h4 className='text-lg font-manrope border-b border-gray-300 pb-2'>Classes</h4>
      <div className='overflow-auto'>
        <div className='mt-10 shadow bg-white overflow-auto'>
          <Table
            columns={COLUMNS}
            // loading={loading}
            pagination={false}
            className='pb-28'
            dataSource={data}
          />
        </div>
      </div>
    </div>
  )
}

export default Classes
