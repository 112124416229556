import React, { useEffect, useState } from 'react'
import { Table, notification } from 'antd'
import SearchTable from './SearchTable'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { updateMentor } from '../graphql/mutations'
import axios from '../services/axios'
import Moment from 'react-moment'
import { TABLES_CALENDAR_STRINGS } from '../data/courses'
import MentorsDropDown from '../sharedComponents/MentorsDropDown'
import MentorApplicationModal from './MentorApplicationModal'

const MentorsApplicationTable = ({ bookMentors, loading }) => {
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const [visible, setVisible] = useState(false)
  const [selectedMentor, setSelectedMentor] = useState({})

  useEffect(() => {
    setData(bookMentors)
    setSearchData(bookMentors)
  }, [bookMentors])
  const handleMentorStatusChange = async (status, record, setLoading, setHidden) => {
    try {
      if (setHidden) setHidden(true)
      setLoading(true)
      const data = await API.graphql(
        graphqlOperation(updateMentor, {
          input: {
            id: record.id,
            status
          }
        })
      )
      await axios.post(
        `/eazy2con${status === 'ACCEPTED' ? '/register-success' : '/decline-mentor-success'}`,
        {
          name: `${record.firstname} ${record.lastname}`,
          email: record.email
        }
      )
      setLoading(false)
      setData(prev =>
        prev.map(mentor =>
          mentor.id === data.data.updateMentor.id
            ? { ...mentor, status: data.data.updateMentor.status }
            : mentor
        )
      )
      setSelectedMentor({ ...selectedMentor, status: data.data.updateMentor.status })
    } catch (err) {
      setLoading(false)
      notification.error({
        message: err?.message || 'Something went wrong!',
        duration: 10000
      })
    }
  }

  const COLUMNS = [
    {
      title: <span className='font-bold'>S/N</span>,
      dataIndex: 'sn',
      key: 'sn',
      render: sn => <span>{sn}</span>,
      sorter: (a, b) => a.sn - b.sn
    },
    {
      title: <span className='font-bold'>Full Name</span>,
      dataIndex: 'fullName',
      key: 'fullName',
      render: fullName => <span>{fullName}</span>
    },
    {
      title: <span className='font-bold'>Email Address</span>,
      dataIndex: 'email',
      key: 'email',
      render: email => <span className='lowercase'>{email}</span>
    },
    {
      title: <span className='font-bold'>Phone No </span>,
      key: 'phone',
      dataIndex: 'phone',
      render: phone => <span>{phone}</span>
    },
    {
      title: <span className='font-bold'>Specialization</span>,
      key: 'specialization',
      dataIndex: 'specialization',
      render: specialization => <span>{specialization}</span>
    },
    {
      title: <span className='font-bold'>YrsOfexperience</span>,
      key: 'yrsOfexperience',
      dataIndex: 'yrsOfexperience',
      render: yrsOfexperience => <span>{yrsOfexperience}</span>
    },
    {
      title: <span className='font-bold'>Status</span>,
      key: 'status',
      dataIndex: 'status',
      width: 200,
      render: status => <span>{status}</span>
    },
    {
      title: <span className='font-bold'>Created At</span>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => <Moment calendar={TABLES_CALENDAR_STRINGS}>{createdAt}</Moment>,
      sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
      title: <span className='font-bold capitalize'>ACTIONS</span>,
      key: 'action',
      render: (record, _) => (
        <MentorsDropDown
          setVisible={setVisible}
          handleMentorStatusChange={handleMentorStatusChange}
          setData={setData}
          setSelectedMentor={setSelectedMentor}
          record={record}
        />
      )
    }
  ]

  const handleSearch = value => {
    const filteredData = data.filter(
      ele =>
        ele.fullName.toLowerCase().includes(value.toLowerCase()) ||
        ele.email.toLowerCase().includes(value.toLowerCase()) ||
        ele.phone.toLowerCase().includes(value.toLowerCase()) ||
        ele.specialization.toLowerCase().includes(value.toLowerCase()) ||
        ele.yrsOfexperience.toLowerCase().includes(value.toLowerCase()) ||
        ele.country.toLowerCase().includes(value.toLowerCase()) ||
        new Date(ele.createdAt).toDateString().includes(value.toLowerCase())
    )
    setData(filteredData)
  }

  return (
    <div className='bg-white shadow'>
      <div
        className='py-2 mb-6 pl-5'
        style={{
          borderBottom: '1px dotted #b4afaf',
          borderTop: '3px solid rgb(219 74 57)'
        }}
      >
        <h3 className='ml-12 text-xl text-gray-700'>{bookMentors.length} Applications found</h3>
      </div>
      <SearchTable
        data={data}
        handleSearch={handleSearch}
        setDataSource={setData}
        searchData={searchData}
      />
      <div className='px-4 overflow-x-auto mb-16'>
        <Table
          columns={COLUMNS}
          onChange={value => setPagination(value)}
          pagination={pagination}
          loading={loading}
          dataSource={data}
        />
      </div>
      <MentorApplicationModal
        setVisible={setVisible}
        visible={visible}
        selectedMentor={selectedMentor}
        handleMentorStatusChange={handleMentorStatusChange}
      />
    </div>
  )
}

MentorsApplicationTable.propTypes = {
  bookMentors: PropTypes.any.isRequired,
  loading: PropTypes.bool
}

export default MentorsApplicationTable
