import React from 'react'
import mentorsImage from '../../images/mentors.jpg'

const Header = () => {
  return (
    <header style={{ background: '#f7f1d1' }} className='md:pl-4 mb-5'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/2 md:pl-6 px-4 py-16 flex flex-col justify-center'>
          <div>
            <h1 className='max-w-2xl text-gray-900 font-manrope font-bold leading-tight text-3xl md:text-5xl'>
              Learn and grow with help from world-class mentors
            </h1>
            <p className='text-lg md:text-xl text-gray-800 py-4 md:py-6 pr-8 font-manrope font-semibold leading-7'>
              Book and meet mentors for 1:1 mentorship in our global community. Find the right
              mentor for you
            </p>
            <form>
              <div
                className='relative'
                style={{ boxShadow: 'rgba(142, 151, 158, 0.15) 0px 4px 19px' }}
              >
                <input
                  type='text'
                  className='border border-gray-200 block w-full py-3 px-5 rounded-md outline-none focus:outline-none focus:shadow-outline-blue duration-200'
                />
                <div className=' absolute right-0 top-0 bottom-0 py-1 pr-1'>
                  <button className='bg-btn-primary hover:bg-opacity-75 duration-200 focus:outline-none text-white block max-w-max w-full px-7 py-1 h-full rounded-md'>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='w-full hidden md:block md:w-1/2 px-2 md:px-0'>
          <img src={mentorsImage} alt='connecting users' className='h-full object-cover' />
        </div>
      </div>
    </header>
  )
}

export default Header
