import React from 'react'
import PropTypes from 'prop-types'

const ClientSpeaks = ({ title1, body1, image1, clientName1, portfolio1 }) => {
  return (
    <div className='w-full px-2 mt-16'>
      <div className='client-btm-icon'>
        <div className='rounded-xl bg-white p-6 client-btm-icon'>
          {title1 && <h1 className='font-sans font-bold text-center text-lg pb-6'>{title1}</h1>}
          <p className='section_four' title={body1}>
            {body1.substr(0, 400)}
            {body1.length > 400 ? '...' : ''}
          </p>
        </div>
      </div>
      <div className='flex flex-wrap pt-2 pl-16 sm:pl-20 md:pl-24 lg:pl-32'>
        <img
          src={image1}
          alt='client speaks'
          className='w-16 h-16 bg-opacity-50 transition duration-500 ease-in-out rounded-full'
        />
        <div className='font-sans ml-4'>
          <h3 className='font-bold text-xl mb-0 text-white'>{clientName1}</h3>
          <p className='text-base text-white'>{portfolio1}</p>
        </div>
      </div>
    </div>
  )
}

ClientSpeaks.propTypes = {
  title1: PropTypes.string,
  body1: PropTypes.string.isRequired,
  image1: PropTypes.string.isRequired,
  clientName1: PropTypes.string.isRequired,
  portfolio1: PropTypes.string.isRequired
}

export default ClientSpeaks
