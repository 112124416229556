import React, { useState, useRef } from 'react'
import exclamationIcon from '../../images/pricing/exclamation.svg'
import PricingFaq from './PricingFaq'
import GeneralFaq from './GeneralFaq'
import MentorsFaq from './MentorsFaq'
import 'antd/dist/antd.css'

const FAQ = () => {
  const [currentFaq, setCurrentFaq] = useState(1)
  const faqRef = useRef()

  const changeCurrentFaq = id => {
    if (faqRef.current) {
      faqRef.current.style.opacity = 0
      setTimeout(() => {
        setCurrentFaq(id)
        faqRef.current.style.opacity = 1
      }, 300)
    }
  }

  return (
    <section className='bg-black pb-20'>
      <div className='container mx-auto px-5'>
        <h2 className='text-gray-300 text-center text-3xl font-bold'>Frequently Asked Questions</h2>
        <p className='text-gray-400 max-w-xs mt-3 leading-6 mx-auto text-center'>
          Find answers to frequently asked questions regarding Mentortribes
        </p>
        <div className='grid grid-cols-1 md:grid-cols-12 gap-10 mt-10'>
          <div className='md:col-span-5'>
            <div
              className={`${
                currentFaq === 1 ? 'bg-red-900' : 'bg-white'
              } mb-5 p-5 rounded-lg cursor-pointer duration-150`}
              onClick={() => changeCurrentFaq(1)}
            >
              <div className='flex'>
                <div className='flex flex-col items-center justify-center mr-5'>
                  <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
                    <img src={exclamationIcon} alt='exclamation' />
                  </figure>
                </div>
                <div>
                  <h4
                    className={`${
                      currentFaq === 1 ? 'text-white' : 'text-black'
                    } text-xl font-semibold`}
                  >
                    General Enquiries
                  </h4>
                  <p className={`${currentFaq === 1 ? 'text-white' : 'text-gray-900'} `}>
                    You can find answers relating to general enquries here
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`${
                currentFaq === 2 ? 'bg-red-900' : 'bg-white'
              } mb-5 p-5 rounded-lg cursor-pointer  duration-150`}
              onClick={() => changeCurrentFaq(2)}
            >
              <div className='flex'>
                <div className='flex flex-col items-center justify-center mr-5'>
                  <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
                    <img src={exclamationIcon} alt='exclamation' />
                  </figure>
                </div>
                <div>
                  <h4
                    className={`${
                      currentFaq === 2 ? 'text-white' : 'text-black'
                    } text-xl font-semibold`}
                  >
                    Pricing
                  </h4>
                  <p className={`${currentFaq === 2 ? 'text-white' : 'text-gray-900'} `}>
                    You can find answers relating to pricing enquries here
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${
                currentFaq === 3 ? 'bg-red-900' : 'bg-white'
              } mb-5 p-5 rounded-lg cursor-pointer duration-150`}
              onClick={() => changeCurrentFaq(3)}
            >
              <div className='flex'>
                <div className='flex flex-col items-center justify-center mr-5'>
                  <figure className='m-0 p-0 bg-red-200 rounded-full w-4 h-4 grid place-items-center'>
                    <img src={exclamationIcon} alt='exclamation' />
                  </figure>
                </div>
                <div>
                  <h4
                    className={`${
                      currentFaq === 3 ? 'text-white' : 'text-black'
                    } text-xl font-semibold`}
                  >
                    Mentors
                  </h4>
                  <p className={`${currentFaq === 3 ? 'text-white' : 'text-gray-900'} `}>
                    You can find answers relating to mentors enquries here
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='md:col-span-7 duration-300' ref={faqRef}>
            <GeneralFaq currentFaq={currentFaq} />
            <PricingFaq currentFaq={currentFaq} />
            <MentorsFaq currentFaq={currentFaq} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ
