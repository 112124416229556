import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { getMentor } from '../../graphql/queries'
import { Link } from 'react-router-dom'
import { getFileURL } from '../../services/amplify'
import userAvatar from '../../images/profile.png'
import PropTypes from 'prop-types'

const SingleSideChat = ({ setChatId, currentChatId, chat }) => {
  const [imageUrl, setImageUrl] = useState(userAvatar)
  const [mentor, setMentor] = useState('')
  const fetchMentor = async () => {
    try {
      const res = await API.graphql(graphqlOperation(getMentor, { id: chat.mentorId }))
      setMentor(res.data.getMentor)
    } catch (error) {}
  }

  useEffect(() => {
    fetchMentor()
  }, [])

  const fetchImage = async () => {
    if (mentor) {
      if (mentor?.imageUrl && mentor?.imageUrl.key) {
        setImageUrl(await getFileURL(mentor.imageUrl.key))
      } else {
        setImageUrl(userAvatar)
      }
    }
  }

  useEffect(() => {
    fetchImage()
  }, [mentor])

  return (
    <Link
      to={`/chat/${chat.id}`}
      className={`${
        currentChatId === chat.id && 'bg-gray-200'
      } cursor-pointer hover:bg-gray-100 duration-200 py-4 px-4 flex items-center`}
    >
      <figure className='m-0 p-0'>
        <img src={imageUrl} alt='' className='w-12 h-12 rounded-full object-cover' />
      </figure>
      <div className='ml-3 flex-1'>
        <div className='flex justify-between'>
          <div>
            <div>
              <h4 className='font-manrope font-semibold text-base'>
                {mentor?.firstname} {mentor?.lastname}
              </h4>
              <p className='font-manrope text-gray-400 font-medium'>{mentor?.specialization}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
SingleSideChat.propTypes = {
  setChatId: PropTypes.any,
  currentChatId: PropTypes.string,
  chat: PropTypes.any
}

export default SingleSideChat
