import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'

const BioInput = ({ label, value, bioRef, handleChange, type, id, noMargin }) => {
  return (
    <div className={`${!noMargin && 'mt-3'}`}>
      <label htmlFor={id} className='text-sm font-manrope mb-2 block font-semibold text-black'>
        {label}
      </label>
      <ReactQuill
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'mention'
        ]}
        modules={{
          toolbar: [
            [{ header: [false] }],
            ['bold', 'italic', 'underline', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link']
          ]
        }}
        defaultValue={value}
        onChange={handleChange}
      />
      {!value && (
        <small ref={bioRef} className='text-red-500 text-xs'>
          Please enter a bio to describe yourself
        </small>
      )}
    </div>
  )
}
BioInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  bioRef: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  id: PropTypes.string,
  noMargin: PropTypes.string
}

export default BioInput
