import webImage from '../images/feature-courses/web-dev.jpg'
import designImage from '../images/feature-courses/UI-UX.png'
import projectImage from '../images/feature-courses/project-management.jpg'
import cryptoImage from '../images/feature-courses/cryptocurrency-trading.jpg'
import cloudImage from '../images/feature-courses/cloud-computing.png'
import marketingImage from '../images/feature-courses/digital-marketing.jpg'
import mlImage from '../images/feature-courses/machine-learning.jpg'
import dataImage from '../images/feature-courses/data-science.jpg'
import mobileDevCrossImage from '../images/feature-courses/mobile-dev-cross.png'
import cyberSecurityImage from '../images/feature-courses/cyber-security.jpg'

const featuredCourses = [
  {
    title: 'Web Development',
    id: 'web-development',
    image: webImage,
    video: 'https://www.youtube.com/embed/5YDVJaItmaY',
    description:
      'At Mentortribes, we understand your passion to create effective and captivating websites. \n In our fun and project-based Bootcamp, you will acquire all the technical skills you need to create and design an amazing website using the latest web development tools and practices. Let us teach you now!',
    profession: 'Web developer',
    programRightCaption:
      'Do you love to build, do you have ideas that you want to bring to live? If so, then this is the program for you',
    prerequites: [
      ["No prior knowledge is required - we'll teach you everything you need to know"],
      ['A Personal Computer (PC) is required']
    ],
    whatYouGet: [
      'Frontend web development using HTML, CSS and javascript',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'Most in-demand CSS frameworks like tailwindcss, bootstrap and more',
      'Javascript library like jQuery and more',
      'Active huge community for support and help',
      'Advanced javascript topics like closures, promise, ES6, ES7, javascript frameworks(Vuejs, React) and so on',
      "Portfolio reviews by the industry's experts",
      'We will also cover top backend topics using backend frameworks like Express, Django, Flask and a lot more',
      'Work on actual real world projects for real clients'
    ],
    faqs: [
      {
        question: 'Who is this course for?',
        answer:
          'This course is for people who want to get a job in web developement<p class="mt-3">People who have ideas but can\'t work on it because they don\'t have the skills</p>'
      },
      {
        question: 'What is frontend development?',
        answer:
          'Frontend web development is a field of web development that deals with Ui, bascially what the user or client sees(i.e buttons, images, text and the enitre page).<p class="mt-3">Frontend web development is also often refers to a Client side web development because it has to do with everything the client sees and interact with.</p>'
      },
      {
        question: 'What is backend web development?',
        answer:
          'Backend web development is a field of web development that deals with the server, bascially what the user or client doesn\'t see or even know about.<p class="mt-3">How is your password stored securely so that next time you can use it next time to login, that\'s the job of the backend. It takes your password and store it somewhere that\'s not on your device(A database).</p> <p class="mt-3">Backend web development is also often refers to a Server side web development because it has to do with everything that happens on the server.</p>'
      },
      {
        question: 'What is Fullstack web development?',
        answer: 'Fullstack web is combination both frontend and backend web development'
      },
      {
        question: 'What kind of Job will I be able to get after this bootcamp?',
        answer:
          'After this class you will go on to get fulltime, partime, remote or intern jobs as a Frontend web developer, Backend web developer or even Fullstack web developer'
      },
      {
        question: 'Do web developers work with mobile developer?',
        answer:
          'Yes often backend web developer find themself working side by side with mobile developers and even game developers'
      }
    ],
    aboutClass:
      'This mentorship program is going to be fully project based, meaning that you learn by building lots of projects to fill you profolio with and get you job ready. You are going to learn the most in demond skills to prepare for the industry at large. You will acquire skills which will set you in the right path, skills that will make you be a part of impactful teams, skils that will enable you build your own projects an implement your ideas. <br /> <br /> At end of this bootcamp you should be able to apply for small jobs roles. This bootcamp will teach you the most wanted skills in the industry right now and in the coming years and even decade. We will cover topics like frontend web development using html, css and javascript. Most in deamnd css frameworks like tailwindcss, bootstrap and more. Javascript librabry like jQuery and more, also advance javascript topics like closures, promise, ES6, ES7, Javascript frameworks(Vuejs, React) and so on. We also cover backend topics using backend frameworks like express, dJango, flask and more. We can even go as deep as secure your web apps, deployment and even devops'
  },
  {
    title: 'UI UX Design',
    id: 'ui-ux-design',
    image: designImage,
    video: 'https://www.youtube.com/embed/TgqeRTwZvIo',
    description:
      'Have you ever visited a website that runs so smoothly, immediately you click on a button you get a super-fast response and you are able to find your way very easily on the site? That’s the work of a good UX/UI designer.<br /><br /> At Mentortribes, we understand your passion to make technology accessible. This course will give you all the technical skills and digital insight that is vital to designing user-friendly products, services and processes. Our industry experts will provide you with the hands-on knowledge you need to nail your job interview - our main objective is to see you land the designer job you have always dreamed of. In a world that is ever-complex, an accessible and simple to use design can improve the life of any human being. <br />Let us teach you how!',
    profession: 'UI UX designer',
    programRightCaption:
      'Do you have an eye for design? Do you like to make things beautiful, organized and user friendly? If so, this program is right for you!',
    prerequites: [
      ["No pre-knowledge required - we'll teach you everything you need to know"],
      [
        'No software is required in advance of the course (all software used in the course is free or has a demo version)'
      ],
      ['A Personal Computer is required']
    ],
    whatYouGet: [
      "You learn the foundations of Experience and User Interface's best practices.",
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will create mobile app design from scratch',
      'You will learn to follow the design process: empathize with users, define pain points, ideate solutions, test and iterate on designs',
      'Resume & LinkedIn profile reviews',
      'You will learn to create wireframe designs and prototypes for any digital project',
      "Portfolio reviews by the industries's experts",
      'Access to our employer network',
      'You will learn to create mockups using Sketch and other tools',
      'You will get cutting-edge knowledge about user journeys, personas, as well as user maps.',
      'Start a new career as a UI/UX designer'
    ],
    faqs: [
      {
        question: 'Who is this course for?',
        answer:
          'This course is for people who want to get a job in UI/UX Design.<p class="mt-3">We require candidates to have strong visual, creativity, and communication skills. </p>'
      },
      {
        question: 'What is the difference UI and UX?',
        answer:
          'Watch the video, it will explain better. Rember an image is worth a thousand words, then a video should be worth a billion words'
      },
      {
        question: 'Do UI UX designers work with web developers?',
        answer:
          'Just as we said at the begning, UI UX is first part of the web and app developemnt process, so UI UX designer always work with web, mobile and even game developers.'
      },
      {
        question: 'What is the difference between a Product designer and UI UX designer?',
        answer:
          'A product designer designs different types of digital products and also non-digital products while UI UX designers focus on digital product with UIs(web app, mobile app and games)'
      }
    ],
    aboutClass:
      'This bootcamp is going to be fully project based, meaning that you learn in practical steps. A lot of projects will be done along the way so as to get you ready for your next job.'
  },
  {
    title: 'Project Management',
    id: 'project-management',
    image: projectImage,
    video: 'https://www.youtube.com/embed/2E6J3vh-8PU',
    description:
      "Your knowlege of when and how to start a project and see it to the end successfully can be the difference between success in your set goals and failure. Project management is a very broad and interesting field. Project Managers are highly in-demand and studying to gain the skill can give you a headstart in your Career in any Industry. We're here to see you succeed",
    profession: 'Project Manager',
    programRightCaption:
      'This Program is for everyone, as long as you have the desire to learn. If you already have a Knack for organizing stuff, are goal-oriented and passionate about management then consider that a plus',
    prerequites: [['No prior knowledge needed'], ['Internet Access']],
    whatYouGet: [
      'Expert-curated curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Resume & LinkedIn profile reviews',
      "Portfolio reviews by the industry's experts",
      'Access to our employer network',
      'Design on actual real world projects for real clients',
      'Collaborate directly with other designers and also developers  '
    ],
    faqs: [
      {
        question: 'Who is this course for?',
        answer:
          'This course is for people who want to start a Career in Project Management.<p class="mt-3">We require candidates to have strong desire to learn and good communication skills. </p>'
      },
      {
        question: 'Where can a Project Manager work?',
        answer:
          'There are Job opportunities everywhere Projects are carried out, virtually every business or service oriented organization seek to track their projects to see how well to reach their goals. That is where you come in.'
      }
    ],
    aboutClass:
      'This bootcamp is going to be hands-on, this means that you learn by building lots of project that will get you industry-ready. This bootcamp will improve your awareness as to how things could better be organized.'
  },
  {
    title: 'Cyber Security',
    id: 'cyber-security',
    image: cyberSecurityImage,
    video: 'https://www.youtube.com/embed/R38GIhL3I3Y',
    description:
      'With the world going digital, most of the services that required in-person processing in the past can now be done over the internet. Terms like Social Media Space, Online Shopping, Online Banking and others have become very common, this course is designed to get you aware of the dangers on the internet and show you how to protect yoursel as well as others in the Internet space.',
    profession: 'Cyber Security Analyst',
    programRightCaption:
      "This Program is for everyone who is intrigued by the flow of information over the Internet, who is conscious of their personal space and security and someone who's interested in building a Career in Cyber Security.",
    prerequites: [
      'Basic knowledge using the Internet is important',
      'A Computer with access to the Internet'
    ],
    whatYouGet: [
      'Expert-curated curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Active huge community for support and help',
      'Resume & LinkedIn profile reviews',
      "Portfolio reviews by the industry's experts",
      'Access to our employer network',
      'Design on actual real world projects for real clients',
      'Collaborate directly with other designers and also developers  '
    ],
    faqs: [
      {
        question: 'Who is this course for?',
        answer:
          'This course is for people who want to start a Career in Cyber Security as well as people who are concerned about their personal safety on the Internet space </p>'
      },
      {
        question: 'Where can a Cyber Security Analyst work?',
        answer:
          "As a Cyber Security Analysts, you can work for computer companies, consulting firms, or business and financial companies, you'll be tasked with "
      },
      {
        question: 'Would I be introduced to Hacking?',
        answer:
          'Ethical hacking is a branch of Cyber Security, this will enable you carry out penetration tests, so YES.'
      }
    ],
    aboutClass:
      'This mentorship program is going to be hands-on, you learn by solving practical Cyber Security problems that will get you industry-ready. This program will improve your awareness as to how things could better be secured over the internet.'
  },
  {
    title: 'Cryptocurrency Trading',
    id: 'cryptocurrency-trading',
    image: cryptoImage,
    video: 'https://www.youtube.com/embed/6Gu2QMTAkEU',
    description:
      'Be guided through everything you need to know so as to start being profitable in Crypto Trading. Master how to pummel your emotions so as to make trades devoid of Fear or Greed and get exposed to trading with charts and signals.',
    profession: 'Cryptocurrency Trader',
    programRightCaption:
      'This course is designed to help you learn modern techniques top-notch traders use in order to stay in profit, if you are intrigued by the Crypro Market then this course is for you',
    prerequites: [['No prior knowledge needed'], ['Internet access to trade']],
    whatYouGet: [
      'Standard and relevant curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Practical trading examples'
    ],
    faqs: [
      {
        question: 'Who is this course for?',
        answer:
          'This course is for YOU! If you have any interest in Crypto Trading, jump on this opportunity.'
      },
      {
        question: 'Where can I work afterwards?',
        answer:
          "You work for no one but yourself, you enter trades when you feel like, set techniques in motion that'll work for your good."
      }
    ],
    aboutClass:
      'You will get exposed to different trading strategies and techniques, you will learn how to read charts on different timeframes. All these is geared towards helping you enter trades strategically so as to remain profitable in the long run. Roll your sleeves and get ready for action.'
  },
  {
    title: 'Cloud Computing',
    id: 'cloud-computing',
    image: cloudImage,
    video: 'https://www.youtube.com/embed/2LaAJq1lB1Q',
    description:
      'Cloud computing is the delivery of in-demand computing services from applications to storage and processing power, typically over the internet and on a pay-as-you-go basis. Learn what you need you become a cloud praticioner.',
    profession: 'Cloud practitioner',
    programRightCaption:
      'This Program is for everyone who is interested in developing cloud applications. Somwone who wants to take their applications to the cloud.',
    prerequites: [
      'No prior knowledge needed',
      'Internet access',
      'A functional Laptop',
      'And Power supply(Electricity)'
    ],
    whatYouGet: [
      'Expert-curated curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Active huge community for support and help',
      'Resume & LinkedIn profile reviews',
      "Portfolio reviews by the industry's experts",
      'Access to our employer network',
      'Work on actual real world projects for real clients'
    ],
    faqs: [
      {
        question: 'Who is this course for?',
        answer:
          'This bootcamp is for someone who wants to  learn about various cloud service models and deployment models'
      },
      {
        question: 'Where can a I get a Job as a cloud pratictioner?',
        answer:
          'Cloud practitioners often work for big companies like Google, Amazon, Paypal, Yelp and also other smaller companies'
      },
      {
        question: 'Are cloud practitioner also engineers?',
        answer: 'Yes cloud practitioner are also engineers'
      },
      {
        question: 'What are examples of cloud computing solutions?',
        answer:
          'Cloud computing underpins a vast number of services. That includes consumer services like Gmail or the cloud back-up of the photos on your smartphone, though to the services which allow large enterprises to host all their data and run all of their applications in the cloud. <p class="mt-3"> Netflix relies on cloud computing services to run its video streaming service and its other business systems too and also a large number of other organisations.</p>'
      }
    ],
    aboutClass:
      'In this class, you will learn You learn about the various cloud service models (IaaS, PaaS, SaaS) and deployment models (Public, Private, Hybrid) and the key components of a cloud infrastructure (Virtual machines, Networking, Storage - File, Block, Object, CDN). We also cover emergent cloud trends and practices including - Hybrid Multicloud, Microservices, Serverless, DevOps, Cloud Native and Application Modernization'
  },
  {
    title: 'Digital Marketing',
    id: 'digital-marketing',
    image: marketingImage,
    video: 'https://www.youtube.com/embed/NvgJ2mPWHX8',
    description:
      'It doesn’t matter why you are learning digital marketing, it could be to grow your own business or to start a career. Either way, you’d be doing yourself a favour because in the next few years digital marketing is suppose to be even more of a hot cake than it is right now.<br /><br /> Every business is geared towards increasing profits and businesses keep finding new ways to do that and this inturn increases the need for digital marketers. Digital marketing is among one of the fastest growing job role in the world right now.',
    profession: 'Digital Marketer',
    programRightCaption:
      'This Program is for everyone who is who has interested in marketing, someone who has ideas and strategies on marketing a products. Someone who wants to increase a brand, product or digital product presence.',
    prerequites: [
      'No experience required',
      'Suitable for all types of businesses (digital product, physical product, service, B2B, B2C).'
    ],
    whatYouGet: [
      'Expert-curated curriculum',
      'Grow a Business Online From Scratch',
      'Social media marketing',
      'Email marketing',
      'Search engine optimisation (SEO)',
      'Understand frameworks and approaches to measuring consumers’ digital actions',
      'Evaluate and choose appropriate web analytics tools and techniques',
      'Get familiar with the unique opportunities and challenges presented by New Media',
      'Work From Home as a Freelance Marketer',
      'Conduct a competitor audit and analysis',
      'Intoduction to digital marketing strategy'
    ],
    faqs: [
      {
        question: 'What is digital marketing?',
        answer:
          'Digital marketing is any kind of marketing that is geared towards smartphones, laptops, tablets and basically any other device that is capable of connecting to Wi-Fi.'
      },
      {
        question: 'As a digital marketer what kind of company I be able to work for?',
        answer:
          'As a digital marketer you can work for big companies, brands(samsung, Cosmetic brands) and even for yourself and become your own boss managing your own clients.'
      }
    ],
    aboutClass:
      'At mentortribes, we understand your passion to get the right message to the right people. Irrespective of your industry or  background, this course will teach you core concepts and tools to help you better understand and excel in digital marketing.<br /><br /> This course will start from the fundamentals of digital marketing up to getting you started as a digital marketing specialist. We will cover top digital marketing topics like display advertising, pay per click advertising, search engine optimisation, email marketing, social media marketing and many more. <br />Let us teach you now!'
  },
  {
    title: 'Machine Learning',
    id: 'machine-learning',
    image: mlImage,
    video: 'https://www.youtube.com/embed/ukzFI9rgwfU',
    description: `Machine learning is a branch of artificial intelligence (AI) and computer science which focuses on the use of data and algorithms to imitate the way that humans learn, gradually improving its accuracy.
      Machine learning is an important component of the growing field of data science. Through the use of statistical methods, algorithms are trained to make classifications or predictions, uncovering key insights within data mining projects.
      `,
    profession: 'Machine Learning Engineer',
    programRightCaption:
      'Did you ever get excited about the recommendations in YouTube, Amazon, or any e-commerce websites? or are you looking forward to travel in self-driving cars? or are you really curious to know “How does human brain works"? If yes, then this program is definetly right for you',
    prerequites: [
      'Prior knowledge needed(preferable intermediate of python)',
      'Internet access',
      'A functional Laptop',
      'And Power supply(Electricity)'
    ],
    whatYouGet: [
      'Expert-curated curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Active huge community for support and help',
      'Resume & LinkedIn profile reviews',
      "Portfolio reviews by the industry's experts",
      'Access to our employer network',
      'Work on actual real world projects for real clients'
    ],
    faqs: [
      {
        question: 'What exactly is machine learning?',
        answer:
          'Arthur Samuel coined the term Machine Learning in 1959. He defined it as “The field of study that gives computers the capability to learn without being explicitly programmed”. <p class="mt-3>Machine Learning is a subset of Artificial Intelligence and it allows machines to learn from their experiences</p>'
      },
      {
        question: 'What is machine learning used for?',
        answer:
          'Machine Learning is used in our daily lives much more than we know it. It is used in Facial Recognition security on your phone, Self-driving cars, Virtual assistants(like Siri, Alexa and The google Assitant), Traffic Predictions, Online Fraud Detection(Facebook use this to block user accounts), Product Recommendations(Amazon, Jumia and other eccomerce Apps use this), Email Spam Filtering(Gmail use this to send unwanted emails to spam) and many more.'
      },
      {
        question: 'What is the difference between machine learning and artificial intelligence?',
        answer:
          'A technology that enables a machine to stimulate human behavior to help in solving complex problems is known as Artificial Intelligence. <p class="my-3">Machine Learning is a subset of AI and allows machines to learn from past data and provide an accurate output.</p> AI deals with unstructured as well as structured data. Whereas, Machine Learning deals with structured and semi-structured data.'
      },
      {
        question: 'How does Machine Learning work?',
        answer:
          'The typical machine learning process involves three steps: Training, Validation, and Testing. <p class="my-3>The first step is to learn from the training set provided, the second step is to measure error, the third step involves managing noise and testing all the parameters. </p>'
      },
      {
        question: 'What are the types of Machine Learning?',
        answer:
          'The broad types of machine learning are, Supervised Machine Learning, Unsupervised Machine Learning, Reinforcement Learning'
      }
    ],
    aboutClass:
      "In this class, you will learn What's behind the machine learning hype? You’ll learn everything you’ve been too afraid to ask about machine learning. Hands-on exercises will help you get past the jargon and learn how this exciting technology powers everything from self-driving cars to your personal Amazon shopping suggestions. How does machine learning work, when can you use it, and what is the difference between AI and machine learning? They’re all covered. Gain skills in this hugely in-demand and influential field, and discover why machine learning is for everyone!"
  },
  {
    title: 'Data science',
    id: 'data-science',
    image: dataImage,
    video: 'https://www.youtube.com/embed/X3paOmcrTjQ',
    description:
      'Data science combines multiple fields, including statistics, scientific methods, artificial intelligence (AI), and data analysis, to extract value from data. Those who practice data science are called data scientists, and they combine a range of skills to analyze data collected from the web, smartphones, customers, sensors, and other sources to derive actionable insights.',
    profession: 'Data scientists',
    programRightCaption:
      'Did you ever get excited about the recommendations in YouTube, Amazon, or any e-commerce websites? If yes, then this program is definetly right for you',
    prerequites: [
      'Prior knowledge needed(preferable intermediate of python)',
      'Pior programming or machine learning knowledge needed',
      'Internet access',
      'A functional Laptop',
      'And Power supply(Electricity)'
    ],
    whatYouGet: [
      'Expert-curated curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Active huge community for support and help',
      'Resume & LinkedIn profile reviews',
      "Portfolio reviews by the industry's experts",
      'Access to our employer network'
    ],
    faqs: [
      {
        question: 'What exactly is data science?',
        answer:
          'Data science is the use of scientific methods, processes, algorithms and systems to extract knowledge and insights from noisy, structured and unstructured data, and apply knowledge and actionable insights from data across a broad range of application domains'
      },
      {
        question: 'Where can I work as a data scientist',
        answer:
          'As a data scientist, you can work in so many variety of places, big companies, businesses, firms, agriculture industry, basic anywhere because every business needs data to operate.'
      },
      {
        question: 'Is Data science and Machine learning the same thing?',
        answer:
          'No they are not the same thing, data scientist collects data and extract usefull knowledge and insights from the data. While machine learning scientist use that data to train their applications and software to think and learn more. Although they are two different professions, they often work hand in hand with each other.'
      }
    ],
    aboutClass:
      'In this class, you will learn the entire toolbox you need to become a data scientist. Fill up your resume with in demand data science skills: Statistical analysis, Python programming with NumPy, pandas, matplotlib, and Seaborn. Understand the mathematics behind Machine Learning (an absolute must which other courses don’t teach!). Impress interviewers by showing an understanding of the data science field. Warm up your fingers as you will be eager to apply everything you have learned here to more and more real-life situations'
  },
  {
    title: 'Cross platform Mobile development(React Native, Flutter)',
    id: 'cross-platform-mobile',
    image: mobileDevCrossImage,
    video: 'https://www.youtube.com/embed/TWFUSxqMJic',
    description:
      'Cross-platform mobile development is the creation of software applications that are compatible with multiple mobile operating systems. The goal is to enable the same app to run on a Windows Phone, tablet, Andriod, IOS. Frameworks like react native, flutter, cordova, xamarin for development',
    profession: 'Cross platform Mobile deeveloper',
    programRightCaption:
      "Do you ever wanted to build own app? or you have an app idea but don't have the proper skills, or you are ready a dev but want to branch out. If yes, then this course is for you",
    prerequites: [
      'Prior knowledge',
      'Pior knowledge of React and Frontend web development required(Only for React Native)',
      'Internet access',
      'A functional Laptop',
      'And Power supply(Electricity)'
    ],
    whatYouGet: [
      'Expert-curated curriculum',
      'One-on-one video calls with your mentor 2-3 times in a week(only for Personal Plan)',
      'You will be able to reach your Mentor as much as needed for assistance',
      'Active huge community for support and help',
      'Resume & LinkedIn profile reviews',
      "Portfolio reviews by the industry's experts",
      'Access to our employer network',
      'Work on an actual project for an actual client'
    ],
    faqs: [
      {
        question: 'What is mobile development?',
        answer:
          'Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phone'
      },
      {
        question: 'What is cross plaatform mobile development?',
        answer:
          'Cross-platform mobile development is the creation of software applications that are compatible with multiple mobile operating systems'
      },
      {
        question: 'Where can I work as a mobile developer?',
        answer:
          'As a mobile developer, you can work in a range of business sectors, including finance and the public sector. They often work as part of a team with other IT professionals, such as software engineers and systems analysts , and write programs according to their specifications.'
      }
    ],
    aboutClass:
      'In this class, you will learn by building projects, you will use fancy emerging technologies to build monile apps that will run on mutiple devices(andriod, IOS). Develop cross-platform (iOS and Android) mobile apps without knowing Swift, ObjectiveC or Java/ Android. Discover mobile design patterns used by experienced engineers. Make truly reusable components that look great.'
  }
]

export { featuredCourses }
