import React from 'react'
import learning from '../images/pro-1.png'
import MentortribesBtn from '../sharedComponents/MentortribesBtn'

const ConnectToPro = () => {
  return (
    <div className='bg-black py-14'>
      <div className='grid md:grid-cols-2 grid-cols-1 bg-black gap-0 px-2 md:px-0'>
        <div className=''>
          <img src={learning} alt='connecting users' className='w-full' />
        </div>
        <div className='w-ful md:px-16 pb-5 pt-10 bg-no-repeat bg-img-22'>
          <h1 className='font-sans px-5 md:px-0 font-bold text-center md:text-left text-dark text-3xl md:text-4xl'>
            Share Your Expertise, Make A Difference
          </h1>
          <p className='text-lg text-dark pt-4 md:pt-6 text-justify leading-8 px-2'>
            &quot;The greatest good you can do for another is not just to share your riches but to
            reveal to him his own.&quot; - Benjamin Disraeli
          </p>
          <p className='text-lg text-dark pt  -4 md:pb-6 text-justify leading-8 px-2'>
            Let us help you make an impact on the world and Let us help you have a significant life
            by bringing out the best in others through a platform where you can teach what you have
            learned.
          </p>
          <MentortribesBtn text='Become a Mentor today' navigateByUrl='/add-mentor' />
        </div>
      </div>
    </div>
  )
}

export default ConnectToPro
