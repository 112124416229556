import React from 'react'

const AreaOfSpecizatiion = () => {
  return (
    <>
      <option value=''>- Specialization -</option>
      <option value='Frontend'>Frontend</option>
      <option value='Backend'>Backend</option>
      <option value='Full Stack'>Full Stack</option>
      <option value='Database Management'>Database Management</option>
      <option value='DevOps'>DevOps</option>
      <option value='CMS - Like wordpress'>CMS - Like wordpress</option>
      <option value='Not Specified'>Not Specified</option>
    </>
  )
}

export default AreaOfSpecizatiion
