import React from 'react'
import { Typography } from 'antd'
import RegisterForWebinarForm from '../components/RegisterForWebinarForm'
import webinarImage from '../images/webinar.jpeg'
import webinar from '../images/webinar.svg'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Helmet } from 'react-helmet-async'

const RegisterForWebinar = () => {
  const url =
    'https://mentortribes.us6.list-manage.com/subscribe/post?u=59a47045a4c546c7947fd1e14&amp;id=809323deb8'

  return (
    <div className='flex flex-wrap'>
      <Helmet>
        <meta name='keywords' content='Mentortribes webinar, webinar, online class' />
        <meta
          name='description'
          content='We hold regular webinars to get the Nigerian and African populace at large informed on how they can tap into the growth in tech'
        />
        <title>Mentortribes | About us</title>
      </Helmet>
      <div className='w-full md:w-1/2 bg-white md:pl-6 px-4 py-0 flex flex-col justify-center'>
        <div
          style={{
            boxShadow: '0px 4px 8px rgba(24, 25, 31, 0.11)'
          }}
          className='md:w-4/6 p-4 w-full mx-auto my-5 rounded-xl'
        >
          <div className='w-full'>
            <img src={webinar} alt='Webinar Image' className='mx-auto h-full object-cover' />
          </div>
          <Typography.Title
            level={3}
            className='text-center'
            style={{
              color: '#404445',
              fontFamily: 'Manrope',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '2em',
              lineHeight: '30px'
            }}
          >
            Register for the webinar
          </Typography.Title>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <RegisterForWebinarForm
                status={status}
                message={message}
                mailSubscribe={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
      <div style={{ height: '70vh' }} className='w-full hidden md:block md:w-1/2 px-2 md:m-auto'>
        <img src={webinarImage} alt='Webinar Image' className='h-full object-cover' />
      </div>
    </div>
  )
}
export default RegisterForWebinar
