export const MENTORS = [
  {
    fullName: 'eric okemmadu',
    id: '1'
  },
  {
    fullName: 'uche okemmadu',
    id: '2'
  },
  {
    fullName: 'Val okemmadu',
    id: '3'
  },
  {
    fullName: 'Afams okemmadu',
    id: '4'
  }
]

export const COURSES = [
  {
    label: 'Course Language',
    name: 'language',
    placeholder: 'e.g Javascrit, Python',
    rules: [
      {
        required: true,
        message: 'Please input your Course Language'
      }
    ]
  },
  {
    label: 'Title',
    name: 'title',
    placeholder: 'E.g Redux',
    rules: [
      {
        required: true,
        message: 'Give your course a title'
      }
    ]
  },
  {
    rows: '',
    textArea: true,
    label: 'Topics',
    name: 'topics',
    placeholder: 'Topics - Separate each topic by comma',
    rules: [
      {
        required: true,
        message: 'Input some topics you will cover'
      }
    ]
  },
  {
    label: 'Video Url',
    name: 'videoUrl',
    placeholder: 'https://youtube.com/sjdksj',
    rules: [
      {
        type: 'url',
        message: 'Link to Video Introduction'
      }
    ]
  },
  {
    label: 'Min Hours Duration',
    name: 'duration',
    placeholder: 'Number of hours you recommend',
    rules: [
      {
        required: true,
        message: 'Recommend a min duration for this course in hours'
      }
    ]
  },
  {
    label: 'Rating',
    name: 'rating',
    placeholder: 'Number of ratings',
    rules: [
      {
        message: 'Number of ratings from students'
      }
    ]
  },
  {
    label: 'Number of students',
    name: 'student',
    placeholder: 'Total students who rated this course',
    rules: [
      {
        message: 'Number of students who rated this course'
      }
    ]
  },
  {
    rows: 5,
    textArea: true,
    label: 'Description',
    name: 'description',
    placeholder: 'Very short and cool about the course',
    rules: [
      {
        required: true,
        message: 'Give your course a description'
      }
    ]
  }
]
