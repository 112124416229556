import React from 'react'
import notebookImage from '../../images/pricing/notebook.png'
import checkIcon from '../../images/pricing/check.svg'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div style={{ background: '#1D2328' }} className='hidden lg:block py-15'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
        <figure className='m-0 p-0 hidden sm:block'>
          <img className='w-full h-full object-cover' src={notebookImage} alt='note book' />
        </figure>
        <div className='row-start-1 md:col-start-2 flex flex-col justify-center items-center md:items-start'>
          <div className='max-w-xl bg-white rounded-md p-10'>
            <div className='grid grid-cols-2 gap-x-5'>
              <div className='flex flex-col justify-center'>
                <div>
                  <h2>
                    <span className='text-4xl text-gray-900 font-bold font-dm-serif-display'>
                      ₦0
                    </span>
                    <sub className='text-gray-500 text-lg'>/week</sub>
                  </h2>
                  <h3
                    className='text-2xl font-bold font-dm-serif-display'
                    style={{ color: '#683838' }}
                  >
                    Trial Period
                  </h3>
                  <p className='text-gray-400 '>
                    Before you make up your mind, here’s a bonus for you.
                  </p>
                </div>
              </div>
              <div className='flex-grow'>
                <ul>
                  <li className='flex py-2'>
                    <figure className='m-0 p-0 mr-3'>
                      <img src={checkIcon} alt='check' />
                    </figure>
                    <h6 className='text-gray-500'>Career Guide - To prepare you for success </h6>
                  </li>
                  <li className='flex py-2'>
                    <figure className='m-0 p-0 mr-3'>
                      <img src={checkIcon} alt='' />
                    </figure>
                    <h6 className='text-gray-500'>Getting started with software dev</h6>
                  </li>
                  <li className='flex py-2'>
                    <figure className='m-0 p-0 mr-3'>
                      <img src={checkIcon} alt='' />
                    </figure>
                    <h6 className='text-gray-500'>A day one on one with any mentor of choice</h6>
                  </li>
                  <li className='flex py-2'>
                    <figure className='m-0 p-0 mr-3'>
                      <img src={checkIcon} alt='' />
                    </figure>
                    <h6 className='text-gray-500'>Free docs and References</h6>
                  </li>
                </ul>
                <Link
                  to={{ pathname: '/book-mentor', state: { type: 'Trial' } }}
                  className='block pl-4 hover:text-white rounded-md py-2 duration-200 hover:bg-opacity-75 bg-btn-primary text-xl text-white w-full mt-5'
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
