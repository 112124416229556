import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// // I have left these items here, I will continue working on this so as to make it more reuseable
const CountDownTimer = ({ date }) => {
  const [{ days, /* hours, minutes, seconds, */ timeUp }, setState] = useState({
    days: 0,
    // hours: 0,
    // minutes: 0,
    // seconds: 0,
    timeUp: false
  })
  const dayString = days > 1 ? 'days' : 'day'

  useEffect(() => {
    setTimeout(() => {
      const eventDate = new Date(date)
      const difference = eventDate - new Date()
      if (difference < 1) {
        setState(prev => ({ ...prev, timeUp: true }))
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((difference / (1000 * 60)) % 60)
        const seconds = Math.floor((difference / 1000) % 60)
        setState(prev => ({
          ...prev,
          hours: hours > 9 ? hours : `0${hours}`,
          minutes: minutes > 9 ? minutes : `0${minutes}`,
          seconds: seconds > 9 ? seconds : `0${seconds}`,
          days
        }))
      }
    }, 1000)
  }, [])

  return (
    <span className='inline '>
      {timeUp ? (
        <span>Ended</span>
      ) : (
        <span>
          {`ends in ${days === 0 ? 1 : days} ${dayString}, `}{' '}
          <Link to='/hiring' className='text-yellow-700'>
            {' '}
            Click here
          </Link>{' '}
          to apply
        </span>
      )}
    </span>
  )
}

CountDownTimer.propTypes = {
  date: PropTypes.string
}

export default CountDownTimer
