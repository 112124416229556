import React, { useState } from 'react'
import Comment from './Comment'
import { LoadingOutlined } from '@ant-design/icons'

const Comments = () => {
  const [comment, setComment] = useState({ data: '', error: '', loading: false })

  const submitCommentHandle = e => {
    if (comment.data === '') {
      setComment({ ...comment, error: 'comment is required!' })
    } else {
      setComment({ ...comment, loading: true })
      setTimeout(() => {
        setComment({ ...comment, data: '', error: '', loading: false })
      }, 1500)
    }
    e.preventDefault()
  }
  return (
    <>
      <form className='px-2 w-full max-w-sm'>
        {comment.error && (
          <small className='text-red-700  text-base ml-4'>**{comment.error}**</small>
        )}
        <div className='flex items-center border-b border-teal-500 py-2'>
          <textarea
            className='resize-y appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none'
            type='text'
            rows='3'
            value={comment.data}
            placeholder='Type your comment here...'
            aria-label='Full name'
            onChange={e => setComment({ ...comment, data: e.target.value })}
          />

          <button
            className='flex-shrink-0 border-transparent disabled border-4 text-brown-500 hover:text-red-900 active:text-gray-800 text-sm font-bold py-1 px-2 rounded'
            type='button'
            onClick={submitCommentHandle}
          >
            {comment.loading ? (
              <>
                <LoadingOutlined /> ADD
              </>
            ) : (
              '+ ADD'
            )}
          </button>
        </div>
      </form>
      <div
        className='bg-teal-lightest border-t-4 border-teal rounded-b text-teal-darkest px-4 py-3 shadow-md mb-1 mt-4'
        role='alert'
      >
        <Comment />
      </div>
    </>
  )
}
export default Comments
