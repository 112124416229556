import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ course, mentor }) => {
  return (
    <header className='bg-gray-900 py-16'>
      <div className='container mx-auto px-5'>
        <div className='md:grid grid-cols-12 gap-10'>
          <div className='col-span-5'>
            <figure className='m-0 p-0'>
              <img
                src='https://static.skillshare.com/uploads/video/thumbnails/63ad0dafb41921278833713049736a52/original'
                className='w-full h-full object-contain'
                alt=''
              />
            </figure>
          </div>
          <div className='col-span-7 mt-5 md:mt-0'>
            <div className='flex flex-col justify-center h-full'>
              <h2 className='text-2xl md:text-3xl text-white font-manrope font-bold'>
                {course?.title}
              </h2>
              <div className='flex items-center mt-5'>
                <figure className='m-0 p-0'>
                  <img
                    src='https://static.skillshare.com/uploads/users/7458116/user-image-small.jpg?1229608683'
                    alt=''
                    className='h-10 w-10 object-cover rounded-full'
                  />
                </figure>
                <h4 className='text-gray-100 text-sm font-medium ml-5 font-manrope'>
                  {mentor?.firstname} {mentor?.lastname}, {mentor?.specialization}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = { course: PropTypes.any, mentor: PropTypes.any }

export default Header
