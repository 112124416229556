import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({ progress }) => {
  return (
    <div className='relative px-3'>
      <div className='flex mb-2 items-center justify-between'>
        <div>
          {progress === '100%' ? (
            <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-green-700'>
              Task completed
            </span>
          ) : (
            <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-brown-400'>
              Task in progress
            </span>
          )}
        </div>
        <div className='text-right'>
          <span
            className={`text-xs font-semibold inline-block ${
              progress === '100%' ? 'text-green-700' : 'text-brown-500'
            }`}
          >
            {progress}
          </span>
        </div>
      </div>
      <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-300'>
        <div
          style={{ width: progress }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
            progress === '100%' ? 'bg-green-700' : 'bg-brown-500'
          }`}
        />
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.any
}
export default ProgressBar
