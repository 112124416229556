const tracks = [
  {
    id: '1',
    icon: 'desktop',
    title: 'Forex/Crypto Trading',
    details:
      'Learn everything you need to know about forex and crypto including investing, technical analysis, risk management, trading psychology and much more ',

    tableData: {
      rows: [
        {
          time: 'Week 1 & 2',
          topic: [
            'Forex and Crypto Introduction(Basics and Introduction)',
            ' ',
            'What is Forex Trading?',
            'What are the the terms and basic concepts in forex?',
            '• Market structure in Forex',
            '• Liquidity and Imbalance in Forex',
            'What is Crypto Trading?',
            'What are the basic terms and concepts in crypto trading?',
            '• Bitcoin and Altcoin',
            '• Defi and Minning',
            '• Crypto and Forex trading Styles (Day trading, Position, Scalping, Swing Trading and Passive trading ; Buy and Hold)',
            'Psychology and mindset in Forex and Crypto trading',
            '• How to develop the right mindset for trading'
          ],
          objective: [
            'To help the student understand the basics and secrets of Forex and crypto trading needed to succeed in the market.'
          ]
        },
        {
          time: 'Week 3 & 4',
          topic: [
            'Forex and Crypto Trading strategies',
            ' ',
            'How to trade the Forex market? (Forex trading concepts)',
            '• Order blocks concept',
            '• Institutional trading',
            'Strategies to trade the forex market',
            '• Price action',
            '• Supply and Demand',
            '• Smart money/wychoff',
            'How to trade the crypto market (Crypto trading methods)',
            '• How to buy bitcoin and other altcoin from binance and other platforms',
            '• How to buy altocins from pankeswap and other exchanges',
            '• How to know when to invest in Bitcoin and other coins'
          ],
          objective: ['To help students learn how to trade the forex and crypto markets']
        },
        {
          time: 'Week 5',
          topic: [
            'Forex and Crypto Trading Strategies Continued.',
            ' ',
            'Forex trading Strategies',
            '• Money Management in Forex Trading',
            '• Risk management in Forex trading',
            '• How to grow small accounts in Trading',
            '• How to properly grow your account',
            'Crypto Trading Strategies',
            '• How to Trade futures and options in crypto',
            '• Fundamental and Technical analysis in Crypto trading'
          ],
          objective: [
            'To help student learn how to manage risks and grow their Forex and Crypto Portfolio'
          ]
        }
      ]
    }
  },
  {
    id: '2',
    icon: 'desktop',
    title: 'Frontend',
    details:
      'Launch your frontend career by learning the necessary language and technology, design fundamentals and how to build interactive web apps',

    tableData: {
      rows: [
        {
          time: 'Week 1 & 2',
          topic: [
            '- Basics of programming and why programming',
            '- Setting up environments { vscode, node using nvm }',
            '- Basic concepts of command lines { ls, cd, ls -a, touch, rm, rm -rf, mkdir, cat, nano, echo, mv, cp, kill, }',
            '- Html, Css and bootstrap',
            '- Basics of UI design with Figma',
            '- JavaScript 1 - Basic js',
            '- Git and github',
            '- Javascript 1 & 2- Promise based js '
          ],
          objective: ['Building foundation for basic web dev tools']
        },
        {
          time: 'Week 3 & 4',
          topic: [
            '- Getting started with Npm, Webpack and babel',
            '- Introduction to UI frameworks like React js, Vue, Angular',
            '- Choose one UI framework, **Recommend React js**',
            '- JSX, Routing, Components, Rendering Lists, Managing States and Props, Redux, Higher-order components',
            'React context',
            'React hooks',
            'React render prop components,'
          ],
          objective: ['Learn and master a frontend framework used in most companies']
        },
        {
          time: 'Week 5',
          topic: [
            '- What is API and making api calls, Environment separation, unit testing in react',
            '- Recap about React js',
            '- Project.'
          ],
          objective: ['Position you to get Junior role in an IT company.']
        }
      ]
    }
  },
  {
    id: '3',
    icon: 'tablet-alt',
    title: 'Digital Marketing',
    details:
      'Boost your digital marketing skills by learning Marketing Strategy, Social Media Maketing, Email Marketing, SEO, Analytics and a lot more',

    tableData: {
      rows: [
        {
          time: 'Week 1 & 2',
          topic: [
            '- Introduction to Digital Marketing',
            '- Market and customer understanding (Market segmentation & Customer personas)',
            '- The 7 Ps: A modern marketing mix',
            '- Understanding the social media marketing process',
            '- Conducting a social media audit',
            '- Assessing profile consistency',
            '- Determining brand identity',
            '- Setting Social Media Objectives',
            '- Social Media marketing strategy'
          ],
          objective: [
            'Build your digital marketing tools and lay the groundwork to plan, create, and measure digital marketing campaigns.',
            'Learn how to build a comprehensive social media marketing strategy, conduct an audit and set objectives.'
          ]
        },
        {
          time: 'Week 3 & 4',
          topic: [
            '- Understanding the psychology behind lead generation',
            '- Lead generation magnets - “bribe-to-subscribe”',
            '- Creating Lead generation/Landing pages',
            '- Creating a Contact Management and Segmentation Strategy',
            '- Email Marketing Softwares',
            '- Creating a High-Performing Email',
            '- Outlining the Design of Your Marketing Emails',
            '- Analyzing Your Marketing Emails',
            '- Developing Relationships With Lead Nurturing',
            '- Creating Automated email campaigns',
            '- The basics of Pay Per Click',
            '- Choosing the right keywords and keyword phrases',
            '- Ad extentions and why they work to improve your PPC performance',
            '- The Basics of Search Engine Optimization',
            '- Keyword research',
            '- On-page SEO',
            '- Link building'
          ],
          objective: [
            "In this lesson, you'll learn how to build an effective email marketing strategy as part of your inbound efforts, the elements of a highperforming email and what you can do to implement them.",
            "You'll also learn how to develop relationships through lead nurturing. Learn the foundations as well as how to start implementing lead nurturing.",
            "-You'll learn to increase your Click Through Rate and DECREASE your Cost per Click.",
            "-You'll also get an understanding of why SEO is important in Google's algorithm and how to build high quality backlinks that rank websites onto Google's first page"
          ]
        },
        {
          time: 'Week 5',
          topic: ['- Summary', '- Quizzes '],
          objective: ['']
        }
      ]
    }
  },
  {
    id: '4',
    icon: 'tools',
    title: 'Backend',
    details:
      'Kickstart your backend career by understanding application architecture and designing, as well as building API’s',

    tableData: {
      rows: [
        {
          time: 'Week 1 & 2',
          topic: [
            'What Is Node.js?',
            'Browser VS Server',
            'How to Install Node',
            'The Node REPL',
            'Cli',
            'Source Code',
            'Globals',
            'Modules Setup',
            'First Module',
            'Alternative Syntax',
            'Mind Grenade',
            'Built-In Module, Intro Os Module',
            'Path Module',
            'Fs Module (Sync)',
            'Fs Module (Async)',
            'Sync Vs Async',
            'Http Intro'
          ],
          objective: [
            'Building foundation for basic nodejs dev tools and understanding how backend services work'
          ]
        },
        {
          time: 'Week 3 & 4',
          topic: [
            'Http Module (Setup)',
            'Http Module (More Features)',
            'NPM Info',
            'NPM Command',
            'First Package',
            'Share Code',
            'Nodemon',
            'Uninstall',
            'Global Install',
            'Package-Lock.Json',
            'Important Topics Intro, Async Patterns - Setup Promises',
            'Async Patterns - Refactor To Async',
            "Async Patterns - Node's Native Option",
            'Events Info',
            'Events Emitter - Code Example',
            'Events Emitter - Additional Info',
            'Events Emitter - Http Module Example',
            'End Of Node Tutorial Module',
            'HTTP Request/Response Cycle',
            'Http Messages',
            'Starter Project Install',
            'Starter Overview',
            'Http Basics',
            'Http - Headers',
            'Http - Request Object',
            'Http - Html File',
            'Http - App Example',
            'Express Info',
            'Express Basics',
            'Express - App Example',
            'Express - All Static'
          ],
          objective: [
            'Gives you access to major things you need to know before building APIs with nodejs'
          ]
        },
        {
          time: 'Week 5',
          topic: [
            'API ',
            'JSON Basics',
            'Params, Query String - Setup',
            'Route Params',
            'Params - Extra Info',
            'Query String',
            'Additional Params And Query String Info',
            'Middleware - Setup',
            'APP.USE',
            'Multiple Middleware Functions',
            'Additional Middleware Info',
            'Methods - GET',
            'Methods - POST',
            'Methods - POST (Form Example)',
            'Methods - POST (Javascript Example)',
            'Install Postman',
            'Methods - PUT',
            'Methods - DELETE',
            'Express Router - Setup',
            'Express Router - Controllers'
          ],
          objective: [
            'Mastering the main pattern and how to use nodejs to develop enterprise backend services'
          ]
        }
      ]
    }
  },
  {
    id: '5',
    icon: 'chart-bar',
    title: 'Data Analysis',
    details:
      'Start your data analysis career by learning the fundamentals of Data Analysis, how to collect data, analyse data and a lot more',

    tableData: {
      rows: [
        {
          time: 'Week 1 & 2',
          topic: [
            'SQL Package and Tools Installation (Dbeaver, PGadmin,Eclipse etc)',
            'What is SQL?',
            'What Can SQL do?',
            'SQL can execute queries against a database',
            'SQL can retrieve data from a database',
            'SQL can insert records in a database',
            'SQL can update records in a database',
            'SQL can delete records from a database',
            ' SQL can create new databases',
            'SQL can create new tables in a database',
            'SQL can create stored procedures in a database',
            'SQL can create views in a database',
            'SQL can set permissions on tables, procedures, and views',
            'Create triggers'
          ],
          objective: ['Learn basic select operations on SQL needed for Business Intelligence']
        },
        {
          time: 'Week 3 & 4',
          topic: [
            'Use of Metabase Full Course ',
            'Install Metabase on work station.',
            '1. Schedule breakfast reports ',
            '2. Create Dashboards and views (filters and dropdowns)',
            '3. Schedule Dashboards subscriptions',
            '4. Forecast and Funnel reports ',
            '5. Admin module of Metabase '
          ],
          objective: [
            'Learn Features and Use of Metabase for your Daily work activity.',
            'Scheduling reports on your personal collection on Metabase.'
          ]
        },
        {
          time: 'Week 5',
          topic: [
            'Use of BIRT Full Course',
            'Install Eclipse on work station.',
            '1. Schedule breakfast reports ',
            '2. Create Reports and Vitualization',
            '3. Embed reports'
          ],
          objective: ['Learn Features and Use of BIRT for your Daily work activity']
        }
      ]
    }
  },
  {
    id: '6',
    icon: 'desktop',
    title: 'UI/UX',
    details:
      'Launch your career in UX&UI design by learning how to build lovable and user-centred Apps from scratch.',

    tableData: {
      rows: [
        {
          time: 'Week 1 & 2',
          topic: [
            "- Nice to meet you. I'm Manuel.",
            '- Introduction to UI/UX',
            '- The design process',
            '- The Figma interface',
            '- Why before How',
            '- Soft skills every designer must have',
            '- Grids and Spacing',
            '- Alignment, Proximity and Cognitive Load',
            '- Colors',
            '- Sketching solutions',
            '- Designing a Simple mobile App'
          ],
          objective: [
            'Foundation of User Interface and User Experience',
            'Deep dive into the core basics of UI designs Shallow dip into UX'
          ]
        },
        {
          time: 'Week 3 & 4',
          topic: [
            '- Continuation of Mobile App design',
            '- Performing Heuristics analysis on the solution',
            '- Introduction to Web design.',
            '- Figma Prototype panels and Trigger.',
            '- Basic Prototyping in -- Figma (Bring your designs to Life)'
          ],
          objective: [
            'Designing a simple mobile application',
            'Give life to your designs, communicate interactions to stake holders and developers.'
          ]
        },
        {
          time: 'Week 5',
          topic: [
            '- Project.',
            "- What's next?",
            '- 10 Amazing figma tips',
            '- Life hack in landing design jobs'
          ],
          objective: ['Progress analysis. Project grading and logging out.']
        }
      ]
    }
  }
]
export default tracks
