import React from 'react'
import whatIsNeeded from '../images/what-is-needed.svg'

const WhatIsNeeded = () => {
  return (
    <div className='bg-gray-50 md:my-15 md:p-15'>
      <div className='flex flex-nowrap mx-auto'>
        <div className='mx-auto w-full lg:w-1/2'>
          <div className='m-5 max-w-5/6 lg:m-24'>
            <h1 className='font-bold text-3xl mb-8'>What is Needed?</h1>
            <ul>
              <li className='flex mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <p className='ml-2 text-lg'>Be a registered member</p>
              </li>
              <li className='flex mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <p className='ml-2 text-lg'>A computer connected to the internet</p>
              </li>
              <li className='flex mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <p className='ml-2 text-lg'>A quiet environment for classes</p>
              </li>
              <li className='flex mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <p className='ml-2 text-lg'>Respect other students by not interrupting the class</p>
              </li>
            </ul>
            <p className='mt-5 text-base'>
              Our team is here to answer these questions and more. We can’t wait to get in touch
              with you and discuss your child academic pathway.
            </p>
          </div>
        </div>
        <div className='hidden lg:block mx-auto w-1/2'>
          <img className='mx-auto' src={whatIsNeeded} alt='arrow' />
        </div>
      </div>
    </div>
  )
}

export default WhatIsNeeded
