import { Modal } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'

const BootcampTracksModal = ({ setModalOpen, modalOpen, tableData }) => {
  return (
    <div>
      <Modal
        title={
          <>
            <button
              onClick={() => setModalOpen({ visible: false, data: null })}
              className='absolute top-4 right-4 outline-none focus:outline-none'
            >
              <i className='fa fa-times text-3xl text-gray-900' />
            </button>
            <h4 className='font-manrope font-bold text-3xl text-center text-gray-800'>
              What to Expect
            </h4>
          </>
        }
        centered
        footer={[]}
        visible={modalOpen}
        closable={false}
        width={1000}
      >
        <div className='w-full flex justify-center items-center flex-col'>
          <div className='flex justify-center border-2 p-0 mb-4'>
            {/* Table for Medium and Big screens */}
            <table
              style={{
                borderCollapse: 'collapse',
                margin: '25px 0',
                fontSize: '0.9em',
                fontFamily: 'sans-serif',
                minWidth: '300px',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)'
              }}
              className='text-base hidden lg:block'
            >
              <thead className='text-lg'>
                <tr
                  style={{
                    backgroundColor: '#009879',
                    color: '#ffffff',
                    textAlign: 'center'
                  }}
                >
                  <th key='head' className='py-3 px-5'>
                    Time
                  </th>
                  <th key='head' className='py-3 px-5'>
                    Topics
                  </th>
                  <th key='head' className='py-3 px-5'>
                    Training Objective
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData.rows.map(data => (
                  <tr key={data.time} className='odd:bg-white even:bg-gray-200'>
                    <td className='p-2 font-bold'>{data.time}</td>
                    <td className='p-2'>
                      {data.topic.map(topics => (
                        <ul key={topics}>
                          <li>{topics}</li>
                        </ul>
                      ))}
                    </td>
                    <td className='p-2'>
                      {data.objective.map(objectives => (
                        <ul key={objectives}>
                          <li>{objectives}</li>
                        </ul>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* To show on Mobile screens */}
            <div className='lg:hidden'>
              {tableData.rows.map(data => (
                <div key={data.time} className='m-4 mb-15'>
                  <div className='text-center text-xl font-bold border-4'>{data.time}</div>
                  <br />
                  <div className='text-lg font-bold my-3 px-2 border-b-2'>Topic</div>
                  {data.topic.map(topics => (
                    <div key={topics} className='py-0.5'>
                      {topics}
                    </div>
                  ))}
                  <div className='text-lg font-bold my-3 px-2 border-b-2'>Training Objective</div>
                  {data.objective.map(objectives => (
                    <div className='py-0.5' key={objectives}>
                      {objectives}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={() => setModalOpen({ visible: false, data: null })}
            className='py-3 px-6 rounded text-white text-lg font-semibold m-auto bottom-2 outline-none focus:outline-none bg-red-700 hover:bg-red-800 focus-within:bg-red-900'
          >
            Register
          </button>
        </div>
      </Modal>
    </div>
  )
}
BootcampTracksModal.propTypes = {
  setModalOpen: PropTypes.func,
  modalOpen: PropTypes.bool,
  tableData: PropTypes.object
}

export default BootcampTracksModal
