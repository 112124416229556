/* eslint-disable indent */
import { notification } from 'antd'
import { graphqlOperation, API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import AboutClass from '../components/single-course/AboutClass'
import AboutMentor from '../components/single-course/AboutMentor'
import Header from '../components/single-course/Header'
import WatchCourse from '../components/single-course/WatchCourse'
import { getCourse, getMentor } from '../graphql/queries'

const SingleCourse = () => {
  const [course, setCourse] = useState({})
  const [mentor, setMentor] = useState({})
  const { id } = useParams()
  const [loading, setLoading] = useState(true)

  const fetchCourse = async () => {
    try {
      const res = await API.graphql(
        graphqlOperation(getCourse, {
          id
        })
      )
      setCourse(res.data?.getCourse)
      if (res.data?.getCourse) {
        const res2 = await API.graphql(
          graphqlOperation(getMentor, {
            id: res.data.getCourse.mentorId
          })
        )
        setMentor(res2.data?.getMentor)
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: error.errors ? error.errors[0].message : 'Something went wrong!'
      })
    }
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  return (
    <div>
      <Helmet>
        <meta name='keywords' content='Mentortribes courses, courses, professional mentors' />
        <meta
          name='description'
          content='We have as our Mission providing you with access to a personal mentor'
        />
        <title>Mentortribes | Course</title>
      </Helmet>
      {loading ? (
        <div className='h-screen grid place-content-center'>
          <i className='fa fa-spin fa-spinner text-xl' />
        </div>
      ) : (course && !!Object.keys(course).length) ||
        (mentor && !!Object.keys(mentor).length) ||
        (mentor && mentor.status === 'ACCEPTED') ? (
        <div>
          <Header course={course} mentor={mentor} />
          <WatchCourse />
          <AboutClass course={course} mentor={mentor} />
          <AboutMentor course={course} mentor={mentor} />
        </div>
      ) : (
        <div className='h-screen grid place-content-center'>
          <h1 className='text-3xl font-manrope text-primary'>404, Oops not Found</h1>
          <h1 className='text-3xl font-manrope font-semibold text-primary'>
            Course does not exist
          </h1>
          <Link
            to='/'
            className='bg-primary text-white py-1.5 text-sm block w-full max-w-max px-5 mx-auto mt-2 hover:bg-opacity-50 duration-200 hover:text-white rounded-md'
          >
            <i className='fa fa-home mr-3' />
            Go Home
          </Link>
        </div>
      )}
    </div>
  )
}

export default SingleCourse
