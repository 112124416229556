import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AreaOfSpecizatiion from '../components/AreaOfSpecizatiion'
import { notification } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from '../services/axios'
import ConfirmAccount from '../components/auth/ConfirmAccount'
import loginImg from '../images/login.png'
import StateCapital from '../components/StateCapital'
import { createMentor } from '../graphql/mutations'
import checkedIcon from '../images/checked.svg'
import Input from '../components/auth/Input'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

const ApplyMentorSchema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
  phone: Yup.string().required('Phone No is required'),
  projects: Yup.string().required('Project links are required'),
  country: Yup.string().required('Country is required'),
  specialization: Yup.string().required('Specialization is required'),
  github: Yup.string().required('Github Link is required'),
  yrsOfexperience: Yup.string().required('Years of Experience is required')
})

const AddMentor = () => {
  const [isSubmmitting, setSubmitting] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [username] = useState('')
  const [password] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const history = useHistory()
  const isLogin = history.location.state?.isLogin

  useEffect(() => {
    if (!history.location.state?.subId && !history.location.state?.email) {
      history.push('/signup')
    }
  }, [history])

  return (
    <div>
      <Helmet>
        <meta name='keywords' content='register as a mentor, become a mentor, sign up mentor' />
        <meta
          name='description'
          content='Mentortribes connects you to Mentees looking for Mentors from all over the World'
        />
        <title>Mentortribes | Sign-up</title>
      </Helmet>
      {isSubmitted ? (
        <div className='flex flex-col justify-center items-center' style={{ height: '75vh' }}>
          <div
            style={{ maxWidth: '361px' }}
            className='bg-white py-10 shadow-lg mx-auto w-full rounded-lg'
          >
            <div className='flex py-5 justify-center'>
              <figure className='w-24 h-24'>
                <img className='w-full h-full object-cover' src={checkedIcon} alt='checked' />
              </figure>
            </div>
            <div className='px-5 text-center'>
              <h3 style={{ color: '#091E42' }} className='text-lg  font-semibold'>
                Mentorship Account Created
              </h3>
              <p className='pt-2 text-base'>
                Your Account has been created succesfully and is under review
              </p>
              <p className='text-base py-2'>You will be notified if you application is accepted.</p>
              <Link
                onClick={() =>
                  notification.success({
                    message: 'You have created your mentorship, now login to get to your dashboard',
                    duration: 5000
                  })
                }
                to={isLogin ? '/mentor-dashboard' : '/login'}
                className='text-blue-600'
              >
                {isLogin ? <span>Go to Dashboard</span> : <span>Go to Login</span>}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-wrap'>
          <div className='hidden xl:flex  items-center w-full md:w-1/2'>
            <img
              src={loginImg}
              alt='connect people'
              className='bg-opacity-50 w-full transition duration-500 ease-in-out'
            />
          </div>
          <div className='w-full xl:w-1/2 md:px-10'>
            <ConfirmAccount
              password={password}
              isLogin
              isVisible={isVisible}
              setVisible={setVisible}
              setLoginSubmitting={setSubmitting}
              username={username}
            />

            <div className='mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto text-gray-900 rounded-lg'>
              <div className='py-4 px-4 sm:px-8 text-gray-600 text-xl border-b border-gray-300'>
                <UserAddOutlined /> &nbsp;Create mentorship account
              </div>
              <Formik
                validationSchema={ApplyMentorSchema}
                initialValues={{
                  firstname: '',
                  lastname: '',
                  phone: '',
                  projects: '',
                  country: '',
                  specialization: '',
                  github: '',
                  yrsOfexperience: '',
                  status: 'REVIEW'
                }}
                onSubmit={async values => {
                  setSubmitting(true)
                  try {
                    const data = await API.graphql(
                      graphqlOperation(createMentor, {
                        input: {
                          ...values,
                          userSubId: history.location.state?.subId,
                          email: history.location.state?.email,
                          isActive: false,
                          isAdmin: false
                        }
                      })
                    )
                    if (data?.errors) throw Error(data?.errors[0]?.message)
                    else {
                      const email = history.location.state?.email
                      history.replace({ ...history.location, state: {} })
                      notification.success({
                        message: 'Mentor Created',
                        description: 'You are now a mentor!'
                      })
                      setSubmitting(false)
                      setSubmitted(true)
                      await axios.post('/Eazy2con/register-success', {
                        name: `${values.firstname} ${values.lastname}`,
                        email: email
                      })
                    }
                  } catch (err) {
                    setSubmitting(false)
                    notification.error({
                      message: err?.errors[0]?.message || 'Something went wrong!',
                      duration: 10000
                    })
                  }
                }}
              >
                {props => (
                  <Form>
                    <div className='py-2 px-0 md:px-2 lg:px-4'>
                      <div className='sm:flex'>
                        <div className='w-full sm:w-1/2 sm:mr-1'>
                          <Input name='firstname' id='firstname' label='First Name' noMargin />
                        </div>
                        <div className='w-full mt-4 sm:mt-0 sm:w-1/2 sm:ml-1'>
                          <Input name='lastname' noMargin id='lastname' label='Last Name' />
                        </div>
                      </div>
                      <div className='w-full'>
                        <Input name='phone' id='phone' label='Phone' />
                      </div>
                      <div className='mt-4'>
                        <label htmlFor='projects' className='text-sm text-black'>
                          Link to Projects
                        </label>
                        <textarea
                          name='projects'
                          id='projects'
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.projects}
                          placeholder='Link to 3 of your most amazing project'
                          className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                        />
                        {props.errors.projects && props.touched.projects && (
                          <small className='text-red-500'>{props.errors.projects}</small>
                        )}
                      </div>
                      <div className='w-full mt-2 mb-3 '>
                        <label htmlFor='country' className='text-sm text-black'>
                          Country
                        </label>
                        <div className='relative'>
                          <select
                            className='block appearance-none w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                            name='country'
                            id='country'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.country}
                          >
                            <StateCapital />
                          </select>
                          {props.errors.country && props.touched.country && (
                            <small className='text-red-500'>{props.errors.country}</small>
                          )}
                          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                            <svg
                              className='fill-current h-4 w-4'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                            >
                              <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className='w-full mt-2 mb-4 '>
                        <label htmlFor='spec' className='text-sm text-black'>
                          Specification
                        </label>
                        <div className='relative'>
                          <select
                            className='block appearance-none w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                            name='specialization'
                            id='spec'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.specialization}
                          >
                            <AreaOfSpecizatiion />
                          </select>
                          {props.errors.specialization && props.touched.specialization && (
                            <small className='text-red-500'>{props.errors.specialization}</small>
                          )}
                          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                            <svg
                              className='fill-current h-4 w-4'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                            >
                              <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className='sm:flex'>
                        <div className='sm:w-1/2 sm:mr-1'>
                          <Input name='github' label='Github Link' noMargin id='github' />
                        </div>
                        <div className='sm:w-1/2 sm:ml-1 mt-4 sm:mt-0'>
                          <label htmlFor='yrsOfexperience' className='text-sm text-black'>
                            Years of Experience
                          </label>
                          <div className='relative'>
                            <select
                              className='block appearance-none w-full text-gray-900 bg-gray-300 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                              name='yrsOfexperience'
                              id='yrsOfexperience'
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.yrsOfexperience}
                            >
                              <option value=''>- Experience -</option>
                              <option value='0 - 1yr'>0 - 1yr</option>
                              <option value='1 - 2yrs'>1 - 2yrs</option>
                              <option value='2 - 3yrs'>2 - 3yrs</option>
                              <option value='3 - 5yrs'>3 - 5yrs</option>
                              <option value='5 - 10yrs'>5 - 10yrs</option>
                              <option value='10+yrs'>10 yrs plus</option>
                            </select>
                            {props.errors.yrsOfexperience && props.touched.yrsOfexperience && (
                              <small className='text-red-500'>{props.errors.yrsOfexperience}</small>
                            )}
                            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                              <svg
                                className='fill-current h-4 w-4'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 20 20'
                              >
                                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex items-center justify-between mt-7'>
                        <button
                          type='submit'
                          // disabled={!(user.name && user.email && user.message)}
                          className='py-3 font-bold bg-btn-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                        >
                          {isSubmmitting && (
                            <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                          )}
                          + Create Now
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

AddMentor.propTypes = {
  values: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
}

export default AddMentor
