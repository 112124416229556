import React from 'react'

const WatchCourse = () => {
  return (
    <div className='bg-teal-900 py-10'>
      <div className='container mx-auto px-5'>
        <h4 className='text-white text-2xl font-manrope font-extrabold'>
          What you will gain from this course
        </h4>
        <div className='grid md:grid-cols-3 gap-14 mt-10'>
          <div>
            <div className='bg-green-400 h-1 w-13 mb-4 rounded-lg' />
            <h4 className='text-white text-lg font-medium font-manrope'>
              Get unlimited access to a community.
            </h4>
          </div>
          <div>
            <div className='bg-green-400 h-1 w-13 mb-4 rounded-lg' />
            <h4 className='text-white text-lg font-medium font-manrope'>
              Get a Mentor to teach you and help you anytime you have issues
            </h4>
          </div>
          <div>
            <div className='bg-green-400 h-1 w-13 mb-4 rounded-lg' />
            <h4 className='text-white text-lg font-medium font-manrope'>
              Learn from the Industrial&apos;s Top 10%
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WatchCourse
