import React, { useState } from 'react'
import StudentModal from './StudentModal'

const Mentees = () => {
  const [open, setOpen] = useState(false)
  const [currentStudent, setCurrentStudent] = useState({})

  const student = {
    fullName: 'Santos Bright',
    courseName: 'Web dev(django)',
    email: 'santosbright16@gmail.com',
    phone: '0903838383838',
    createdAt: new Date(),
    github: 'github.com/SantosBright',
    location: 'Abuja, Nigeria'
  }

  const handleOpen = studentRecord => {
    setOpen(true)
    // setCurrentStudent(studentRecord);
    setCurrentStudent(student)
  }

  return (
    <div id='mentees' className='py-5 mt-5'>
      <h4 className='text-lg font-manrope border-b border-gray-300 pb-2'>Mentees</h4>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mt-10'>
        <div className='grid place-content-center bg-white rounded-2xl py-5 shadow'>
          <figure className='m-0 p-0 grid place-content-center mx-auto'>
            <img className='h-20 w-20' src='/user.svg' alt='user' />
          </figure>
          <h3 className='text-base mt-2 py-1 font-manrope font-bold text-center'>Santos Bright</h3>
          <h6 className='font-manrope text-center text-sm text-gray-600'>
            Web development(django)
          </h6>
          <h6 className='font-manrope text-center py-1 text-sm text-gray-600'>7 - 12 years</h6>
          <button
            onClick={handleOpen}
            className='text-xs mt-2 font-manrope px-5 py-2 block w-full max-w-max mx-auto text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'
          >
            View Profile
          </button>
        </div>
        <div className='grid place-content-center bg-white rounded-2xl py-5 shadow'>
          <figure className='m-0 p-0 grid place-content-center mx-auto'>
            <img className='h-20 w-20' src='/user.svg' alt='user' />
          </figure>
          <h3 className='text-base mt-2 py-1 font-manrope font-bold text-center'>Santos Bright</h3>
          <h6 className='font-manrope text-center text-sm text-gray-600'>
            Web development(django)
          </h6>
          <h6 className='font-manrope text-center py-1 text-sm text-gray-600'>7 - 12 years</h6>
          <button className='text-xs mt-2 font-manrope px-5 py-2 block w-full max-w-max mx-auto text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'>
            View Profile
          </button>
        </div>
        <div className='grid place-content-center bg-white rounded-2xl py-5 shadow'>
          <figure className='m-0 p-0 grid place-content-center mx-auto'>
            <img className='h-20 w-20' src='/user.svg' alt='user' />
          </figure>
          <h3 className='text-base mt-2 py-1 font-manrope font-bold text-center'>Obim thelma</h3>
          <h6 className='font-manrope text-center text-sm text-gray-600'>UI UX(Adobe XD)</h6>
          <h6 className='font-manrope text-center py-1 text-sm text-gray-600'>Adult</h6>
          <button className='text-xs mt-2 font-manrope px-5 py-2 block w-full max-w-max mx-auto text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'>
            View Profile
          </button>
        </div>
        <div className='grid place-content-center bg-white rounded-2xl py-5 shadow'>
          <figure className='m-0 p-0 grid place-content-center mx-auto'>
            <img className='h-20 w-20' src='/user.svg' alt='user' />
          </figure>
          <h3 className='text-base mt-2 py-1 font-manrope font-bold text-center'>Obim thelma</h3>
          <h6 className='font-manrope text-center text-sm text-gray-600'>UI UX(Adobe XD)</h6>
          <h6 className='font-manrope text-center py-1 text-sm text-gray-600'>Adult</h6>
          <button className='text-xs mt-2 font-manrope px-5 py-2 block w-full max-w-max mx-auto text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'>
            View Profile
          </button>
        </div>
        <div className='grid place-content-center bg-white rounded-2xl py-5 shadow'>
          <figure className='m-0 p-0 grid place-content-center mx-auto'>
            <img className='h-20 w-20' src='/user.svg' alt='user' />
          </figure>
          <h3 className='text-base mt-2 py-1 font-manrope font-bold text-center'>Obim thelma</h3>
          <h6 className='font-manrope text-center text-sm text-gray-600'>UI UX(Adobe XD)</h6>
          <h6 className='font-manrope text-center py-1 text-sm text-gray-600'>Adult</h6>
          <button className='text-xs mt-2 font-manrope px-5 py-2 block w-full max-w-max mx-auto text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'>
            View Profile
          </button>
        </div>
        <div className='grid place-content-center bg-white rounded-2xl py-5 shadow'>
          <figure className='m-0 p-0 grid place-content-center mx-auto'>
            <img className='h-20 w-20' src='/user.svg' alt='user' />
          </figure>
          <h3 className='text-base mt-2 py-1 font-manrope font-bold text-center'>Obim thelma</h3>
          <h6 className='font-manrope text-center text-sm text-gray-600'>UI UX(Adobe XD)</h6>
          <h6 className='font-manrope text-center py-1 text-sm text-gray-600'>Adult</h6>
          <button className='text-xs mt-2 font-manrope px-5 py-2 block w-full max-w-max mx-auto text-white bg-btn-primary duration-200 hover:bg-opacity-75 outline-none focus:outline-none cursor-pointer'>
            View Profile
          </button>
        </div>
      </div>
      <StudentModal visible={open} setVisible={setOpen} selectedMentee={currentStudent} />
    </div>
  )
}

export default Mentees
