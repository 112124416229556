import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

/**
 * This is a custom button, it can navigate with url when given a path
 * @param {Function} onClick - optional - This is your normal onclick event handler
 * @param {String} text - Required - This is the text to display for the button
 * @param {String} className - optional - This is your normal className
 * @param {String} navigateByUrl - optional - This is the url to navigate to
 */
const Eazy2conBtn = ({ onClick, text, className, navigateByUrl, ...rest }) => {
  const history = useHistory()

  const navigation = () => {
    history.push(navigateByUrl)
  }

  return (
    <button
      {...rest}
      onClick={navigateByUrl ? navigation : onClick}
      className={`${
        className || ''
      }  block md:inline-block mx-auto md:mx-1 text-base outline-none px-8 py-3 font-bold rounded text-white hover:text-white hover:bg-red-900 bg-btn-primary mt-4 `}
    >
      {text}
    </button>
  )
}

Eazy2conBtn.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  navigateByUrl: PropTypes.string
}

export default Eazy2conBtn
