import { Button, notification } from 'antd'
import React, { useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import contactImage from '../images/computer.svg'
import { createContactUs } from '../graphql/mutations'
import { Helmet } from 'react-helmet-async'

const ContactUs = () => {
  const [contact, setContact] = useState({ name: '', email: '', message: '' })
  const [loading, setLoading] = useState(false)

  const onChangeText = e => {
    setContact({ ...contact, [e.target.name]: e.target.value })
  }

  const submitFormHandle = async event => {
    event.preventDefault()
    if (contact.message.length < 20 || !contact.email.includes('@')) {
      return notification.error({
        message: 'filled the inputs very well, write a valid message!!!'
      })
    }
    try {
      setLoading(true)
      await API.graphql(
        graphqlOperation(createContactUs, {
          input: {
            fullName: contact.name,
            email: contact.email,
            message: contact.message
          }
        })
      )

      const result = await window.fetch('https://mail-servies.herokuapp.com/eazy2con/contact', {
        body: JSON.stringify(contact),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (result.ok) {
        notification.success({
          message: 'THANKS FOR CONTACTING US, WE WILL GET BACK TO YOU AS SOON AS POSSIBLE'
        })
      }

      if (result.status > 300) {
        notification.info({
          message: 'ERROR OCCURED, PLEASE TRY AGAIN'
        })
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      notification.error({
        message: 'AN ERROR OCCURED, PLEASE TRY AGAIN'
      })
    }
  }

  return (
    <>
      <Helmet>
        <meta name='keywords' content='Contact Mentortribes, reach mentortribes' />
        <meta name='description' content='Reach our customer care representative easily.' />
        <title>Mentortribes | Contact Us</title>
      </Helmet>
      <div className='max-w-screen-xl mt-2 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg'>
        <div className='flex flex-col justify-between'>
          <div>
            <h2 className='text-4xl lg:text-5xl font-bold leading-tight'>Contact Us Today!</h2>
            <div className='text-gray-700 mt-8'>
              Hate forms? Send us an{' '}
              <a href='mailto:contact@mentortribes.com' className='underline text-black'>
                email
              </a>{' '}
              instead.
            </div>
          </div>
          <div>
            <img src={contactImage} alt='contact-us' />
          </div>
        </div>
        <form className='' onSubmit={submitFormHandle}>
          <div>
            <span className='uppercase text-sm text-gray-600 font-bold'>Full Name</span>
            <input
              className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
              type='text'
              value={contact.name}
              onChange={onChangeText}
              name='name'
            />
          </div>
          <div className='mt-8'>
            <span className='uppercase text-sm text-gray-600 font-bold'>Email</span>
            <input
              className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
              type='email'
              required
              value={contact.email}
              onChange={onChangeText}
              name='email'
            />
          </div>
          <div className='mt-8'>
            <span className='uppercase text-sm text-gray-600 font-bold'>Message</span>
            <textarea
              className='w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
              onChange={onChangeText}
              value={contact.message}
              minLength='11'
              name='message'
            />
          </div>
          <div className='mt-8'>
            <Button
              loading={loading}
              htmlType='submit'
              disabled={!(contact.name && contact.email && contact.message)}
              className={`h-10 uppercase text-sm font-bold tracking-wide bg-gray-900 text-gray-100  rounded-lg w-full focus:outline-none focus:shadow-outline opacity-50${
                !(contact.name && contact.email && contact.message) ? ' cursor-not-allowed ' : ' '
              }`}
            >
              Send Message
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ContactUs
