import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'

const CourseDescInput = ({ label, value, handleChange, ref, id, noMargin }) => {
  return (
    <div className={`${!noMargin && 'mt-3'}`}>
      <label
        htmlFor={id}
        ref={ref}
        className='text-sm font-manrope mb-2 block font-semibold text-black'
      >
        {label}
      </label>
      <ReactQuill
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'mention'
        ]}
        modules={{
          toolbar: [
            [{ header: [false] }],
            ['bold', 'italic', 'underline', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link']
          ]
        }}
        defaultValue={value}
        onChange={handleChange}
      />
      {!value && (
        <small className='text-red-500 text-xs'>
          Please enter a describe for the course you offer
        </small>
      )}
    </div>
  )
}
CourseDescInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  ref: PropTypes.any,
  id: PropTypes.string,
  noMargin: PropTypes.string
}

export default CourseDescInput
