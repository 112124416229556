export const PRICISING_PLANS = [
  {
    id: 1,
    type: 'Personal',
    title: 'Get direct 1-1 mentorship from experienced mentor',
    price: '49k - 75k',
    color: 'bg-yellow-700',
    margin: 'mt-20',
    listOfPerks: [
      'Career guide',
      'Expose the concept of IT',
      '2Hrs daily one on one within your chosen goal',
      'You determine the time that works for you',
      'Direct one-on-one access to our mentors '
    ]
  },
  {
    id: 2,
    price: '35k',
    type: 'Team',
    title: 'Get mentorship from experienced mentor with other mentees',
    color: 'bg-yellow-400',
    margin: 'mt-15',
    listOfPerks: [
      'Career guide',
      'Expose the concept of IT ',
      '2Hrs daily one on one within your chosen goal',
      'Mentor determines the time for daily meetings',
      'Learn with other mentees',
      'Project evaluation'
    ]
  },
  {
    id: 3,
    title: 'Get direct 1-1 mentorship from experienced mentors uptill first job',
    price: '99k- 299k',
    type: 'Premium',
    color: 'bg-green-400',
    margin: 'mt-32',
    listOfPerks: [
      'Career guide',
      'Expose the concept of IT',
      '2Hrs daily one on one within your chosen goal',
      'Hands-on Projects ',
      'Internship opportunity'
    ]
  }
]
