import React from 'react'
import learning from '../images/learning.png'
import MentortribesBtn from '../sharedComponents/MentortribesBtn'

const ExpandKnowledge = () => {
  return (
    <div className='bg-black pt-14 px-2 md:px-0'>
      <div className='grid md:grid-cols-2 grid-cols-1 bg-black gap-0 px-2 md:px-0'>
        <div className='block md:hidden w-full md:w-1/2 md:mx-24'>
          <img src={learning} alt='connecting users' />
        </div>
        <div className='w-ful md:px-16 pt-10 bg-no-repeat bg-img-2  pb-4'>
          <h1 className='font-sans px-5 md:px-0 font-bold text-center md:text-left text-dark text-2xl lg:text-4xl md:text-2xl'>
            Learn That New Skill, Launch That Project, Start Your Dream Career
          </h1>
          <p className='text-lg text-dark py-4 md:py-4 text-justify md:leading-8 lg:leading-9 px-2 md:px-0'>
            The road to career success has never been easy. But imagine how much easier it would be
            if you have an experienced professional available anytime to offer advice and guidance.
            Your mentor will offer guidance where they see opportunity and even push you to do
            amazing things you probably thought you couldn’t. Work with leading tech mentors and
            gain access to personalized guidance to reach your potential
          </p>
          <MentortribesBtn navigateByUrl='/pricing' text='Find a mentor today' />
        </div>
        <img src={learning} alt='connecting users' className='w-full hidden md:block' />
      </div>
    </div>
  )
}

export default ExpandKnowledge
