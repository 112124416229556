import React, { useState, useEffect } from 'react'
import { Modal, message, Form, notification } from 'antd'
import BookMentorsForm from '../../components/BookMentorsForm'
import { API, graphqlOperation } from 'aws-amplify'
import { useHistory, useLocation } from 'react-router-dom'
import { createBookAmentor } from '../../graphql/mutations'
import axios from '../../services/axios'
import PropTypes from 'prop-types'

const MEMBERSHIP_PLANS = [
  { name: 'Trial', price: '0' },
  { name: 'Team', price: '35k' },
  { name: 'Personal', price: '49k - 75k' },
  { name: 'Premium', price: '99k - 299k' }
]

const BookMentor = ({ isBookFormOpen, setBookFormOpen, mentor }) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { state } = useLocation()
  const [currentPlan, setCurrentPlan] = useState({})
  const [whatToLearn, setWhatToLearn] = useState('')
  const [{ success, loading, errors }, setState] = useState({
    success: false,
    loading: false,
    errors: ''
  })

  const handleSubmit = async values => {
    const menteeInfo = {
      ...values,
      startEndDate: values.startEndDate.duration
    }
    setState(prev => ({ ...prev, loading: true }))
    try {
      const res = await API.graphql(graphqlOperation(createBookAmentor, { input: menteeInfo }))
      if (res.data.createBookAmentor.id) {
        await axios.post('/Eazy2con/book-single-mentor-success', {
          mentor,
          mentee: {
            fullName: menteeInfo.fullName,
            email: menteeInfo.email,
            phone: menteeInfo.phone,
            plan: menteeInfo.type,
            date: `${new Date(menteeInfo.startEndDate.start).toDateString()} - ${new Date(
              menteeInfo.startEndDate.end
            ).toDateString()}`,
            courseInterest: menteeInfo.course
          }
        })
        setState(prev => ({ ...prev, success: true, loading: false }))
        setBookFormOpen(false)
      } else if (res?.errors.length > 0) throw Error(res.errors[0].message)
    } catch (error) {
      setState({ error: true, success: false, loading: false })
      notification.error({
        message: error.errors
          ? error.errors[0].message
          : 'Something went wrong, please your Network connection',
        duration: 5000
      })
    }
  }

  useEffect(() => {
    if (success) {
      // message.success('We have received your request and will contact you soon', 9)
      history.push('/success', { name: form.getFieldValue('fullName') })
    }
    if (errors) {
      message.error('A problem occured, can you check your network connection ')
    }
    return () => {}
  }, [success, errors])

  useEffect(() => {
    if (state && state.type) {
      const currentPlan = MEMBERSHIP_PLANS.find(({ name }) => name === state.type)
      setCurrentPlan(currentPlan)
    } else {
      setCurrentPlan(MEMBERSHIP_PLANS[2])
    }
  }, [state, currentPlan])

  return (
    <Modal
      title={
        <div className='flex items-center justify-between'>
          <h4 className='font-manrope font-semibold text-base text-gray-900'>
            Book {mentor.firstname} {mentor.lastname} as a mentor
          </h4>
          <button
            onClick={() => setBookFormOpen(false)}
            className='outline-none focus:outline-none'
          >
            <i className='fa fa-times' />
          </button>
        </div>
      }
      centered
      footer={[]}
      visible={isBookFormOpen}
      closable={false}
      width={700}
    >
      <BookMentorsForm
        handleSubmit={handleSubmit}
        loading={loading}
        whatToLearn={whatToLearn}
        form={form}
        currentPlan={currentPlan}
        setWhatToLearn={setWhatToLearn}
      />
    </Modal>
  )
}
BookMentor.propTypes = {
  isBookFormOpen: PropTypes.bool,
  setBookFormOpen: PropTypes.any,
  mentor: PropTypes.any
}

export default BookMentor
