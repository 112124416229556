import React, { useState, useRef, useContext } from 'react'
import { Storage, API, graphqlOperation } from 'aws-amplify'
import { Form, Formik } from 'formik'
import { createCourse } from '../../graphql/mutations'
import * as Yup from 'yup'
import Input from '../../components/auth/Input'
import { notification } from 'antd'
import CourseDescInput from '../fields/CourseDescInput'
import { userContext } from '../../context/UserContext'
import { useHistory } from 'react-router'

const AddCourse = () => {
  const [image, setImage] = useState({ error: '', file: '' })
  const [video, setVideo] = useState({ error: '', file: '' })
  const { userInfo, setUserInfo } = useContext(userContext)
  const [description, setDescription] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const history = useHistory()
  const descriptionRef = useRef()

  const handleAddCourse = async (values, image, video) => {
    setSubmitting(true)
    try {
      const imageUrl = await Storage.put(
        `${Math.floor(Date.now() * Math.random())}-${image.name}`,
        image
      )
      let videoUrl = ''
      if (video.file) {
        videoUrl = await Storage.put(
          `${Math.floor(Date.now() * Math.random())}-${video.name}`,
          video
        )
      }
      const data = await API.graphql(
        graphqlOperation(createCourse, {
          input: {
            ...values,
            mentorId: userInfo.data.id,
            image: imageUrl.key,
            video: videoUrl.key
          }
        })
      )
      if (data?.errors) throw Error(data?.errors[0]?.message)
      else {
        notification.success({
          message: 'Mentor Created',
          description: 'You are now a mentor!'
        })
        setSubmitting(false)
        setUserInfo(prev => ({
          ...prev,
          data: {
            ...prev.data,
            courses: {
              items: [...userInfo.data.courses.items, data.data.createCourse]
            }
          }
        }))
        history.push('/mentor-dashboard')
      }
    } catch (err) {
      setSubmitting(false)
      notification.error({
        message: err?.errors[0]?.message || 'Something went wrong!',
        duration: 10000
      })
    }
  }

  return (
    <div className='max-w-xl bg-white py-6 shadow mx-auto px-5'>
      <Formik
        initialValues={{
          title: '',
          description: ''
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().trim().required('Please enter a course title')
        })}
        onSubmit={async (values, { resetForm }) => {
          if (!image.file) {
            setImage({ error: 'Please upload an image', file: '' })
          }
          if (!description) {
            descriptionRef.current.scrollIntoView()
          } else {
            values.description = description
            if (!video.error && !image.error && image.file) {
              await handleAddCourse(values, image.file, video.file)
              resetForm()
              setImage({ error: '', file: '' })
              setVideo({ error: '', file: '' })
            }
          }
        }}
      >
        <Form>
          <Input label='Course Title' id='title' name='title' noMargin />
          <CourseDescInput
            value={description}
            ref={descriptionRef}
            handleChange={value => setDescription(value)}
            name='description'
            label='Description'
            id='description'
            rows={4}
          />
          <label htmlFor='' />
          <div>
            <input
              onChange={e => {
                const file = e.target.files[0]
                setImage({ error: '', file })
                if (file.size / 1024 > 1024 * 3) {
                  setImage(prev => ({
                    ...prev,
                    error: 'Image must 3mb or less'
                  }))
                }
              }}
              type='file'
              accept='image/png, image/gif, image/jpeg'
              id='image'
              className='hidden'
              name='image'
            />
            <label
              htmlFor='image'
              className={`bg-gray-200 h-20 rounded cursor-pointer w-full mt-5 ${
                image.file ? 'block' : 'grid place-content-center'
              }`}
            >
              {image.file ? (
                <figure className='m-0 p-0 relative group h-full w-full'>
                  <img
                    className='h-full w-full object-cover'
                    src={URL.createObjectURL(image.file)}
                    alt=''
                  />
                  <div className='absolute inset-0 bg-black bg-opacity-75 sm:opacity-0 grid place-content-center hover:opacity-100 duration-200'>
                    <div className='flex font-manrope items-center text-white'>
                      <i className='fa fa-exchange-alt mr-3' /> Change Course Image
                    </div>
                  </div>
                </figure>
              ) : (
                <div className='flex text-gray-800 font-manrope items-center'>
                  <i className='fa fa-plus mr-3' /> Add Course Image
                </div>
              )}
            </label>
            <small className='block mt-1 text-red-700 font-manrope'>{image.error}</small>
          </div>
          <div>
            <input
              onChange={e => {
                if (e.target.files.length) {
                  const file = e.target.files[0]
                  setVideoUrl(URL.createObjectURL(file))
                  setVideo({ error: '', file })
                  if (file.size / 1024 > 1024 * 50) {
                    setVideo(prev => ({
                      ...prev,
                      error: 'Video must 50mb or less'
                    }))
                  }
                }
              }}
              type='file'
              id='video'
              accept='video/mp4,video/x-m4v,video/*'
              className='hidden'
              name='video'
            />
            <label
              htmlFor='video'
              className={`bg-gray-200 h-52 rounded cursor-pointer w-full mt-5 ${
                video.file ? 'block' : 'grid place-content-center'
              }`}
            >
              {video.file ? (
                <div className='m-0 p-0 relative group h-full w-full'>
                  <video
                    autoPlay
                    style={{ height: '100%', width: '100%' }}
                    loop
                    id='myVideo'
                    src={videoUrl}
                  />
                  <div className='absolute inset-0 bg-black bg-opacity-75 sm:opacity-0 grid place-content-center hover:opacity-100 duration-200'>
                    <div className='flex font-manrope justify-center items-center text-white'>
                      <i className='fa fa-exchange-alt mr-3' /> Change Course Video
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='flex text-gray-800 font-manrope justify-center items-center'>
                    <i className='fa fa-plus mr-3' /> Add Course Intro Video(Optional)
                  </div>
                  <p className='text-gray-800 font-manrope text-center'>
                    Just a simple recording explaining what you teach in the course, make sure video
                    is attractive and professional
                  </p>
                </>
              )}
            </label>
            <small className='block mt-1 text-red-700 font-manrope'>{video.error}</small>
          </div>

          <div className='flex justify-end'>
            <button
              type='button'
              onClick={() => {
                setImage({ error: '', file: '' })
                setVideo({ error: '', file: '' })
                setVideoUrl('')
                history.push('/mentor-dashboard')
              }}
              className='border border-red-700 focus:outline-none px-5 mt-5 block max-w-max py-1 mr-5 text-red-700 rounded shadow-xl'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='bg-red-700 focus:outline-none px-5 mt-5 block max-w-max py-1 text-white rounded shadow-xl'
            >
              {isSubmitting && <i className='fa fa-spin fa-spinner mr-3' />}
              Add Course
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default AddCourse
