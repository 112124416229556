import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

const BootcampTable = ({ attendees, loading }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })

  const COLUMN = [
    {
      title: <span className='font-bold'>S/N</span>,
      dataIndex: 'sn',
      key: 'sn',
      render: sn => <span>{sn}</span>
    },
    {
      title: <span className='font-bold'>Email</span>,
      dataIndex: 'email',
      key: 'email',
      render: email => <span>{email}</span>,
      sorter: (a, b) => a.email - b.email
    },
    {
      title: <span className='font-bold'>Fullname</span>,
      key: 'fullName',
      dataIndex: 'fullName',
      render: fullName => <span>{fullName}</span>,
      sorter: (a, b) => a.fullName - b.fullName
    },
    {
      title: <span className='font-bold'>Phone No</span>,
      key: 'phone',
      dataIndex: 'phone',
      render: phone => <span>{phone}</span>,
      sorter: (a, b) => a.phone - b.phone
    },
    {
      title: <span className='font-bold'>Country</span>,
      key: 'country',
      dataIndex: 'country',
      render: country => <span>{country}</span>,
      sorter: (a, b) => a.country - b.country
    },
    {
      title: <span className='font-bold'>Track</span>,
      key: 'track',
      dataIndex: 'track',
      render: track => <span>{track}</span>,
      sorter: (a, b) => a.track - b.track
    },
    {
      title: <span className='font-bold'>Laptop?</span>,
      key: 'doYouHaveAlaptop',
      dataIndex: 'doYouHaveAlaptop',
      render: doYouHaveAlaptop => <span>{doYouHaveAlaptop}</span>,
      sorter: (a, b) => a.doYouHaveAlaptop - b.doYouHaveAlaptop
    },
    {
      title: <span className='font-bold'>Reason for Choice</span>,
      key: 'whyLearnTheCourse',
      dataIndex: 'whyLearnTheCourse',
      render: whyLearnTheCourse => <span>{whyLearnTheCourse}</span>,
      sorter: (a, b) => a.whyLearnTheCourse - b.whyLearnTheCourse
    },
    {
      title: <span className='font-bold'>Source</span>,
      key: 'source',
      dataIndex: 'source',
      render: source => <span>{source}</span>,
      sorter: (a, b) => a.source - b.source
    },
    {
      title: <span className='font-bold'>Payment</span>,
      key: 'payment',
      dataIndex: 'payment',
      render: payment => <span>{payment ? payment.status : 'Not Paid'}</span>,
      sorter: (a, b) => a.payment - b.payment
    },
    {
      title: <span className='font-bold'>Tx Ref</span>,
      key: 'payment',
      dataIndex: 'payment',
      render: payment => <span>{payment ? payment.txRef : 'Not Paid'}</span>,
      sorter: (a, b) => a.payment - b.payment
    },
    {
      title: <span className='font-bold'>Clicked on Bank Transfer?</span>,
      key: 'transferOption',
      dataIndex: 'transferOption',
      render: transferOption => <span>{transferOption ? 'Yes' : 'No'}</span>,
      sorter: (a, b) => a.transferOption - b.transferOption
    }
  ]
  useEffect(() => {
    setData(attendees)
  }, [attendees])

  return (
    <div className='bg-white shadow'>
      <div className='px-4 overflow-x-auto mb-16'>
        <Table
          columns={COLUMN}
          onChange={value => setPagination(value)}
          pagination={pagination}
          loading={loading}
          dataSource={data}
        />
      </div>
    </div>
  )
}
BootcampTable.propTypes = { attendees: PropTypes.any, loading: PropTypes.bool }
export default BootcampTable
